import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import moment from "moment";

import { FormattedMessage } from "react-intl";
// import AuthContext from "./../../context/auth";

const GraphReceita = ({ dataFilter }) => {
  const [loadingList, setLoadingList] = useState(true);

  // const { user } = useContext(AuthContext);

  const [labels, setLabels] = useState([]);
  const [graphRealizados, setGraphRealizados] = useState([]);
  const [graphAprovados, setGraphAprovados] = useState([]);

  let loadGraph = {
    series: [
      {
        name: "Volume Não Vendido",
        data: graphRealizados,
      },
      {
        name: "Volume Vendido",
        data: graphAprovados,
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#c02c31", "#030894"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString('pt-BR')
        },
        textAnchor: 'middle',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          opacity: 0.9,

        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString('pt-BR')
          }
        }
      },
      labels: labels,
      tooltip: {
        y: {
          formatter: function (val) {
            return (parseFloat(val)).toLocaleString('pt-BR') + " Litros"
          }
        }
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const dataRealizados = [];
        const dataAprovados = [];
        const labels = [];

        const { data: response } = await api.get(
          `/dashboard-distribuidor/vendido-naovendido`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter
                ?.replaceAll(".", "")
                ?.replaceAll("/", "")
                ?.replaceAll("-", "")
                ?.replaceAll("_", ""),
              grupo_economico: dataFilter?.grupoEconomicoFilter,
              base_distribuicao_id: dataFilter?.bases?.value,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
              // cadastro_cliente_id: user.cadastro_id
            },
          }
        );


        Object.values(response.nao_vendido).map((item) => {
          const obj = {};

          Object.assign(obj, {
            dataRealizados: dataRealizados.push(item.total_litro),
          });

          return obj;
        });

        Object.values(response.vendido).map((item) => {
          const obj2 = {};
          const date = item.data_group.split("-");

          Object.assign(obj2, {
            dataAprovados: dataAprovados.push(item.total_litro),
            labels: labels.push(
              format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
                locale: ptBR,
              })
            ),
          });

          return obj2;
        });

        setGraphRealizados(dataRealizados);
        setGraphAprovados(dataAprovados);
        setLabels(labels);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="volumeVendidoENaoVendidoDistribuidor" />
            </PanelHeader>
            <PanelBody className="p-0">
              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="bar"
                width="100%"
                height="300px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default GraphReceita;
