import React, { useContext, useState, useEffect } from "react";
import { withRouter, useLocation, Redirect } from "react-router-dom";
import AuthContext from "./../context/auth";

import ScreenLoading from "./../components/Spinner/Screen";
import api from "./../services/api";
import AppRoutes from "./app.routes";
import AuthRoutes from "./auth.routes";

const Routes = () => {
  const { signed, loading } = useContext(AuthContext);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [urlError, setUrlError] = useState(null);
  const history = useLocation();
  const location = useLocation();


  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        setIsAuthorized(false);
        if (error?.response?.status === 401) {
          setUrlError(error.response.config.url);
          setIsAuthorized(true);
          //  return <Link to="/NotAuthorized" />;
        }

        return Promise.reject(error);
      }
    );
  }, [history.pathname]);

  if (loading) {
    return <ScreenLoading />;
  }

  return (
    <>
      {signed ? (
        <>
          <AppRoutes />
          {isAuthorized && (
            <Redirect to={{ pathname: "/NotAuthorized", state: urlError }} />
          )}
          {history.pathname === "/signin" && <Redirect to="/home" />}
          {history.pathname === "/" && <Redirect to="/home" />}
        </>
      ) : (
        <>
          <AuthRoutes />
          {(history.pathname === "/" ||
            (history.pathname === location.pathname &&
              history.pathname !== "/set-email-recovery")) && (
            <Redirect to="/signin" />
          )}
        </>
      )}
    </>
  );
};

export default withRouter(Routes);
