import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import { Container, CreateForm, CreateFooter, Image } from "./styles";

import ListSelect from "../../../components/ListSelect";
import InputLabel from "../../../components/InputLabel";
import Button from "../../../components/ButtonDefault";
import api from "../../../services/api";

export const DisplayProduct = ({ intl }) => {
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [tipoUnitario, setTipoUnitario] = useState("");
  const [ncm, setNcm] = useState("");
  const [marca, setMarca] = useState("");
  const [categoria, setCategoria] = useState("");
  const [codigo, setCodigo] = useState("");
  const [file, setFile] = useState("");
  const history = useHistory();
  const [status, setStatus] = useState();

  useEffect(() => {
    if (!history.location.state?.id) {
      return history.push("/register/graal/products-fuels/list");
    }

    var id = history.location.state?.id;

    async function loadProduct() {
      const { data: response } = await api.get(`/produto/${id}`);

      setNome(response?.nome);
      setCodigo(response?.codigo);
      setDescricao(response?.descricao);

      const formattedNCM = response?.ncm.map((n) => n?.codigo);
      setNcm(formattedNCM);

      setTipoUnitario(response?.tipo_unitario?.descricao);
      setMarca(response?.marca?.nome );
      setCategoria( response?.categoria?.nome);

      setStatus(history.location.state?.status === 1 ? "Ativo" : "Inativo");
      setFile(response?.imagem);

    };

    loadProduct();


  }, [history]);


  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirProduto" />
          </h4>
        </header>

        <form>
          <InputLabel
            name="nome"
            type="text"
            label={<FormattedMessage id="nome" />}
            value={nome}
            readOnly

          />

          <InputLabel
            name="descricao"
            type="text"
            label={<FormattedMessage id="descricao" />}
            value={descricao}
            readOnly
          />

          <InputLabel
            value={tipoUnitario}
            label={<FormattedMessage id="tipoUnitario" />}
            readOnly
          />

          <InputLabel
            value={marca}
            label={<FormattedMessage id="marca" />}
            readOnly
          />

          <InputLabel
            value={categoria}
            label={<FormattedMessage id="categoria" />}
            readOnly
          />

          <InputLabel
            name="codigo"
            type="text"
            label={"Código de Barras"}
            value={codigo}
            readOnly
          />

          <ListSelect
            isDisabled={true}
            labelPlaceholder={"Status"}
            value={{ value: status, label: status }}
          />

          <Image>
          <label>
            <FormattedMessage id="imagem" />:
          </label>
          <div>
            <img src={file} alt="file" />
          </div>
        </Image>
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayProduct);
