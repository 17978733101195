import React, { useCallback, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import AuthContext from "./../../../context/auth";
import MessageDefault from "./../../../components/MessageDefault";
import { MessageError } from "../../../components/InputLabel/styles";
import { format, parseISO } from "date-fns";

import { searchCep } from "./../../../services/cep";

import { FiPlus, FiTrash2, FiAlertCircle } from "react-icons/fi";
import { BsQuestion } from "react-icons/bs";

import api from "./../../../services/api";

import MaskInput from "./../../../config/input-mask";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";

import {
  Container,
  CreateForm,
  CreateFooter,
  TabStyled,
  FormTax,
  TitleTax,
  HeaderTax,
  FormConsumer,
  FormEndereco
} from "./styles";

import InputMaskDefault from "./../../../components/InputMaskDefault";
import InputLabel from "./../../../components/InputLabel";
import TextAreaLabel from "./../../../components/TextAreaLabel";
import Button from "./../../../components/ButtonDefault";
import ButtonIcon from "./../../../components/ButtonIcon";
import CheckboxDefault from "./../../../components/CheckboxDefault";
import LoadingContent from "./../../../components/Spinner/Content";

import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

export const EditCustomer = ({ idCadastro, intl }) => {
  const [showContent, setShowContent] = useState(true);

  // Cliente = 3, Distribuidor = 2, Sodexo = 1
  const [tipoCadastro] = useState(3);

  const [permissions, setPermissions] = useState("");
  const [idCertificado] = useState(() => idCadastro);

  // Dados Cadastrais
  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [dataCadastro, setDataCadastro] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [registroANP, setRegistroANP] = useState("");
  const [grupoEconomico, setGrupoEconomico] = useState("");
  const [perfil, setPerfil] = useState(null);
  const [matriz, setMatriz] = useState(0);
  const [empresaMatriz, setEmpresaMatriz] = useState(0);
  const [classificacao, setClassificacao] = useState(null);
  const [contato, setContato] = useState("");
  const [email, setEmail] = useState("");
  const [contatoDois, setContatoDois] = useState("");
  const [emailDois, setEmailDois] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [celularDois, setCelularDois] = useState("");
  //const [listaMesaOperacao, setListaMesaOperacao] = useState("");
  const [mesaOperacao, setMesaOperacao] = useState("");
  const [status, setStatus] = useState({});
  const [farmer, setFarmer] = useState("");
  const [hunter, setHunter] = useState("");

  // Dados de endereços
  const [enderecoId1, setEnderecoId1] = useState("");
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [cidadeFilter, setCidadeFilter] = useState("");

  const [enderecoId2, setEnderecoId2] = useState("");
  const [cep2, setCep2] = useState("");
  const [logradouro2, setLogradouro2] = useState("");
  const [numero2, setNumero2] = useState("");
  const [complemento2, setComplemento2] = useState("");
  const [bairro2, setBairro2] = useState("");
  const [estado2, setEstado2] = useState("");
  const [cidade2, setCidade2] = useState("");
  const [basesDistribuicao2, setBaseDistribuicao2] = useState([
    { value: "selecione", label: <FormattedMessage id="selecione" /> },
  ]);

  const [enderecoId3, setEnderecoId3] = useState("");
  const [cep3, setCep3] = useState("");
  const [logradouro3, setLogradouro3] = useState("");
  const [numero3, setNumero3] = useState("");
  const [complemento3, setComplemento3] = useState("");
  const [bairro3, setBairro3] = useState("");
  const [estado3, setEstado3] = useState("");
  const [cidade3, setCidade3] = useState("");
  const [basesDistribuicao3, setBaseDistribuicao3] = useState([
    { value: "selecione", label: <FormattedMessage id="selecione" /> },
  ]);

  // Dados de Entrega
  const [modalidadeTransporte, setModalidadeTransporte] = useState("");
  const [tanque, setTanque] = useState("");
  const [modoAbastecimento, setModoAbastecimento] = useState("");
  const [tipoRecebimento, setTipoRecibimento] = useState("");
  const [observacoes, setObservacoes] = useState("");

  //Dados Cobrança
  const [limiteCredito, setLimiteCredito] = useState("");
  const [toleranciaMaxima, setToleranciaMaxima] = useState("");
  const [saldoCredito, setSaldoCredito] = useState("");
  const [saldoCreditoEmissor, setSaldoCreditoEmissor] = useState("");
  const [prazoPagamento, setPrazoPagamento] = useState("");
  const [taxaAdm, setTaxaAdm] = useState("");
  const [taxaFinanceira, setTaxaFinanceira] = useState([
    {
      id: "",
      taxa_financeira_de: "",
      taxa_financeira_ate: "",
      taxa_financeira_percentual: "",
    },
  ]);

  //Consumo e Capacidade
  const [produtos, setProdutos] = useState([]);

  //Certificado digital
  const [nomeCertificado, setNomeCertificado] = useState("");
  const [certificado, setCertificado] = useState("");
  const [validade, setValidade] = useState("");
  const [senha, setSenha] = useState("");

  // Bases de distribuição
  const [listaCidades, setListaCidades] = useState("cidade");
  const [listaCidades2, setListaCidades2] = useState("cidade");
  const [listaCidades3, setListaCidades3] = useState("cidade");
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });
  const [preferenciaDist, setPreferenciaDist] = useState("");

  const [basesDistribuicao, setBaseDistribuicao] = useState([
    { value: "selecione", label: <FormattedMessage id="selecione" /> },
  ]);

  // Versão
  const [versao, setVersao] = useState("");
  const [dataVersao, setDataVersao] = useState([
    {
      data: "",
      de: "",
      para: "",
    },
  ]);

  // Bloqueio disribuidor
  const [distribuidores, setDistribuidores] = useState([
    { value: "selecione", label: <FormattedMessage id="selecione" /> },
  ]);

  const { user } = useContext(AuthContext);

  const [loadingButton, setLoadingButton] = useState(false);

  const [errors, setErrors] = useState();

  const history = useHistory();

  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const carregandoPlaceholder = intl.formatMessage({ id: "carregando" });
  const registroPlaceholder = intl.formatMessage({ id: "nenhumRegistroEncontrado" });

  //Dados Cadastrais
  const razaoSocialPlaceholder = intl.formatMessage({ id: "razaoSocial" });
  const nomeFantasiaPlaceholder = intl.formatMessage({ id: "nomeFantasia" });
  const inscricaoEstadualPlaceholder = intl.formatMessage({
    id: "inscricaoEstadual",
  });
  const registroANPPlaceholder = intl.formatMessage({ id: "registroANP" });
  const grupoEconomicoPlaceholder = intl.formatMessage({
    id: "grupoEconomico",
  });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });
  const matrizPlaceholder = intl.formatMessage({ id: "matriz" });
  const empresaMatrizPlaceholder = intl.formatMessage({ id: "empresaMatriz" });
  const classificacaoPlaceholder = intl.formatMessage({ id: "classificacao" });
  const contatoPlaceholder = intl.formatMessage({ id: "contato" });
  const telefonePlaceholder = intl.formatMessage({ id: "telefone" });
  const celularPlaceholder = intl.formatMessage({ id: "celular" });
  const mesaOperacaoPlaceholder = intl.formatMessage({ id: "mesaOperacao" });

  // Dados endereço
  const cepPlaceholder = intl.formatMessage({ id: "cep" });
  const logradouroPlaceholder = intl.formatMessage({ id: "logradouro" });
  const numeroPlaceholder = intl.formatMessage({ id: "numero" });
  const complementoPlaceholder = intl.formatMessage({ id: "complemento" });
  const bairroPlaceholder = intl.formatMessage({ id: "bairro" });

  //Dados Entrega
  const modalidadeTransportePlaceholder = intl.formatMessage({
    id: "modalidadeTransporte",
  });
  const tanquesPropriosTransportePlaceholder = intl.formatMessage({
    id: "tanquesProprios",
  });
  const modoAbastecimentoPlaceholder = intl.formatMessage({
    id: "modoAbastecimento",
  });
  const tipoRecebimentoPlaceholder = intl.formatMessage({
    id: "tipoRecebimento",
  });
  const distribuidorPlaceholder = intl.formatMessage({
    id: "distribuidor",
  });
  const observacoesPlaceholder = intl.formatMessage({
    id: "observacoes",
  });

  // Dados Cobraça

  const limiteCreditoDisponivelPlaceholder = intl.formatMessage({
    id: "limiteCreditoDisponivel",
  });
  const toleranciaMaximaPlaceholder = intl.formatMessage({
    id: "toleranciaMaxima",
  });
  const saldoCreditoDisponivelPlaceholder = intl.formatMessage({
    id: "saldoCreditoDisponivel",
  });
  const prazoPagamentoPlaceholder = intl.formatMessage({
    id: "prazoPagamento",
  });
  const taxaAdministrativaPlaceholder = intl.formatMessage({
    id: "taxaAdministrativa",
  });
  const taxaFinanceiraPlaceholder = intl.formatMessage({
    id: "taxaFinanceira",
  });
  const dePlaceholder = intl.formatMessage({ id: "de" });
  const atePlaceholder = intl.formatMessage({ id: "ate" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const profileOptions = [
    { value: "Privado", label: <FormattedMessage id="privado" /> },
    { value: "Público", label: <FormattedMessage id="publico" /> },
  ];

  const YesNoOptions = [
    { value: 1, label: <FormattedMessage id="opcaoSim" /> },
    { value: 0, label: <FormattedMessage id="opcaoNao" /> },
  ];

  const modalidadeTransporteOptions = [
    { value: "CIF (Entrega)", label: "CIF (Entrega)" },
    { value: "FOB (Retira)", label: "FOB (Retira)" },
  ];

  const abastecimentoOptions = [
    { value: "Bomba", label: "Com Bomba" },
    { value: "Descarga", label: "Sem Bomba" },
  ];

  const versaoOptions = [
    { value: "v1", label: "Graal V1 – Modelo Reativo" },
    { value: "v2", label: "Graal V2 – Modelo Proativo" },
  ];

  useEffect(() => {
    // return
    if (!history.location?.state && !idCadastro) {
      return history.push("/register/graal/customers/list");
    }

    async function loadCustomer() {
      const id = history.location?.state || idCadastro;
      let todosProdutos = [];
      async function loadProducts() {
        try {
          const { data: response } = await api.get("/produto");

          const formattedList = response.data.map((item) => {
            const obj = {};

            Object.assign(obj, {
              produto_id: item.id,
              codigo: item.codigo,
              descricao: item.descricao,
              capacidade_tanque: "",
              produto: item,
              volume_compras_mensal: "",
            });

            return obj;
          });

          todosProdutos = formattedList;
        } catch (error) {}
      }

      loadProducts();

      try {
        const { data: response } = await api.get(`/cadastro/${id}`);

        function formatDate(date) {
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2)
              month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          return [day, month, year].join('/');

      }

        setDataCadastro(formatDate(response.data_cadastro))

        setNomeCertificado(response.certificado_digital?.nome_certificado);
        setSenha(response.certificado_digital?.senha);
        setCertificado(response.certificado_digital);
        setEmpresaMatriz(() => {
          if (response.empresa_matriz) {
            return {
              value: response.empresa_matriz?.id,
              label: response.cpf_cnpj + " - " + response.razao_social,
            };
          }
        });

        setCnpj(response.cpf_cnpj);

        setRazaoSocial(response.razao_social);
        setNomeFantasia(response.fantasia);
        setInscricaoEstadual(response.inscricao_estadual);
        setRegistroANP(response.registro_amp);
        setGrupoEconomico(response.grupo_economico);
        setPerfil(
          response.perfil === "Privado"
            ? { value: "Privado", label: <FormattedMessage id="privado" /> }
            : { value: "Público", label: <FormattedMessage id="publico" /> }
        );
        setMatriz(
          response.matriz === 1
            ? { value: 1, label: <FormattedMessage id="opcaoSim" /> }
            : { value: 0, label: <FormattedMessage id="opcaoNao" /> }
        );

        setContato(response.contato);
        setEmail(response.email);

        setContatoDois(response.contato_2);
        setEmailDois(response.contato_email_2);

        setClassificacao({
          value: response.classificacao_cliente?.id,
          label: response.classificacao_cliente?.descricao,
        });
        setTelefone(response.telefone);
        setCelular(response.celular);
        setCelularDois(response.cel_contato_2);
        setMesaOperacao({
          value: response.mesa_operacao?.id,
          label: response.mesa_operacao?.descricao,
        });

        setFarmer({
          value: response?.farmer?.id,
          label: response?.farmer?.descricao,
        });

        setHunter({
          value: response?.hunter?.id,
          label:  response?.hunter?.descricao,
        });

        setStatus(
          parseInt(response?.ativo) === 1
            ? {
                value: 1,
                label: <FormattedMessage id="ativo" />,
              }
            : {
                value: 0,
                label: <FormattedMessage id="inativo" />,
              }
        );

        // Dados de Endereço 1
      setEnderecoId1(response.enderecos[0]?.id || "");
      setCep(response.enderecos[0]?.cep || "");
      setLogradouro(response.enderecos[0]?.logradouro || "");
      setNumero(response.enderecos[0]?.numero || "");
      setComplemento(response.enderecos[0]?.complemento || "");
      setBairro(response.enderecos[0]?.bairro || "");

      setEstado({
        value: response.enderecos[0]?.estado?.id,
        label: response.enderecos[0]?.estado?.sigla,
      });
      setCidade({
        value: response.enderecos[0]?.municipio?.id,
        label: response.enderecos[0]?.municipio?.nome,
      });

       // Dados de Endereço 2
       setEnderecoId2(response.enderecos[1]?.id || "");
       setCep2(response.enderecos[1]?.cep || "");
       setLogradouro2(response.enderecos[1]?.logradouro || "");
       setNumero2(response.enderecos[1]?.numero || "");
       setComplemento2(response.enderecos[1]?.complemento || "");
       setBairro2(response.enderecos[1]?.bairro || "");

       setEstado2({
         value: response.enderecos[1]?.estado?.id,
         label: response.enderecos[1]?.estado?.sigla,
       });
       setCidade2({
         value: response.enderecos[1]?.municipio?.id,
         label: response.enderecos[1]?.municipio?.nome,
       });

       if (response.enderecos[1]?.bases_distribuicoes) {
        const formattedBases2 = response.enderecos[1]?.bases_distribuicoes.map((base) => ({
          label: base.nome,
          value: base.id,
        }));

        setBaseDistribuicao2(formattedBases2);
      }

      // Dados de Endereço 3
      setEnderecoId3(response.enderecos[2]?.id || "");
      setCep3(response.enderecos[2]?.cep || "");
      setLogradouro3(response.enderecos[2]?.logradouro || "");
      setNumero3(response.enderecos[2]?.numero || "");
      setComplemento3(response.enderecos[2]?.complemento || "");
      setBairro3(response.enderecos[2]?.bairro || "");

      setEstado3({
        value: response.enderecos[2]?.estado?.id,
        label: response.enderecos[2]?.estado?.sigla,
      });
      setCidade3({
        value: response.enderecos[2]?.municipio?.id,
        label: response.enderecos[2]?.municipio?.nome,
      });
      if (response.enderecos[2]?.bases_distribuicoes) {
        const formattedBases3 = response.enderecos[2]?.bases_distribuicoes.map((base) => ({
          label: base.nome,
          value: base.id,
        }));

        setBaseDistribuicao3(formattedBases3);
      }
      ///////////////////////

        setModalidadeTransporte({
          value: response.dados_entrega?.modalidade_transporte,
          label: response.dados_entrega?.modalidade_transporte,
        });

        setTanque(
          response.dados_entrega?.tanques_proprios === 1
            ? {
                value: 1,
                label: <FormattedMessage id="opcaoSim" />,
              }
            : {
                value: 0,
                label: <FormattedMessage id="opcaoNao" />,
              }
        );

        if (response.dados_entrega?.modo_abastecimento === "Bomba") {
          setModoAbastecimento({
            value: response.dados_entrega?.modo_abastecimento,
            label: "Com Bomba",
          });
        } else {
          setModoAbastecimento({
            value: response.dados_entrega?.modo_abastecimento,
            label: "Sem Bomba",
          });
        }

        setTipoRecibimento(response.dados_entrega?.tipo_recebimento || "");
        setObservacoes(response.dados_entrega?.observacao || "");

        // // Dados Cobrança

        setLimiteCredito(
          response.dados_cobranca?.limite_credito !== null
            ? response.dados_cobranca?.limite_credito.replace(".", ",") || ""
            : ""
        );

        setToleranciaMaxima(
          response.dados_cobranca?.tolerancia_maxima !== null
            ? response.dados_cobranca?.tolerancia_maxima.replace(".", ",") || ""
            : ""
        );

        setSaldoCredito(
          response.dados_cobranca?.saldo_credito_total !== null
            ? response.dados_cobranca?.saldo_credito_total.replace(".", ",") ||
                ""
            : ""
        );

        setSaldoCreditoEmissor(
          response.dados_cobranca?.saldo_credito !== null
            ? response.dados_cobranca?.saldo_credito.replace(".", ",") || ""
            : ""
        );

        setPrazoPagamento(response.dados_cobranca?.prazo_pagamento || "");

        setTaxaAdm(
          response.dados_cobranca?.taxa_administrativa !== null
            ? response.dados_cobranca?.taxa_administrativa.replace(".", ",") ||
                ""
            : ""
        );

        setTaxaFinanceira(
          response.taxa_financeira?.map((taxa) => {
            return {
              id: taxa.id,
              taxa_financeira_ate: taxa.taxa_financeira_ate,
              taxa_financeira_de: taxa.taxa_financeira_de,
              taxa_financeira_percentual:
                taxa.taxa_financeira_percentual.replace(".", ","),
            };
          }) || []
        );

        //Consumo e Capacidade
        setProdutos(
          response.consumo_capacidade.length !== 0
            ? response.consumo_capacidade.map((prod) => {
                return {
                  produto_id: prod.id,
                  capacidade_tanque: prod?.capacidade_tanque?.replace(".", ","),
                  volume_compras_mensal: prod?.volume_compras_mensal?.replace(
                    ".",
                    ","
                  ),
                  produto: prod.produto,
                };
              })
            : todosProdutos
        );

        setValidade(response.certificado_digital?.validade_certificado || "");

        setPreferenciaDist(
          response.distribuidor_preferencia.length !== 0
            ? {
                value: response.distribuidor_preferencia[0]?.id,
                label:
                  response.distribuidor_preferencia[0]?.cpf_cnpj +
                  " - " +
                  response.distribuidor_preferencia[0]?.razao_social,
              }
            : ""
        );

        if (response?.base_distribuicao) {
          const formattedBases = response?.base_distribuicao.map((base) => ({
            label: base.nome,
            value: base.id,
          }));

          setBaseDistribuicao(formattedBases);
        }

        if (response.versao_sistema === "v1") {
          setVersao({
            value: "v1",
            label: "Graal V1 – Modelo Reativo",
          });
        } else {
          setVersao({
            value: "v2",
            label: "Graal V2 – Modelo Proativo",
          });
        }

        setDataVersao(
          response.cadastro_setting_history?.map((history) => {
            return {
              data: format(
                parseISO(history.created_at),
                "dd/MM/yyyy hh:mm"
              ),
              de: history.versao_de === "v1" ? "Graal V1 – Modelo Reativo" : "Graal V2 – Modelo Proativo",
              para: history.versao_para === "v1" ? "Graal V1 – Modelo Reativo" : "Graal V2 – Modelo Proativo",
            };
          }) || []
        );

        if (response?.bloqueados) {
          const formattedBloqueados = response?.bloqueados.map((bloqueado) => ({
            label: bloqueado.cadastro_bloqueado.cpf_cnpj + " - " + bloqueado.cadastro_bloqueado.razao_social,
            value: bloqueado.cadastro_bloqueado.id,
          }));

          setDistribuidores(formattedBloqueados);
        }


        setShowContent(false);
      } catch (error) {
        setShowContent(false);
        console.log(error);
      }
    }
    loadCustomer();
  }, [history, idCadastro, idCertificado]);

  useEffect(() => {
    const permissoes = user?.permission;

    const objPermissoes = {
      dadosCadastrais: permissoes?.find(
        (item) => item.namePermission === "cadastro-cliente-dadoscadastrais[1]"
      ),
      dadosEnderecos: permissoes?.find(
        (item) => item.namePermission === "cadastro-cliente-dadosendereco[1]"
      ),
      dadosCobranca: permissoes?.find(
        (item) => item.namePermission === "cadastro-cliente-dadoscobranca[1]"
      ),
      dadosBasesDist: permissoes?.find(
        (item) =>
          item.namePermission === "cadastro-cliente-basesdistribuicao[1]"
      ),
      dadosConsumo: permissoes?.find(
        (item) =>
          item.namePermission === "cadastro-cliente-consumocapacidade[1]"
      ),
      dadosEntrega: permissoes?.find(
        (item) => item.namePermission === "cadastro-cliente-dadosentrega[1]"
      ),
      certificado: permissoes?.find(
        (item) =>
          item.namePermission === "cadastro-cliente-certificadodigitalnfe[1]" ||
          item.namePermission === "cadastro-cliente-certificadodigitalnfe[3]"
      ),
    };

    setPermissions(objPermissoes);
  }, [history, preferenciaDist, user]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleSearchCEP = useCallback(async (cep, param) => {
    try {
      const response = await searchCep(cep);

      const { data: estado } = await api.post(`/estado/filter`, {
        sigla: response.uf,
      });

      const { data: cidade } = await api.post(`/municipio/filter`, {
        codigo: response.ibge,
      });

      if (param === 1) {
        setBairro(response.bairro);
        setLogradouro(response.logradouro);
        setListaCidades(estado[0].sigla);
        setEstado({ value: estado[0].id, label: estado[0].sigla });
        setCidade({ value: cidade[0]?.id, label: cidade[0]?.nome });
      }

      if (param === 2) {
        setBairro2(response.bairro);
        setLogradouro2(response.logradouro);
        setListaCidades2(estado[0].sigla);
        setEstado2({ value: estado[0].id, label: estado[0].sigla });
        setCidade2({ value: cidade[0]?.id, label: cidade[0]?.nome });
      }

      if (param === 3) {
        setBairro3(response.bairro);
        setLogradouro3(response.logradouro);
        setListaCidades3(estado[0].sigla);
        setEstado3({ value: estado[0].id, label: estado[0].sigla });
        setCidade3({ value: cidade[0]?.id, label: cidade[0]?.nome });
      }

    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleLoadUF = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado`);

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleFilterCitiesByUf = useCallback(
    async (id) => {
      if (!id) {
        return;
      }

      try {
        const { data } = await api.post(`/municipio/filter`, {
          estado: id,
          nome: cidadeFilter,
        });

        const arr = data.map((municipio) => {
          const obj = {};

          Object.assign(obj, {
            value: String(municipio.id),
            label: municipio.nome,
          });

          return obj;
        });

        return arr;
      } catch (error) {
        console.log(error);
      }
    },
    [cidadeFilter]
  );

  //Função que cria registro
  const handleEdit = useCallback(async () => {
    setLoadingButton(true);

    const taxaFinanceiraFormmated = taxaFinanceira.map((taxa) => {
      return {
        id: taxa.id,
        taxa_financeira_ate: taxa.taxa_financeira_ate,
        taxa_financeira_de: taxa.taxa_financeira_de,
        taxa_financeira_percentual: taxa.taxa_financeira_percentual
          .replace(".", "")
          .replace(",", "."),
      };
    });

    const produtoFormatted = produtos.map((prod) => {
      if (prod.capacidade_tanque === "") {
        prod.capacidade_tanque = "0";
      }
      if (prod.volume_compras_mensal === "") {
        prod.volume_compras_mensal = "0";
      }
      return {
        produto_id: prod.produto.id,
        capacidade_tanque: prod.capacidade_tanque
          .replaceAll(".", "")
          .replaceAll(",", "."),
        volume_compras_mensal: prod.volume_compras_mensal
          .replaceAll(".", "")
          .replaceAll(",", "."),
      };
    });

    const basesDistribuicaoId = basesDistribuicao.map((base) => {
      return base.value;
    });

    const basesDistribuicaoId2 = basesDistribuicao2.map((base) => {
      return base.value;
    });

    const basesDistribuicaoId3 = basesDistribuicao3.map((base) => {
      return base.value;
    });

    var mesaOperacaoCheck = mesaOperacao?.value;
    if (mesaOperacao?.label === "Sem Registro") {
      mesaOperacaoCheck = null;
    }

    var classificacaoCheck = classificacao?.value;
    if (classificacao?.label === "Sem Registro") {
      classificacaoCheck = null;
    }

    let estadoCheck = estado?.value;
    if (estado?.label === "0") {
      estadoCheck = null;
    }

    let cidadeCheck = cidade?.value;
    if (cidade?.label === "Sem Registro") {
      cidadeCheck = null;
    }

    let estadoCheck2 = estado2?.value;
    if (estado2?.label === "0") {
      estadoCheck2 = null;
    }

    let cidadeCheck2 = cidade2?.value;
    if (cidade2?.label === "Sem Registro") {
      cidadeCheck2 = null;
    }

    let estadoCheck3 = estado3?.value;
    if (estado3?.label === "0") {
      estadoCheck3 = null;
    }

    let cidadeCheck3 = cidade3?.value;
    if (cidade3?.label === "Sem Registro") {
      cidadeCheck3 = null;
    }

    let listEnderecos = []
    if (cep !== ""){
      listEnderecos.push(
        {
          id: enderecoId1,
          cep,
          logradouro,
          numero: numero,
          bairro,
          complemento,
          estado_id: estadoCheck,
          municipio_id: cidadeCheck,
          pais_id: 1,
          base_distribuicao:
          basesDistribuicao[0]?.value === "selecione" ? [] : basesDistribuicaoId,
        }
      )
    }

    if (cep2 !== ""){
      listEnderecos.push(
        {
          id: enderecoId2,
          cep: cep2,
          logradouro: logradouro2,
          numero: numero2,
          bairro: bairro2,
          complemento: complemento2,
          estado_id: estadoCheck2,
          municipio_id: cidadeCheck2,
          pais_id: 1,
          base_distribuicao:
          basesDistribuicao2[0]?.value === "selecione" ? [] : basesDistribuicaoId2,
        },
      )
    }

    if (cep3 !== ""){
      listEnderecos.push(
        {
          id: enderecoId3,
          cep: cep3,
          logradouro: logradouro3,
          numero: numero3,
          bairro: bairro3,
          complemento: complemento3,
          estado_id: estadoCheck3,
          municipio_id: cidadeCheck3,
          pais_id: 1,
          base_distribuicao:
          basesDistribuicaoId3[0]?.value === "selecione" ? [] : basesDistribuicaoId3,
        },
      )
    }

    const distribuidorFormmated = distribuidores.map((distribuidor) => {
      return {
        cadastro_bloqueado_id: distribuidor.value,
      };
    });

    const data = {
      dados: {
        ativo: status?.value,
        cadastro_tipo_id: tipoCadastro,
        cpf_cnpj: cnpj,
        razao_social: razaoSocial,
        fantasia: nomeFantasia,
        telefone: telefone?.replace(/[^\d]+/g, ""),
        celular: celular?.replace(/[^\d]+/g, ""),
        cel_contato_2: celularDois?.replace(/[^\d]+/g, ""),
        grupo_economico: grupoEconomico,
        perfil: perfil?.value,
        matriz: matriz?.value,
        empresa_matriz_id: empresaMatriz?.value,
        inscricao_estadual: inscricaoEstadual,
        registro_amp: registroANP,
        email,
        contato,
        contato_2: contatoDois,
        contato_email_2: emailDois,
        tabela_mesa_operacao_id: mesaOperacaoCheck,
        tabela_classificacao_distribuidor_id: 1,
        tabela_classificacao_clientes_id: classificacaoCheck,
        consultor_id: 1,
        farmer_id: farmer?.value,
        hunter_id: hunter?.value,
        distribuidor_preferencia_id: preferenciaDist?.value || "0",
      },
      enderecos: listEnderecos,
      dados_entrega: {
        modalidade_transporte: modalidadeTransporte?.value,
        tanques_proprios: tanque?.value,
        modo_abastecimento: modoAbastecimento?.value,
        tipo_recebimento: tipoRecebimento,
        observacao: observacoes,
      },
      dados_cobranca: {
        prazo_pagamento: prazoPagamento,
        taxa_administrativa: taxaAdm.replace(".", "").replace(",", "."),
      },
      taxa_financeira: taxaFinanceiraFormmated,
      consumo_capacidade: produtoFormatted,
      //ENVIAR BASE
      base_distribuicao:
        basesDistribuicao[0]?.value === "selecione" ? [] : basesDistribuicaoId,
      bloqueados: distribuidorFormmated
    };

    if (certificado) {
      Object.assign(data, {
        certificado_digital: {
          nome_certificado: nomeCertificado,
          validade_certificado: "2020/10/10",
          senha,
          certificado_digital: certificado?.certificado_digital || certificado,
        },
      });
    }

    try {
      const id = history.location?.state || idCertificado;

      await api.put(`/cadastro/${id}`, data);

      try {
        await api.put("/cadastro-settings", {
          ids: [history.location?.state],
          versao_sistema: versao?.value
        });
      } catch (error) {
        toast.error("Erro ao alterar versão.");
      }

      setLoadingButton(false);
      setErrors("");
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);
      history.goBack();
    } catch (error) {
      console.log(error);
      setErrors(getErrorsApiValidation(error));

      setLoadingButton(false);

      if (user?.cadastro_tipo_id === 1) {
        return toast.error(<FormattedMessage id="erroEditarRegistro" />);
      } else {
        return toast.error(<FormattedMessage id="erroCadastroIncompleto" />);
      }
    }

    setErrors(null);
  }, [
    basesDistribuicao,
    bairro,
    celular,
    celularDois,
    cep,
    certificado,
    cidade,
    classificacao,
    cnpj,
    complemento,
    contato,
    email,
    contatoDois,
    emailDois,
    empresaMatriz,
    estado,
    grupoEconomico,
    history,
    idCertificado,
    inscricaoEstadual,
    logradouro,
    matriz,
    mesaOperacao,
    modalidadeTransporte.value,
    modoAbastecimento,
    nomeCertificado,
    nomeFantasia,
    numero,
    observacoes,
    perfil,
    prazoPagamento,
    preferenciaDist,
    produtos,
    razaoSocial,
    registroANP,
    senha,
    status,
    tanque,
    taxaAdm,
    taxaFinanceira,
    telefone,
    tipoCadastro,
    tipoRecebimento,
    user.cadastro_tipo_id,
    farmer,
    hunter,
    versao,
    cep2,
    logradouro2,
    numero2,
    bairro2,
    complemento2,
    cidade2,
    estado2,
    cep3,
    logradouro3,
    numero3,
    bairro3,
    complemento3,
    cidade3,
    estado3,
    errors,
    distribuidores,
    basesDistribuicao2,
    basesDistribuicao3
  ]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleAddTaxInput = useCallback(() => {
    setTaxaFinanceira([
      ...taxaFinanceira,

      {
        id: "",
        taxa_financeira_de: "",
        taxa_financeira_ate: "",
        taxa_financeira_percentual: "",
      },
    ]);
  }, [taxaFinanceira]);

  const handleChangeInputTaxaFinanceiraDe = useCallback(
    (e, index) => {
      taxaFinanceira[index].taxa_financeira_de = e.target.value.replace(
        /[^\d]+/g,
        ""
      );
      setTaxaFinanceira([...taxaFinanceira]);
    },
    [taxaFinanceira]
  );

  const handleChangeInputTaxaFinanceiraAte = useCallback(
    (e, index) => {
      taxaFinanceira[index].taxa_financeira_ate = e.target.value.replace(
        /[^\d]+/g,
        ""
      );
      setTaxaFinanceira([...taxaFinanceira]);
    },
    [taxaFinanceira]
  );

  const handleChangeInputTaxaFinanceiraPercent = useCallback(
    (e, index) => {
      taxaFinanceira[index].taxa_financeira_percentual = e.target.value;
      setTaxaFinanceira([...taxaFinanceira]);
    },
    [taxaFinanceira]
  );

  const handleRemoveInputTaxaFinanceira = useCallback(
    (position) => {
      setTaxaFinanceira([
        ...taxaFinanceira.filter((_, index) => {
          return index !== position;
        }),
      ]);
    },
    [taxaFinanceira]
  );

  const handleChangeInputCapacidadeTanque = useCallback(
    (e, index) => {
      produtos[index].capacidade_tanque = e.target.value;

      setProdutos([...produtos]);
    },
    [produtos]
  );

  const handleChangeInputVolumeCompraMensal = useCallback(
    (e, index) => {
      produtos[index].volume_compras_mensal = e.target.value;

      setProdutos([...produtos]);
    },
    [produtos]
  );

  const loadBases = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.post(
        `/tabela-base-distribuicao-lista`,
        {
          nome: inputValue,
          ativo: 1
        }
      );

      const basesFormatted = response.map((bases) => {
        const objeto = {
          value: bases.id,
          label: bases.nome,
        };

        return objeto;
      });

      return basesFormatted;
  } catch (error) {}
}, []);

const handleChangeBase = useCallback((item, param) => {
  if (!item || item?.length === 0) {

    if(param === 1){
      setBaseDistribuicao([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    }

    if(param === 2){
      setBaseDistribuicao2([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    }

    if(param === 3){
      setBaseDistribuicao3([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    }

  } else {
      const basesDistribuicao = item.filter(
        (base) => base.value !== "selecione"
      );

      if(param === 1){
        setBaseDistribuicao(basesDistribuicao);
      }

      if(param === 2){
        setBaseDistribuicao2(basesDistribuicao);
      }

      if(param === 3){
        setBaseDistribuicao3(basesDistribuicao);
      }
  }
}, []);

  const handleFilterBasesByPreferenciaDist = useCallback(async (id) => {
    try {
      if (!id) {
        setBaseDistribuicao([
          { value: "selecione", label: <FormattedMessage id="selecione" /> },
        ]);

      } else {
        const { data: response } = await api.post(`/cadastro/filter`, {
          id: id,
        });

        const arr = response.data[0].base_distribuicao.map((municipio) => {
          const obj = {};

          Object.assign(obj, {
            value: String(municipio.id),
            label: municipio.nome,
          });

          return obj;
        });

        setBaseDistribuicao(arr);
      }

    } catch (error) {}
  }, []);

  const handleChangeDistribuidor = useCallback((item) => {
    if (!item || item?.length === 0) {
      setDistribuidores([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    } else {
        const distribuidores = item.filter(
          (distribuidor) => distribuidor.value !== "selecione"
        );

        setDistribuidores(distribuidores);
    }
  }, []);

  const handleUploadFile = useCallback(async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const convertFile = await toBase64(file);

    setCertificado(convertFile);
    setNomeCertificado(file.name);
  }, []);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  if (showContent) {
    return <LoadingContent />;
  }

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="editarCliente" />: {cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                )} - {razaoSocial}
          </h4>
          {errors && <ButtonIcon Icon={FiAlertCircle} geterror={errors} />}
        </header>

        {/* const objPermissoes = {
        dadosCadastrais: permissoes?.find(item => item.namePermission === "cadastro-dadoscadastrais[3]"),
        dadosEnderecos: permissoes?.find(item => item.namePermission === "cadastro-dadosendereco[3]"),
        dadosCobranca: permissoes?.find(item => item.namePermission === "cadastro-dadoscobranca[3]"),
        dadosBasesDist: permissoes?.find(item => item.namePermission === "cadastro-basesdistribuicao[3]"),
        dadosConsumo: permissoes?.find(item => item.namePermission === "cadastro-consumocapacidade[3]"),
        dadosEntrega: permissoes?.find(item => item.namePermission === "cadastro-dadosentrega[3]"),
        certificado: permissoes?.find(item => item.namePermission === "cadastro-certificadodigitalnfe[3]"),
      } */}

        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
              permissions.dadosCadastrais) && (
              <TabStyled className={"react-tabs__tab"}>
                <FormattedMessage id="dadosCadastrais" />
                {(errors?.cpf_cnpj ||
                  errors?.razao_social ||
                  errors?.fantasia ||
                  errors?.inscricao_estadual ||
                  errors?.registro_amp ||
                  errors?.grupo_economico ||
                  errors?.perfil ||
                  errors?.matriz ||
                  errors?.tabela_classificacao_clientes_id ||
                  errors?.contato ||
                  errors?.contato_email ||
                  errors?.contato_2 ||
                  errors?.contato_email_2 ||
                  errors?.telefone ||
                  errors?.celular ||
                  errors?.cel_contato_2 ||
                  errors?.tabela_mesa_operacao_id) && (
                  <FiAlertCircle
                    size={15}
                    style={{ marginLeft: "5px", color: "#ff3232" }}
                  />
                )}
              </TabStyled>
            )}

            {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
              permissions.dadosEnderecos) && (
              <TabStyled className={"react-tabs__tab"}>
                <FormattedMessage id="dadosEndereco" />
                {(errors?.cep ||
                  errors?.logradouro ||
                  errors?.numero ||
                  errors?.bairro ||
                  errors?.estado_id ||
                  errors?.municipio_id) && (
                  <FiAlertCircle
                    size={15}
                    style={{ marginLeft: "5px", color: "#ff3232" }}
                  />
                )}
              </TabStyled>
            )}

            {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
              permissions.dadosEntrega) && (
              <TabStyled className={"react-tabs__tab"}>
                <FormattedMessage id="dadosEntrega" />
                {(errors?.modalidade_transporte ||
                  errors?.tanques_proprios ||
                  errors?.modo_abastecimento ||
                  errors?.tipo_recebimento) && (
                  <FiAlertCircle
                    size={15}
                    style={{ marginLeft: "5px", color: "#ff3232" }}
                  />
                )}
              </TabStyled>
            )}

            {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
              permissions.dadosCobranca) && (
              <TabStyled className={"react-tabs__tab"}>
                <FormattedMessage id="dadosCobranca" />
                {errors?.limite_credito ||
                errors?.saldo_credito ||
                errors?.prazo_pagamento ||
                errors?.taxa_administrativa ||
                errors?.taxa_financeira ||
                errors?.taxa_financeira_de ||
                errors?.taxa_financeira_ate ||
                Object?.keys(errors || [])?.filter((elem) => {
                  let arr = elem.split(".");

                  if (arr.length > 1) {
                    return (
                      arr[1] === "taxa_financeira_de" ||
                      arr[1] === "taxa_financeira_ate" ||
                      arr[1] === "taxa_financeira_percentual"
                    );
                  } else {
                    return false;
                  }
                }).length > 1 ? (
                  <FiAlertCircle
                    siz
                    e={15}
                    style={{ marginLeft: "5px", color: "#ff3232" }}
                  />
                ) : (
                  <></>
                )}
              </TabStyled>
            )}

            {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
              permissions.dadosConsumo) && (
              <TabStyled className={"react-tabs__tab"}>
                <FormattedMessage id="consumoCapacidade" />
                {(errors?.capacidade_tanque ||
                  errors?.volume_compras_mensal ||
                  Object?.keys(errors || [])?.filter((elem) => {
                    let arr = elem.split(".");

                    if (arr.length > 1) {
                      return (
                        arr[1] === "capacidade_tanque" ||
                        arr[1] === "volume_compras_mensal"
                      );
                    } else {
                      return false;
                    }
                  }).length > 1) && (
                  <FiAlertCircle
                    size={15}
                    style={{ marginLeft: "5px", color: "#ff3232" }}
                  />
                )}
              </TabStyled>
            )}

            {(user?.tipo_id === 1 || permissions.certificado) && (
              <TabStyled className={"react-tabs__tab"}>
                <FormattedMessage id="certificadoDigital" />
                {errors?.senha && (
                  <FiAlertCircle
                    size={15}
                    style={{ marginLeft: "5px", color: "#ff3232" }}
                  />
                )}
              </TabStyled>
            )}

            {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
              permissions.dadosBasesDist) && (
              <TabStyled className={"react-tabs__tab"}>
                <FormattedMessage id="baseDistribuicao" />
                {errors?.estado_id ||
                  (errors?.["0"] && (
                    <FiAlertCircle
                      size={15}
                      style={{ marginLeft: "5px", color: "#ff3232" }}
                    />
                  ))}
              </TabStyled>
            )}

            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="configuracao" />
            </TabStyled>

            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="bloqueioDistribuidor" />
            </TabStyled>
          </TabList>

          {/* Dados Cadastrais */}
          {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
            permissions.dadosCadastrais) && (
            <TabPanel>
              <form>
                <InputLabel
                  name="cnpj"
                  type="text"
                  label={"CNPJ"}
                  mask={MaskInput.CNPJ}
                  value={cnpj}
                  onChange={(e) => setCnpj(e.target.value)}
                  readOnly
                />

                <InputLabel
                  name="razaoSocial"
                  type="text"
                  label={razaoSocialPlaceholder}
            required={true}
                  maxLength={100}
                  value={razaoSocial}
                  onChange={(e) => setRazaoSocial(e.target.value)}
                  className={"currency4"}
                  geterror={errors?.razao_social}
                />

                <InputLabel
                  name="nomeFantasia"
                  type="text"
                  label={nomeFantasiaPlaceholder}
            required={true}
                  maxLength={80}
                  value={nomeFantasia}
                  onChange={(e) => setNomeFantasia(e.target.value)}
                  geterror={errors?.fantasia}
                />

                <InputLabel
                  name="dataCadastro"
                  type="text"
                  label={"Data Cadastro"}
                  value={dataCadastro}
                  readOnly
                />

                <InputLabel
                  name="inscricaoEstadual"
                  type="text"
                  label={inscricaoEstadualPlaceholder}
                  maxLength={15}
                  value={inscricaoEstadual}
                  onChange={(e) => setInscricaoEstadual(e.target.value)}
                  geterror={errors?.inscricao_estadual}
                />

                <InputLabel
                  name="registroANP"
                  type="text"
                  label={registroANPPlaceholder}
                  maxLength={15}
                  value={registroANP}
                  onChange={(e) => setRegistroANP(e.target.value)}
                />

                <InputLabel
                  name="grupoEconomico"
                  type="text"
                  label={grupoEconomicoPlaceholder}
            required={true}
                  maxLength={50}
                  value={grupoEconomico}
                  onChange={(e) => setGrupoEconomico(e.target.value)}
                  geterror={errors?.grupo_economico}
                />

                <ListSelect
                  labelPlaceholder={perfilPlaceholder}
            required={true}
                  placelholder={<FormattedMessage id="selecionar" />}
                  options={profileOptions}
                  onChange={(e) => setPerfil(e)}
                  value={perfil}
                  geterror={errors?.perfil}
                />

                <ListSelect
                  labelPlaceholder={matrizPlaceholder}
            required={true}
                  placelholder={<FormattedMessage id="selecionar" />}
                  options={YesNoOptions}
                  onChange={(e) => setMatriz(e)}
                  value={matriz}
                  isClearable
                />

                <AsyncListSelect
                  key={cnpj}
                  value={empresaMatriz}
                  onChange={(e) => {
                    setEmpresaMatriz(e);
                  }}
                  myOptions={"clientes"}
                  cacheOptions
                  defaultOptions
                  labelPlaceholder={empresaMatrizPlaceholder}
                  placeholder={<FormattedMessage id="selecionar" />}
                  loadingMessage={() => <FormattedMessage id="carregando" />}
                  noOptionsMessage={() => (
                    <FormattedMessage id="nenhumRegistroEncontrado" />
                  )}
                  isSearchable={false}
                  isDisabled={matriz.value === 1}
                  geterror={errors?.matriz}
                />

                <AsyncListSelect
                  labelPlaceholder={classificacaoPlaceholder}
            required={true}
                  placelholder={<FormattedMessage id="selecionar" />}
                  onChange={(e) => setClassificacao(e)}
                  value={classificacao}
                  myOptions={"classificaoClientes"}
                  cacheOptions
                  defaultOptions
                  geterror={errors?.tabela_classificacao_clientes_id}
                />

                <InputLabel
                  name="contato"
                  type="text"
                  label={contatoPlaceholder}
            required={true}
                  maxLength={100}
                  value={contato}
                  onChange={(e) => setContato(e.target.value)}
                  geterror={errors?.contato}
                />

                <InputLabel
                  name="email"
                  type="text"
                  label={"E-mail"}
                  required={true}
                  maxLength={80}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  geterror={errors?.email}
                  readOnly
                />

                <InputLabel
                  name="telefone"
                  type="text"
                  label={telefonePlaceholder}
            required={true}
                  mask={MaskInput.phone}
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                  geterror={errors?.telefone}
                />

                <InputLabel
                  name="celular"
                  type="text"
                  label={celularPlaceholder}
                  mask={MaskInput.cellphone}
                  value={celular}
                  onChange={(e) => setCelular(e.target.value)}
                  geterror={errors?.celular}
                />

                <InputLabel
                  name="contato"
                  type="text"
                  label={"Segundo Contato"}
                  maxLength={100}
                  value={contatoDois}
                  onChange={(e) => setContatoDois(e.target.value)}
                  geterror={errors?.contato_2}
                />

                <InputLabel
                  name="email"
                  type="text"
                  label={"Segundo E-mail"}
                  maxLength={80}
                  value={emailDois}
                  onChange={(e) => setEmailDois(e.target.value)}
                  geterror={errors?.contato_email_2}
                />

                <InputLabel
                  name="celular"
                  type="text"
                  label={"Segundo Celular"}
                  mask={MaskInput.cellphone}
                  value={celularDois}
                  onChange={(e) => setCelularDois(e.target.value)}
                  geterror={errors?.celularDois}
                />

                <AsyncListSelect
                  labelPlaceholder={mesaOperacaoPlaceholder}
            required={true}
                  placeholder={<FormattedMessage id="selecionar" />}
                  cacheOptions
                  defaultOptions
                  onChange={(e) => setMesaOperacao(e)}
                  value={mesaOperacao}
                  myOptions={"classificaoMesaOperacao"}
                  geterror={errors?.tabela_mesa_operacao_id}
                />

                <AsyncListSelect
                labelPlaceholder={"Farmer"}
                placeholder={<FormattedMessage id="selecionar" />}
                cacheOptions
                defaultOptions
                onChange={(e) => setFarmer(e)}
                value={farmer}
                myOptions={"farmer"}
                geterror={errors?.farmer}
              />

              <AsyncListSelect
                labelPlaceholder={"Hunter"}
                placeholder={<FormattedMessage id="selecionar" />}
                cacheOptions
                defaultOptions
                onChange={(e) => setHunter(e)}
                value={hunter}
                myOptions={"hunter"}
                geterror={errors?.hunter}
              />

                <ListSelect
                  labelPlaceholder={"Status"}
                  placeholder={<FormattedMessage id="selecionar" />}
                  value={status}
                  onChange={(e) => setStatus(e)}
                  cacheOptions
                  defaultOptions
                  options={statusOptions}
                />
              </form>
            </TabPanel>
          )}

          {/* Dados de Endereço */}
          {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
            permissions.dadosEnderecos) && (
              <TabPanel>
              <form>
                <InputLabel
                  name="cep"
                  type="text"
                  label={cepPlaceholder + " *"}
                  mask={MaskInput.CEP}
                  onBlur={async () => handleSearchCEP(cep, 1)}
                  value={cep}
                  onChange={(e) =>
                    setCep(e.target.value.replace(/[^\d]+/g, ""))
                  }
                  geterror={
                    errors?.[`0.cep`]
                  }
                />

                <InputLabel
                  name="logradouro"
                  type="text"
                  label={logradouroPlaceholder + " *"}
                  maxLength={100}
                  value={logradouro}
                  onChange={(e) => setLogradouro(e.target.value)}
                  geterror={
                    errors?.[`0.logradouro`]
                  }
                />

                <InputLabel
                  name="numero"
                  // type="text"
                  label={numeroPlaceholder + " *"}
                  // mask={"int6"}
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                  geterror={
                    errors?.[`0.numero`]
                  }
                />

                <InputLabel
                  name="complemento"
                  type="text"
                  label={complementoPlaceholder}
                  maxLength={50}
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                  geterror={
                    errors?.[`0.complemento`]
                  }
                />

                <InputLabel
                  name="bairro"
                  type="text"
                  label={bairroPlaceholder + " *"}
                  maxLength={30}
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  geterror={
                    errors?.[`0.bairro`]
                  }
                />

                <AsyncListSelect
                  value={estado}
                  onChange={(e) => {
                    setCidade("");
                    setEstado(e);
                    setListaCidades(e?.label);
                  }}
                  onInput={(e) => {}}
                  loadOptions={handleLoadUF}
                  cacheOptions
                  defaultOptions
                  labelPlaceholder={estadoPlaceholder + " *"}
                  placeholder={<FormattedMessage id="selecionar" />}
                  loadingMessage={() => carregandoPlaceholder}
                  isSearchable={false}
                  geterror={
                    errors?.[`0.estado_id`]
                  }
                />

                <AsyncListSelect
                  key={listaCidades}
                  value={cidade}
                  cacheOptions
                  defaultOptions
                  loadOptions={() => {
                    return handleFilterCitiesByUf(listaCidades);
                  }}
                  onChange={(e) => {
                    setCidade(e);
                  }}
                  onInputChange={(e) => {
                    setCidadeFilter(e);
                  }}
                  labelPlaceholder={cidadePlaceholder + " *"}
                  placeholder={<FormattedMessage id="selecionar" />}
                  loadingMessage={() => carregandoPlaceholder}
                  noOptionsMessage={() => registroPlaceholder}
                  geterror={
                    errors?.[`0.municipio_id`]
                  }
                />
              </form>

              <br/>

              <FormEndereco>

              <form>
                <b style={{color:'#030894'}}>
                  <FormattedMessage id="enderecoEntrega" /> 1:
                </b>
                <br/>
                <br/>
                <InputLabel
                  name="cep2"
                  type="text"
                  label={cepPlaceholder + " *"}
                  mask={MaskInput.CEP}
                  onBlur={async () => handleSearchCEP(cep2, 2)}
                  value={cep2}
                  onChange={(e) =>
                    setCep2(e.target.value.replace(/[^\d]+/g, ""))
                  }
                  geterror={
                    errors?.[`1.cep`]
                  }
                />

                <InputLabel
                  name="logradouro2"
                  type="text"
                  label={logradouroPlaceholder + " *"}
                  maxLength={100}
                  value={logradouro2}
                  onChange={(e) => setLogradouro2(e.target.value)}
                  geterror={
                    errors?.[`1.logradouro`]
                  }
                />

                <InputLabel
                  name="numero2"
                  // type="text"
                  label={numeroPlaceholder + " *"}
                  // mask={"int6"}
                  value={numero2}
                  onChange={(e) => setNumero2(e.target.value)}
                  geterror={
                    errors?.[`1.numero`]
                  }
                />

                <InputLabel
                  name="complemento2"
                  type="text"
                  label={complementoPlaceholder}
                  maxLength={50}
                  value={complemento2}
                  onChange={(e) => setComplemento2(e.target.value)}
                  geterror={
                    errors?.[`1.complemento`]
                  }
                />

                <InputLabel
                  name="bairro2"
                  type="text"
                  label={bairroPlaceholder + " *"}
                  maxLength={30}
                  value={bairro2}
                  onChange={(e) => setBairro2(e.target.value)}
                  geterror={
                    errors?.[`1.bairro`]
                  }
                />

                <AsyncListSelect
                  value={estado2}
                  onChange={(e) => {
                    setCidade2("");
                    setEstado2(e);
                    setListaCidades2(e?.label);
                  }}
                  onInput={(e) => {}}
                  loadOptions={handleLoadUF}
                  cacheOptions
                  defaultOptions
                  labelPlaceholder={estadoPlaceholder + " *"}
                  placeholder={<FormattedMessage id="selecionar" />}
                  loadingMessage={() => carregandoPlaceholder}
                  isSearchable={false}
                  geterror={
                    errors?.[`1.estado_id`]
                  }
                />

                <AsyncListSelect
                  key={listaCidades2}
                  value={cidade2}
                  cacheOptions
                  defaultOptions
                  loadOptions={() => {
                    return handleFilterCitiesByUf(listaCidades2);
                  }}
                  onChange={(e) => {
                    setCidade2(e);
                  }}
                  onInputChange={(e) => {
                    setCidadeFilter(e);
                  }}
                  labelPlaceholder={cidadePlaceholder + " *"}
                  placeholder={<FormattedMessage id="selecionar" />}
                  noOptionsMessage={() => (
                    "Selecione um estado para carregar"
                  )}
                  loadingMessage={() => carregandoPlaceholder}
                  // isSearchable={false}
                  geterror={
                    errors?.[`1.municipio_id`]
                  }
                />

                <AsyncListSelect
                  labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                  placeholder={<FormattedMessage id="selecionar" />}
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={loadBases}
                  value={basesDistribuicao2}
                  onChange={(item) => handleChangeBase(item, 2)}
                />
              </form>

              <form>
                <b style={{color:'#030894'}}>
                <FormattedMessage id="enderecoEntrega" /> 2:
                </b>
                <br/>
                <br/>

                <InputLabel
                  name="cep3"
                  type="text"
                  label={cepPlaceholder + " *"}
                  mask={MaskInput.CEP}
                  onBlur={async () => handleSearchCEP(cep3, 3)}
                  value={cep3}
                  onChange={(e) =>
                    setCep3(e.target.value.replace(/[^\d]+/g, ""))
                  }
                  geterror={
                    errors?.[`2.cep`]
                  }
                />

                <InputLabel
                  name="logradouro3"
                  type="text"
                  label={logradouroPlaceholder + " *"}
                  maxLength={100}
                  value={logradouro3}
                  onChange={(e) => setLogradouro3(e.target.value)}
                  geterror={
                    errors?.[`2.logradouro`]
                  }
                />

                <InputLabel
                  name="numero3"
                  // type="text"
                  label={numeroPlaceholder + " *"}
                  // mask={"int6"}
                  value={numero3}
                  onChange={(e) => setNumero3(e.target.value)}
                  geterror={
                    errors?.[`2.numero`]
                  }
                />

                <InputLabel
                  name="complemento"
                  type="text"
                  label={complementoPlaceholder}
                  maxLength={50}
                  value={complemento3}
                  onChange={(e) => setComplemento3(e.target.value)}
                />

                <InputLabel
                  name="bairro3"
                  type="text"
                  label={bairroPlaceholder + " *"}
                  maxLength={30}
                  value={bairro3}
                  onChange={(e) => setBairro3(e.target.value)}
                  geterror={
                    errors?.[`2.bairro`]
                  }
                />

                <AsyncListSelect
                  value={estado3}
                  onChange={(e) => {
                    setCidade3("");
                    setEstado3(e);
                    setListaCidades3(e?.label);
                  }}
                  onInput={(e) => {}}
                  loadOptions={handleLoadUF}
                  cacheOptions
                  defaultOptions
                  labelPlaceholder={estadoPlaceholder + " *"}
                  placeholder={<FormattedMessage id="selecionar" />}
                  loadingMessage={() => carregandoPlaceholder}
                  isSearchable={false}
                  geterror={
                    errors?.[`2.estado_id`]
                  }
                />

                <AsyncListSelect
                  key={listaCidades3}
                  value={cidade3}
                  cacheOptions
                  defaultOptions
                  loadOptions={() => {
                    return handleFilterCitiesByUf(listaCidades3);
                  }}
                  onChange={(e) => {
                    setCidade3(e);
                  }}
                  onInputChange={(e) => {
                    setCidadeFilter(e);
                  }}
                  labelPlaceholder={cidadePlaceholder + " *"}
                  placeholder={<FormattedMessage id="selecionar" />}
                  noOptionsMessage={() => (
                    "Selecione um estado para carregar"
                  )}
                  loadingMessage={() => carregandoPlaceholder}
                  geterror={
                    errors?.[`2.municipio_id`]
                  }
                />

                <AsyncListSelect
                  labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                  placeholder={<FormattedMessage id="selecionar" />}
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={loadBases}
                  value={basesDistribuicao3}
                  onChange={(item) => handleChangeBase(item, 3)}
                />
              </form>

              </FormEndereco>

            </TabPanel>
          )}

          {/* Dados de Entrega */}
          {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
            permissions.dadosEntrega) && (
            <TabPanel>
              <form>
                <ListSelect
                  labelPlaceholder={modalidadeTransportePlaceholder}
            required={true}
                  placeholder={<FormattedMessage id="selecionar" />}
                  options={modalidadeTransporteOptions}
                  onChange={(e) => setModalidadeTransporte(e)}
                  value={modalidadeTransporte}
                  geterror={errors?.modalidade_transporte}
                />

                <ListSelect
                  labelPlaceholder={tanquesPropriosTransportePlaceholder}
            required={true}
                  placeholder={<FormattedMessage id="selecionar" />}
                  options={YesNoOptions}
                  onChange={(e) => setTanque(e)}
                  value={tanque}
                  geterror={errors?.tanques_proprios}
                />

                <ListSelect
                  labelPlaceholder={modoAbastecimentoPlaceholder}
            required={true}
                  placeholder={<FormattedMessage id="selecionar" />}
                  options={abastecimentoOptions}
                  onChange={(e) => setModoAbastecimento(e)}
                  value={modoAbastecimento}
                  geterror={errors?.modo_abastecimento}
                />

                <section>
                  <strong style={{ marginLeft: "12px" }}>
                    {tipoRecebimentoPlaceholder}
                  </strong>
                  <CheckboxDefault
                    type="radio"
                    name="recebimento"
                    labelName="TRR"
                    onChange={() => setTipoRecibimento("TRR")}
                    checked={tipoRecebimento === "TRR" ? true : false}
                  />

                  <CheckboxDefault
                    type="radio"
                    name="recebimento"
                    labelName={distribuidorPlaceholder}
                    onChange={() => setTipoRecibimento("Distribuição")}
                    checked={tipoRecebimento === "Distribuição" ? true : false}
                  />
                </section>

                <MessageDefault geterror={errors?.tipo_recebimento} />

                <TextAreaLabel
                  name="obs"
                  placeholder={
                    observacoesPlaceholder + " -  Máx. 200 caracteres"
                  }
                  maxLength={200}
                  value={observacoes}
                  onChange={(e) => setObservacoes(e.target.value)}
                />
              </form>
            </TabPanel>
          )}

          {/* Dados de cobrança */}
          {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
            permissions.dadosCobranca) && (
            <TabPanel>
              <form onSubmit={(e) => e.preventDefault()}>
                <InputMaskDefault
                  type="text"
                  name="limiteCredito"
                  label={limiteCreditoDisponivelPlaceholder}
            required={true}
                  mask={"currency9"}
                  value={limiteCredito.replace(".", ",")}
                  onChange={(e) => setLimiteCredito(e.target.value)}
                  geterror={errors?.limite_credito}
                  readOnly
                />

                <InputMaskDefault
                  type="text"
                  name="toleranciaMaxima"
                  label={toleranciaMaximaPlaceholder + " %"}
                  value={toleranciaMaxima && toleranciaMaxima.replace(".", ",")}
                  mask={"currency2WithNegative"}
                  readOnly
                />

                <InputMaskDefault
                  type="text"
                  name="saldoCredito"
                  label={saldoCreditoDisponivelPlaceholder + " - Graal"}
                  required={true}
                  value={saldoCredito && saldoCredito.replace(".", ",")}
                  mask={"currency2WithNegative"}
                  onChange={(e) => setSaldoCredito(e.target.value)}
                  toolTip={
                    <ButtonIcon
                      Icon={BsQuestion}
                      size={16}
                      backgroundColor={"#c02c31"}
                      placeholder={
                        "Limite de Crédito Disponível Graal + Tolerância Máxima de Limite - Pedidos Aprovados"
                      }
                    />
                  }
                  readOnly
                />

                <InputMaskDefault
                  type="text"
                  name="saldoCredito"
                  label={saldoCreditoDisponivelPlaceholder + " - Emissor"}
                  required={true}
                  value={
                    saldoCreditoEmissor && saldoCreditoEmissor.replace(".", ",")
                  }
                  mask={"currency2WithNegative"}
                  toolTip={
                    <ButtonIcon
                      Icon={BsQuestion}
                      size={16}
                      backgroundColor={"#c02c31"}
                      placeholder={
                        "Limite de Crédito Disponível Emissor + Tolerância Máxima de Limite - Pedidos/Faturas em aberto"
                      }
                    />
                  }
                  readOnly
                />

                <InputMaskDefault
                  name="prazoPagamento"
                  label={prazoPagamentoPlaceholder}
            required={true}
                  mask={"int3"}
                  value={prazoPagamento}
                  onChange={(e) => setPrazoPagamento(e.target.value)}
                  geterror={errors?.prazo_pagamento}
                />

                <InputMaskDefault
                  type="text"
                  label={taxaAdministrativaPlaceholder}
            required={true}
                  mask={"currency7"}
                  value={taxaAdm}
                  onChange={(e) => {
                    return setTaxaAdm(e.target.value);
                  }}
                  geterror={errors?.taxa_administrativa}
                />
                <HeaderTax>
                  <strong>{taxaFinanceiraPlaceholder} *</strong>
                  <ButtonIcon
                    Icon={FiPlus}
                    backgroundColor={"#030894"}
                    placeholder={<FormattedMessage id="incluir" />}
                    style={{ height: "25px", width: "25px" }}
                    onClick={handleAddTaxInput}
                  />
                </HeaderTax>
                <FormTax>
                  <header>
                    <strong>
                      <TitleTax>{dePlaceholder}</TitleTax>
                    </strong>
                    <strong>
                      <TitleTax>{atePlaceholder}</TitleTax>
                    </strong>
                    <strong>
                      <TitleTax>%</TitleTax>
                    </strong>
                  </header>

                  <ul>
                    {taxaFinanceira.map((taxa, index) => {
                      return (
                        <div key={index}>
                          <li>
                            <InputMaskDefault
                              mask={"int2"}
                              value={taxa.taxa_financeira_de}
                              onChange={(e) => {
                                return handleChangeInputTaxaFinanceiraDe(
                                  e,
                                  index
                                );
                              }}
                              geterrorcolorbackground={
                                errors?.[`${index}.taxa_financeira_de`]
                              }
                            />
                          </li>
                          <li>
                            <InputMaskDefault
                              mask={"int2"}
                              value={taxa.taxa_financeira_ate}
                              onChange={(e) =>
                                handleChangeInputTaxaFinanceiraAte(e, index)
                              }
                              geterrorcolorbackground={
                                errors?.[`${index}.taxa_financeira_ate`]
                              }
                            />
                          </li>
                          <li>
                            <InputMaskDefault
                              onPaste={(e) => e.preventDefault(e)}
                              mask={"currency7"}
                              value={taxa.taxa_financeira_percentual}
                              onChange={(e) =>
                                handleChangeInputTaxaFinanceiraPercent(e, index)
                              }
                              geterrorcolorbackground={
                                errors?.[`${index}.taxa_financeira_percentual`]
                              }
                            />
                          </li>
                          <li>
                            <ButtonIcon
                              Icon={FiTrash2}
                              backgroundColor={"#B30000"}
                              color={"#fff"}
                              placeholder={<FormattedMessage id="excluir" />}
                              style={{ height: "27px", width: "27px" }}
                              onClick={() =>
                                handleRemoveInputTaxaFinanceira(index)
                              }
                            />
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                </FormTax>
              </form>
            </TabPanel>
          )}

          {/* Capacidade e Consumo */}
          {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
            permissions.dadosConsumo) && (
            <TabPanel>
              <FormConsumer>
                <header>
                  <strong>
                    <TitleTax>
                      <FormattedMessage id="produto" />
                    </TitleTax>
                  </strong>
                  <strong>
                    <TitleTax>
                      <FormattedMessage id="capacidadeTanque" />
                    </TitleTax>
                  </strong>
                  <strong>
                    <TitleTax>
                      <FormattedMessage id="volumeComprasMensal" />
                    </TitleTax>
                  </strong>
                </header>

                <ul>
                  {produtos.map((produto, index) => (
                    <div key={index}>
                      <li>
                        <InputLabel
                          value={
                            produto?.produto?.descricao || produto?.descricao
                          }
                          readOnly
                        />
                      </li>
                      <li>
                        <InputMaskDefault
                          value={produto?.capacidade_tanque}
                          onChange={(e) => {
                            return handleChangeInputCapacidadeTanque(e, index);
                          }}
                          mask="currency6"
                          geterrorcolorbackground={
                            errors?.[`${index}.capacidade_tanque`]
                          }
                        />
                      </li>
                      <li>
                        <InputMaskDefault
                          value={produto?.volume_compras_mensal}
                          onChange={(e) =>
                            handleChangeInputVolumeCompraMensal(e, index)
                          }
                          mask="currency6"
                          geterrorcolorbackground={
                            errors?.[`${index}.volume_compras_mensal`]
                          }
                        />
                      </li>
                    </div>
                  ))}
                </ul>
              </FormConsumer>
            </TabPanel>
          )}

          {/* Certificado digital */}
          {(user?.tipo_id === 1 || permissions.certificado) && (
            <TabPanel>
              <form>
                <InputLabel
                  name="nomeCertificado"
                  type="text"
                  label={<FormattedMessage id="nomeCertificado" />}
                  maxLength={100}
                  readOnly
                  value={nomeCertificado}
                />
                <InputLabel
                  name="validadeCertificado"
                  type="text"
                  label={<FormattedMessage id="validadeCertificado" />}
                  mask={MaskInput.date}
                  value={validade}
                  readOnly
                />
                <InputLabel
                  name="certificadoDigital"
                  type="file"
                  label={<FormattedMessage id="certificadoDigitalA1" />}
                  onChange={(e) => handleUploadFile(e)}
                  geterror={errors?.nome_certificado}
                />

                <InputLabel
                  name="password"
                  type="password"
                  label={<FormattedMessage id="senha" />}
                  value={senha}
                  maxLength={20}
                  onChange={(e) => setSenha(e.target.value)}
                />
              </form>
            </TabPanel>
          )}

          {/* Bases de Distribuição */}
          {((user?.tipo_id === 1 && user?.cadastro_tipo_id === 1) ||
            permissions.dadosBasesDist) && (
            <TabPanel>
              <form>
                <MessageError>
                  <p style={{color:'#030894'}}>
                    Cotações com Distribuidor de Preferência serão destacadas no
                    Painel de Controle pela cor azul{" "}
                  </p>
                </MessageError>
                <br />
                <AsyncListSelect
                  value={preferenciaDist}
                  cacheOptions
                  defaultOptions
                  myOptions={"distribuidores"}
                  onChange={(e) => {
                    setPreferenciaDist(e);
                    handleFilterBasesByPreferenciaDist(e?.value);
                  }}
                  labelPlaceholder={
                    <FormattedMessage id="distribuidorPreferencia" />
                  }
                  placeholder={<FormattedMessage id="selecionar" />}
                  loadingMessage={() => <FormattedMessage id="carregando" />}
                  isClearable={true}
                />

                <AsyncListSelect
                  labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                  placeholder={<FormattedMessage id="selecionar" />}
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={loadBases}
                  value={basesDistribuicao}
                  onChange={(item) => handleChangeBase(item, 1)}
                />
                <MessageDefault geterror={errors?.[`0`]} />
              </form>
            </TabPanel>
          )}

           {/* Versão */}
           <TabPanel>
            <form>
              <ListSelect
                labelPlaceholder={<FormattedMessage id="padraoCotacao" />}
                placeholder={<FormattedMessage id="selecionar" />}
                options={versaoOptions}
                onChange={(e) => setVersao(e)}
                value={versao}
                geterror={errors?.versao}
              />


              <FormTax>
                <header>
                  <span>
                  <b>
                    <FormattedMessage id="data" />
                  </b>
                  <b>
                    <FormattedMessage id="versaoDe" />
                  </b>
                  <b>
                    <FormattedMessage id="versaoPara" />
                  </b>
                  </span>
                </header>

                <ul>
                  {dataVersao.map((data, index) => {
                    return (
                      <div key={data.data}>
                        <li style={{width: '30%'}}>
                          <InputLabel
                            value={data.data}
                            readOnly={true}
                          />
                        </li>
                        <li style={{width: '30%'}}>
                          <InputLabel
                            value={data.de}
                            readOnly={true}
                          />
                        </li>
                        <li style={{width: '30%'}}>
                          <InputLabel
                            value={data.para}
                            readOnly={true}
                          />
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </FormTax>
          </form>
          </TabPanel>

          {/* Bloqueio distribuidor */}
          <TabPanel>
            <form>
              <AsyncListSelect
                labelPlaceholder={<FormattedMessage id="distribuidor" />}
                placeholder={<FormattedMessage id="selecionar" />}
                isMulti
                cacheOptions
                defaultOptions
                value={distribuidores}
                onChange={handleChangeDistribuidor}
                myOptions={'distribuidores'}
              />
            </form>

          </TabPanel>
        </Tabs>
        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button type="button" onClick={handleEdit} loading={loadingButton}>
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditCustomer);
