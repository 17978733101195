import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, FilterContainer, ContainerOptions, CreateForm, FormConsumer, TitleTax } from "./styles";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  FiSave,
  FiPlus,
  FiArrowLeft,
  FiXCircle,
} from "react-icons/fi";

import ButtonImage from "./../../../../components/ButtonImage";
import iconVoltar from "./../../../../assets/images/icone-voltar.svg";

import api from "./../../../../services/api";
import ButtonIcon from "./../../../../components/ButtonIcon";
import InputLabel from "./../../../../components/InputLabel";
import InputMaskDefault from "./../../../../components/InputMaskDefault";
import ContentSpinner from "./../../../../components/Spinner/Content";
import AuthContext from "./../../../../context/auth";
import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";

const ListPricesFuels = ({ intl }) => {
  const [showFilter, setShowFilter] = useState(false);

  const { user } = useContext(AuthContext);

  const [loadingList, setLoadingList] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  // Campos Filtros
  const [distribuidor, setDistribuidor] = useState(() => {
    if (user.cadastro_tipo_id === 1) {
      return;
    } else {
      return {
        value: user.cadastro_id,
        label: user.razao_social,
      };
    }
  });
  const [baseDistribuicao, setBaseDistribuicao] = useState("");
  const [listBases, setListBases] = useState("");
  const [produto, setProduto] = useState("");

  useEffect(() => {(() => {
    if (user.cadastro_tipo_id === 2){
      loadDistribuidor(user.cadastro_id)
    } else {
      setLoadingList(false);
    }
  })();
  }, []);

  const handleCreate = useCallback(() => {
    history.push("/orders/price-product/create");
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const loadDistribuidor = useCallback(async(id) => {
    setLoadingList(true);

    try {
      const { data: response } = await api.get(`/cadastro/${id}`);

        var formattedProducts  =  response?.lista_preco_produtos.map((produto) => ({
          produto: {value: produto.produto?.id, label: produto.produto?.nome},
          tipo_unitario: {value: produto.produto.tipo_unitario.id, label: produto.produto.tipo_unitario.descricao},
          marca: {value: produto.produto?.marca?.id, label: produto.produto?.marca?.nome},
          categoria: {value: produto.produto?.categoria?.id, label: produto.produto?.categoria?.nome},
          base: {value: produto.base_distribuicao?.id, label: produto.base_distribuicao?.nome},
          // valor_frete: produto?.valor_frete?.replace(".", ","),
          valor: produto?.valor_produto?.replace(".", ","),
          vigencia: produto?.vigencia_ate_produto,
          // prazo: produto?.prazo_entrega_produto,
          inativo: !produto?.ativo
        }))

      setBaseDistribuicao(formattedProducts);

      var bases = [];
      response.base_distribuicao.map((base) => {
        bases.push(base.id)
      })

      setListBases(bases)

      setLoadingList(false);


    } catch (error) {
      setLoadingList(false)
    }
  }, [distribuidor, baseDistribuicao, listBases]);

  const handleChangeInputValorProduto = useCallback(
    (e, index) => {

      let newValue;

      const checkValueFormat = e?.target?.value.split(",");
      if (checkValueFormat?.length <= 1) {
        newValue = e?.target?.value + ",00";
      } else if (checkValueFormat[1]?.length < 2) {
        newValue = e?.target?.value + "00";
      } else if (
        checkValueFormat[0].length <= 2 &&
        checkValueFormat[1]?.length < 2
      ) {
        newValue = checkValueFormat[0] + ",00";
      } else if (checkValueFormat[0]?.length === 0) {
        newValue = "0,00";
      } else {
        newValue = e?.target?.value;
      }

      baseDistribuicao[index].valor = newValue;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleChangeInputInativo = useCallback(
    (e, index) => {
      baseDistribuicao[index].inativo = !baseDistribuicao[index].inativo;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleChangeInputFrete = useCallback(
    (e, index) => {
      baseDistribuicao[index].valor_frete = e.target.value;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleChangeInputVigencia = useCallback(
    (e, index) => {
      baseDistribuicao[index].vigencia = e.target.value;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleChangeInputPrazo = useCallback(
    (e,  index) => {
      baseDistribuicao[index].prazo = e.target.value;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleUpdate = useCallback(async () => {
    setLoadingButton(true)
    let produtoFormmated = []

    baseDistribuicao.map((product) => {
      produtoFormmated.push({
        base_distribuicao_id : product.base.value,
        produto_id : product.produto.value,
        valor_produto : product.inativo === true ? '0': String(product.valor)?.replaceAll(
          ",",
          "."
        ),
        prazo_entrega_produto: product.inativo === true ? "0" : product.prazo,
        vigencia_ate_produto: product.inativo === true ? "2023-01-01" : product.vigencia,
        vigencia_ate_frete: product.inativo === true ? "2023-01-01" : product.vigencia,
        // valor_frete : product.inativo === true ? '0': parseFloat(product?.valor_frete
        //   .replace(".", "")
        //   .replace(",", ".")),
        ativo: product.inativo === false ? 1 : 0
      })
    })

    const data = {
      lista_preco_produtos: produtoFormmated,
      base_distribuicao: listBases
    };

    try {
      if (user.cadastro_tipo_id === 1) {
        await api.put(`/cadastro/${distribuidor.value}`, data);
      } else {
        await api.put(`/cadastro-distribuidor/${distribuidor.value}`, data);
      }
      setLoadingButton(false);
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);
    } catch (error) {
      setLoadingButton(false);
      return toast.error(<FormattedMessage id="erroEditarRegistro" />);
    }
  }, [baseDistribuicao, distribuidor, listBases]);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="valoresProdutos" />
            </h2>
          </nav>

          <div>
            <ButtonIcon
              Icon={FiPlus}
              backgroundColor={"#030894"}
              onClick={handleCreate}
              placeholder={<FormattedMessage id="incluir" />}
              permission={
                user.cadastro_tipo_id === 1
                  ? "preco-importar[1]"
                  : "preco-importar[2]"
              }
            />

            <ButtonIcon
              Icon={FiSave}
              backgroundColor={"#4c84ec"}
              onClick={handleUpdate}
              placeholder={<FormattedMessage id="salvar" />}
              permission={true}
              loading={loadingButton}
            />

          </div>
        </header>

        <form>
          {user.cadastro_tipo_id === 1 && (
            <AsyncListSelect
              value={distribuidor}
              labelPlaceholder={<FormattedMessage id="distribuidores" />}
              placeholder={<FormattedMessage id="selecione" />}
              onChange={(e) => {
                setDistribuidor(e);
                loadDistribuidor(e.value);
              }}
              myOptions={"distribuidores"}
              cacheOptions
              defaultOptions
              isClearable
            />
          )}
        </form>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <CreateForm>
              <header>
                <p>
                  {distribuidor?.label}
                </p>
              </header>

                {baseDistribuicao === '' ? (
                    <h1>
                      <FormattedMessage id="selecioneDistribuidor" />
                    </h1>
                  ) : (
                   <ContainerOptions>
                     <FormConsumer>
                       <span>
                         <strong>
                           <TitleTax>
                            <FormattedMessage id="produto" />
                           </TitleTax>
                         </strong>
                         <strong>
                           <TitleTax>
                            <FormattedMessage id="baseDistribuicao" />
                           </TitleTax>
                         </strong>
                         <strong>
                           <TitleTax>
                             <FormattedMessage id="valorProduto" />
                           </TitleTax>
                         </strong>
                         {/* <strong>
                           <TitleTax>
                            <FormattedMessage id="valorFrete" />
                           </TitleTax>
                         </strong> */}
                         <strong>
                           <TitleTax>
                            <FormattedMessage id="dataVigencia" />
                           </TitleTax>
                         </strong>
                         {/* <strong>
                           <TitleTax>
                            <FormattedMessage id="prazo" />
                           </TitleTax>
                         </strong> */}
                         {user.cadastro_tipo_id === 1 ? (
                          <strong>
                            <TitleTax>
                              <FormattedMessage id="inativo" />
                            </TitleTax>
                          </strong>
                         ) : (
                          ""
                         )}
                       </span>
                     </FormConsumer>
                    {baseDistribuicao.map((produto, index) => (
                      <div key={index}>
                          <FormConsumer>
                            <ul>
                              <div >
                                <li>
                                  <p>{produto?.produto?.label}</p>
                                </li>
                                <li>
                                  <p>{produto?.base?.label}</p>
                                </li>
                                <li>
                                  <InputMaskDefault
                                    value={produto.inativo === true ? "" : produto?.valor}
                                    onChange={(e) => {
                                      return handleChangeInputValorProduto(e, index);
                                    }}
                                    mask="currency2"
                                    readOnly={produto.inativo}
                                  />
                                </li>
                                {/* <li>
                                  <InputMaskDefault
                                    value={produto.inativo === true ? "" : produto?.valor_frete}
                                    onChange={(e) => {
                                      return handleChangeInputFrete(e, index);
                                    }}
                                    mask="currency4"
                                    readOnly={produto.inativo}
                                  />
                                </li> */}

                                <li>
                                  <InputLabel
                                    type="date"
                                    style={{ color: "hsl(0,0%,50%)" }}
                                    value={produto.inativo === true ? "" : produto?.vigencia}
                                    onChange={(e) => {
                                      return handleChangeInputVigencia(e, index);
                                    }}
                                    readOnly={produto.inativo}
                                  />
                                </li>

                                {/* <li>
                                  <InputLabel
                                    style={{ color: "hsl(0,0%,50%)" }}
                                    value={produto.inativo === true ? "" : produto?.prazo}
                                    onChange={(e) => {
                                      return handleChangeInputPrazo(e, index);
                                    }}
                                    readOnly={produto.inativo}
                                  />
                                </li> */}

                                {user.cadastro_tipo_id === 1 && (
                                    <li>
                                     <input
                                       type="checkbox"
                                       value={produto?.inativo}
                                       checked={produto?.inativo}
                                       onChange={(e) => handleChangeInputInativo(e, index)}
                                     />
                                   </li>
                                )}

                              </div>
                          </ul>
                        </FormConsumer>

                         {/* {user.cadastro_tipo_id === 2 && (
                          valorBase.produtos.map((produto, indexProduto) => (
                            (produto.inativo === false) && (
                              <FormConsumer key={produto.value}>
                                <ul>
                                  <div >
                                    <li>
                                      <p>{produto.label}</p>
                                    </li>
                                    <li>
                                    <InputMaskDefault
                                       value={produto?.valor}
                                       onChange={(e) => {
                                         return handleChangeInputValorProduto(e, indexBase, indexProduto);
                                       }}
                                       mask="currency4"
                                    />
                                    </li>
                                    <li>
                                      <InputLabel
                                        type="date"
                                        style={{ color: "hsl(0,0%,50%)" }}
                                        value={produto?.vigencia}
                                        onChange={(e) => {
                                          return handleChangeInputVigenciaProduto(e, indexBase, indexProduto);
                                        }}
                                      />
                                    </li>
                                  </div>
                              </ul>

                            </FormConsumer>
                          )))
                         )} */}
                     </div>
                    ))}
                  </ContainerOptions>
                )}
              </CreateForm>
            <hr/>
          </>
        )}
      </Container>

      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>
            <AsyncListSelect
              value={produto}
              labelPlaceholder={<FormattedMessage id="produto" />}
              placeholder={<FormattedMessage id="selecionar" />}
              onChange={(e) => {
                setProduto(e);
              }}
              myOptions={"produtos"}
              cacheOptions
              defaultOptions
            />

            <AsyncListSelect
              value={baseDistribuicao}
              labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
              placeholder={<FormattedMessage id="todos" />}
              onChange={(e) => {
                setBaseDistribuicao(e);
              }}
              myOptions={"basesDistribuição"}
              cacheOptions
              defaultOptions
              isClearable
            />
          </main>

          <footer>
            {/* <Button
              type="button"
              loading={loadingButton}
              onClick={handleFilter}
            >
              <FormattedMessage id="filtrar" />
            </Button> */}
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(ListPricesFuels);
