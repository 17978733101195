import React, { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";

import ListSelect from "./../../../../components/ListSelect";
import InputLabel from "./../../../../components/InputLabel";

import api from "./../../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";
import Button from "./../../../../components/ButtonDefault";
import InputMaskDefault from "./../../../../components/InputMaskDefault";

//import Language from "./../../../context/language";

export const ReproveOrders = ({ statusPedido, intl }) => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [checkOrder, setCheckOrder] = useState([]);
  const [motivo, setMotivo] = useState("");
  const [preco, setPreco] = useState("");
  const [justifique, setJustifique] = useState("");
  const [selectedCotacao, setSelectedCotacao] = useState("");
  const [cancelarPedido, setCancelarPedido] = useState("");

  const history = useHistory();
  //const { language } = useContext(Language);

  const handleBackButton = useCallback(() => {
    const painelControle = {
      painelControle: "painelControle"
    };

    history.push("/orders/painel/v2", painelControle);
  },
  [history]
);

  const justificativaPlaceholder = intl.formatMessage({ id: "justifique" });

  const quanto = intl.formatMessage({ id: "quanto" });

  useEffect(() => {
    if (!history.location?.state) {
      return history.push("/orders/painel/v2");
    }

    var formattedOrders = [];

    if (history.location.state?.pedidosId) {
      setCancelarPedido(history.location.state?.pedidosId);
      formattedOrders = history.location.state?.pedidosId.map((elem) => {
        return {
          id: elem.split("-")[0],
          status: elem.split("-")[1],
          idPedido: elem?.split("-")[2]
            ? elem?.split("-")[2]
            : elem.split("-")[0],
        };
      });
    } else {
      setSelectedCotacao(history.location.state?.cotacaoId);
    }

    const checkStatusOrders =
      statusPedido === "Cancelado"
        ? []
        : formattedOrders.filter(
            (elem) =>
              elem.status === "Aprovado" ||
              //elem.status === "Cotação" ||
              elem.status === "Reprovado" ||
              elem.status === "Cancelado" ||
              elem.status === "Aguardando Faturamento" ||
              elem.status === "Faturado"
          );

    setCheckOrder(checkStatusOrders);

    setSelectedOrders(formattedOrders);
  }, [history, statusPedido]);

  const reproveOptions = [
    {
      value: "Preço do combustível está mais caro",
      label: "Preço do combustível está mais caro",
    },
    {
      value: "Demora no envio da cotação",
      label: "Demora no envio da cotação",
    },
    {
      value: "Prazo de entrega não atende",
      label: "Prazo de entrega não atende",
    },
    {
      value: "Horário de entrega não atende",
      label: "Horário de entrega não atende",
    },
    {
      value: "Prazo de retirada não atende",
      label: "Prazo de retirada não atende",
    },
    {
      value: "Horário de retirada não atende",
      label: "Horário de retirada não atende",
    },
    {
      value: "Apenas fazendo uma cotação",
      label: "Apenas fazendo uma cotação",
    },
    { value: "Desistência do pedido", label: "Desistência do pedido" },
    { value: "Produtos Indisponíveis", label: "Produtos Indisponíveis" },

    { value: "Outros", label: "Outros" },
  ];

  const handleReproveOrder = useCallback(async () => {
    if (selectedOrders.length === 0) {
      return toast.warning("Nenhum pedido selecionado");
    }

    if (checkOrder.length > 0) {
      return toast.warning("Status do pedido invalído para reprovar.");
    }

    if (!motivo) {
      return toast.warning("Informe o motivo");
    }

    let sePreco = 0;
    if (preco) {
      sePreco = preco.replaceAll(".", "").replaceAll(",", ".");
    }

    if (motivo === "Preço do combustível está mais caro" && sePreco === 0) {
      return toast.warning("Informe o valor");
    }

    const data = {
      status: statusPedido,
      motivo,
      justificacao: justifique || "Sem justificativa",
      preco: sePreco,
    };

    try {
      Promise.all(
        selectedOrders.map(async (elem) => {
          return await api.post(`/reprovar/pedido/${elem.idPedido}`, data);
        })
      );

      if (statusPedido === "Reprovado") {
        toast.success("Pedidos reprovado com sucesso");
      } else {
        toast.success("Pedidos cancelado com sucesso");
      }
    } catch (error) {
      toast.error("Erro no processo de reprovação dos pedidos");
    }

    history.push("/orders/painel/v2");
  }, [
    checkOrder.length,
    history,
    justifique,
    motivo,
    preco,
    selectedOrders,
    statusPedido,
  ]);

  const handleReproveCotacao = useCallback(async () => {
    if (!selectedCotacao) {
      return toast.warning("Nenhuma cotação selecionada");
    }

    if (checkOrder.length > 0) {
      return toast.warning("Status do pedido invalído para reprovar.");
    }

    if (!motivo) {
      return toast.warning("Informe o motivo");
    }

    let sePreco = 0;
    if (preco) {
      sePreco = preco.replaceAll(".", "").replaceAll(",", ".");
    }

    if (motivo === "Preço do combustível está mais caro" && sePreco === 0) {
      return toast.warning("Informe o valor");
    }

    const data = {
      status_cotacao: "Cancelado",
      motivo: motivo,
      justificacao: justifique || "Sem justificativa",
      preco: sePreco,
    };

    try {
      await api.put(`/cotacao/${selectedCotacao}`, data);

      toast.success("Cotação cancelada com sucesso");
    } catch (error) {
      toast.error("Erro no processo de reprovação da cotação");
    }

    history.push("/orders/painel/v2");
  }, [checkOrder.length, history, justifique, motivo, preco, selectedCotacao]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            {statusPedido === "Reprovado" ? (
              <FormattedMessage id="motivosReprovacao" />
            ) : (
              <FormattedMessage id="motivosCancelamento" />
            )}
          </h4>
        </header>

        <form>
          <ListSelect
            labelPlaceholder={
              statusPedido === "Reprovado" ? (
                <FormattedMessage id="informeMotivoReprovacaoCotacao" />
              ) : (
                "Informe o motivo do cancelamento do pedido/cotação"
              )
            }
            placeholder={<FormattedMessage id="selecionar" />}
            options={reproveOptions}
            onChange={(e) => setMotivo(e.value)}
            cacheOptions
            defaultOptions
          />

          {motivo === "Preço do combustível está mais caro" && (
            <InputMaskDefault
              label={quanto + " (R$)"}
              type="text"
              mask={"currency2"}
              onChange={(e) => setPreco(e.target.value)}
              value={preco}
            />
          )}

          {motivo === "Outros" && (
            <InputLabel
              label={justificativaPlaceholder + " -  Máx. 50 caracteres"}
              maxLength={50}
              type="text"
              onChange={(e) => setJustifique(e.target.value)}
              value={justifique}
            />
          )}
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            {cancelarPedido ? (
              <Button type="button" onClick={handleReproveOrder}>
                <FormattedMessage id="salvar" />
              </Button>
            ) : (
              <Button type="button" onClick={handleReproveCotacao}>
                <FormattedMessage id="salvar" />
              </Button>
            )}
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(ReproveOrders);
