import styled, { keyframes } from "styled-components";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }


`;

export const Container = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;

  .sideBySide {
    width: 50%;
    float: left;
    padding: 5px;
  }

  .sideBySideTwo {
    display: inline-block;
    width: 50%;
    padding: 5px;
  }
`;

export const CreateForm = styled.div`
  width: 95%;
  margin-top: 20px;
  /* background-color: #f8f8f8; */

  border-radius: 10px;

  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #030894;
    margin-bottom: 20px;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }

  form {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const CheckForm = styled.div`
  display: flex;
  margin-top: 15px;

  main {
    display: flex;
    justify-content: left;
    label {
      width: 70px;
    }
    p {
      font-weight: bold;
      width: 70px;
    }
    div {
      width: max-content;
      height: 18px;
      display: flex;
      margin-left: 10px;

      input {
        margin-top: 2px;
        margin-right: 5px;
      }
    }
  }
`;

export const RadioForm = styled.div`
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;

  label {
    margin-bottom: 5px !important;
    padding-left: 10px;
    font-weight: bold;
  }

  main {
    display: flex;
    width: 100%;
    margin-right: 5px;

    div {
      width: max-content;
      height: 18px;
      display: flex;
      margin-right: 30px;

      input {
        margin-top: 2px;
        margin-right: 5px;
      }
    }
  }
`;

export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  div {
    width: 120px;
  }
`;
