import styled from "styled-components";

export const Container = styled.div`
  width: max-content;
  height: 18px;
  display: flex;
  margin-right: 10px;

  input {
    margin-top: 2px;

    cursor: pointer;
  }

  label {
    margin-left: 4px;
  }
`;

export const MessageError = styled.div`
  width: 100%;
  height: 100%;
  display: block;

  align-items: center;
  height: 10px !important;
  transition: all 0.7s;
  margin-top: 5px;

  p {
    width: max-content;
    height: 100%;
    font-size: 11px;
    color: #27262a;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    width: max-content;
  }
`;
