import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { FormattedMessage, injectIntl } from "react-intl";

import api from "./../../../services/api";

import MaskInput from "./../../../config/input-mask";
import { Container, CreateForm, CreateFooter, TabStyled } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import InputMaskDefault from "./../../../components/InputMaskDefault";

import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";
import LoadingContent from "./../../../components/Spinner/Content";

export const DisplayDistributors = ({ intl }) => {
  const [showContent, setShowContent] = useState(true);

  /** aba Dados Cadastrais */
  const [cnpj, setCNPJ] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [dataCadastro, setDataCadastro] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [grupoEconomico, setGrupoEconomico] = useState("");
  const [perfil, setPerfil] = useState("");
  const [matriz, setMatriz] = useState("");
  const [empresaMatriz, setEmpresaMatriz] = useState("");
  const [classificacao, setClassificacao] = useState("");
  const [contato, setContato] = useState("");
  const [email, setEmail] = useState("");
  const [contatoDois, setContatoDois] = useState("");
  const [emailDois, setEmailDois] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [celularDois, setCelularDois] = useState("");
  // const [consultor, setConsultor] = useState("");
  const [status, setStatus] = useState({});
  const [merchant, setMerchant] = useState("");

  /** aba Dados de Endereço */
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [listaCidades, setListaCidades] = useState("");
  const [cidade, setCidade] = useState({});

  /** aba Base Distribuicao */
  const [basesDistribuicao, setBaseDistribuicao] = useState([
    { value: "todos", label: <FormattedMessage id="todos" /> },
  ]);

  // State de erros
  // const [errors, setErrors] = useState();
  const history = useHistory();
  const listPerfis = [
    { value: "Privado", label: <FormattedMessage id="privado" /> },
    { value: "Público", label: <FormattedMessage id="publico" /> },
  ];
  const listCompanies = [
    { value: 1, label: "Empresa 1" },
    { value: 2, label: "Empresa 2" },
  ];
  const listMatrixCompanies = [
    { value: 1, label: "Empresa 3" },
    { value: 2, label: "Empresa 4" },
  ];
  const listClassifications = [
    { value: 1, label: "Classificacao 1" },
    { value: 2, label: "Classificacao 2" },
  ];
  // const listConsultants = [
  //   { value: 1, label: "Consultor 1" },
  //   { value: 2, label: "Consultor 2" },
  // ];
  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });

  //Dados Cadastrais
  const razaoSocialPlaceholder = intl.formatMessage({ id: "razaoSocial" });
  const nomeFantasiaPlaceholder = intl.formatMessage({ id: "nomeFantasia" });
  const inscricaoEstadualPlaceholder = intl.formatMessage({
    id: "inscricaoEstadual",
  });
  const baseDistribuicaoPlaceholder = intl.formatMessage({
    id: "baseDistribuicao",
  });
  const grupoEconomicoPlaceholder = intl.formatMessage({
    id: "grupoEconomico",
  });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });
  const matrizPlaceholder = intl.formatMessage({ id: "matriz" });
  const empresaMatrizPlaceholder = intl.formatMessage({ id: "empresaMatriz" });
  const classificacaoPlaceholder = intl.formatMessage({ id: "classificacao" });
  const contatoPlaceholder = intl.formatMessage({ id: "contato" });
  const telefonePlaceholder = intl.formatMessage({ id: "telefone" });
  const celularPlaceholder = intl.formatMessage({ id: "celular" });
  // const consultorPlaceholder = intl.formatMessage({ id: "consultor" });

  // Dados endereço
  const cepPlaceholder = intl.formatMessage({ id: "cep" });
  const logradouroPlaceholder = intl.formatMessage({ id: "logradouro" });
  const numeroPlaceholder = intl.formatMessage({ id: "numero" });
  const complementoPlaceholder = intl.formatMessage({ id: "complemento" });
  const bairroPlaceholder = intl.formatMessage({ id: "bairro" });

  /** trazer dados para exibicao do Distribuidor/TRRs e setar dados trazidos do BD nos campos */
  useEffect(() => {
    async function loadCustomer() {
      try {
        if (!history.location?.state) {
          return history.push("/register/graal/distributors-trrs/list");
        }
        /** id do cadastro que vem da tela anterior */
        const id = history.location?.state;
        const { data: response } = await api.get(`/cadastro/${id}`);

        function formatDate(date) {
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2)
              month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          return [day, month, year].join('/');
      }

        setDataCadastro(formatDate(response.data_cadastro))

        /** aba dados Cadastrais */

        setCNPJ(response?.cpf_cnpj);
        setRazaoSocial(response?.razao_social);
        setNomeFantasia(response?.fantasia);
        setInscricaoEstadual(response?.inscricao_estadual);
        setGrupoEconomico(response?.grupo_economico);
        setPerfil(
          response?.perfil === "Privado"
            ? { value: "Privado", label: <FormattedMessage id="privado" /> }
            : { value: "Público", label: <FormattedMessage id="publico" /> }
        );
        setMatriz(
          response?.matriz === 1
            ? { value: 1, label: <FormattedMessage id="opcaoSim" /> }
            : { value: 0, label: <FormattedMessage id="opcaoNao" /> }
        );
        setEmpresaMatriz(() => {
          if (response.empresa_matriz) {
            return {
              value: response?.empresa_matriz?.id,
              label: response?.cpf_cnpj + " - " + response?.razao_social,
            };
          }
        });
        setClassificacao({
          value: response?.classificacao_cliente?.id,
          label: response?.classificacao_cliente?.descricao,
        });
        setContato(response?.contato);
        setEmail(response?.email);
        setContatoDois(response.contato_2);
        setEmailDois(response.contato_email_2);
        setTelefone(response?.telefone);
        setCelular(response?.celular);
        setCelularDois(response?.cel_contato_2);
        // setConsultor({
        //   value: response?.consultores?.id,
        //   label: response?.consultores?.descricao,
        // });
        setMerchant({
          value: response?.merchant?.id,
          label: response?.merchant?.descricao,
        })
        setStatus(
          response.ativo === 1
            ? {
                value: 1,
                label: <FormattedMessage id="ativo" />,
              }
            : {
                value: 0,
                label: <FormattedMessage id="inativo" />,
              }
        );
        /** aba dados Endereço */
        setCep(response?.endereco?.cep);
        setLogradouro(response?.endereco?.logradouro);
        setNumero(response?.endereco?.numero);
        setComplemento(response?.endereco?.complemento);
        setBairro(response?.endereco?.bairro);
        setEstado(
          response?.endereco.estado_id !== ""
            ? {
                value: response.endereco.estado.id,
                label: response.endereco.estado.sigla,
              }
            : { value: "", label: "Selecione o Estado" }
        );

        setListaCidades(response.endereco.estado.sigla);
        setCidade(
          response?.endereco.municipio_id !== ""
            ? {
                value: response.endereco.municipio.id,
                label: response.endereco.municipio.nome,
              }
            : { value: "", label: "Selecione antes o Estado" }
        );
        /** aba base Distribuicao */
        if (response?.base_distribuicao) {
          const formattedBases = response?.base_distribuicao.map((base) => ({
            label: base.nome,
            value: base.id,
          }));

          setBaseDistribuicao(formattedBases);
        }
        setShowContent(false);
      } catch (error) {
        setShowContent(false);
      }
    }
    loadCustomer();
  }, [history]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);
  const [tabIndex, setTabIndex] = useState(0);

  if (showContent) {
    return <LoadingContent />;
  }

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirDistribuidorTRR" />: {cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                )} - {razaoSocial}
          </h4>
          {/* {errors && <ButtonIcon Icon={FiAlertCircle} geterror={errors} />} */}
        </header>
        <form>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            {
              <>
                <TabList>
                  <TabStyled className={"react-tabs__tab"} selected>
                    <FormattedMessage id="dadosCadastrais" />
                  </TabStyled>
                  <TabStyled className={"react-tabs__tab"} selected>
                    <FormattedMessage id="dadosEndereco" />
                  </TabStyled>
                  <TabStyled className={"react-tabs__tab"} selected>
                    <FormattedMessage id="baseDistribuicao" />
                  </TabStyled>
                </TabList>

                {/* Dados cadastrais */}
                <TabPanel>
                  <InputLabel
                    name="cnpj"
                    type="text"
                    label="CNPJ"
                    mask={MaskInput.CNPJ}
                    value={cnpj || ""}
                    readOnly
                  />

                  <InputLabel
                    name="razaoSocial"
                    type="text"
                    label={razaoSocialPlaceholder}
            required={true}
                    value={razaoSocial || ""}
                    readOnly
                  />

                  <InputLabel
                    name="nomeFantasia"
                    type="text"
                    label={nomeFantasiaPlaceholder}
            required={true}
                    value={nomeFantasia || ""}
                    readOnly
                  />

                  <InputLabel
                    name="dataCadastro"
                    type="text"
                    label={"Data Cadastro"}
                    value={dataCadastro}
                    readOnly
                  />

                  <InputLabel
                    name="inscricaoEstadual"
                    type="text"
                    label={inscricaoEstadualPlaceholder}
                    value={inscricaoEstadual || ""}
                    readOnly
                  />

                  <InputLabel
                    name="grupoEconomico"
                    type="text"
                    label={grupoEconomicoPlaceholder}
            required={true}
                    value={grupoEconomico || ""}
                    readOnly
                  />

                  <ListSelect
                    options={listPerfis}
                    isDisabled
                    value={perfil}
                    labelPlaceholder={perfilPlaceholder}
            required={true}
                  />

                  <ListSelect
                    options={listCompanies}
                    labelPlaceholder={matrizPlaceholder}
            required={true}
                    isDisabled
                    value={matriz}
                  />

                  <ListSelect
                    labelPlaceholder={empresaMatrizPlaceholder}
                    options={listMatrixCompanies}
                    isDisabled
                    value={empresaMatriz}
                  />

                  <ListSelect
                    labelPlaceholder={classificacaoPlaceholder}
            required={true}
                    options={listClassifications}
                    isDisabled
                    value={classificacao}
                  />

                  <InputLabel
                    name="contato"
                    type="text"
                    label={contatoPlaceholder}
            required={true}
                    value={contato || ""}
                    readOnly
                  />

                  <InputLabel
                    name="email"
                    type="text"
                    value={email || ""}
                    label="E-mail"
                    readOnly
                  />

                  <InputLabel
                    name="telefone"
                    type="text"
                    label={telefonePlaceholder}
            required={true}
                    mask={MaskInput.phone}
                    value={telefone || ""}
                    readOnly
                  />

                  <InputLabel
                    name="celular"
                    type="text"
                    label={celularPlaceholder}
                    mask={MaskInput.cellphone}
                    value={celular || ""}
                    readOnly
                  />

                  <InputLabel
                    name="contato"
                    type="text"
                    label={"Segundo Contato"}
                    maxLength={100}
                    value={contatoDois || ""}
                    readOnly
                  />

                  <InputLabel
                    name="email"
                    type="text"
                    label={"Segundo E-mail"}
                    maxLength={80}
                    value={emailDois || ""}
                    readOnly
                  />

                  <InputLabel
                    name="celular"
                    type="text"
                    label={"Segundo Celular"}
                    mask={MaskInput.cellphone}
                    value={celularDois || ""}
                    readOnly
                  />

                  {/* <ListSelect
                    labelPlaceholder={consultorPlaceholder}
            required={true}
                    options={listConsultants}
                    isDisabled
                    value={consultor}
                  /> */}

                  <ListSelect
                    labelPlaceholder={"Consultor *"}
                  required={true}
                    isDisabled
                    value={merchant}
                  />

                  <ListSelect
                    labelPlaceholder="Status"
                    value={status}
                    isDisabled
                    cacheOptions
                    defaultOptions
                    options={statusOptions}
                  />
                </TabPanel>

                {/* Dados de endereço */}
                <TabPanel>
                  <InputLabel
                    name="cep"
                    type="text"
                    label={cepPlaceholder}
            required={true}
                    mask={MaskInput.CEP}
                    readOnly
                    value={cep || ""}
                  />
                  <InputLabel
                    name="logradouro"
                    type="text"
                    label={logradouroPlaceholder}
            required={true}
                    value={logradouro || ""}
                    readOnly
                  />
                  <InputMaskDefault
                    name="numero"
                    type="text"
                    label={numeroPlaceholder}
            required={true}
                    mask={"int6"}
                    value={numero || ""}
                    readOnly
                  />
                  <InputLabel
                    name={"complemento"}
                    type="text"
                    label={complementoPlaceholder}
                    readOnly
                    maxLength={50}
                    value={complemento || ""}
                  />
                  <InputLabel
                    name={"bairro"}
                    type="text"
                    label={bairroPlaceholder}
            required={true}
                    maxLength={30}
                    value={bairro || ""}
                    readOnly
                  />
                  <AsyncListSelect
                    value={estado}
                    isDisabled
                    cacheOptions
                    defaultOptions
                    isSearchable={false}
                    labelPlaceholder={estadoPlaceholder}
            required={true}
                  />
                  <AsyncListSelect
                    labelPlaceholder={cidadePlaceholder}
            required={true}
                    key={listaCidades}
                    value={cidade}
                    cacheOptions
                    defaultOptions
                    isDisabled
                    loadingMessage={() => <FormattedMessage id="carregando" />}
                    isSearchable={false}
                  />
                </TabPanel>

                {/* Base de distribuição */}
                <TabPanel>
                  <AsyncListSelect
                    isMulti
                    labelPlaceholder={baseDistribuicaoPlaceholder}
            required={true}
                    cacheOptions
                    defaultOptions
                    value={basesDistribuicao}
                    isDisabled
                  />
                </TabPanel>
              </>
            }
          </Tabs>
        </form>
        <hr />
        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayDistributors);
