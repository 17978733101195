import React, {
  useCallback,
  useEffect,
  useState,
  createRef,
  useContext,
} from "react";
import { useTable, usePagination } from "react-table";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, FilterContainer, ContainerOptions, CreateForm, FormConsumer, TitleTax } from "./styles";

import { FormattedMessage, injectIntl } from "react-intl";

import {
  FiEdit,
  FiSave,
  FiPlus,
  FiArrowLeft,
  FiX,
  FiFileText,
  FiFilter,
  FiXCircle,
} from "react-icons/fi";

import api from "./../../../../services/api";
import MaskInput from "./../../../../config/input-mask";

import ExportTableToExcel from "./../../../../components/ExportTableToExcel";

import ButtonIcon from "./../../../../components/ButtonIcon";
import ButtonImage from "./../../../../components/ButtonImage";
import iconVoltar from "./../../../../assets/images/icone-voltar.svg";

import InputLabel from "./../../../../components/InputLabel";
import InputMaskDefault from "./../../../../components/InputMaskDefault";

import ContentSpinner from "./../../../../components/Spinner/Content";

import ExcelLogo from "./../../../../assets/images/logo-excel.png";
import AuthContext from "./../../../../context/auth";

import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";

import Button from "../../../../components/ButtonDefault";

const ListPricesFuels = ({ intl }) => {
  const [showFilter, setShowFilter] = useState(false);

  const { user } = useContext(AuthContext);

  const [loadingList, setLoadingList] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();
  const refExcel = createRef(null);


  // Campos Filtros

  const [distribuidor, setDistribuidor] = useState(() => {
    if (user.cadastro_tipo_id === 1) {
      return;
    } else {
      return {
        value: user.cadastro_id,
        label: user.razao_social,
      };
    }
  });
  const [baseDistribuicao, setBaseDistribuicao] = useState("");
  const [produto, setProduto] = useState("");

  useEffect(() => {(() => {


    if (user.cadastro_tipo_id === 2){
      loadDistribuidor(user.cadastro_id)
    } else {
      setLoadingList(false);
    }


  })();
  }, []);

  const handleCreate = useCallback(() => {
    history.push("/orders/price-fuel/create");
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const loadDistribuidor = useCallback(async(id) => {
    setLoadingList(true);

    try {
      const { data: response } = await api.get(`/cadastro/${id}`);

      /** aba base Distribuicao */
      if (response?.base_distribuicao) {
        const { data: produtos } = await api.get("/produto");

        const formattedBases = response?.base_distribuicao.map((base) => ({
          label: base.nome,
          value: base.id,
          produtos: produtos.data.map((produto) => {
            const objeto = {
              value: produto.id,
              label: produto.descricao,
              valor: "",
              vigencia: "",
              inativo: false,
            };

            return objeto;
          })
        }));

        formattedBases.map((base) => {
          response?.lista_preco_combustiveis.map((produto) => {
            if (produto.base_distribuicao.id === base.value) {
              base.produtos.map((produtoBase) => {
                if (produtoBase.value === produto.produto_id){
                  produtoBase.valor = produto.valor_produto.replace(".", ",");
                  produtoBase.vigencia = produto.vigencia_ate_produto;
                  produtoBase.inativo = produto.ativo === 0 ? false : true;
                }
              })
            }
          })
          return base
        })

        setBaseDistribuicao(formattedBases);
        setLoadingList(false)

      }

    } catch (error) {
      setLoadingList(false)
    }
  }, []);

  const handleChangeInputValorProduto = useCallback(
    (e, indexBase, indexProduto) => {

      let newValue;

      const checkValueFormat = e?.target?.value.split(",");
      if (checkValueFormat?.length <= 1) {
        newValue = e?.target?.value + ",00";
      } else if (checkValueFormat[1]?.length < 2) {
        newValue = e?.target?.value + "00";
      } else if (
        checkValueFormat[0].length <= 2 &&
        checkValueFormat[1]?.length < 2
      ) {
        newValue = checkValueFormat[0] + ",00";
      } else if (checkValueFormat[0]?.length === 0) {
        newValue = "0,00";
      } else {
        newValue = e?.target?.value;
      }

      baseDistribuicao[indexBase].produtos[indexProduto].valor = newValue;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleChangeInputVigenciaProduto = useCallback(
    (e, indexBase, indexProduto) => {
      baseDistribuicao[indexBase].produtos[indexProduto].vigencia = e.target.value;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleChangeInputInativo = useCallback(
    (e, indexBase, indexProduto) => {
      baseDistribuicao[indexBase].produtos[indexProduto].inativo = !baseDistribuicao[indexBase].produtos[indexProduto].inativo;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleUpdate = useCallback(async () => {
    setLoadingButton(true)
    var bases = [];
    var produtoFormmated = []

    baseDistribuicao.map((base) => {
      bases.push(base.value)
      base.produtos.map((product) => {
        produtoFormmated.push({
          cadastro_id: distribuidor?.value,
          id: product.id,
          base_distribuicao_id: base.value,
          produto_id: product.value,
          vigencia_ate_produto: product.inativo === true ? "2023-01-01" : product.vigencia ,
          valor_produto: product.inativo === true ? 0 : String(product.valor)?.replaceAll(
            ",",
            "."
          ),
            ativo: product.inativo === false ? 0 : 1
        })
      })
    });

    const data = {
      lista_preco_combustiveis: produtoFormmated,
      base_distribuicao: bases
    };

    try {
      if (user.cadastro_tipo_id === 1) {
        await api.put(`/cadastro/${distribuidor.value}`, data);
      } else {
        await api.put(`/cadastro-distribuidor/${distribuidor.value}`, data);
      }
      setLoadingButton(false);
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);
    } catch (error) {
      setLoadingButton(false);
      return toast.error(<FormattedMessage id="erroEditarRegistro" />);
    }

  }, [baseDistribuicao, distribuidor]);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="valoresCombustiveis" />
            </h2>
          </nav>

          <div>
            <ButtonIcon
              Icon={FiPlus}
              backgroundColor={"#030894"}
              onClick={handleCreate}
              placeholder={<FormattedMessage id="incluir" />}
              permission={
                user.cadastro_tipo_id === 1
                  ? "preco-importar[1]"
                  : "preco-importar[2]"
              }
            />

            <ButtonIcon
              Icon={FiSave}
              backgroundColor={"#4c84ec"}
              onClick={handleUpdate}
              placeholder={<FormattedMessage id="salvar" />}
              permission={true}
              loading={loadingButton}
            />

          </div>
        </header>

        <form>
          {user.cadastro_tipo_id === 1 && (
            <AsyncListSelect
              value={distribuidor}
              labelPlaceholder={<FormattedMessage id="distribuidores" />}
              placeholder={<FormattedMessage id="selecione" />}
              onChange={(e) => {
                setDistribuidor(e);
                loadDistribuidor(e.value);
              }}
              myOptions={"distribuidores"}
              cacheOptions
              defaultOptions
              isClearable
            />
          )}
        </form>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <CreateForm>
              <header>
                <p>
                  {distribuidor?.label}
                </p>
              </header>

                {baseDistribuicao === '' ? (
                    <h1>
                      <FormattedMessage id="selecioneDistribuidor" />
                    </h1>
                  ) : (
                   <ContainerOptions>
                    {baseDistribuicao.map((valorBase, indexBase) => (
                      <div key={indexBase}>
                         <main>
                           <h3>{valorBase.label}</h3>
                         </main>
                         <FormConsumer>
                           <span>
                             <strong>
                               <TitleTax>
                                 Combustível
                               </TitleTax>
                             </strong>
                             <strong>
                               <TitleTax>
                                 Valor (R$)
                               </TitleTax>
                             </strong>
                             <strong>
                               <TitleTax>
                                 Vigência
                               </TitleTax>
                             </strong>
                             {user.cadastro_tipo_id === 1 ? (
                              <strong>
                                <TitleTax>
                                  Inativo
                                </TitleTax>
                              </strong>
                             ) : (
                              ""
                             )}

                           </span>
                         </FormConsumer>

                         {user.cadastro_tipo_id === 1 && (
                          valorBase.produtos.map((produto, indexProduto) => (
                            // (produto.inativo === false) && (
                              <FormConsumer key={indexProduto}>
                                <ul>
                                  <div >
                                    <li>
                                      <p>{produto.label}</p>
                                    </li>
                                    <li>
                                      <InputMaskDefault
                                        value={produto.inativo === true ? "" : produto?.valor}
                                        onChange={(e) => {
                                          return handleChangeInputValorProduto(e, indexBase, indexProduto);
                                        }}
                                        mask="precoCombustiveis"
                                        readOnly={produto.inativo === true ? true : false}
                                      />
                                    </li>
                                    <li>
                                      <InputLabel
                                        type="date"
                                        style={{ color: "hsl(0,0%,50%)" }}
                                        value={produto.inativo === true ? "" : produto?.vigencia}
                                        onChange={(e) => {
                                          return handleChangeInputVigenciaProduto(e, indexBase, indexProduto);
                                        }}
                                        readOnly={produto.inativo === true ? true : false}
                                      />
                                    </li>
                                    <li>
                                      <input
                                        type="checkbox"
                                        value={produto?.inativo}
                                        checked={produto?.inativo === true ? true : false}
                                        onChange={(e) => handleChangeInputInativo(e, indexBase, indexProduto)}
                                      />
                                    </li>
                                  </div>
                              </ul>

                            </FormConsumer>
                          // )
                          ))
                         )}

                         {user.cadastro_tipo_id === 2 && (
                          valorBase.produtos.map((produto, indexProduto) => (
                            (produto.inativo === false) && (
                              <FormConsumer key={indexProduto}>
                                <ul>
                                  <div >
                                    <li>
                                      <p>{produto.label}</p>
                                    </li>
                                    <li>
                                    <InputMaskDefault
                                       value={produto?.valor}
                                       onChange={(e) => {
                                         return handleChangeInputValorProduto(e, indexBase, indexProduto);
                                       }}
                                       mask="currency4"
                                    />
                                    </li>
                                    <li>
                                      <InputLabel
                                        type="date"
                                        style={{ color: "hsl(0,0%,50%)" }}
                                        value={produto?.vigencia}
                                        onChange={(e) => {
                                          return handleChangeInputVigenciaProduto(e, indexBase, indexProduto);
                                        }}
                                      />
                                    </li>
                                  </div>
                              </ul>

                            </FormConsumer>
                          )
                          ))
                         )}



                     </div>
                    ))}

                 </ContainerOptions>
                  )}


            </CreateForm>
            <hr/>
          </>
        )}
      </Container>

      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>
            <AsyncListSelect
              value={produto}
              labelPlaceholder={<FormattedMessage id="produto" />}
              placeholder={<FormattedMessage id="selecionar" />}
              onChange={(e) => {
                setProduto(e);
              }}
              myOptions={"produtos"}
              cacheOptions
              defaultOptions
            />

            <AsyncListSelect
              value={baseDistribuicao}
              labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
              placeholder={<FormattedMessage id="todos" />}
              onChange={(e) => {
                setBaseDistribuicao(e);
              }}
              myOptions={"basesDistribuição"}
              cacheOptions
              defaultOptions
              isClearable
            />
          </main>

          <footer>
            {/* <Button
              type="button"
              loading={loadingButton}
              onClick={handleFilter}
            >
              <FormattedMessage id="filtrar" />
            </Button> */}
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(ListPricesFuels);
