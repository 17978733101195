import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.button`
  height: 30px;
  width: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.border ? "#fff" : props.backgroundColor};

  /* border: 1px solid #333; */
  border: ${(props) => props.border ? "0" :  "1px solid #333"};
  transition: all 0.3s;

  /* border-radius: 50%; */

  svg {
    height: 65%;
    width: 65%;
    color: ${(props) => (props.color ? props.color : "#fff")};
  }

  &:hover {
    background-color: ${(props) => props.border ?
      "#fff" : props.backgroundColor ? shade(0.3, props.backgroundColor) : "#030894"} ;

    div {
      opacity: 1;
      visibility: visible;
    }
  }

  & div {
    opacity: 0;
    visibility: hidden;
    width: max-content !important;
    padding: 2px 10px;
  }
`;

export const ToolTip = styled.div`
  display: flex;
  background-color: #c02c31;
  /* border-radius: 5px; */
  position: absolute;
  bottom: calc(100%);
  transition: all 0.7s;
  z-index: 9999;
  color: #fff;
  font-weight: 700;

  cursor: default;

  &::before {
    content: "";
    border-style: solid;
    border-color: #c02c31 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;

    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
`;
