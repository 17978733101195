import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid  #030894;
  box-shadow: transparent;
  transition: background-color 180s ease-in-out 0s;

}
    *:focus{
        outline: 0;
    }
    html , body , #root{
        height: 100%;


    }
    body{
        -webkit-font-smoothing: antialiased;


    }
    body , input , button{
      outline: none !important
    }

    a{
        text-decoration: none;

    }
    ul{
        list-style:none
    }

    li {
        list-style:none;
    }

    button{
        cursor: pointer;
    }
    svg{
        /* cursor: pointer; */
    }
`;
