import React, { useCallback, useEffect, useState, createRef } from "react";
import { useTable } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { injectIntl, FormattedMessage } from "react-intl";
import {
  FiEdit,
  FiEye,
  FiTrash2,
  FiPlus,
  FiXCircle,
} from "react-icons/fi";
import ContentSpinner from "./../../../components/Spinner/Content";
import ButtonIcon from "./../../../components/ButtonIcon";
import iconFiltro from "./../../../assets/images/icone-filtro.png";
import api from "./../../../services/api";
import iconVoltar from "./../../../assets/images/icone-voltar.svg";

import MaskInput from "./../../../config/input-mask";

import { Container, FilterContainer } from "./styles";
import OptionsList from "./../../../components/OptionsList";
import OptionsIconPlaceholder from "./../../../components/OptionsList/OptionsIconPlaceholder";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";

import Paginate from "./../../../components/Paginate";
import swal from "sweetalert";
import Alert from "./../../../assets/images/alert.png";

import ButtonImage from "./../../../components/ButtonImage";
import ExcelLogo from "./../../../assets/images/logo-excel.png";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ListDistributors = ({ intl }) => {
  //Estados Listagem
  const [distributionBases, setDistributionBases] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [cnpj] = useState("");
  const [razaoSocial] = useState("");
  const [nomeFantasia] = useState("");
  const [grupoEconomico] = useState("");
  const [perfil] = useState("");
  const [matriz] = useState("");
  const [empresaMatriz] = useState("");
  const [certificado] = useState("");
  const [status] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });

  //Estados Filtros
  const [cnpjFilter, setCnpjFilter] = useState("");
  const [razaoSocialFilter, setRazaoSocialFilter] = useState("");
  const [nomeFantasiaFilter, setNomeFantasiaFilter] = useState("");
  const [grupoEconomicoFilter, setGrupoEconomicoFilter] = useState("");
  const [perfilFilter, setPerfilFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });
  const [showFilter, setShowFilter] = useState(false);

  // Paginação
  const location = useLocation();
  const [total, setTotal] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [currentPage, setCurrentPage] = useState(() => {
    return new URLSearchParams(location.search).get("page") || 1;
  });
  const [countPage, setCountPage] = useState([]);

  const history = useHistory();

  const todosPlaceholder = intl.formatMessage({ id: "todos" });
  const desejaExcluirRegistro = intl.formatMessage({
    id: "desejaExcluirRegistro",
  });
  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const profileOptions = [
    { value: "Distribuidor", label: "Distribuidor" },
    { value: "TRR", label: "TRR" },
  ];

  const refExcel = createRef(null);

  function formatExcelData(data) {
    const dataFormat = data.map((item) => {
      return [
        {
          value: item?.cnpj || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.razaoSocial || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.fantasia || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.grupoEconomico || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.perfil || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.matriz_formattedExcel || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.empresaMatriz || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },

        {
          value: item?.status_formatted || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
      ];
    });

    return [
      {
        columns: [
          {
            title: "CNPJ",
            width: { wpx: 130 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Razão Social",
            width: { wpx: 250 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Nome Fantasia",
            width: { wpx: 250 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Grupo Econômico",
            width: { wpx: 140 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Perfil",
            width: { wpx: 80 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Matriz",
            width: { wpx: 60 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Empresa Matriz",
            width: { wpx: 350 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Status",
            width: { wpx: 70 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
        ],
        data: dataFormat,
      },
    ];
  }

  useEffect(() => {
    (async () => {
      const checkedStatus = isNaN(status?.value) ? "" : String(status?.value);
      try {
        const data = {
          cpf_cnpj: cnpj.replace(/[^\d]+/g, ""),
          razao_social: razaoSocial,
          fantasia: nomeFantasia,
          grupo_economico: grupoEconomico,
          perfil: perfil?.value,
          matriz: matriz?.value,
          empresa_matriz_id: empresaMatriz?.value,
          certificado_uploaded: certificado?.value,
          ativo: checkedStatus || "",
          cadastro_tipo_id: 2,
        };

        const { data: response } = await api.post("/cadastro/filter", data);
        const formattedList = response.data.map((cadastro) => {
          const obj = {};
          Object.assign(obj, {
            id: cadastro.id,
            cnpj: cadastro.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ),
            razaoSocial: cadastro.razao_social,
            fantasia: cadastro.fantasia,
            grupoEconomico: cadastro.grupo_economico,
            perfil: cadastro.perfil,
            matriz: cadastro.matriz,
            matriz_formatted:
              cadastro.matriz === 1 ? (
                <FormattedMessage id="opcaoSim" />
              ) : (
                <FormattedMessage id="opcaoNao" />
              ),
            matriz_formattedExcel: cadastro.matriz === 1 ? "Sim" : "Não",
            empresaMatriz: cadastro?.empresa_matriz?.razao_social
              ? cadastro?.empresa_matriz.cpf_cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                ) +
                " - " +
                cadastro?.empresa_matriz.razao_social
              : "",
            status_formatted: cadastro.ativo === 1 ? "Ativo" : "Inativo",
          });

          return obj;
        });

        setTotal(response?.total);
        setFirstPage(response?.first_page_url);
        setLastPage(response?.last_page);
        setLastPageUrl(response?.last_page_url);
        setNextPage(response?.next_page_url);
        setPrevPage(response?.prev_page_url);
        setCurrentPage(response?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page - current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = current_Page - current_Page + 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setDistributionBases(formattedList);
        setLoadingList(false);
        setShowFilter(false);
        setLoadingButton(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [
    certificado,
    status,
    cnpj,
    empresaMatriz,
    grupoEconomico,
    matriz,
    nomeFantasia,
    perfil,
    razaoSocial,
  ]);

  /** Filtro de Distribuidores */
  const handleFilter = useCallback(async () => {
    setLoadingButton(true);
    const checkedStatus = isNaN(statusFilter?.value)
      ? ""
      : String(statusFilter?.value);

    //alert(cnpjFilter.replace(/[^\d]+/g, ""));
    const data = {
      cpf_cnpj: cnpjFilter.replace(/[^\d]+/g, ""),
      razao_social: razaoSocialFilter,
      fantasia: nomeFantasiaFilter,
      grupo_economico: grupoEconomicoFilter,
      perfil: perfilFilter?.value,
      ativo: checkedStatus || "",
      cadastro_tipo_id: 2,
    };

    try {
      const { data: response } = await api.post("/cadastro/filter", data);
      const formattedList = response.data.map((cadastro) => {
        const obj = {};
        Object.assign(obj, {
          id: cadastro.id,
          cnpj: cadastro.cpf_cnpj.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          ),
          razaoSocial: cadastro.razao_social,
          fantasia: cadastro.fantasia,
          grupoEconomico: cadastro.grupo_economico,
          perfil: cadastro.perfil,
          matriz: cadastro.matriz,
          matriz_formatted:
            cadastro.matriz === 1 ? (
              <FormattedMessage id="opcaoSim" />
            ) : (
              <FormattedMessage id="opcaoNao" />
            ),
          matriz_formattedExcel: cadastro.matriz === 1 ? "Sim" : "Não",
          empresaMatriz: cadastro?.empresa_matriz
            ? cadastro?.empresa_matriz.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              cadastro?.empresa_matriz.razao_social
            : "",
          status_formatted: cadastro.ativo === 1 ? "Ativo" : "Inativo",
        });

        return obj;
      });

      setTotal(response?.total);
      setFirstPage(response?.first_page_url);
      setLastPage(response?.last_page);
      setLastPageUrl(response?.last_page_url);
      setNextPage(response?.next_page_url);
      setPrevPage(response?.prev_page_url);
      setCurrentPage(response?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setDistributionBases(formattedList);
      setLoadingList(false);
      setShowFilter(false);
      setLoadingButton(false);
    } catch (error) {
      setLoadingList(false);
      setShowFilter(false);
      setLoadingButton(false);
      return () => <h3>Erro ao carregar a lista</h3>;
    }
  }, [
    cnpjFilter,
    grupoEconomicoFilter,
    nomeFantasiaFilter,
    perfilFilter,
    razaoSocialFilter,
    statusFilter,
  ]);

  const handleCreate = useCallback(() => {
    history.push("/register/graal/distributors-trrs/create");
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleExportToExcel = useCallback(() => {
    refExcel.current.click();
  }, [refExcel]);

  const handleDisplay = useCallback(
    async (data) => {
      history.push("/register/graal/distributors-trrs/show", data);
    },
    [history]
  );

  const handleEdit = useCallback(
    (data) => {
      history.push("/register/graal/distributors-trrs/edit", data);
    },
    [history]
  );

  const handleDelete = useCallback(
    async (id) => {
      const willDelete = await swal({
        title: voceTemCerteza,
        text: desejaExcluirRegistro,
        icon: Alert,
        buttons: {
          cancel: {
            text: cancelar,
            value: null,
            visible: true,
            closeModal: true,
            className: "teste",
          },
          confirm: {
            text: opcaoSim,
            value: true,
            visible: true,
            closeModal: true,
          },
        },
        dangerMode: true,
      });

      if (willDelete) {
        try {
          await api.delete(`/cadastro/${id}`);
          setDistributionBases(
            distributionBases.filter((register) => register.id !== id)
          );
          toast.success(<FormattedMessage id="registroExcluidoComSucesso" />);
        } catch (err) {
          toast.error(<FormattedMessage id="erroExcluirRegistro" />);
        }
      }
    },
    [
      distributionBases,
      voceTemCerteza,
      desejaExcluirRegistro,
      opcaoSim,
      cancelar,
    ]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "CNPJ",
        accessor: "cnpj", // accessor is the "key" in the data
      },
      {
        Header: <FormattedMessage id="razaoSocial" />,
        accessor: "razaoSocial",
      },
      {
        Header: <FormattedMessage id="nomeFantasia" />,
        accessor: "fantasia",
      },
      {
        Header: <FormattedMessage id="grupoEconomico" />,
        accessor: "grupoEconomico",
      },
      {
        Header: <FormattedMessage id="perfil" />,
        accessor: "perfil",
      },
      {
        Header: <FormattedMessage id="matriz" />,
        accessor: "matriz_formatted",
      },
      {
        Header: <FormattedMessage id="empresaMatriz" />,
        accessor: "empresaMatriz",
      },
      {
        Header: "Status",
        accessor: "status_formatted",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: distributionBases });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // Paginação
  const handleUpdateList = useCallback(async (url) => {
    try {
      const { data: response } = await api.get(url);

      const formattedList = response.data.map((cadastro) => {
        const obj = {};
        Object.assign(obj, {
          id: cadastro.id,
          cnpj: cadastro.cpf_cnpj.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          ),
          razaoSocial: cadastro.razao_social,
          fantasia: cadastro.fantasia,
          grupoEconomico: cadastro.grupo_economico,
          perfil: cadastro.perfil,
          matriz: cadastro.matriz,
          matriz_formatted:
            cadastro.matriz === 1 ? (
              <FormattedMessage id="opcaoSim" />
            ) : (
              <FormattedMessage id="opcaoNao" />
            ),
          matriz_formattedExcel: cadastro.matriz === 1 ? "Sim" : "Não",
          empresaMatriz: cadastro?.empresa_matriz
            ? cadastro?.empresa_matriz.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              cadastro?.empresa_matriz.razao_social
            : "",
          status_formatted: cadastro.ativo === 1 ? "Ativo" : "Inativo",
        });

        return obj;
      });

      setTotal(response?.total);
      setFirstPage(response?.first_page_url);
      setLastPage(response?.last_page);
      setLastPageUrl(response?.last_page_url);
      setNextPage(response?.next_page_url);
      setPrevPage(response?.prev_page_url);
      setCurrentPage(response?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setDistributionBases(formattedList);
      setLoadingList(false);
      setShowFilter(false);
      setLoadingButton(false);
    } catch (error) {
      setLoadingList(false);
    }
  }, []);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="distribuidores_trrs" />
            </h2>
          </nav>

          <div>
            <ButtonIcon
              Icon={FiPlus}
              backgroundColor={"#030894"}
              onClick={handleCreate}
              placeholder={<FormattedMessage id="incluir" />}
              permission="cadastro-distribuidor-store[1]"
            />

                          <ButtonImage
                ImageSrc={iconFiltro}
                backgroundColor={"#030894"}
                color={"#ffffff"}
              placeholder={<FormattedMessage id="filtrar" />}
              onClick={() => setTimeout(() => setShowFilter(!showFilter), 150)}
            />

            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>
        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              <ExcelFile
                element={
                  <button style={{ display: "none" }} ref={refExcel}></button>
                }
                filename="Distribuidores"
              >
                <ExcelSheet
                  dataSet={formatExcelData(distributionBases)}
                  name="Distribuidores"
                />
              </ExcelFile>
              <table {...getTableProps()}>
                <thead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps()}>
                              {
                                // Render the header
                                column.render("Header")
                              }
                            </th>
                          ))
                        }
                        <th>
                          <FormattedMessage id="acoes" />
                        </th>
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    rows.map((row) => {
                      // Prepare the row for display

                      prepareRow(row);
                      return (
                        // Apply the row props
                        <tr {...row.getRowProps()}>
                          {
                            // Loop over the rows cells
                            row.cells.map((cell) => {
                              // Apply the cell props
                              return (
                                <td {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render("Cell")
                                  }
                                </td>
                              );
                            })
                          }
                          <td>
                            <OptionsList>
                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiEye}
                                  placeholder={<FormattedMessage id="exibir" />}
                                  onClick={() => handleDisplay(row.original.id)}
                                  permission="cadastro-distribuidor-show[1]"
                                />
                              </li>

                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiEdit}
                                  placeholder={<FormattedMessage id="editar" />}
                                  onClick={() => handleEdit(row.original.id)}
                                  permission="cadastro-distribuidor-update[1]"
                                />
                              </li>
                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiTrash2}
                                  placeholder={
                                    <FormattedMessage id="excluir" />
                                  }
                                  onClick={() => handleDelete(row.original.id)}
                                  permission="cadastro-distribuidor-destroy[1]"
                                />
                              </li>
                            </OptionsList>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </main>
            <Paginate
              handleUpdateListPaginate={handleUpdateList}
              countPage={countPage}
              currentPg={currentPage}
              firstPage={firstPage}
              lastPage={lastPage}
              lastPageUrl={lastPageUrl}
              nextPage={nextPage}
              prevPage={prevPage}
              total={total}
            />
          </>
        )}
      </Container>
      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>
            <InputLabel
              name="cnpj"
              type="text"
              label={"CNPJ"}
              mask={MaskInput.CNPJ}
              value={cnpjFilter}
              onChange={(e) => {
                setCnpjFilter(e.target.value);
              }}
            />

            <InputLabel
              name="razaoSocial"
              type="text"
              label={<FormattedMessage id="razaoSocial" />}
              maxLength={100}
              value={razaoSocialFilter}
              onChange={(e) => {
                setRazaoSocialFilter(e.target.value);
              }}
            />

            <InputLabel
              name="nomeFantasia"
              type="text"
              label={<FormattedMessage id="nomeFantasia" />}
              maxLength={80}
              value={nomeFantasiaFilter}
              onChange={(e) => {
                setNomeFantasiaFilter(e.target.value);
              }}
            />

            <InputLabel
              name="grupoEconomico"
              type="text"
              label={<FormattedMessage id="grupoEconomico" />}
              maxLength={50}
              value={grupoEconomicoFilter}
              onChange={(e) => {
                setGrupoEconomicoFilter(e.target.value);
              }}
            />

            <ListSelect
              value={perfilFilter}
              onChange={(e) => setPerfilFilter(e)}
              labelPlaceholder={<FormattedMessage id="perfil" />}
              placeholder={<FormattedMessage id="todos" />}
              cacheOptions
              defaultOptions
              options={profileOptions}
              isClearable
            />

            <ListSelect
              value={statusFilter}
              onChange={(e) => setStatusFilter(e)}
              labelPlaceholder={"Status"}
              placeholder={todosPlaceholder}
              cacheOptions
              defaultOptions
              options={statusOptions}
              isClearable
            />

            <Button
              type="button"
              themeType="default"
              onClick={handleFilter}
              loading={loadingButton}
            >
              <FormattedMessage id="filtrar" />
            </Button>
          </main>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(ListDistributors);
