import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);

  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button`
  border: none;
  background-color: transparent;
  svg, img {
    :hover {
      font-weight: bold;
      color: #c02c31;
    }
  }

  svg, img {
    animation: ${(props) => props.loading && rotate} 2s linear infinite;
    height: 18px;
    width: 18px;
  }

  :hover {
    div {
      opacity: 1;
      visibility: visible;
    }
  }

  & div {
    /* width: 100%; */
    opacity: 0;
    visibility: hidden;
    padding: 2px 10px;
    text-align: center;
  }
`;

export const ToolTip = styled.div`
  position: absolute;
  height: max-content;
  width: max-content;
  background-color: #c02c31;
  /* border-radius: 5px; */
  border: 1px solid #c02c31;
  bottom: calc(100%);
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-weight: 700;
  transition: all 0.7s;
  z-index: 1020;

  cursor: default;
`;
