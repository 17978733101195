import React, { useCallback, useEffect, useState, useContext } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import swal from "sweetalert";
import Alert from "./../../../../assets/images/alert.png";
import swalComponent from '@sweetalert/with-react'

import ListSelect from "./../../../../components/ListSelect";
import InputLabel from "./../../../../components/InputLabel";
import InputMaskDefault from "./../../../../components/InputMaskDefault";

import api from "./../../../../services/api";
import {
  Container,
  CreateForm,
  CreateFooter,
  ActionContainer,
  FormTax,
  TitleTax,
  StyleButton,
  InfoOrder,
  HeaderOrder,
  InfoMotoristaContainer,
  SelectMotivos,
  // PrazoGrid,
  ProdutoGrid
} from "./styles";

import MaskInput from "./../../../../config/input-mask";
import Button from "./../../../../components/ButtonDefault";
import ButtonIcon from "./../../../../components/ButtonIcon";
import TextArea from "./../../../../components/TextAreaLabel";
import ContentSpinner from "./../../../../components/Spinner/Content";
import CardProduct from "./CardProducts";

import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";

import { toast } from "react-toastify";

import AuthContext from "./../../../../context/auth";

import { FiXCircle, FiRefreshCw } from "react-icons/fi";

import { FaAngleDoubleRight } from "react-icons/fa";

//import Language from "./../../../context/language";

export const EditPainelOrders = ({ intl }) => {
  const timeoutRef = React.useRef(null);
  const [idCadastro, setIdCadastro] = useState("");
  const [idCotacao, setIdCotacao] = useState("");
  const [idPedido, setIdPedido] = useState("");
  const [statusPedido, setStatusPedido] = useState("");
  const [cotacao, setCotacao] = useState("");
  const [saldo, setSaldo] = useState("");
  const [limiteCredito, setLimiteCredito] = useState("");
  const [tolerancia, setTolerancia] = useState("");
  const [prazoPedido, setPrazoPedido] = useState("");
  const [versao, setVersao] = useState("");

  const [observacoes, setObservacoes] = useState("");
  const [cliente, setCliente] = useState("");
  const [baseDist, setBaseDist] = useState("");
  const [numeroCotacao, setNumeroCotacao] = useState("");
  const [date, setDate] = useState(new Date());
  const [frete, setFrete] = useState("");
  const [abastecimento, setAbastecimento] = useState("");
  const [periodo, setPeriodo] = useState("");
  const [horario, setHorario] = useState("");
  const [numeroPedido, setNumeroPedido] = useState("");
  const [numeroPedidoCliente, setNumeroPedidoCliente] = useState("");
  const [numeroNFDistribuidor, setNumeroNFDistribuidor] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [UF, setUF] = useState("");
  const [cidade, setCidade] = useState("");
  const [cep, setCep] = useState("");
  const [distancia, setDistancia] = useState("");
  const [distribuidorPreferencia, setDistribuidorPreferencia] = useState("");

  const [taxaFinanceiraTotal, setTaxaFinanceiraTotal] = useState(0);
  const [taxaFinanceiraPercentual, setTaxaFinanceiraPercentual] = useState(0);
  const [taxaAdministrativa, setTaxaAdministrativa] = useState(0);
  const [taxaAdministrativaTotal, setTaxaAdministrativaTotal] = useState(0);

  const [buttonLabelDist, setButtonLabelDist] = useState("");

  const [listaProdutos, setListaProdutos] = useState([]);
  const [listaMenorPreco, setListaMenorPreco] = useState([]);
  const [cotacaoSelected, setCotacaoSelected] = useState("");

  const [isRefresh, setIsRefresh] = useState(false);
  const [loadingButtonEnviar, setButtonEnviar] = useState(false);
  const [loadingButtonAjusteDist, setLoadingButtonAjusteDist] = useState(false);
  const [loadingButtonLiberarCotacao, setLoadingButtonLiberarCotacao] =
    useState(false);

  const [showScreen, setShowScreen] = useState(false);

  const [showEndereco, setShowEndereco] = useState(false);
  const [showInfoMotorista, setShowInfoMotorista] = useState(false);

  const [nomeMotorista, setMotorista] = useState("");
  const [cpfMotorista, setCpfMotorista] = useState("");
  const [RgMotorista, setRgMotorista] = useState("");
  const [placaVeiculo, setPlacaVeiculo] = useState("");
  const [celular, setCelular] = useState("");

  const [motivo, setMotivo] = useState("");
  const [justificativa, setJustificativa] = useState("");
  const [hasProgramacao, setHasProgramacao] = useState([]);

  const [tipoProduto, setTipoProduto] = useState([]);

  const [baseDistribuicaoId, setBaseDistribuicaoId] = useState("");

  const history = useHistory();
  const { user } = useContext(AuthContext);

  const handleBackButton = useCallback(() => {
    const painelControle = {
      painelControle: "painelControle"
    };

    if ( user.cadastro_tipo_id === 1){
      history.push("/orders/painel/v2", painelControle);

    } else {
      history.push("/orders/painel", painelControle);
    }

  },
  [history]
);

  const taxaAdministrativaPlaceholder = intl.formatMessage({
    id: "taxaAdministrativa",
  });

  const quantidadePlaceholder = intl.formatMessage({
    id: "quantidade",
  });

  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });

  useEffect(() => {

    async function loadData() {

      setShowScreen(false);
      try {
        if (!history.location?.state) {
          return history.push("/orders/painel");
        }

        let responseCadastro = [];

        const cadastro = () => new Promise((resolve, reject) => {
          timeoutRef.current = setTimeout(async ()=> {
            timeoutRef.current = null;

            responseCadastro = await api.get(
              `/cadastro/${history.location.state.original.cadastro?.id}`
            );

            resolve(responseCadastro)

          }, 1000);

        }).then(
          resolve => {
            return resolve
          }
        ).catch(
          reject => {
            console.log(reject)
          }
        )

        await cadastro()


        // const { data: responseCadastro } = await api.get(
        //   `/cadastro/${history.location.state.original.cadastro?.id}`
        // );

        setIdCadastro(history.location.state.original.cadastro?.id);

        const taxFinanceira = responseCadastro.data.taxa_financeira;

        const { data: response } = await api.post(`/pedido/filter`, {
          id: history.location.state?.id,
        });

        setTipoProduto(response.dados.data[0]?.tipo_produto)

        setVersao(response.dados.data[0]?.cadastro?.versao_sistema);

        var cotacao = [];

        setHasProgramacao(response.dados.data[0]?.programacao);

        setListaMenorPreco(history.location.state?.cotacaoIdProduto);

        // Seleciona Cotação para exibir
        if (parseInt(user.cadastro_tipo_id) !== 3) {
          cotacao = response.dados.data[0].cotacoes.filter((cot) => {
            return cot.id === history.location.state?.id_cotacao;
          });
        } else {
          cotacao = response.dados.data[0].cotacoes.filter((cot) => {
            return (
              cot.status_cotacao === "Análise Cliente" ||
              cot.status_cotacao === "Aprovado" ||
              cot.status_cotacao === "Entregue" ||
              cot.status_cotacao === "Faturado"
            );
          });

          // Caso não encontre cotação nessas condições ele pega a primeira cotação dentro do pedido. Visão Cliente
          if (cotacao.length === 0) {
            cotacao = response.dados.data[0].cotacoes.slice(0, 1);
          }
        }

        cotacao = cotacao[0];

        setMotorista(response.dados?.data[0]?.motorista_nome);
        setRgMotorista(response.dados?.data[0]?.motorista_rg);
        setCpfMotorista(response.dados?.data[0]?.motorista_cpf);
        setPlacaVeiculo(response.dados?.data[0]?.motorista_placa);
        setCelular(response.dados?.data[0]?.motorista_tel);

        setDistancia(cotacao?.distance);

        setBaseDist({
          value: cotacao.base_distribuicao?.id,
          label: cotacao.base_distribuicao?.nome,
        });

        setDistribuidorPreferencia({
          label: cotacao?.distribuidor?.razao_social || "",
          value: cotacao?.distribuidor?.id || "",
        });

        if (
          cotacao?.status_cotacao === "Análise Distribuidor/TRR" ||
          cotacao?.status_cotacao === "Reanálise Distribuidor/TRR" ||
          cotacao?.status_cotacao === "Análise Mesa de Operação"
        ) {
          // Atualiza os valores das taxas, usa as taxas que foram salvas devido o status do pedido.
          const taxAdm = responseCadastro.data.dados_cobranca.taxa_administrativa;

          setTaxaAdministrativa(
            responseCadastro.data.dados_cobranca.taxa_administrativa.replaceAll(
              ".",
              ","
            )
          );

          const listaMenorPrecoStatus = history?.location?.state?.cotacaoIdProduto;

          setBaseDistribuicaoId(cotacao?.base_distribuicao_id)

          const disponibilidadeProdutos = await cotacao.produtos_cotacao.map(
            (item) => {
              if (cotacao?.status_cotacao === "Análise Mesa de Operação" && user?.cadastro_tipo_id === 1 && item.valor_unitario !== "0.0000"){
                if (listaMenorPrecoStatus.find( id => id === item?.id )) {
                  return {
                    disponibilidade_distribuidor: "s",
                    produto_id: item.produto_id,
                  };
                } else {
                  return {
                    disponibilidade_distribuidor: "n",
                    produto_id: item.produto_id,
                  };
                }
              } else if (cotacao?.status_cotacao === "Análise Distribuidor/TRR" && user?.cadastro_tipo_id === 1) {
                if (listaMenorPrecoStatus.find( id => id === item?.id )) {
                  return {
                    disponibilidade_distribuidor: "s",
                    produto_id: item.produto_id,
                  };
                } else {
                  return {
                    disponibilidade_distribuidor: "n",
                    produto_id: item.produto_id,
                  };
                }
              } else {
                return {
                  disponibilidade_distribuidor: item.disponibilidade_distribuidor,
                  produto_id: item.produto_id,
                };
              }
            }
          );

          const formattedList = cotacao.prazos.map((prazo) => {
            let precoLitroFinal = 0;
            let totalLinhaSemTaxas = 0;
            let totalLinhaProduto = 0;
            let totalProdutoSemTaxa = 0;
            let totalProdutoComTaxa = 0;
            let idPrazo = prazo.id;
            let prazoCotacao = prazo.prazo;
            let totalLinhaFrete = 0;
            let totalLinhaTaxaFinanceira = 0;
            let totalLitros = 0;
            let taxaFinan = "";
            let totalLinhaProdutoSemTaxa = 0;
            let precoLitroSemTaxas = 0;
            // let totalTaxaFinan = 0;

            const arr = prazo.produtos.map((prod) => {
              console.log(prod)
              const obj = {};
              let filterTaxa = [];

              //Total Litros/Quantidade da Cotação
              totalLitros = prazo.produtos.reduce((total, b) => {
                const totalM3 = parseFloat(b.quantidade);
                return total + totalM3;
              }, 0);

              const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                (cot_prod) => {
                  return cot_prod.produto_id === prod.produto_id;
                }
              );

              if (!prod.taxa_financeira_percentual) {
                filterTaxa = taxFinanceira.filter((tax) => {
                  if (
                    parseInt(prazo.prazo) >= parseInt(tax.taxa_financeira_de) &&
                    parseInt(prazo.prazo) <= parseInt(tax.taxa_financeira_ate)
                  ) {
                    return true;
                  }

                  return false;
                });

                taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;

                setTaxaFinanceiraPercentual(
                  filterTaxa[0]?.taxa_financeira_percentual || 0
                );

              } else {
                filterTaxa = taxFinanceira.filter((tax) => {
                  if (
                    parseInt(prod.prazo) >= parseInt(tax.taxa_financeira_de) &&
                    parseInt(prod.prazo) <= parseInt(tax.taxa_financeira_ate)
                  ) {
                    return true;
                  }

                  return false;
                });
                taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;

                setTaxaFinanceiraPercentual(
                  filterTaxa[0]?.taxa_financeira_percentual || 0
                );
              }

              if (parseInt(user.cadastro_tipo_id) !== 2) {
                // Valor Preço Litro Final
                precoLitroFinal = (
                  parseFloat(
                    parseFloat(prod?.valor_unitario) +
                      parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ) *
                    (parseFloat(taxaFinan) / 100 + 1) +
                  parseFloat(taxAdm)
                )
                .toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });
              } else {
                // Valor Preço Litro Final
                precoLitroFinal = (
                  parseFloat(prod?.valor_unitario) +
                  parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });
              }

              // Valor Total sem taxas produto linha
              totalLinhaSemTaxas = (
                parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              // Preço Final sem Taxas (Preço + Frete)
              precoLitroSemTaxas = (
                parseFloat(prod?.valor_unitario || 0) +
                parseFloat(prod?.valor_frete || 0)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 4,
              });

              // Valor Total produto linha sem Taxas (Preço + Frete * Quantidade)
              totalLinhaProdutoSemTaxa = (
                (parseFloat(prod?.valor_unitario || 0) +
                  parseFloat(prod?.valor_frete || 0)) *
                parseFloat(prod?.quantidade)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              if (parseInt(user.cadastro_tipo_id) !== 2) {
                // Valor Total produto linha
                totalLinhaProduto = (
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100 + 1)
                    ).toFixed(4)
                  ) *
                    parseFloat(prod?.quantidade) +
                  parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                ).toFixed(2); //arredondamento;

                totalLinhaProduto = parseFloat(
                  totalLinhaProduto
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              } else {
                // Valor Total produto linha
                totalLinhaProduto = (
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0)) *
                  parseFloat(prod?.quantidade)
                ).toFixed(2);

                totalLinhaProduto = parseFloat(
                  totalLinhaProduto
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              }

              //Soma total Sem taxas Com Frete
              totalProdutoSemTaxa =
                totalProdutoSemTaxa +
                parseFloat(prod?.quantidade) *
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0));

              //Soma total Com taxas + Frete
              // Calcula o total do pedido inteiro com todas as taxas.

              // Valor total da cotação com todas as taxas
              totalProdutoComTaxa =
                parseFloat(totalProdutoComTaxa) +
                parseFloat(
                  totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                );

              totalLinhaFrete =
                parseFloat(prod?.valor_frete || 0) *
                parseFloat(prod?.quantidade);

              totalLinhaTaxaFinanceira =
                parseFloat(
                  (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    (parseFloat(taxaFinan) / 100)
                  ).toFixed(4)
                ) * parseFloat(prod?.quantidade);

              totalLinhaTaxaFinanceira = parseFloat(
                totalLinhaTaxaFinanceira.toFixed(2)
              );

              // Informações com taxa na visao dos distribuidores
              let precoLitroFinalDist;
              let totalLinhaProdutoDist;

              precoLitroFinalDist = (
                parseFloat(
                  parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                ) *
                  (parseFloat(taxaFinan) / 100 + 1) +
                parseFloat(taxAdm)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 4,
              });

              // Valor Total produto linha
              totalLinhaProdutoDist = (
                parseFloat(
                  (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    (parseFloat(taxaFinan) / 100 + 1)
                  ).toFixed(4)
                ) *
                  parseFloat(prod?.quantidade) +
                parseFloat(prod?.quantidade) * parseFloat(taxAdm)
              ).toFixed(2);

              totalLinhaProdutoDist = parseFloat(
                totalLinhaProdutoDist
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              Object.assign(obj, {
                id: prod?.id,
                id_produto: prod?.produto_id,
                disponibilidade_distribuidor: disponibilidadeProdutos.filter(
                  (i) => i.produto_id === prod?.produto_id
                )[0].disponibilidade_distribuidor,
                id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                descricao: prod.produto.descricao,
                prazo: prazo.prazo,
                quantidade: prod.quantidade || 0,
                qtdInput: //aqui
                response.dados.data[0]?.tipo_produto === 'outros' ? (
                  prod.quantidade
                ) : (
                  (parseInt(prod.quantidade) / 1000)
                    .toFixed(2)
                    ?.replaceAll(".", ",") || 0
                ),
                valor_referencia: prod?.valor_referencia
                  ? parseFloat(prod?.valor_referencia)
                      .toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                valor_unitario: prod?.valor_unitario
                  ? parseFloat(prod?.valor_unitario)
                      .toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                valor_frete: prod?.valor_frete
                  ? parseFloat(prod?.valor_frete)
                      ?.toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                total_frete: totalLinhaFrete.toFixed(4),
                total_taxa_financeira: totalLinhaTaxaFinanceira,
                preco_litro_final: precoLitroFinal,
                total: totalLinhaProduto || 0,
                total_sem_taxas: totalLinhaSemTaxas,
                taxa_financeira: taxaFinan || 0,
                total_servico:
                  (
                    parseFloat(
                      totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                    ) -
                    parseFloat(
                      totalLinhaProdutoSemTaxa
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    )
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  }) || 0
                  ,
                previsao_entrega: prod?.prazo_entrega,
                preco_total_produto_sem_taxa: precoLitroSemTaxas || 0,
                valor_total_produto_sem_taxa: totalLinhaProdutoSemTaxa || 0,
                total_dist: totalLinhaProdutoDist,
                preco_litro_final_dist: precoLitroFinalDist,
                total_servico_dist:
                  (
                    parseFloat(
                      totalLinhaProdutoDist
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    ) -
                    parseFloat(
                      totalLinhaProdutoSemTaxa
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    )
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  }) || 0,
              });

              return obj;
            });

            setTaxaAdministrativaTotal(
              parseFloat(taxAdm) * parseFloat(totalLitros)
            );
            setTaxaFinanceiraTotal(
              totalProdutoComTaxa -
                totalProdutoSemTaxa -
                parseFloat(taxAdm) * parseFloat(totalLitros)
            );

            // soma total do pedido com taxa e sem taxa
            arr.map((prod) => {
              prod.total_cotacao_sem_taxa =
                parseFloat(totalProdutoSemTaxa.toFixed(3)) || 0;
              prod.total_cotacao_com_taxa =
                parseFloat(totalProdutoComTaxa.toFixed(3)) || 0;
              return prod;
            });

            return {
              id: idPrazo,
              prazo: prazoCotacao,
              produtos: arr,
            };
          });

          setListaProdutos(formattedList);

          //     const cotacao = listaProdutos.filter((cot) => cot.id === item.id);
          if (formattedList.length === 1) {
            setCotacaoSelected(formattedList);
          }
        } else {
          // Atualiza os valores das taxas

          const taxAdm = cotacao?.taxa_administrativa_unitaria;

          setTaxaAdministrativa(
            cotacao?.taxa_administrativa_unitaria?.replaceAll(".", ",")
          );

          const listaMenorPrecoStatus = history?.location?.state?.cotacaoIdProduto;

          const disponibilidadeProdutos = await cotacao.produtos_cotacao.map(
            (item) => {
              if (cotacao?.status_cotacao === "Análise Mesa de Operação" && user?.cadastro_tipo_id === 1 && item.valor_unitario !== "0.0000"){
                if (listaMenorPrecoStatus.find( id => id === item?.id )) {
                  return {
                    disponibilidade_distribuidor: "s",
                    produto_id: item.produto_id,
                  };
                } else {
                  return {
                    disponibilidade_distribuidor: "n",
                    produto_id: item.produto_id,
                  };
                }
              } else {
                return {
                  disponibilidade_distribuidor: item.disponibilidade_distribuidor,
                  produto_id: item.produto_id,
                };
              }
            }
          );

          const formattedList = cotacao.prazos.map((prazo) => {
            let precoLitroFinal = 0;
            let totalLinhaSemTaxas = 0;
            let totalLinhaProduto = 0;
            let totalProdutoSemTaxa = 0;
            let totalProdutoComTaxa = 0;
            let idPrazo = prazo.id;
            let prazoCotacao = prazo.prazo;
            let totalLinhaFrete = 0;
            let totalLinhaTaxaFinanceira = 0;
            let totalLitros = 0;
            let taxaFinan = "";
            let totalLinhaProdutoSemTaxa = 0;
            let precoLitroSemTaxas = 0;

            const arr = prazo.produtos.map((prod) => {
              const obj = {};
              let filterTaxa = [];

              //Total Litros/Quantidade da Cotação
              totalLitros = prazo.produtos.reduce((total, b) => {
                const totalM3 = parseFloat(b.quantidade);
                return total + totalM3;
              }, 0);

              const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                (cot_prod) => {
                  return cot_prod.produto_id === prod.produto_id;
                }
              );

              if (!prod.taxa_financeira_percentual) {
                filterTaxa = taxFinanceira.filter((tax) => {
                  if (
                    parseInt(prazo.prazo) >= parseInt(tax.taxa_financeira_de) &&
                    parseInt(prazo.prazo) <= parseInt(tax.taxa_financeira_ate)
                  ) {
                    return true;
                  }

                  return false;
                });

                taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                setTaxaFinanceiraPercentual(
                  filterTaxa[0]?.taxa_financeira_percentual || 0
                );
              } else {
                taxaFinan = prod.taxa_financeira_percentual;
                setTaxaFinanceiraPercentual(prod.taxa_financeira_percentual);
              }

              if (parseInt(user.cadastro_tipo_id) !== 2) {
                // Valor Preço Litro Final
                precoLitroFinal = (
                  parseFloat(
                    parseFloat(prod?.valor_unitario) +
                      parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ) *
                    (parseFloat(taxaFinan) / 100 + 1) +
                  parseFloat(taxAdm)
                )
                .toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });

              } else {
                // Valor Preço Litro Final
                precoLitroFinal = (
                  parseFloat(prod?.valor_unitario) +
                  parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });
              }

              // Valor Total sem taxas produto linha
              totalLinhaSemTaxas = (
                parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              // Preço Final sem Taxas (Preço + Frete)
              precoLitroSemTaxas = (
                parseFloat(prod?.valor_unitario || 0) +
                parseFloat(prod?.valor_frete || 0)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 4,
              });

              // Valor Total produto linha sem Taxas (Preço + Frete * Quantidade)
              totalLinhaProdutoSemTaxa = (
                (parseFloat(prod?.valor_unitario || 0) +
                  parseFloat(prod?.valor_frete || 0)) *
                parseFloat(prod?.quantidade)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              if (parseInt(user.cadastro_tipo_id) !== 2) {
                // Valor Total produto linha
                totalLinhaProduto = (
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100 + 1)
                    ).toFixed(4)
                    // .toFixed(4)
                  ) *
                    parseFloat(prod?.quantidade) +
                  parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                 ).toFixed(2); //arredondamento;
                // .toFixed(2);

                totalLinhaProduto = parseFloat(
                  totalLinhaProduto
                ).toLocaleString("pt-br", { minimumFractionDigits: 2, });
                // .toLocaleString("pt-br", {
                //   minimumFractionDigits: 2,
                // });
              } else {
                // Valor Total produto linha
                totalLinhaProduto = (
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0)) *
                  parseFloat(prod?.quantidade)
                ).toFixed(2);

                totalLinhaProduto = parseFloat(
                  totalLinhaProduto
                )
                .toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              }

              //Soma total Sem taxas Com Frete
              totalProdutoSemTaxa =
                totalProdutoSemTaxa +
                parseFloat(prod?.quantidade) *
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0));

              //Soma total Com taxas + Frete
              // Calcula o total do pedido inteiro com todas as taxas.
             // Valor total da cotação com todas as taxas
              totalProdutoComTaxa =
                parseFloat(totalProdutoComTaxa) +
                parseFloat(
                  totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                );

              totalLinhaFrete =
                parseFloat(prod?.valor_frete || 0) *
                parseFloat(prod?.quantidade);

              totalLinhaTaxaFinanceira =
                parseFloat(
                  (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    (parseFloat(taxaFinan) / 100)
                  ).toFixed(4)
                ) * parseFloat(prod?.quantidade);

              totalLinhaTaxaFinanceira = parseFloat(
                totalLinhaTaxaFinanceira.toFixed(2)
              );

              Object.assign(obj, {
                id: prod?.id,
                id_produto: prod?.produto_id,
                disponibilidade_distribuidor: disponibilidadeProdutos.filter(
                  (i) => i.produto_id === prod?.produto_id
                )[0].disponibilidade_distribuidor,
                id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                descricao: prod.produto.descricao,
                prazo: prazo.prazo,
                quantidade: prod.quantidade || 0,
                qtdInput: //aqui
                tipoProduto === 'outros' ? (
                  prod.quantidade
                ) : (
                  (parseInt(prod.quantidade) / 1000)
                    .toFixed(2)
                    ?.replaceAll(".", ",") || 0
                ),
                valor_referencia: prod?.valor_referencia
                  ? parseFloat(prod?.valor_referencia)
                      .toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                valor_unitario: prod?.valor_unitario
                  ? parseFloat(prod?.valor_unitario)
                      .toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                valor_frete: prod?.valor_frete
                  ? parseFloat(prod?.valor_frete)
                      ?.toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                total_frete: totalLinhaFrete.toFixed(4),
                total_taxa_financeira: totalLinhaTaxaFinanceira,
                preco_litro_final: precoLitroFinal,
                total: totalLinhaProduto || 0,
                total_sem_taxas: totalLinhaSemTaxas,
                taxa_financeira: taxaFinan || 0,
                total_servico:
                  (
                    parseFloat(
                      totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                    ) -
                    parseFloat(
                      totalLinhaProdutoSemTaxa
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    )
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  }) || 0,

                preco_total_produto_sem_taxa: precoLitroSemTaxas || 0,
                valor_total_produto_sem_taxa: totalLinhaProdutoSemTaxa || 0,
              });

              return obj;
            });

            setTaxaAdministrativaTotal(
              parseFloat(taxAdm) * parseFloat(totalLitros)
            );
            setTaxaFinanceiraTotal(
              totalProdutoComTaxa -
                totalProdutoSemTaxa -
                parseFloat(taxAdm) * parseFloat(totalLitros)
            );
            // soma total do pedido com taxa e sem taxa
            arr.map((prod) => {
              prod.total_cotacao_sem_taxa =
                parseFloat(totalProdutoSemTaxa.toFixed(3)) || 0;
              prod.total_cotacao_com_taxa =
                parseFloat(totalProdutoComTaxa.toFixed(3)) || 0;
              return prod;
            });

            return {
              id: idPrazo,
              prazo: prazoCotacao,
              produtos: arr,
            };
          });

          setListaProdutos(formattedList);

          //     const cotacao = listaProdutos.filter((cot) => cot.id === item.id);
          if (formattedList.length === 1) {
            setCotacaoSelected(formattedList);
          }
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const { data: response } = await api.post(`/pedido/filter`, {
          id: history.location.state?.id,
        });

        const pedido = response.dados.data[0];

        const cliente =
          pedido.cadastro.razao_social +
          " - " +
          pedido.cadastro.cpf_cnpj.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          );

        const date = pedido.data_entrega_desejada;
        const numeroPedido = pedido.numero_pedido_sistema;
        const numeroCotacao = history.location.state?.numeroCotacao
          ? history.location.state?.numeroCotacao
          : cotacao.numero_cotacao_sistema;
        const frete = pedido.frete;
        const abastecimento = pedido.modo_abasteciemento;
        const logradouro = pedido.cadastro.endereco.logradouro;
        const numero = pedido.cadastro.endereco.numero;
        const complemento = pedido.cadastro.endereco.complemento;
        const bairro = pedido.cadastro.endereco.bairro;
        const UF = pedido.cadastro.endereco.municipio.estado;
        const cidade = pedido.cadastro.endereco.municipio.nome;
        const cep = pedido.cadastro.endereco.cep;
        const periodo = pedido.periodo_desejado;
        const horario = pedido.horario_desejado;
        const saldo = pedido.cadastro.dados_cobranca.saldo_credito_total;
        const limite_credito = pedido.cadastro.dados_cobranca.limite_credito;
        const numeroPedidoCliente = pedido.numero_pedido_cliente;
        const obs = pedido.observacao;

        // setBaseDist({
        //   value: response.dados.data[0].cotacoes[0].base_distribuicao?.id,
        //   label: response.dados.data[0].cotacoes[0].base_distribuicao?.nome,
        // });
        setIdPedido(history.location.state?.id);
        setStatusPedido(
          response.dados.data[0].status_pedido ||
            history.location.state.original?.status_pedido
        );
        setIdCotacao(cotacao?.id);
        setCotacao(cotacao?.status_cotacao);
        setCliente({ value: `${cliente}`, label: `${cliente}` });
        setDate(date);
        setNumeroPedido(numeroPedido);
        setNumeroCotacao(numeroCotacao);
        setObservacoes(obs);
        setFrete({ value: `${frete}`, label: `${frete}` });
        // setAbastecimento({
        //   value: `${abastecimento}`,
        //   label: `${abastecimento}`,
        // });
        if (pedido.modo_abasteciemento === "Bomba") {
          setAbastecimento({
            value: pedido.modo_abasteciemento,
            label: "Com Bomba",
          });
        } else {
          setAbastecimento({
            value: pedido.modo_abasteciemento,
            label: "Sem Bomba",
          });
        }
        setPeriodo({ value: `${periodo}`, label: `${periodo}` });
        setHorario(horario);
        setLogradouro(logradouro);
        setNumero(numero);
        setNumeroPedidoCliente(numeroPedidoCliente);
        setNumeroNFDistribuidor(cotacao.numero_nfe_distribuidor);
        setComplemento(complemento);
        setBairro(bairro);
        setUF(UF);
        setCidade(cidade);
        setCep(cep);

        // Info Motorista
        // setMotorista(cotacao.motorista_nome);
        // setRgMotorista(cotacao.motorista_rg);
        // setCpfMotorista(cotacao.motorista_cpf);
        // setPlacaVeiculo(cotacao.motorista_placa);
        // setCelular(cotacao.motorista_tel);

        setMotivo(pedido?.motivo_cancelamento?.motivo);
        setJustificativa(pedido?.motivo_cancelamento?.justificacao);

        setSaldo(saldo);
        setLimiteCredito(limite_credito);
        setTolerancia(pedido.cadastro.dados_cobranca.tolerancia_maxima);
        setPrazoPedido(pedido.prazo);

        setButtonLabelDist(() => {
          if (
            response.dados.data[0].cotacoes[0]?.status_cotacao ===
            "Reanálise Distribuidor/TRR"
          ) {
            return "Reenviar";
          } else {
            return "Enviar";
          }
        });

        setShowScreen(true);
      } catch (error) {
        console.log(error);
        setShowScreen(true);
      }
    }
    loadData();
  }, [history, user]);

  const handleRefreshOrder = useCallback(async () => {
    try {
      setIsRefresh(true);
      if (!history.location?.state) {
        return history.push("/orders/painel");
      }

      const { data: responseCadastro } = await api.get(
        `/cadastro/${history.location.state.original.cadastro?.id}`
      );

      setIdCadastro(history.location.state.original.cadastro?.id);

      const taxFinanceira = responseCadastro.taxa_financeira;

      const { data: response } = await api.post(`/pedido/filter`, {
        id: history.location.state?.id,
      });

      var cotacao = [];

      // Seleciona Cotação para exibir

      if (parseInt(user.cadastro_tipo_id) !== 3) {
        cotacao = response.dados.data[0].cotacoes.filter((cot) => {
          return cot.id === history.location.state?.id_cotacao;
        });
      } else {
        cotacao = response.dados.data[0].cotacoes.filter((cot) => {
          return (
            cot.status_cotacao === "Análise Cliente" ||
            cot.status_cotacao === "Aprovado" ||
            cot.status_cotacao === "Entregue" ||
            cot.status_cotacao === "Faturado"
          );
        });
      }
      cotacao = cotacao[0];

      const taxAdm = responseCadastro.dados_cobranca.taxa_administrativa;

      setTaxaAdministrativa(
        responseCadastro.dados_cobranca.taxa_administrativa.replaceAll(".", ",")
      );

      const disponibilidadeProdutos = await cotacao.produtos_cotacao.map(
        (item) => {
          if (cotacao?.status_cotacao === "Análise Mesa de Operação" && user?.cadastro_tipo_id === 1){
            if (listaMenorPreco.find( id => id === item?.id )) {
              return {
                disponibilidade_distribuidor: "s",
                produto_id: item.produto_id,
              };
            } else {
              return {
                disponibilidade_distribuidor: "n",
                produto_id: item.produto_id,
              };
            }
          } else {
            return {
              disponibilidade_distribuidor: item.disponibilidade_distribuidor,
              produto_id: item.produto_id,
            };
          }
        }
      );


      const formattedList = cotacao.prazos.map((prazo) => {
        let precoLitroFinal = 0;
        let totalLinhaSemTaxas = 0;
        let totalLinhaProduto = 0;
        let totalProdutoSemTaxa = 0;
        let totalProdutoComTaxa = 0;
        let idPrazo = prazo.id;
        let prazoCotacao = prazo.prazo;
        let totalLinhaFrete = 0;
        let totalLinhaTaxaFinanceira = 0;
        let totalLitros = 0;
        let taxaFinan = "";
        let totalLinhaProdutoSemTaxa = 0;
        let precoLitroSemTaxas = 0;

        const arr = prazo.produtos.map((prod) => {
          const obj = {};
          let filterTaxa = [];

          //Total Litros/Quantidade da Cotação
          totalLitros = prazo.produtos.reduce((total, b) => {
            const totalM3 = parseFloat(b.quantidade);
            return total + totalM3;
          }, 0);

          const ProdutoCotacao = cotacao.produtos_cotacao.filter((cot_prod) => {
            return cot_prod.produto_id === prod.produto_id;
          });

          if (!prod.taxa_financeira_percentual) {
            filterTaxa = taxFinanceira.filter((tax) => {
              if (
                parseInt(prazo.prazo) >= parseInt(tax.taxa_financeira_de) &&
                parseInt(prazo.prazo) <= parseInt(tax.taxa_financeira_ate)
              ) {
                return true;
              }

              return false;
            });

            taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;

            setTaxaFinanceiraPercentual(
              filterTaxa[0]?.taxa_financeira_percentual || 0
            );
          } else {
            filterTaxa = taxFinanceira.filter((tax) => {
              if (
                parseInt(prod.prazo) >= parseInt(tax.taxa_financeira_de) &&
                parseInt(prod.prazo) <= parseInt(tax.taxa_financeira_ate)
              ) {
                return true;
              }

              return false;
            });
            taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;

            setTaxaFinanceiraPercentual(
              filterTaxa[0]?.taxa_financeira_percentual || 0
            );
          }

          if (parseInt(user.cadastro_tipo_id) !== 2) {
            // Valor Preço Litro Final
            precoLitroFinal = (
              parseFloat(
                parseFloat(prod?.valor_unitario) +
                  parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
              ) *
                (parseFloat(taxaFinan) / 100 + 1) +
              parseFloat(taxAdm)
            )
            .toLocaleString("pt-br", {
              minimumFractionDigits: 4,
            });
          } else {
            // Valor Preço Litro Final
            precoLitroFinal = (
              parseFloat(prod?.valor_unitario) +
              parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
            ).toLocaleString("pt-br", {
              minimumFractionDigits: 4,
            });
          }

          // Valor Total sem taxas produto linha
          totalLinhaSemTaxas = (
            parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
          ).toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          });

          // Preço Final sem Taxas (Preço + Frete)
          precoLitroSemTaxas = (
            parseFloat(prod?.valor_unitario || 0) +
            parseFloat(prod?.valor_frete || 0)
          ).toLocaleString("pt-br", {
            minimumFractionDigits: 4,
          });

          // Valor Total produto linha sem Taxas (Preço + Frete * Quantidade)
          totalLinhaProdutoSemTaxa = (
            (parseFloat(prod?.valor_unitario || 0) +
              parseFloat(prod?.valor_frete || 0)) *
            parseFloat(prod?.quantidade)
          ).toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          });

          if (parseInt(user.cadastro_tipo_id) !== 2) {
            // Valor Total produto linha
            totalLinhaProduto = (
              parseFloat(
                (
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0)) *
                  (parseFloat(taxaFinan) / 100 + 1)
                ).toFixed(4)
              ) *
                parseFloat(prod?.quantidade) +
              parseFloat(prod?.quantidade) * parseFloat(taxAdm)
             ).toFixed(2); //arredondamento;

            totalLinhaProduto = parseFloat(totalLinhaProduto).toLocaleString(
              "pt-br",
              {
                minimumFractionDigits: 2,
              }
            );
          } else {
            // Valor Total produto linha
            totalLinhaProduto = (
              (parseFloat(prod?.valor_unitario) +
                parseFloat(prod?.valor_frete || 0)) *
              parseFloat(prod?.quantidade)
            ).toFixed(2);

            totalLinhaProduto = parseFloat(totalLinhaProduto).toLocaleString(
              "pt-br",
              {
                minimumFractionDigits: 2,
              }
            );
          }

          //Soma total Sem taxas Com Frete
          totalProdutoSemTaxa =
            totalProdutoSemTaxa +
            parseFloat(prod?.quantidade) *
              (parseFloat(prod?.valor_unitario) +
                parseFloat(prod?.valor_frete || 0));

          //Soma total Com taxas + Frete
          // Calcula o total do pedido inteiro com todas as taxas.
          // Valor total da cotação com todas as taxas
          totalProdutoComTaxa =
            parseFloat(totalProdutoComTaxa) +
            parseFloat(
              totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
            );

          totalLinhaFrete =
            parseFloat(prod?.valor_frete || 0) * parseFloat(prod?.quantidade);

          totalLinhaTaxaFinanceira =
            parseFloat(
              (
                (parseFloat(prod?.valor_unitario) +
                  parseFloat(prod?.valor_frete || 0)) *
                (parseFloat(taxaFinan) / 100)
              ).toFixed(4)
            ) * parseFloat(prod?.quantidade);

          totalLinhaTaxaFinanceira = parseFloat(
            totalLinhaTaxaFinanceira.toFixed(2)
          );

          Object.assign(obj, {
            id: prod?.id,
            id_produto: prod?.produto_id,
            id_cotacao_produto: ProdutoCotacao[0]?.id || "",
            disponibilidade_distribuidor: disponibilidadeProdutos.filter(
              (i) => i.produto_id === prod?.produto_id
            )[0].disponibilidade_distribuidor,
            descricao: prod.produto.descricao,
            prazo: prazo.prazo,
            quantidade: prod.quantidade || 0,
            qtdInput: //aqui
            tipoProduto === 'outros' ? (
              prod.quantidade
            ) : (
              (parseInt(prod.quantidade) / 1000)
                .toFixed(2)
                ?.replaceAll(".", ",") || 0
            ),
            valor_referencia: prod?.valor_referencia
              ? parseFloat(prod?.valor_referencia)
                  .toFixed(4)
                  ?.replaceAll(".", ",")
              : (0).toFixed(4).replaceAll(".", ","),
            valor_unitario: prod?.valor_unitario
              ? parseFloat(prod?.valor_unitario)
                  .toFixed(4)
                  ?.replaceAll(".", ",")
              : (0).toFixed(4).replaceAll(".", ","),
            valor_frete: prod?.valor_frete
              ? parseFloat(prod?.valor_frete)?.toFixed(4)?.replaceAll(".", ",")
              : (0).toFixed(4).replaceAll(".", ","),
            total_frete: totalLinhaFrete.toFixed(4),
            total_taxa_financeira: totalLinhaTaxaFinanceira,
            preco_litro_final: precoLitroFinal,
            total: totalLinhaProduto || 0,
            total_sem_taxas: totalLinhaSemTaxas,
            taxa_financeira: taxaFinan || 0,

            preco_total_produto_sem_taxa: precoLitroSemTaxas || 0,
            valor_total_produto_sem_taxa: totalLinhaProdutoSemTaxa || 0,
          });

          return obj;
        });

        setTaxaAdministrativaTotal(
          parseFloat(taxAdm) * parseFloat(totalLitros)
        );
        setTaxaFinanceiraTotal(
          totalProdutoComTaxa -
            totalProdutoSemTaxa -
            parseFloat(taxAdm) * parseFloat(totalLitros)
        );
        // soma total do pedido com taxa e sem taxa
        arr.map((prod) => {
          prod.total_cotacao_sem_taxa = totalProdutoSemTaxa || 0;
          prod.total_cotacao_com_taxa = totalProdutoComTaxa || 0;
          return prod;
        });

        return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
      });

      setListaProdutos(formattedList);

      //     const cotacao = listaProdutos.filter((cot) => cot.id === item.id);
      if (formattedList.length === 1) {
        setCotacaoSelected(formattedList);
      }
      setIsRefresh(false);
    } catch (error) {
      setIsRefresh(false);
      toast.success("Erro ao Atualizar o pedido");
      console.log(error);
    }
  }, [history, user, listaMenorPreco]);

  const handleSaveUpdateSodexo = useCallback(async () => {
    // Visão do distribuidor para enviar para a Sodexo o pedido para analise.
    setButtonEnviar(true);

    const validarPreco =  listaProdutos[0].produtos.filter(
      (preco) => (preco.valor_unitario === "0,0000" || preco.valor_unitario === "0") && preco.disponibilidade_distribuidor === 's'
    )

    if (validarPreco.length > 0) {
      toast.error("Preencha o(s) preço(s) negociado(s) do(s) produto(s).");
      setButtonEnviar(false);
      return
    }

    try {
      let produtoPrazos = [];
      let produtoPrazosDist = [];
      let produtosCotacao = [];


      listaProdutos.forEach((cotacao) => {
        cotacao.produtos.forEach((prod) => {

          const produtoCotacao = {
            id: prod.id,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),
            total: String(prod.total_sem_taxas)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            taxa_financeira: String(prod.total_taxa_financeira),
            total_com_taxa: String(prod.total)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            total_taxa: prod.total_servico
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            prazo: cotacao.prazo,
          };

          const produtoCotacaoDist = {
            id: prod.id_cotacao_produto,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final_dist)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),

            total: String(prod.valor_total_produto_sem_taxa)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            // ?.replaceAll(".", "")
            // ?.replaceAll(",", "."),
            taxa_financeira: String(prod.total_taxa_financeira),
            total_com_taxa: String(prod.total_dist)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            total_taxa: isNaN(prod.total_servico_dist)
              ? String(prod.total_servico_dist)
                  ?.replaceAll(".", "")
                  ?.replaceAll(",", ".")
              : prod.total_servico_dist.toFixed(2),

            prazo: cotacao.prazo,
          };

          produtosCotacao.push({
            id: prod.id_cotacao_produto,
            disponibilidade_distribuidor: prod.disponibilidade_distribuidor,
          });

          produtoPrazos.push(produtoCotacao);
          produtoPrazosDist.push(produtoCotacaoDist);
        });

      });

      const data = {
        status_cotacao: "Análise Mesa de Operação",
        prazos_produtos: produtoPrazos,
        produtos: produtoPrazosDist.filter(
          (item) => item.prazo === produtoPrazosDist[0].prazo
        ),
        numero_nfe_distribuidor: numeroNFDistribuidor,
        produtos_cotacao: produtosCotacao,
        base_distribuicao_id: baseDist?.value,
      };

      if (frete?.value === "FOB (Retira)") {
        Object.assign(data, {
          dados_motorista: {
            motorista_nome: nomeMotorista,
            motorista_rg: RgMotorista,
            motorista_placa: placaVeiculo,
            motorista_tel: celular,
            motorista_cpf: cpfMotorista,
          },
        });
      }

      await api.put(`/cotacao/${idCotacao}`, data);
      toast.success(<FormattedMessage id="cotacaoEnviadaComSucesso" />);
      history.goBack();

      setButtonEnviar(false);
    } catch (error) {

      if (error.response.data.validacao_distribuidor) {
        toast.error(error.response.data.validacao_distribuidor);
      } else {
        toast.error("Erro!");
      }
      setButtonEnviar(false);

    }
  }, [
    RgMotorista,
    baseDist.value,
    celular,
    cpfMotorista,
    frete.value,
    history,
    idCotacao,
    listaProdutos,
    nomeMotorista,
    numeroNFDistribuidor,
    placaVeiculo,
  ]);

  const handleSavePriceSodexo = useCallback(async () => {
    // Sodexo faz o update no status do pedido salvando os preços do distribuidor para mesa.
    setButtonEnviar(true);

    const validarPreco =  listaProdutos[0].produtos.filter(
      (preco) => (preco.valor_unitario === "0,0000" || preco.valor_unitario === "0") && preco.disponibilidade_distribuidor === 's'
    )

    if (validarPreco.length > 0) {
      toast.error("Preencha o(s) preço(s) negociado(s) do(s) produto(s).");
      setButtonEnviar(false);
      return
    }

    try {
      let produtoPrazos = [];
      let produtoPrazosDist = [];
      let produtosCotacao = [];


      listaProdutos.forEach((cotacao) => {
        cotacao.produtos.forEach((prod) => {

          const produtoCotacao = {
            id: prod.id,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),
            total: String(prod.total_sem_taxas)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            taxa_financeira: String(prod.total_taxa_financeira),
            total_com_taxa: String(prod.total)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            total_taxa: prod.total_servico
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            prazo: cotacao.prazo,
          };

          const produtoCotacaoDist = {
            id: prod.id_cotacao_produto,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final_dist)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),

            total: String(prod.valor_total_produto_sem_taxa)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            // ?.replaceAll(".", "")
            // ?.replaceAll(",", "."),
            taxa_financeira: String(prod.total_taxa_financeira),
            total_com_taxa: String(prod.total_dist)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            total_taxa: isNaN(prod.total_servico_dist)
              ? String(prod.total_servico_dist)
                  ?.replaceAll(".", "")
                  ?.replaceAll(",", ".")
              : prod.total_servico_dist.toFixed(2),

            prazo: cotacao.prazo,
          };

          produtosCotacao.push({
            id: prod.id_cotacao_produto,
            disponibilidade_distribuidor: prod.disponibilidade_distribuidor,
          });

          produtoPrazos.push(produtoCotacao);
          produtoPrazosDist.push(produtoCotacaoDist);
        });

      });

      const data = {
        status_cotacao: "Análise Mesa de Operação",
        prazos_produtos: produtoPrazos,
        produtos: produtoPrazosDist.filter(
          (item) => item.prazo === produtoPrazosDist[0].prazo
        ),
        numero_nfe_distribuidor: numeroNFDistribuidor,
        produtos_cotacao: produtosCotacao,
        base_distribuicao_id: baseDist?.value,
      };

      if (frete?.value === "FOB (Retira)") {
        Object.assign(data, {
          dados_motorista: {
            motorista_nome: nomeMotorista,
            motorista_rg: RgMotorista,
            motorista_placa: placaVeiculo,
            motorista_tel: celular,
            motorista_cpf: cpfMotorista,
          },
        });
      }

      await api.put(`/cotacao/${idCotacao}`, data);
      toast.success("Cotação salva com sucesso");
      history.goBack();

      setButtonEnviar(false);
    } catch (error) {
      console.log(error);

      setButtonEnviar(false);
      toast.error("Erro!");
    }
  }, [
    RgMotorista,
    baseDist.value,
    celular,
    cpfMotorista,
    frete.value,
    history,
    idCotacao,
    listaProdutos,
    nomeMotorista,
    numeroNFDistribuidor,
    placaVeiculo,
  ]);

  const handleAjusteDistribuidora = useCallback(async () => {
    // Função para  devolver a  cotação para analise do distribuidor.
    setLoadingButtonAjusteDist(true);

    try {
      const produtoPrazos = [];

      listaProdutos.forEach((cotacao) => {
        cotacao.produtos.forEach((prod) => {
          const produtoCotacao = {
            id: prod.id,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),

            total: String(prod.total_sem_taxas)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            // ?.replaceAll(".", "")
            // ?.replaceAll(",", "."),
            taxa_financeira: String(
              parseFloat(prod.total_taxa_financeira)?.toFixed(4)
            ),
            total_com_taxa: String(prod.total)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            prazo: cotacao.prazo,
          };

          produtoPrazos.push(produtoCotacao);
        });
      });

      const data = {
        status_cotacao: "Reanálise Distribuidor/TRR",
        prazos_produtos: produtoPrazos,
        numero_pedido_cliente: numeroPedidoCliente,
        numero_nfe_distribuidor: numeroNFDistribuidor,
        taxa_administrativa_unitaria:
          taxaAdministrativa?.replaceAll(",", ".") || 0,
        operador_id: user.user_id,
        base_distribuicao_id: baseDist?.value,
        observacao: observacoes || "",
      };

      if (frete?.value === "FOB (Retira)") {
        Object.assign(data, {
          dados_motorista: {
            motorista_nome: nomeMotorista,
            motorista_rg: RgMotorista, //?.replaceAll(".", "").replaceAll("-", ""),
            motorista_placa: placaVeiculo,
            motorista_tel: celular,
            motorista_cpf: cpfMotorista,
          },
        });
      }

      await api.put(`/cotacao/${idCotacao}`, data);

      toast.success(<FormattedMessage id="cotacaoRedirecionadaDistribuidor" />);

      setLoadingButtonAjusteDist(false);
      history.goBack();
    } catch (error) {
      console.log(error);

      setLoadingButtonAjusteDist(false);
      toast.error("Erro!");
    }
  }, [
    listaProdutos,
    numeroPedidoCliente,
    numeroNFDistribuidor,
    taxaAdministrativa,
    user.user_id,
    baseDist?.value,
    observacoes,
    frete?.value,
    idCotacao,
    history,
    nomeMotorista,
    RgMotorista,
    placaVeiculo,
    celular,
    cpfMotorista,
  ]);

  const handleLiberarCotacaoParaCliente = useCallback(async () => {
    try {

      // const validarPreco =  listaProdutos[0].produtos.filter(
      //   (preco) => (preco.valor_unitario === "0,0000" || preco.valor_unitario === "0") && preco.disponibilidade_distribuidor === 's'
      // )

      const validarPreco = []

      listaProdutos.forEach((cotacao) => {
        cotacao.produtos.forEach((prod) => {
          if ((prod.valor_unitario === "0,0000" || prod.valor_unitario === "0") && prod.disponibilidade_distribuidor === 's'){
            validarPreco.push(prod.id) // id cotação dos produtos que não foram respondidos
          }
        });
      });

      if (validarPreco.length > 0) {
        toast.error("Preencha o(s) preço(s) negociado(s) do(s) produto(s).");
        setButtonEnviar(false);
        return
      }


      const arrayPreco = []

      listaProdutos.forEach((cotacao) => {
       cotacao.produtos.forEach((prod) => {
         if (prod?.disponibilidade_distribuidor === "s") {
           arrayPreco.push(prod?.id_cotacao_produto)
         }
       });
     });

     var produtosEnviados = arrayPreco.filter((element) => {
         if(listaMenorPreco.indexOf(element) === -1){
           return element;
         }
     });

     var justificativa = "";
     var status = ""

    const MoodButton = () => (
      <SelectMotivos>
        <select id="motivos">
          <option value="" selected disabled hidden>Selecione</option>
          <option value="Preferência por um Distribuidor/TRR">Preferência por um Distribuidor/TRR</option>
          <option value="Distribuidor/TRR sem logística para atendimento do pedido">Distribuidor/TRR sem logística para atendimento do pedido</option>
          <option value="Distribuidor/TRR com restrição de produto">Distribuidor/TRR com restrição de produto</option>
        </select>
      </SelectMotivos>
    )


     if (produtosEnviados.length > 0) {
      // const erroMenorPrecoAlerta =
      await swalComponent({
         title:
           "Essa cotação contém produtos que não são melhores preços. Deseja enviar para o cliente?",
         icon: Alert,
         text: "Informe o motivo:",
         content: (
          <div>
              <MoodButton/>
          </div>
        ),
         buttons: {
           cancel: {
             text: "Não",
             value: false,
             visible: true,
             closeModal: true,
           },
           confirm: {
             text: "Sim",
             value: true,
             visible: true,
             closeModal: true,
           },
         },
         showCloseButton: true,
       }).then((value) => {

        if(value === true){
          var select = document.getElementById("motivos");
          var opcaoValor = select.options[select.selectedIndex].value;

           if (opcaoValor === null){
             status = false
           };

           if (opcaoValor === "") {
             status = false
           }

           if (opcaoValor) {
             justificativa = opcaoValor;
             status = true
           }
        }
       });
     }

     if (produtosEnviados.length > 0) {
       if (status !== true) {
         if (status === false) {
             setLoadingButtonLiberarCotacao(false);
             toast.error("Informe o motivo");
             return
         } else {
           setLoadingButtonLiberarCotacao(false);
           return;
         }
       }
      }

      if (cotacao !== "Análise Mesa de Operação") {
        const erroSaldoAlerta = await swal({
          title:
            "Deseja liberar o pedido sem o registro histórico de negociação do distribuidor/TRR no sistema?",
          text: "",
          icon: Alert,
          buttons: {
            cancel: {
              text: "Não",
              value: false,
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "Sim",
              value: true,
              visible: true,
              closeModal: true,
            },
          },
          //dangerMode: true,
          showCloseButton: true,
        });

        if (!erroSaldoAlerta) {
          setLoadingButtonLiberarCotacao(false);
          return;
        }
      }

      let produtoPrazos = [];
      let produtosCotacao = [];
      let produtosPrazoMesa = [];


      listaProdutos.forEach((cotacao) => {
        cotacao.produtos.forEach((prod) => {
          const produtoCotacao = {
            id: prod.id,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final) // Verificar campo preco_litro_final
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),

            total: String(prod.valor_total_produto_sem_taxa)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            taxa_financeira: String(
              parseFloat(prod.total_taxa_financeira)?.toFixed(4)
            ),
            total_com_taxa: String(prod.total)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            total_taxa: isNaN(prod.total_servico)
              ? String(prod.total_servico)
                  ?.replaceAll(".", "")
                  ?.replaceAll(",", ".")
              : prod.total_servico.toFixed(2),
            prazo: cotacao.prazo,
            disponibilidade_distribuidor: prod.disponibilidade_distribuidor,
            produto_id: prod.id_produto,
            descricao: prod.descricao,
            quantidade: prod.quantidade
          };

          const produtoCotacaoCliente = {
            id: prod.id_cotacao_produto,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final) // Verificar campo preco_litro_final
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),

            total: String(prod.valor_total_produto_sem_taxa)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            taxa_financeira: String(
              parseFloat(prod.total_taxa_financeira)?.toFixed(4)
            ),
            total_com_taxa: String(prod.total)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            total_taxa: isNaN(prod.total_servico)
              ? String(prod.total_servico)
                  ?.replaceAll(".", "")
                  ?.replaceAll(",", ".")
              : prod.total_servico.toFixed(2),
            prazo: cotacao.prazo,
            disponibilidade_distribuidor: prod.disponibilidade_distribuidor,
            produto_id: prod.id_produto
          };

          produtosCotacao.push({
            id: prod.id_cotacao_produto,
            disponibilidade_distribuidor: prod.disponibilidade_distribuidor,
          });
          produtoPrazos.push(produtoCotacao);
          produtosPrazoMesa.push(produtoCotacaoCliente);
        });
      });

      setLoadingButtonLiberarCotacao(true);

      const checkDisponibilidade = produtosCotacao.filter(
        (item) => item.disponibilidade_distribuidor === "n"
      );

      if (checkDisponibilidade.length > 0) {
        const erroDisponibilidade = await swal({
          title:
            "Há produtos indisponíveis nesta cotação, deseja liberar pedido?",
          text: "",
          icon: Alert,
          buttons: {
            cancel: {
              text: "Não",
              value: false,
              visible: true,
              closeModal: true,
            },
            confirm: {
              // text: "Liberar Pedido",
              text: "Sim",
              value: true,
              visible: true,
              closeModal: true,
            },
          },
          dangerMode: true,
          showCloseButton: true,
        });

        if (!erroDisponibilidade) {
          setLoadingButtonLiberarCotacao(false);
          return;
        }
      }

      var objConfirm = []
      listaProdutos.forEach((itemLista, indexPrazo) => {
        var checkPrazo = produtoPrazos.filter(
          (itemPrazo) => itemPrazo.prazo === itemLista.prazo
        );
        objConfirm.push(checkPrazo)
      })

      var totalConfirm = []
      objConfirm.forEach((itemLista) => {
        var total = 0
        itemLista.forEach((item) => {
          total = total + parseFloat(item.total_com_taxa);
        })
        totalConfirm.push(total.toFixed(2))
      })

      var statusConfirm = ""

        // const erroMenorPrecoAlerta =
        await swalComponent({
           title:
             "Confirmar informações do pedido",
          //  icon: Alert,
           text: "",
           className: "modal-confirm-lg",
           content: (
            <div>
                {objConfirm.map((itemLista, index) => {
                    return (
                      <>
                        <div>
                          <ProdutoGrid>
                            <li>
                              <b>
                                Prazo em dias
                              </b>
                            </li>
                            <li>
                              <b>
                              Taxa Financeira %
                              </b>
                            </li>
                            <li>
                              <b>
                                Taxa Administrativa R$
                              </b>
                            </li>
                            <li>
                              <b>
                                Valor Total Pedido
                              </b>
                            </li>
                          </ProdutoGrid>
                          <ul>
                            <ProdutoGrid>
                              <li>
                                {itemLista[0].prazo}
                              </li>
                              <li>
                                {itemLista[0].taxa_financeira_percentual.replace(".", ",")}
                              </li>
                              <li>
                                {taxaAdministrativa}
                              </li>
                              <li>
                                R$ {parseFloat(totalConfirm[index]).toLocaleString('pt-BR')}
                              </li>
                            </ProdutoGrid>
                          </ul>
                        </div>
                        <div style={{borderBottom: '1px solid #6666', paddingBottom: '10px'}}>
                          <ProdutoGrid>
                            <li>
                              <b>
                                Produto
                              </b>
                            </li>
                            <li>
                              <b>
                               Quantidade (M³)
                              </b>
                            </li>
                            <li>
                              <b>
                                Valor Negociado
                              </b>
                            </li>
                            <li>
                              <b>
                                Valor com Taxa
                              </b>
                            </li>
                          </ProdutoGrid>
                          <ul>
                            <ProdutoGrid>
                            {itemLista.map((item, index) => {
                              return (
                                <>
                                  <li>
                                    {item.descricao}
                                  </li>
                                  <li>
                                    {item.quantidade / 1000}
                                  </li>
                                  <li>
                                    R$ {parseFloat(item.total).toLocaleString('pt-BR').replace(".", ",")}
                                  </li>
                                  <li>
                                    R$ {parseFloat(item.total_com_taxa).toLocaleString('pt-BR')}
                                  </li>
                                </>
                              )
                            })}

                            </ProdutoGrid>
                          </ul>
                        </div>
                      </>

                    )
                  })}
            </div>
          ),
           buttons: {
             cancel: {
               text: "Cancelar",
               value: false,
               visible: true,
               closeModal: true,
             },
             confirm: {
               text: "Liberar Pedido",
               value: true,
               visible: true,
               closeModal: true,
             },
           },
           showCloseButton: true,
         }).then((value) => {
          statusConfirm = value
         });

         if(statusConfirm === false){
            setLoadingButtonLiberarCotacao(false);
            return;
         }

      var data = {
        status_cotacao:
          checkDisponibilidade.length === 0
            ? "Análise Cliente"
            : "Cotação com produto indisponível",
        prazos_produtos: produtoPrazos,
        produtos: produtosPrazoMesa.filter(
          (item) => item.prazo === produtoPrazos[0].prazo
        ),
        taxa_administrativa_unitaria:
          taxaAdministrativa?.replaceAll(",", ".") || 0,
        taxa_administrativa: taxaAdministrativaTotal,
        taxa_financeira_percentual: taxaFinanceiraPercentual,
        taxa_financeira_total: taxaFinanceiraTotal,
        numero_pedido_cliente: numeroPedidoCliente,
        numero_nfe_distribuidor: numeroNFDistribuidor,
        operador_id: user.user_id,
        produtos_cotacao: produtosCotacao,
        base_distribuicao_id: baseDist?.value,
        observacao: observacoes || "",
        motivo_liberacao: justificativa || ""
      };

      if (frete?.value === "FOB (Retira)") {
        Object.assign(data, {
          dados_motorista: {
            motorista_nome: nomeMotorista,
            motorista_rg: RgMotorista,
            motorista_placa: placaVeiculo,
            motorista_tel: celular,
            motorista_cpf: cpfMotorista,
          },
        });
      }

      await api.put(`/cotacao/${idCotacao}`, data);

      toast.success(<FormattedMessage id="cotacaoRedirecionadaCliente" />);

      setLoadingButtonLiberarCotacao(false);
      history.goBack();
    } catch (error) {
      console.log(error);
      try {
        if (error.response.data.saldo_credito_total) {
          const erroSaldoAlerta = await swal({
            title: "Saldo de crédito insuficiente para liberação da cotação.",
            text: "",
            icon: Alert,
            buttons: {
              cancel: {
                text: "Voltar",
                value: false,
                visible: true,
                closeModal: true,
              },
              confirm: {
                text: "Cancelar Pedido",
                value: true,
                visible: true,
                closeModal: true,
              },
            },
            dangerMode: true,
            showCloseButton: true,
          });

          if (erroSaldoAlerta) {
            const dataError = {
              status: "Cancelado",
              motivo: "Saldo insuficiente",
              justificacao: "Sem justificativa",
              preco: 0,
              cadastro_id: idCadastro,
            };
            setLoadingButtonLiberarCotacao(false);

            try {
              await api.post(`/reprovar/pedido/${idPedido}`, dataError);
              toast.success("Pedido reprovado com sucesso");
              history.go(0); // Refresh Window
            } catch (error) {
              console.log(error);
            }
          }
          setLoadingButtonLiberarCotacao(false);
        } else {
          toast.error("Erro!");
          setLoadingButtonLiberarCotacao(false);
        }
      } catch (error) {
        toast.error("Erro!");
      }
    }

    try {
      await api.get(`/email/cotacao-analise-cliente/${idCotacao}`);
    } catch (error) {
      toast.error("Erro no envio do e-mail para o cliente.");
    }
    toast.success("Pedido enviado para o cliente!");
  }, [
    RgMotorista,
    baseDist?.value,
    celular,
    cotacao,
    cpfMotorista,
    frete?.value,
    history,
    idCadastro,
    idCotacao,
    idPedido,
    listaProdutos,
    nomeMotorista,
    numeroNFDistribuidor,
    numeroPedidoCliente,
    observacoes,
    placaVeiculo,
    taxaAdministrativa,
    taxaAdministrativaTotal,
    taxaFinanceiraPercentual,
    taxaFinanceiraTotal,
    user.user_id,
    listaMenorPreco,
  ]);

  const handleReprovarPedidoPeloCliente = useCallback(async () => {
    const reintegrarAlert = await swal({
      title: voceTemCerteza,
      text: "Deseja reprovar o pedido?",
      icon: Alert,
      buttons: {
        cancel: {
          text: cancelar,
          value: null,
          visible: true,
          closeModal: true,
          className: "teste",
        },
        confirm: {
          text: opcaoSim,
          value: true,
          visible: true,
          closeModal: true,
        },
      },
      dangerMode: true,
    });

    if (reintegrarAlert) {
      history.push("/orders/painel/reprove", {
        pedidosId: [`${idPedido}-${statusPedido}`],
      });
    }
  }, [cancelar, history, idPedido, opcaoSim, statusPedido, voceTemCerteza]);

  const handleRenegociarPedidoPeloCliente = useCallback(async () => {
    const renegociarAlert = await swal({
      title: voceTemCerteza,
      text: "Deseja renegociar o pedido?",
      icon: Alert,
      buttons: {
        cancel: {
          text: cancelar,
          value: null,
          visible: true,
          closeModal: true,
          className: "teste",
        },
        confirm: {
          text: opcaoSim,
          value: true,
          visible: true,
          closeModal: true,
        },
      },
      dangerMode: false,
    });

    if (renegociarAlert) {
      try {
        await api.put(`/pedido/${idPedido}`, {
          status_pedido: "Cotação",
          observacao: observacoes || "",
        });

        await api.put(`/cotacao/${idCotacao}`, {
          status_cotacao: "Análise Mesa de Operação",
        });

        toast.success("Pedido renegociado com sucesso!");
        return history.push("/orders/painel");
      } catch (error) {
        toast.error("Erro ao renegociar pedido.");
      }
    }
  }, [
    cancelar,
    history,
    idCotacao,
    idPedido,
    observacoes,
    opcaoSim,
    voceTemCerteza,
  ]);

    const handleAprovacaoPedidoV2= useCallback(async () => {
    if (cotacaoSelected.length === 0 || !cotacaoSelected) {
      return toast.warning(
        <FormattedMessage id="selecionePrazoLiberarPedido" />
      );
    }

    // Valida se a cotação ainda não foi editado pelo distribuidor e bloqueia a liberação da cotação
    const checkCotacaoProdutos = cotacaoSelected[0].produtos.filter((prod) => {
      return (
        prod.valor_unitario === prod.valor_referencia && prod.valor_frete === 0
      );
    });

    if (checkCotacaoProdutos?.length === cotacaoSelected[0].produtos?.length) {
      return toast.warning(<FormattedMessage id="prazoCotacaoNaoEditado" />);
    }

    setLoadingButtonLiberarCotacao(true);

    try {
      // Atualiza prazos antes enviar para o cliente
      const produtoPrazos = [];

      const arrayPreco = []

      listaProdutos.forEach((cotacao) => {
       cotacao.produtos.forEach((prod) => {
         if (prod?.disponibilidade_distribuidor === "s") {
           arrayPreco.push(prod?.id_cotacao_produto)
         }
       });
     });

     var produtosEnviados = arrayPreco.filter((element) => {
         if(listaMenorPreco.indexOf(element) === -1){
           return element;
         }
     });

     var justificativa = "";
     var status = "";

       const MoodButton = () => (
      <SelectMotivos>
        <select id="motivos">
          <option value="" selected disabled hidden>Selecione</option>
          <option value="Preferência por um Distribuidor/TRR">Preferência por um Distribuidor/TRR</option>
          <option value="Distribuidor/TRR sem logística para atendimento do pedido">Distribuidor/TRR sem logística para atendimento do pedido</option>
          <option value="Distribuidor/TRR com restrição de produto">Distribuidor/TRR com restrição de produto</option>
        </select>
      </SelectMotivos>
    )


     if (produtosEnviados.length > 0) {
      // const erroMenorPrecoAlerta =
      await swalComponent({
         title:
           "Essa cotação contém produtos que não são melhores preços. Deseja enviar para o cliente?",
         icon: Alert,
         text: "Informe o motivo:",
         content: (
          <div>
              <MoodButton/>
          </div>
        ),
         buttons: {
           cancel: {
             text: "Não",
             value: false,
             visible: true,
             closeModal: true,
           },
           confirm: {
             text: "Sim",
             value: true,
             visible: true,
             closeModal: true,
           },
         },
         showCloseButton: true,
       }).then((value) => {

        if(value === true){
          var select = document.getElementById("motivos");
          var opcaoValor = select.options[select.selectedIndex].value;

           if (opcaoValor === null){
             status = false
           };

           if (opcaoValor === "") {
             status = false
           }

           if (opcaoValor) {
             justificativa = opcaoValor;
             status = true
           }
        }
       });
     }

     if (produtosEnviados.length > 0) {
       if (status !== true) {
         if (status === false) {
             setLoadingButtonLiberarCotacao(false);
             toast.error("Informe o motivo");
             return
         } else {
           setLoadingButtonLiberarCotacao(false);
           return;
         }
       }
      }

      listaProdutos.forEach((cotacao) => {
        cotacao.produtos.forEach((prod) => {
          const produtoCotacao = {
            id: prod.id,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),

            total: String(prod.total_sem_taxas)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            taxa_financeira: String(
              parseFloat(prod.total_taxa_financeira)?.toFixed(4)
            ),
            total_com_taxa: String(prod.total)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            prazo: cotacao.prazo,
            disponibilidade_distribuidor: prod.disponibilidade_distribuidor,
          };

          produtoPrazos.push(produtoCotacao);

        });
      });

      let produtosCotacao = [];

      let disponibilidade = []


      cotacaoSelected[0].produtos.forEach((prod) => {
        const produtos = {
          id: prod.id_cotacao_produto,
          taxa_financeira_percentual: String(prod.taxa_financeira)?.replaceAll(
            ",",
            "."
          ),
          valor_referencia: String(prod.valor_referencia)?.replaceAll(",", "."),
          valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
          valor_final: String(prod.preco_litro_final)
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
          valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),
          total: String(prod.total_sem_taxas)
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
          taxa_frete: String(prod.total_frete),
          taxa_financeira: String(
            parseFloat(prod.total_taxa_financeira)?.toFixed(4)
          ),
          total_com_taxa: String(prod.total)
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
            total_sem_taxa: String(prod.total_sem_taxas)
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
          total_taxa: prod.total_servico
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
            disponibilidade_distribuidor: prod?.disponibilidade_distribuidor,
        };
        disponibilidade.push({
          id: prod.id_cotacao_produto,
          disponibilidade_distribuidor: prod.disponibilidade_distribuidor,
        });
         produtosCotacao.push(produtos);
      });

      var taxaFinanceiraTotalCalculada = produtoPrazos.filter((item) => {
        return item.prazo === cotacaoSelected[0].prazo;
      });

      var taxaFinanceiraSomada = 0

      taxaFinanceiraTotalCalculada.forEach((taxa) => {
        taxaFinanceiraSomada = taxaFinanceiraSomada +  parseFloat(taxa.taxa_financeira)
      })


      const checkDisponibilidade = produtosCotacao.filter(
        (item) => item.disponibilidade_distribuidor === "n"
      );

      if (checkDisponibilidade.length > 0) {
        const erroDisponibilidade = await swal({
          title:
            "Há produtos indisponíveis nesta cotação, deseja liberar pedido?",
          text: "",
          icon: Alert,
          buttons: {
            cancel: {
              text: "Não",
              value: false,
              visible: true,
              closeModal: true,
            },
            confirm: {
              // text: "Liberar Pedido",
              text: "Sim",
              value: true,
              visible: true,
              closeModal: true,
            },
          },
          dangerMode: true,
          showCloseButton: true,
        });

        if (!erroDisponibilidade) {
          setLoadingButtonLiberarCotacao(false);
          return;
        }
      }

      const data = {
        // status_cotacao: "Aprovado",
        status_cotacao:
        checkDisponibilidade.length === 0
          ? "Aprovado"
          : "Cotação com produto indisponível",
        taxa_financeira_total: taxaFinanceiraSomada,
        produtos: produtosCotacao,
        prazos_produtos: produtoPrazos,
        prazo: cotacaoSelected[0].prazo,
        numero_pedido_cliente: numeroPedidoCliente,
        observacao: observacoes || "",
        // cadastro_cliente_id: user.cadastro_id,
         motivo_liberacao: justificativa || "",
         produtos_cotacao: disponibilidade,
        //  base_distribuicao_id: baseDistribuicaoId
      };


      if (frete?.value === "FOB (Retira)") {
        Object.assign(data, {
          dados_motorista: {
            motorista_nome: nomeMotorista,
            motorista_rg: RgMotorista,
            motorista_placa: placaVeiculo,
            motorista_tel: celular,
            motorista_cpf: cpfMotorista,
          },
        });
      }

      await api.put(`/cotacao/${idCotacao}`, data);
    } catch (error) {
      console.log(error);
      setLoadingButtonLiberarCotacao(false);
      toast.error("Erro ao aprovar o pedido.");
      return;
    }
    try {
      await api.get(`/email/pedido-aprovado/${idCotacao}`);
    } catch (error) {
      setLoadingButtonLiberarCotacao(false);
      toast.error("Erro no envio do e-mail do pedido.");
    }
    toast.success("Pedido aprovado com sucesso!");
    history.goBack();
    setLoadingButtonLiberarCotacao(false);
  }, [
    cotacaoSelected,
    history,
    idCotacao,
    listaProdutos,
    numeroPedidoCliente,
    observacoes,
    RgMotorista,
    celular,
    cpfMotorista,
    frete,
    nomeMotorista,
    placaVeiculo,
    baseDistribuicaoId
  ]);

  const handleAprovacaoPedidoPeloCliente = useCallback(async () => {
    if (cotacaoSelected.length === 0 || !cotacaoSelected) {
      return toast.warning(
        <FormattedMessage id="selecionePrazoLiberarPedido" />
      );
    }

    // Valida se a cotação ainda não foi editado pelo distribuidor e bloqueia a liberação da cotação
    const checkCotacaoProdutos = cotacaoSelected[0].produtos.filter((prod) => {
      return (
        prod.valor_unitario === prod.valor_referencia && prod.valor_frete === 0
      );
    });

    if (checkCotacaoProdutos?.length === cotacaoSelected[0].produtos?.length) {
      return toast.warning(<FormattedMessage id="prazoCotacaoNaoEditado" />);
    }

    setLoadingButtonLiberarCotacao(true);

    try {
      // Atualiza prazos antes enviar para o cliente
      const produtoPrazos = [];

      listaProdutos.forEach((cotacao) => {
        cotacao.produtos.forEach((prod) => {
          const produtoCotacao = {
            id: prod.id,
            taxa_financeira_percentual: String(
              prod.taxa_financeira
            )?.replaceAll(",", "."),
            valor_referencia: String(prod.valor_referencia)?.replaceAll(
              ",",
              "."
            ),
            valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
            valor_final: String(prod.preco_litro_final)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),

            total: String(prod.total_sem_taxas)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            taxa_frete: String(prod.total_frete),
            taxa_financeira: String(
              parseFloat(prod.total_taxa_financeira)?.toFixed(4)
            ),
            total_com_taxa: String(prod.total)
              ?.replaceAll(".", "")
              ?.replaceAll(",", "."),
            prazo: cotacao.prazo,
          };

          produtoPrazos.push(produtoCotacao);
        });
      });

      let produtosCotacao = [];

      cotacaoSelected[0].produtos.forEach((prod) => {
        const produtos = {
          id: prod.id_cotacao_produto,
          taxa_financeira_percentual: String(prod.taxa_financeira)?.replaceAll(
            ",",
            "."
          ),
          valor_referencia: String(prod.valor_referencia)?.replaceAll(",", "."),
          valor_unitario: String(prod.valor_unitario)?.replaceAll(",", "."),
          valor_final: String(prod.preco_litro_final)
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
          valor_frete: String(prod.valor_frete)?.replaceAll(",", "."),
          total: String(prod.total_sem_taxas)
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
          taxa_frete: String(prod.total_frete),
          taxa_financeira: String(
            parseFloat(prod.total_taxa_financeira)?.toFixed(4)
          ),
          total_com_taxa: String(prod.total)
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
            total_sem_taxa: String(prod.total_sem_taxas)
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
          total_taxa: prod.total_servico
            ?.replaceAll(".", "")
            ?.replaceAll(",", "."),
        };

        produtosCotacao.push(produtos);
      });

      var taxaFinanceiraTotalCalculada = produtoPrazos.filter((item) => {
        return item.prazo === cotacaoSelected[0].prazo;
      });

      var taxaFinanceiraSomada = 0

      taxaFinanceiraTotalCalculada.forEach((taxa) => {
        taxaFinanceiraSomada = taxaFinanceiraSomada +  parseFloat(taxa.taxa_financeira)
      })

      const data = {
        status_cotacao: "Aprovado",
        taxa_financeira_total: taxaFinanceiraSomada,
        produtos: produtosCotacao,
        prazos_produtos: produtoPrazos,
        prazo: cotacaoSelected[0].prazo,
        numero_pedido_cliente: numeroPedidoCliente,
        observacao: observacoes || "",
        // cadastro_cliente_id: user.cadastro_id,
      };


      if (frete?.value === "FOB (Retira)") {
        Object.assign(data, {
          dados_motorista: {
            motorista_nome: nomeMotorista,
            motorista_rg: RgMotorista,
            motorista_placa: placaVeiculo,
            motorista_tel: celular,
            motorista_cpf: cpfMotorista,
          },
        });
      }

      await api.put(`/cotacao/${idCotacao}`, data);
    } catch (error) {
      console.log(error);
      setLoadingButtonLiberarCotacao(false);
      toast.error("Erro ao aprovar o pedido.");
      return;
    }
    try {
      await api.get(`/email/pedido-aprovado/${idCotacao}`);
    } catch (error) {
      setLoadingButtonLiberarCotacao(false);
      toast.error("Erro no envio do e-mail do pedido.");
    }
    toast.success("Pedido aprovado com sucesso!");
    history.goBack();
    setLoadingButtonLiberarCotacao(false);
  }, [
    cotacaoSelected,
    history,
    idCotacao,
    listaProdutos,
    numeroPedidoCliente,
    observacoes,
    RgMotorista,
    celular,
    cpfMotorista,
    frete,
    nomeMotorista,
    placaVeiculo,
  ]);

  const handleChangePreco = useCallback(
    (e, item, data, index) => {
      e.persist();
      setListaProdutos((state) => {
        const newState = state.map((cotacao) => {
          let precoLitroSemTaxas = 0;
          let precoLitroFinal = 0;
          let totalLinhaProdutoSemTaxa = 0;
          let totalLinhaProdutoComTaxa = 0;
          let totalLinhaSemTaxas = 0;
          let totalProdutoSemTaxa = 0;
          let totalLinhaTaxaFinanceira = 0;
          let totalLinhaFrete = 0;
          let totalLitros = 0;

          if (user.cadastro_tipo_id === 1) {
            // Visão Sodexo
            // Preennche os dados somente no prazo que está alterando
            if (cotacao.id === item.id) {
              cotacao.produtos.map((c) => {
                if (c.id === data.id) {
                  let newValue;

                  const checkValueFormat = e?.target?.value.split(",");
                  if (checkValueFormat[1]?.length < 4) {
                    newValue = e?.target?.value + "0000";
                  } else if (e?.target?.value.length <= 3) {
                    newValue = e?.target?.value + ",0000";
                  } else if (checkValueFormat[0]?.length === 0) {
                    newValue = e?.target?.value + ",0000";
                  }

                  c.precoInput = newValue;

                  c.valor_unitario = String(
                    parseFloat(
                      String(e?.target?.value)
                        ?.replaceAll(".", "")
                        ?.replaceAll(",", ".")
                    ) || 0
                  );

                  //Total Litros/Quantidade da Cotação
                  totalLitros = cotacao.produtos.reduce((total, b) => {
                    const totalM3 = parseFloat(b.quantidade);
                    return total + totalM3;
                  }, 0);

                  //Valor Preço Litro Final Sem Taxas
                  precoLitroSemTaxas = (
                    parseFloat(
                      String(c?.valor_unitario)?.replaceAll(",", ".") || 0
                    ) + parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 4,
                  });

                  //Valor Preço Litro Final Com Taxas
                  precoLitroFinal = (
                    (parseFloat(
                      String(c?.valor_unitario)?.replaceAll(",", ".") || 0
                    ) +
                      parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)) *
                      (parseFloat(taxaFinanceiraPercentual) / 100 + 1) +
                    parseFloat(taxaAdministrativa?.replaceAll(",", "."))
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 4,
                  });

                  // Valor Total sem taxas produto linha
                  totalLinhaSemTaxas = (
                    parseFloat(c?.valor_unitario) * parseFloat(c?.quantidade)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });

                  // Valor Total produto linha Sem Taxa
                  totalLinhaProdutoSemTaxa = (
                    (parseFloat(c?.valor_unitario || 0) +
                      parseFloat(c?.valor_frete || 0)) *
                      parseFloat(c?.quantidade) || "0"
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });

                  // Valor Total produto linha Com Taxa
                  totalLinhaProdutoComTaxa = (
                    (parseFloat(
                      (
                        (parseFloat(
                          c?.valor_unitario.replaceAll(",", ".") || 0
                        ) +
                          parseFloat(
                            c?.valor_frete.replaceAll(",", ".") || 0
                          )) *
                        (parseFloat(c?.taxa_financeira) / 100 + 1) // taxa financeira alterada
                      ).toFixed(4)
                    ) +
                      parseFloat(taxaAdministrativa?.replaceAll(",", "."))) *
                      parseFloat(c?.quantidade) || "0"
                  ).toFixed(2);

                  totalLinhaProdutoComTaxa = parseFloat(
                    totalLinhaProdutoComTaxa
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });

                  //Soma total Sem taxas
                  totalProdutoSemTaxa =
                    totalProdutoSemTaxa +
                    parseFloat(c?.quantidade) * parseFloat(c?.valor_unitario);

                  totalLinhaTaxaFinanceira =
                    parseFloat(c?.valor_unitario) *
                    String(c.valor_frete)?.replaceAll(",", ".") *
                    (parseFloat(
                      String(c.taxa_financeira)?.replaceAll(",", ".")
                    ) /
                      100) *
                    parseFloat(c?.quantidade);

                  totalLinhaFrete =
                    parseFloat(c?.valor_frete || 0) * parseFloat(c?.quantidade);

                  c.preco_litro_final = precoLitroFinal;
                  c.total = totalLinhaProdutoComTaxa || 0;
                  c.total_sem_taxas = totalLinhaSemTaxas;
                  c.total_taxa_financeira = totalLinhaTaxaFinanceira.toFixed(4);
                  c.total_frete = totalLinhaFrete.toFixed(4);

                  c.valor_total_produto_sem_taxa = totalLinhaProdutoSemTaxa;
                  c.preco_total_produto_sem_taxa = precoLitroSemTaxas;
                }

                return c;
              });
            }
          } else {
            // Visão distribuidor
            //  Altera os preços de todos os prazos
            cotacao.produtos.map((c) => {
              if (c.id_produto === data.id_produto) {
                let newValue;

                const checkValueFormat = e?.target?.value.split(",");
                if (checkValueFormat[1]?.length < 4) {
                  newValue = e?.target?.value + "0000";
                } else if (e?.target?.value.length <= 3) {
                  newValue = e?.target?.value + ",0000";
                } else if (checkValueFormat[0]?.length === 0) {
                  newValue = e?.target?.value + ",0000";
                }

                c.precoInput = newValue;

                c.valor_unitario = String(
                  parseFloat(
                    String(e?.target?.value)
                      ?.replaceAll(".", "")
                      ?.replaceAll(",", ".")
                  ) || 0
                );

                //Total Litros/Quantidade da Cotação
                totalLitros = cotacao.produtos.reduce((total, b) => {
                  const totalM3 = parseFloat(b.quantidade);
                  return total + totalM3;
                }, 0);

                //Valor Preço Litro Final Sem Taxas
                precoLitroSemTaxas = (
                  parseFloat(
                    String(c?.valor_unitario)?.replaceAll(",", ".") || 0
                  ) + parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });

                //Valor Preço Litro Final Com Taxas
                precoLitroFinal = (
                  (parseFloat(
                    String(c?.valor_unitario)?.replaceAll(",", ".") || 0
                  ) +
                    parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)) *
                    (parseFloat(taxaFinanceiraPercentual) / 100 + 1) +
                  parseFloat(taxaAdministrativa?.replaceAll(",", "."))
                )
                .toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(c?.valor_unitario) * parseFloat(c?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                // Valor Total produto linha Sem Taxa
                totalLinhaProdutoSemTaxa = (
                  (parseFloat(c?.valor_unitario || 0) +
                    parseFloat(c?.valor_frete || 0)) *
                    parseFloat(c?.quantidade) || "0"
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                // Valor Total produto linha Com Taxa
                totalLinhaProdutoComTaxa = (
                  (parseFloat(
                    (
                      (parseFloat(c?.valor_unitario.replaceAll(",", ".") || 0) +
                        parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)) *
                      (parseFloat(taxaFinanceiraPercentual) / 100 + 1)
                    ).toFixed(4)
                  ) +
                    parseFloat(taxaAdministrativa?.replaceAll(",", "."))) *
                    parseFloat(c?.quantidade) || "0"
                ).toFixed(2);

                totalLinhaProdutoComTaxa = parseFloat(
                  totalLinhaProdutoComTaxa
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                //Soma total Sem taxas
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(c?.quantidade) * parseFloat(c?.valor_unitario);

                totalLinhaTaxaFinanceira =
                  parseFloat(c?.valor_unitario) *
                  (parseFloat(String(c.taxa_financeira)?.replaceAll(",", ".")) /
                    100) *
                  parseFloat(c?.quantidade);

                totalLinhaFrete =
                  parseFloat(c?.valor_frete || 0) * parseFloat(c?.quantidade);

                c.preco_litro_final = precoLitroFinal;
                c.total = totalLinhaProdutoComTaxa || 0;
                c.total_sem_taxas = totalLinhaSemTaxas;
                c.total_taxa_financeira =
                  parseFloat(totalLinhaTaxaFinanceira.toFixed(4)) || 0;
                c.total_frete = totalLinhaFrete.toFixed(4);

                c.valor_total_produto_sem_taxa = totalLinhaProdutoSemTaxa;
                c.preco_total_produto_sem_taxa = precoLitroSemTaxas;

                c.total_dist = totalLinhaProdutoComTaxa || 0;
                c.preco_litro_final_dist = precoLitroFinal;
                c.total_servico_dist =
                  parseFloat(
                    totalLinhaProdutoComTaxa
                      .replaceAll(".", "")
                      .replaceAll(",", ".")
                  ) -
                  parseFloat(
                    totalLinhaProdutoSemTaxa
                      .replaceAll(".", "")
                      .replaceAll(",", ".")
                  );
              }

              return c;
            });
          }

          let pedidoTotalSemTaxa = 0;
          let pedidoTotalComTaxa = 0;

          cotacao.produtos.map((prod) => {
            pedidoTotalSemTaxa =
              pedidoTotalSemTaxa +
                parseFloat(prod?.quantidade) *
                  (parseFloat(prod?.valor_unitario.replaceAll(",", ".")) +
                    parseFloat(prod?.valor_frete.replaceAll(",", ".") || 0)) ||
              (parseFloat(prod?.valor_referencia.replaceAll(",", ".") || 0) +
                parseFloat(prod?.valor_frete.replaceAll(",", ".") || 0)) *
                parseFloat(prod?.quantidade);

            pedidoTotalComTaxa =
              parseFloat(pedidoTotalComTaxa) +
              parseFloat(
                String(prod?.total).replaceAll(".", "").replaceAll(",", ".")
              );

            return prod;
          });

          cotacao.produtos.map((prod) => {
            prod.total_cotacao_sem_taxa = parseFloat(
              pedidoTotalSemTaxa.toFixed(2)
            );
            prod.total_cotacao_com_taxa = parseFloat(
              pedidoTotalComTaxa.toFixed(2)
            );

            return prod;
          });

          return cotacao;
        });

        return newState;
      });
    },
    [taxaAdministrativa, taxaFinanceiraPercentual, user.cadastro_tipo_id]
  );

  const handleChangeFrete = useCallback(
    (e, item, data, index) => {
      e.persist();
      setListaProdutos((state) => {
        const newState = state.map((cotacao) => {
          let precoLitroFinal = 0;
          let precoLitroSemTaxas = 0;
          let totalLinhaSemTaxas = 0;
          let totalProdutoSemTaxa = 0;
          let totalLinhaTaxaFinanceira = 0;
          let totalLinhaFrete = 0;
          let totalLitros = 0;
          let totalLinhaProdutoComTaxa = 0;
          let totalLinhaProdutoSemTaxa = 0;

          if (user.cadastro_tipo_id === 1) {
            // Visão Sodexo
            // Preennche os dados somente no prazo que está alterando
            if (cotacao.id === item.id) {
              cotacao.produtos.map((c) => {
                if (c.id === data.id) {
                  let newValue;

                  const checkValueFormat = e?.target?.value.split(",");
                  if (checkValueFormat[1]?.length < 4) {
                    newValue = e?.target?.value + "0000";
                  } else if (e?.target?.value.length <= 3) {
                    newValue = e?.target?.value + ",0000";
                  } else if (checkValueFormat[0]?.length === 0) {
                    newValue = e?.target?.value + ",0000";
                  }
                  c.freteInput = newValue;

                  c.valor_frete = String(
                    parseFloat(
                      String(e?.target?.value)
                        ?.replaceAll(".", "")
                        ?.replaceAll(",", ".")
                    ) || 0
                  );

                  //Total Litros/Quantidade da Cotação
                  totalLitros = cotacao.produtos.reduce((total, b) => {
                    const totalM3 = parseFloat(b.quantidade);
                    return total + totalM3;
                  }, 0);

                  //Valor Preço Litro Final Sem Taxas
                  precoLitroSemTaxas = (
                    parseFloat(
                      String(c?.valor_unitario)?.replaceAll(",", ".") || 0
                    ) + parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 4,
                  });

                  //Valor Preço Litro Final Com Taxas
                  precoLitroFinal = (
                    (parseFloat(
                      String(c?.valor_unitario)?.replaceAll(",", ".") || 0
                    ) +
                      parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)) *
                      (parseFloat(taxaFinanceiraPercentual) / 100 + 1) +
                    parseFloat(taxaAdministrativa?.replaceAll(",", "."))
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 4,
                  });

                  // Valor Total sem taxas produto linha (Preço * Quantidade)
                  totalLinhaSemTaxas = (
                    parseFloat(c?.valor_unitario) * parseFloat(c?.quantidade)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });

                  // Valor Total produto linha sem Taxas (Preço + Frete * Quantidade)
                  totalLinhaProdutoSemTaxa = (
                    (parseFloat(
                      c?.valor_unitario
                        ? c?.valor_unitario.replace(",", ".")
                        : 0
                    ) +
                      parseFloat(c?.valor_frete || 0)) *
                      parseFloat(c?.quantidade) || "0"
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });

                  // Valor Total produto linha Com Taxa
                  totalLinhaProdutoComTaxa = (
                    (parseFloat(
                      (
                        (parseFloat(
                          c?.valor_unitario.replaceAll(",", ".") || 0
                        ) +
                          parseFloat(
                            c?.valor_frete.replaceAll(",", ".") || 0
                          )) *
                        (parseFloat(taxaFinanceiraPercentual) / 100 + 1)
                      ).toFixed(4)
                    ) +
                      parseFloat(taxaAdministrativa?.replaceAll(",", "."))) *
                      parseFloat(c?.quantidade) || "0"
                  ).toFixed(2);

                  totalLinhaProdutoComTaxa = parseFloat(
                    totalLinhaProdutoComTaxa
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });

                  //Soma total Sem taxas
                  totalProdutoSemTaxa =
                    totalProdutoSemTaxa +
                    parseFloat(c?.quantidade) *
                      parseFloat(
                        c?.valor_unitario
                          ? c?.valor_unitario.replace(",", ".")
                          : 0
                      );

                  totalLinhaTaxaFinanceira =
                    parseFloat(
                      c?.valor_unitario
                        ? c?.valor_unitario.replace(",", ".")
                        : 0
                    ) *
                    (parseFloat(
                      String(c.taxa_financeira).replaceAll(",", ".")
                    ) /
                      100) *
                    parseFloat(c?.quantidade);

                  totalLinhaFrete =
                    parseFloat(c?.valor_frete || 0) * parseFloat(c?.quantidade);

                  c.preco_litro_final = precoLitroFinal;
                  c.total_sem_taxas = totalLinhaSemTaxas;
                  c.total_taxa_financeira = totalLinhaTaxaFinanceira.toFixed(4);
                  c.total = totalLinhaProdutoComTaxa || 0;
                  c.total_frete = totalLinhaFrete.toFixed(4);

                  c.valor_total_produto_sem_taxa = totalLinhaProdutoSemTaxa;
                  c.preco_total_produto_sem_taxa = precoLitroSemTaxas;

                  c.total_servico =
                    parseFloat(
                      totalLinhaProdutoComTaxa
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    ) -
                    parseFloat(
                      totalLinhaProdutoSemTaxa
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    );
                }

                return c;
              });
            }
          } else {
            // Visão distribuidor
            //  Altera os preços de todos os prazos

            cotacao.produtos.map((c) => {
              if (c.id_produto === data.id_produto) {
                let newValue;

                const checkValueFormat = e?.target?.value.split(",");
                if (checkValueFormat[1]?.length < 4) {
                  newValue = e?.target?.value + "0000";
                } else if (e?.target?.value.length <= 3) {
                  newValue = e?.target?.value + ",0000";
                } else if (checkValueFormat[0]?.length === 0) {
                  newValue = e?.target?.value + ",0000";
                }
                c.freteInput = newValue;

                c.valor_frete = String(
                  parseFloat(
                    String(e?.target?.value)
                      ?.replaceAll(".", "")
                      ?.replaceAll(",", ".")
                  ) || 0
                );

                //Total Litros/Quantidade da Cotação
                totalLitros = cotacao.produtos.reduce((total, b) => {
                  const totalM3 = parseFloat(b.quantidade);
                  return total + totalM3;
                }, 0);

                //Valor Preço Litro Final Sem Taxas
                precoLitroSemTaxas = (
                  parseFloat(
                    String(c?.valor_unitario)?.replaceAll(",", ".") || 0
                  ) + parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });

                //Valor Preço Litro Final Com Taxas
                precoLitroFinal = (
                  (parseFloat(
                    String(c?.valor_unitario)?.replaceAll(",", ".") || 0
                  ) +
                    parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)) *
                    (parseFloat(taxaFinanceiraPercentual) / 100 + 1) +
                  parseFloat(taxaAdministrativa?.replaceAll(",", "."))
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });

                // Valor Total sem taxas produto linha (Preço * Quantidade)
                totalLinhaSemTaxas = (
                  parseFloat(c?.valor_unitario) * parseFloat(c?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                // Valor Total produto linha sem Taxas (Preço + Frete * Quantidade)
                totalLinhaProdutoSemTaxa = (
                  (parseFloat(
                    c?.valor_unitario ? c?.valor_unitario.replace(",", ".") : 0
                  ) +
                    parseFloat(c?.valor_frete || 0)) *
                    parseFloat(c?.quantidade) || "0"
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                // Valor Total produto linha Com Taxa
                totalLinhaProdutoComTaxa = (
                  (parseFloat(
                    (
                      (parseFloat(c?.valor_unitario.replaceAll(",", ".") || 0) +
                        parseFloat(c?.valor_frete.replaceAll(",", ".") || 0)) *
                      (parseFloat(taxaFinanceiraPercentual) / 100 + 1)
                    ).toFixed(4)
                  ) +
                    parseFloat(taxaAdministrativa?.replaceAll(",", "."))) *
                    parseFloat(c?.quantidade) || "0"
                ).toFixed(2);

                totalLinhaProdutoComTaxa = parseFloat(
                  totalLinhaProdutoComTaxa
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                //Soma total Sem taxas
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(c?.quantidade) *
                    parseFloat(
                      c?.valor_unitario
                        ? c?.valor_unitario.replace(",", ".")
                        : 0
                    );

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    c?.valor_unitario ? c?.valor_unitario.replace(",", ".") : 0
                  ) *
                  (parseFloat(String(c.taxa_financeira).replaceAll(",", ".")) /
                    100) *
                  parseFloat(c?.quantidade);

                totalLinhaFrete =
                  parseFloat(c?.valor_frete || 0) * parseFloat(c?.quantidade);

                c.preco_litro_final = precoLitroFinal;
                c.total_sem_taxas = totalLinhaSemTaxas;
                c.total_taxa_financeira = totalLinhaTaxaFinanceira.toFixed(4);
                c.total = totalLinhaProdutoComTaxa || 0;
                c.total_frete = totalLinhaFrete.toFixed(4);

                c.valor_total_produto_sem_taxa = totalLinhaProdutoSemTaxa;
                c.preco_total_produto_sem_taxa = precoLitroSemTaxas;

                c.total_dist = totalLinhaProdutoComTaxa || 0;
                c.preco_litro_final_dist = precoLitroFinal;
                c.total_servico_dist =
                  parseFloat(
                    totalLinhaProdutoComTaxa
                      .replaceAll(".", "")
                      .replaceAll(",", ".")
                  ) -
                  parseFloat(
                    totalLinhaProdutoSemTaxa
                      .replaceAll(".", "")
                      .replaceAll(",", ".")
                  );
              }

              return c;
            });
          }

          let pedidoTotalSemTaxa = 0;
          let pedidoTotalComTaxa = 0;

          cotacao.produtos.map((prod) => {
            pedidoTotalSemTaxa =
              pedidoTotalSemTaxa +
                parseFloat(prod?.quantidade) *
                  (parseFloat(prod?.valor_unitario.replaceAll(",", ".")) +
                    parseFloat(prod?.valor_frete || 0)) ||
              (parseFloat(prod?.valor_referencia.replaceAll(",", ".") || 0) +
                parseFloat(prod?.valor_frete || 0)) *
                parseFloat(prod?.quantidade);

            //Soma total Com taxas
            pedidoTotalComTaxa =
              parseFloat(pedidoTotalComTaxa) +
              parseFloat(
                String(prod?.total).replaceAll(".", "").replaceAll(",", ".")
              );

            return prod;
          });

          cotacao.produtos.map((prod) => {
            prod.total_cotacao_sem_taxa = parseFloat(
              pedidoTotalSemTaxa.toFixed(2)
            );
            prod.total_cotacao_com_taxa = parseFloat(
              pedidoTotalComTaxa.toFixed(2)
            );

            return prod;
          });

          return cotacao;
        });

        return newState;
      });
    },
    [taxaAdministrativa, taxaFinanceiraPercentual, user.cadastro_tipo_id]
  );

  const handleChangePrecoReferencia = useCallback(
    (e, item, data, index) => {
      e.persist();
      setListaProdutos((state) => {
        const newState = state.map((cotacao) => {
          if (user.cadastro_tipo_id === 1) {
            // Visão Sodexo
            // Preennche os dados somente no prazo que está alterando
            if (cotacao.id === item.id) {
              cotacao.produtos.map((c) => {
                if (c.id === data.id) {
                  let newValue;

                  const checkValueFormat = e?.target?.value.split(",");
                  if (checkValueFormat[1]?.length < 4) {
                    newValue = e?.target?.value + "0000";
                  } else if (e?.target?.value.length <= 3) {
                    newValue = e?.target?.value + ",0000";
                  } else if (checkValueFormat[0]?.length === 0) {
                    newValue = e?.target?.value + ",0000";
                  }

                  c.precoRefInput = newValue;

                  c.valor_referencia = String(
                    parseFloat(
                      String(e?.target?.value)
                        ?.replaceAll(".", "")
                        ?.replaceAll(",", ".")
                    ) || 0
                  );
                }

                return c;
              });
            }
          } else {
            // Visão distribuidor
            //  Altera os preços de todos os prazos
            cotacao.produtos.map((c) => {
              if (c.id_produto === data.id_produto) {
                let newValue;

                const checkValueFormat = e?.target?.value.split(",");
                if (checkValueFormat[1]?.length < 4) {
                  newValue = e?.target?.value + "0000";
                } else if (e?.target?.value.length <= 3) {
                  newValue = e?.target?.value + ",0000";
                } else if (checkValueFormat[0]?.length === 0) {
                  newValue = e?.target?.value + ",0000";
                }

                c.precoRefInput = newValue;

                c.valor_referencia = String(
                  parseFloat(
                    String(e?.target?.value)
                      ?.replaceAll(".", "")
                      ?.replaceAll(",", ".")
                  ) || 0
                );
              }

              return c;
            });
          }

          return cotacao;
        });

        return newState;
      });
    },
    [user.cadastro_tipo_id]
  );

  const handleSelectCotacao = useCallback(
    (e, item) => {
      const cotacao = listaProdutos.filter((cot) => cot.id === item.id);

      setCotacaoSelected(cotacao);
    },
    [listaProdutos]
  );

  const handleViewOrdersProgramed = useCallback(() => {
    history.push("/orders/painel/program", { numeroPedido });
  }, [history, numeroPedido]);

  const handleChangeDisponibilidade = useCallback((e, item, data, index) => {
    e.persist();

    const inputs = document.querySelectorAll("input[type=checkbox]");

    setListaProdutos((state) => {
      const newState = state.map((cotacao) => {
        cotacao.produtos.map((c) => {
          if (c.id_produto === data.id_produto) {
            c.disponibilidade_distribuidor =
              e.target.checked === true ? "s" : "n";
          }

          return c;
        });

        return cotacao;
      });

      inputs.forEach((input) => {
        // eslint-disable-next-line
        if (input.value == data.id_produto && e.target.checked === true) {
          input.checked = true;
        } else if (
          // eslint-disable-next-line
          input.value == data.id_produto &&
          e.target.checked === false
        ) {
          input.checked = false;
        }
      });
      return newState;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadBases = useCallback(async () => {
    // Cadastro do Cliente que fez o pedido
    const idCadastro = history.location?.state?.distribuidor_id;

    if (!idCadastro) {
      return;
    }

    try {
      const { data: response } = await api.get(`/cadastro/${idCadastro}`);

      const base = response.base_distribuicao.map((item) => {
        const obj = {};

        Object.assign(obj, {
          value: item.id,
          label: item.nome,
        });

        return obj;
      });

      return base;
    } catch (error) {
      console.log(error);
    }
  }, [history]);

  return (
    <>
      <Container>
        {showScreen ? (
          <CreateForm>
            <header>
              <h4>
                <FormattedMessage id="pedidoCotacao" />
              </h4>
            </header>

            <form>
              {parseInt(user.cadastro_tipo_id) === 1 ? (
                <>
                  <HeaderOrder>
                    {parseInt(user.cadastro_tipo_id) !== 2 &&
                    hasProgramacao?.length > 0 ? (
                      <div style={{ width: "150px" }}>
                        <Button
                          type="button"
                          onClick={handleViewOrdersProgramed}
                        >
                          {<FormattedMessage id="pedidosProgramado" />}
                        </Button>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <div style={{ float: "right" }}>
                      <InfoOrder>
                        <div>
                          <p>
                            <FormattedMessage id="limiteCreditoDisponivel" />
                          </p>
                          <strong>
                            {isNaN(limiteCredito)
                              ? (0).toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : parseFloat(
                                  limiteCredito * (tolerancia / 100 + 1) || 0
                                ).toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "BRL",
                                })}
                          </strong>
                        </div>
                        <div>
                          <p>
                            <FormattedMessage id="seuLimiteSaldoDisponivel" />
                          </p>
                          <strong>
                            {isNaN(saldo)
                              ? (0).toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : parseFloat(saldo || 0).toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "BRL",
                                })}
                          </strong>
                        </div>

                        <div style={{ width: "100px", marginBottom: "-5px" }}>
                          <h5>Status</h5>
                          <h5>{cotacao}</h5>
                        </div>
                      </InfoOrder>
                    </div>
                  </HeaderOrder>
                </>
              ) : parseInt(user.cadastro_tipo_id) === 3 ? (
                <HeaderOrder>
                  {parseInt(user.cadastro_tipo_id) !== 2 &&
                  hasProgramacao?.length > 0 ? (
                    <div style={{ width: "150px" }}>
                      <Button type="button" onClick={handleViewOrdersProgramed}>
                        {<FormattedMessage id="pedidosProgramado" />}
                      </Button>
                      {/* <span>{hasProgramacao[0]}</span> */}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </HeaderOrder>
              ) : (
                <></>
              )}

              <div className="sideBySide">
                <div>
                  <header className="header-two">
                    <h4>
                      <FormattedMessage id="dadosPedido" />
                    </h4>
                  </header>
                </div>

                {parseInt(user.cadastro_tipo_id) === 2
                  ? (statusPedido === "Aprovado" ||
                      statusPedido === "Aguardando Faturamento" ||
                      statusPedido === "Faturado") && (
                      <ListSelect
                        labelPlaceholder={<FormattedMessage id="cliente" />}
                        value={cliente}
                        isDisabled={true}
                        readOnly
                      />
                    )
                  : null}

                {parseInt(user.cadastro_tipo_id) === 1 ? (
                  <ListSelect
                    labelPlaceholder={<FormattedMessage id="cliente" />}
                    value={cliente}
                    isDisabled={true}
                    onChange={(e) => setCliente(e)}
                  />
                ) : (
                  <InputLabel
                    name="cotacao"
                    label={"Status"}
                    type="text"
                    value={cotacao}
                    readOnly
                  />
                )}

                <InputMaskDefault
                  mask={"int15"}
                  label={<FormattedMessage id="numPedidoSistema" />}
                  type="text"
                  value={numeroPedido}
                  onChange={(e) => setNumeroPedido(e)}
                  readOnly
                />

                <InputLabel
                  label={<FormattedMessage id="numeroCotacao" />}
                  type="text"
                  value={numeroCotacao}
                  onChange={(e) => setNumeroCotacao(e)}
                  readOnly
                />

                <InputMaskDefault
                  mask={"int15"}
                  label={<FormattedMessage id="numeroPedidoCliente" />}
                  type="text"
                  value={numeroPedidoCliente}
                  onChange={(e) => setNumeroPedidoCliente(e.target.value)}
                  readOnly={user.cadastro_tipo_id !== 2 ? false : true}
                />

                {/* {parseInt(user.cadastro_tipo_id) !== 3 && (
                  <InputMaskDefault
                    mask={"int15"}
                    label={<FormattedMessage id="nfDistribuidorTRR" />}
                    type="text"
                    onChange={(e) => setNumeroNFDistribuidor(e.target.value)}
                  />
                )} */}

                <TextArea
                  maxLength={"200"}
                  placeholder={"Observações - Máx. 200 caracteres"}
                  value={observacoes}
                  onChange={(e) => setObservacoes(e.target.value)}
                  readOnly={user.cadastro_tipo_id !== 2 ? false : true}
                />

                {parseInt(user.cadastro_tipo_id) === 1 ? (
                  <>
                    <InputMaskDefault
                      mask={"currency7"}
                      label={taxaAdministrativaPlaceholder + " R$:"}
                      type="text"
                      value={taxaAdministrativa}
                      // onChange={(e) => {
                      //   setTaxaAdministrativa(
                      //     e.target.value ? e.target.value : 0
                      //   );
                      // }}
                      // onKeyUp={(e) => {
                      //   handleChangeTaxaAdm(e);
                      // }}
                      readOnly
                    />

                    {/* <InputMaskDefault
                      mask={"currency4"}
                      label={"Taxa Financeira %:"}
                      type="text"
                      value={taxaFinanceira}
                      onChange={(e) => {
                        setTaxaFinanceira(e.target.value || 0);
                      }}
                      onKeyUp={() => {
                        handleCalculateTotal();
                      }}
                    /> */}
                  </>
                ) : (
                  ""
                )}

                {/* <InputMaskDefault
                  mask={"currency2"}
                  name="Valor total Pedido"
                  value={totalPedidoCalculado.toString().replaceAll(".", ",")}
                  label={"Valor Pedido:"}
                  type="text"
                  readOnly
                /> */}

                {cotacao === "Análise Distribuidor/TRR" ||
                cotacao === "Reanálise Distribuidor/TRR" ||
                cotacao === "Análise Mesa de Operação"
                  ? user.cadastro_tipo_id !== 2 && (
                      <ButtonIcon
                        Icon={FiRefreshCw}
                        backgroundColor={"#52e378"}
                        onClick={handleRefreshOrder}
                        loading={isRefresh}
                        placeholder={<FormattedMessage id="atualizarTaxas" />}
                      />
                    )
                  : null}
              </div>

              <div className="sideBySideTwo">
                <div>
                  <header className="header-two">
                    <h4>
                      <FormattedMessage id="dadosEntrega" />
                    </h4>
                  </header>
                </div>

                <InputLabel
                  name="date"
                  label={<FormattedMessage id="dataEntregaDesejada" />}
                  type="date"
                  style={{ color: "hsl(0,0%,50%)" }}
                  value={date}
                  onChange={(e) => setDate(e)}
                  readOnly
                />

                <div>
                  <ListSelect
                    labelPlaceholder={<FormattedMessage id="frete" />}
                    value={frete}
                    isDisabled={true}
                    onChange={(e) => setFrete(e)}
                  />
                  {frete?.value === "FOB (Retira)" && (
                    // <ButtonIcon
                    //   backgroundColor={"#030894"}
                    //   type={"button"}
                    //   onClick={() => {
                    //     setShowEndereco(false);
                    //     setShowInfoMotorista(!showInfoMotorista);
                    //   }}
                    // />
                    <Button
                      type="button"
                      onClick={() => {
                        setShowEndereco(false);
                        setShowInfoMotorista(!showInfoMotorista);
                      }}
                    >
                      {<FormattedMessage id="infoMotorista" />}
                    </Button>
                  )}
                </div>

                <ListSelect
                  labelPlaceholder={<FormattedMessage id="modoAbastecimento" />}
                  placeholder={<FormattedMessage id="selecionar" />}
                  value={abastecimento}
                  cacheOptions
                  defaultOptions
                  onChange={(e) => setAbastecimento(e)}
                  isDisabled={true}
                />

                <StyleButton>
                  <p>
                    <FormattedMessage id="endereco" />:
                  </p>
                  <div>
                    <ButtonIcon
                      Icon={FaAngleDoubleRight}
                      backgroundColor={"#c02c31"}
                      type={"button"}
                      onClick={() => {
                        setShowInfoMotorista(false);
                        setShowEndereco(!showEndereco);
                      }}
                    />
                  </div>
                </StyleButton>

                <ListSelect
                  labelPlaceholder={<FormattedMessage id="periodoDesejado" />}
                  placeholder={<FormattedMessage id="selecionar" />}
                  value={periodo}
                  onChange={(e) => setPeriodo(e)}
                  isDisabled={true}
                />

                <InputLabel
                  label={<FormattedMessage id="horarioDesejado" />}
                  value={horario}
                  mask={MaskInput.hour}
                  onChange={(e) => setHorario(e)}
                  readOnly
                />

                {parseInt(user.cadastro_tipo_id) === 3 ? (
                  (statusPedido === "Aprovado" ||
                    statusPedido === "Aguardando Faturamento" ||
                    statusPedido === "Faturado") && (
                    <>
                      <AsyncListSelect
                        value={baseDist}
                        labelPlaceholder={
                          <FormattedMessage id="baseDistribuicao" />
                        }
                        placeholder={<FormattedMessage id="selecionar" />}
                        onChange={(e) => {
                          setBaseDist(e);
                        }}
                        loadOptions={handleLoadBases}
                        cacheOptions
                        defaultOptions
                        isDisabled={user.cadastro_tipo_id !== 3 ? false : true}
                      />

                      <AsyncListSelect
                        value={distribuidorPreferencia}
                        labelPlaceholder={
                          <FormattedMessage id="distribuidores" />
                        }
                        placeholder={<FormattedMessage id="selecionar" />}
                        isDisabled={true}
                      />
                    </>
                  )
                ) : (
                  <>
                    <AsyncListSelect
                      value={baseDist}
                      labelPlaceholder={
                        <FormattedMessage id="baseDistribuicao" />
                      }
                      placeholder={<FormattedMessage id="selecionar" />}
                      onChange={(e) => {
                        setBaseDist(e);
                      }}
                      loadOptions={handleLoadBases}
                      cacheOptions
                      defaultOptions
                      isDisabled={user.cadastro_tipo_id !== 3 ? false : true}
                    />

                    <AsyncListSelect
                      value={distribuidorPreferencia}
                      labelPlaceholder={
                        <FormattedMessage id="distribuidores" />
                      }
                      placeholder={<FormattedMessage id="selecionar" />}
                      isDisabled={true}
                    />
                  </>
                )}
              </div>
            </form>

            <br />

            {listaProdutos.map((item, index) => {
              if (user.cadastro_tipo_id === 2) {
                if (prazoPedido === item.prazo && statusPedido === "Aprovado") {
                } else if (index > 0) {
                  return null;
                } else {
                }
              }

              return (
                <FormTax key={index}>
                  <CardProduct
                    data={item}
                    //onChange={(e) => handleChangeTaxaFinanceira(e, item)}
                    selectCotacao={(e) => handleSelectCotacao(e, item)}
                    sizeArray={listaProdutos.length}
                    isRefresh={isRefresh}
                    index={index}
                    statusPedido={statusPedido}
                    prazoPedido={prazoPedido}
                  >
                       <main>
                      <table>
                        <thead>
                          <tr>
                            {parseInt(user.cadastro_tipo_id) === 1 ? (
                              <th className="disponivel">
                                <TitleTax>Melhor Preço?</TitleTax>
                              </th>
                            ) : (
                              parseInt(user.cadastro_tipo_id) === 2 && (
                                <th className="disponivel">
                                  <TitleTax>Disponível?</TitleTax>
                                </th>
                              )
                            )}

                            <th className="produto">
                              <TitleTax>
                                <FormattedMessage id="produto" />
                              </TitleTax>
                            </th>
                            {user.cadastro_tipo_id !== 2 && (
                              <th className="prazo">
                                <TitleTax>
                                  <FormattedMessage id="prazoPagamento" />
                                </TitleTax>
                              </th>
                            )}

                            <th className="quantidade">
                              {tipoProduto === 'outros' ? (
                                  <>
                                    {quantidadePlaceholder}
                                  </>
                                ) : (
                                  <TitleTax>{quantidadePlaceholder} (M³)</TitleTax>
                                )}
                            </th>
                            {/* <th className="precoRef">
                              <TitleTax>
                                {tipoProduto === 'outros' ? (
                                  <>
                                    Preço Referência
                                  </>
                                ) : (
                                  <FormattedMessage id="precoReferenciaLitro" />
                                )}
                              </TitleTax>
                            </th> */}
                            <th className="precoNegociado">
                              <TitleTax>
                                {tipoProduto === 'outros' ? (
                                  <>
                                    Preço Negociado
                                  </>
                                ) : (
                                  <FormattedMessage id="precoNegociadoLitro" />
                                )}
                              </TitleTax>
                            </th>
                            <th className="precoFrete">
                              <TitleTax>
                              {tipoProduto === 'outros' ? (
                                  <>
                                    Preço Frete
                                  </>
                                ) : (
                                  <FormattedMessage id="precoFreteLitro" />
                                )}
                              </TitleTax>
                            </th>
                            {tipoProduto === "outros" && (
                            <th className="precoFinalSemTaxa">
                              <TitleTax>
                                Previsão Entrega
                              </TitleTax>
                            </th>
                            )}

                            <th className="precoFinalSemTaxa">
                              {user.cadastro_tipo_id === 2 ? (
                                <TitleTax>Preço Final</TitleTax>
                              ) : user.cadastro_tipo_id === 3 ? (
                                <TitleTax>Preço Final Distribuidor (Litro)</TitleTax>
                              ) : (
                                <TitleTax>Preço Final Sem Taxa</TitleTax>
                              )}
                            </th>

                            {user.cadastro_tipo_id === 3 ||
                            user.cadastro_tipo_id === 2 ? null : (
                              <th className="precoFinalComTaxa">
                                <TitleTax>Preço Final Com Taxa</TitleTax>
                              </th>
                            )}

                            {user.cadastro_tipo_id !== 3 && (
                              <th className="TotalSemTaxa">
                                {user.cadastro_tipo_id === 2 ? (
                                  <TitleTax>Total</TitleTax>
                                ) : (
                                  <TitleTax>Total sem Taxa</TitleTax>
                                )}
                              </th>
                            )}

                            {user.cadastro_tipo_id === 3 && (
                              <th className="TotalComTaxa">
                                <TitleTax>Valor Taxa Serviço</TitleTax>
                              </th>
                            )}

                            {user.cadastro_tipo_id === 3 ? (
                              <th className="TotalComTaxa">
                                <TitleTax>Valor Total</TitleTax>
                              </th>
                            ) : user.cadastro_tipo_id === 2 ? null : (
                              <th className="TotalComTaxa">
                                <TitleTax>Total com Taxa</TitleTax>
                              </th>
                            )}
                          </tr>
                        </thead>

                        <tbody>
                          {item.produtos.map((data, index) => {
                            return (
                              <tr key={index}>
                                {parseInt(user.cadastro_tipo_id) === 2 && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        data?.disponibilidade_distribuidor ===
                                        "s"
                                          ? true
                                          : false
                                      }
                                      onClick={(e) =>
                                        handleChangeDisponibilidade(
                                          e,
                                          item,
                                          data,
                                          index
                                        )
                                      }
                                      value={data?.id_produto}
                                      disabled={
                                        parseInt(user.cadastro_tipo_id) === 1 &&
                                        cotacao !== "Análise Distribuidor/TRR"
                                          ? true
                                          : false
                                      }
                                    />
                                  </td>
                                )}

                                {parseInt(user.cadastro_tipo_id) === 1 && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        listaMenorPreco.find( id => id === data?.id_cotacao_produto ) ? true : false
                                      }
                                      onClick={(e) =>
                                        handleChangeDisponibilidade(
                                          e,
                                          item,
                                          data,
                                          index
                                        )
                                      }
                                      value={data?.id_produto}
                                      disabled={
                                        data?.disponibilidade_distribuidor ===
                                        "n" && data?.preco_total_produto_sem_taxa === "0,0000" && cotacao === "Análise Mesa de Operação"
                                          ? true
                                          : false
                                      }
                                    />
                                  </td>
                                )}

                                <td>
                                  <strong>{data?.descricao}</strong>
                                </td>
                                {user.cadastro_tipo_id !== 2 && (
                                  <td>
                                    <InputMaskDefault
                                      type="text"
                                      name="prazo"
                                      value={data?.prazo}
                                      readOnly
                                    />
                                  </td>
                                )}

                                <td>
                                  <InputMaskDefault
                                    mask={"currency2"}
                                    type="text"
                                    name="quantidade"
                                    value={data?.qtdInput}
                                    readOnly
                                  />
                                </td>
                                {/* validar */}
                                {/* {parseInt(user.cadastro_tipo_id) === 3 ? (
                                  <td>
                                    <InputMaskDefault
                                      mask={"precoCombustiveis"}
                                      type="text"
                                      name="valor_referencia"
                                      value={data?.valor_referencia}
                                      readOnly
                                    />
                                  </td>
                                ) : (
                                  <td>
                                    <InputMaskDefault
                                      mask={"precoCombustiveis"}
                                      type="text"
                                      name="valor_referencia"
                                      defaultValue={data?.valor_referencia}
                                      value={data?.precoRefInput}
                                      onChange={(e) => {
                                        handleChangePrecoReferencia(
                                          e,
                                          item,
                                          data,
                                          index
                                        );
                                      }}
                                      readOnly={
                                        data?.disponibilidade_distribuidor ===
                                        "n"
                                          ? true
                                          : false
                                      }
                                    />
                                  </td>
                                )} */}

                                {parseInt(user.cadastro_tipo_id) === 3 ? (
                                  <>
                                    <td>
                                      <InputMaskDefault
                                        mask={"currency4"}
                                        type="text"
                                        value={data?.valor_unitario}
                                        readOnly
                                      />
                                    </td>
                                    <td>
                                      <InputMaskDefault
                                        mask={"currency4"}
                                        type="text"
                                        value={data?.valor_frete}
                                        onChange={() => {}}
                                        readOnly
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <InputMaskDefault
                                        mask={"precoCombustiveis"}
                                        type="text"
                                        defaultValue={data?.valor_unitario}
                                        value={data?.precoInput}
                                        onChange={(e) => {
                                          handleChangePreco(
                                            e,
                                            item,
                                            data,
                                            index
                                          );
                                        }}
                                        readOnly={
                                          data?.disponibilidade_distribuidor ===
                                          "n"
                                            ? true
                                            : false
                                        }
                                      />
                                    </td>
                                    <td>
                                      <InputMaskDefault
                                        mask={"precoCombustiveis"}
                                        type="text"
                                        defaultValue={data?.valor_frete}
                                        value={data?.freteInput}
                                        onChange={(e) => {
                                          if (frete?.value !== "FOB (Retira)") {
                                            return handleChangeFrete(
                                              e,
                                              item,
                                              data,
                                              index
                                            );
                                          }
                                        }}
                                        readOnly={
                                          data?.disponibilidade_distribuidor ===
                                          "n"
                                            ? true
                                            : frete?.value === "FOB (Retira)"
                                            ? true
                                            : false
                                        }
                                      />
                                    </td>
                                  </>
                                )}

                                {tipoProduto === "outros" && (
                                  <td>
                                  <InputMaskDefault
                                   type="text"
                                   name="previsao_entrega"
                                   value={data?.previsao_entrega}
                                   readOnly
                                 />
                                 </td>
                                )}
                                <td>
                                  <InputMaskDefault
                                    mask={"currency4"}
                                    type="text"
                                    name="preco_final"
                                    value={data?.preco_total_produto_sem_taxa}
                                    readOnly
                                  />
                                </td>
                                {user.cadastro_tipo_id === 3 ||
                                user.cadastro_tipo_id === 2 ? null : (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency4"}
                                      type="text"
                                      name="preco_final"
                                      value={data?.preco_litro_final}
                                      readOnly
                                    />
                                  </td>
                                )}

                                {user.cadastro_tipo_id !== 3 && (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency4"}
                                      type="text"
                                      name="preco_final"
                                      value={data?.valor_total_produto_sem_taxa}
                                      readOnly
                                    />
                                  </td>
                                )}

                                {user.cadastro_tipo_id === 3 && (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency2"}
                                      type="text"
                                      name="total"
                                      value={data?.total_servico}
                                      readOnly
                                    />
                                  </td>
                                )}

                                {user.cadastro_tipo_id === 3 ? (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency2"}
                                      type="text"
                                      name="total"
                                      value={data?.total}
                                      readOnly
                                    />
                                  </td>
                                ) : user.cadastro_tipo_id === 2 ? null : (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency2"}
                                      type="text"
                                      name="total"
                                      value={data?.total}
                                      readOnly
                                    />
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </main>
                  </CardProduct>
                </FormTax>
              );
            })}

            <hr />

            <CreateFooter>
              <div>
                <Button
                  type="button"
                  themeType="back"
                  onClick={handleBackButton}
                >
                  <FormattedMessage id="voltar" />
                </Button>
              </div>


              {/* Seleciona Botões conforme as visões Sodexo e Distribuidores e Cliente */}
              {parseInt(user.cadastro_tipo_id) === 1 ? (
                <>
                  {cotacao === "Análise Cliente" ||
                  cotacao === "Aprovado" ||
                  cotacao === "Entregue" ? (
                    <h5 style={{ lineHeight: "50px", color: "#030894" }}>
                      <FormattedMessage id="cotacaoEnviadaCliente" />
                    </h5>
                  ) : cotacao === "Cancelado" || cotacao === "Reprovado" ? (
                    <>
                      {motivo && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "250px",
                            wordBreak: "break-word",
                            justifyContent: "space-around",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <h5 style={{ color: "#030894" }}>
                            Motivo{" "}
                            {cotacao === "Cancelado"
                              ? "Cancelamento"
                              : "Reprovação"}
                            : {motivo}
                          </h5>
                          {motivo === "Outros" && (
                            <strong>{justificativa}</strong>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        width: "250px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {cotacao !== "Análise Distribuidor/TRR" &&
                        cotacao !== "Reanálise Distribuidor/TRR" && versao === "v1" &&(
                          <div style={{ float: "left", marginRight: "5px" }}>
                            <Button
                              type="button"
                              onClick={handleAjusteDistribuidora}
                              style={{ fontSize: "12px" }}
                              loading={loadingButtonAjusteDist}
                            >
                              <FormattedMessage id="ajusteDistribuidor" />
                            </Button>
                          </div>
                        )}

                      {cotacao === "Análise Distribuidor/TRR" && versao === 'v1' ? (
                         listaProdutos[0]?.produtos.filter(
                          (i) => i?.disponibilidade_distribuidor === "n"
                        )?.length !== listaProdutos[0]?.produtos?.length && (
                          <div style={{ display: "inline-block",  marginRight: "10px" }}>
                            <Button
                              type="button"
                              onClick={handleSavePriceSodexo}
                              style={{ fontSize: "12px" }}
                              loading={loadingButtonLiberarCotacao}
                              permission="pedido-liberar[1]"
                            >
                              Salvar Preço
                            </Button>
                          </div>
                        )
                      ) : ("")}

                      {/* Verifica se o pedido tem todos produtos indisponiveis e não exibe o botao caso sim*/}
                      {listaProdutos[0]?.produtos.filter(
                        (i) => i?.disponibilidade_distribuidor === "n"
                      )?.length !== listaProdutos[0]?.produtos?.length && versao === 'v1' && (
                        <div style={{ display: "inline-block" }}>
                          <Button
                            type="button"
                            onClick={handleLiberarCotacaoParaCliente}
                            style={{ fontSize: "12px" }}
                            loading={loadingButtonLiberarCotacao}
                            permission="pedido-liberar[1]"
                          >
                            <FormattedMessage id="liberarCotacaoCliente" />
                          </Button>
                        </div>
                      )}

                      {
                        cotacao === "Análise Mesa de Operação" && versao === 'v2' && (
                          <div style={{ display: "inline-block" }}>
                            <Button
                              type="button"
                              onClick={handleAprovacaoPedidoV2}
                              style={{ fontSize: "12px" }}
                              loading={loadingButtonLiberarCotacao}
                              themeType={"green"}
                            >
                              Aprovar Pedido
                            </Button>
                          </div>
                        )
                      }

                      {
                        cotacao === "Reanálise Distribuidor/TRR" && versao === 'v2' && (
                          <div style={{ display: "inline-block" }}>
                            <Button
                              type="button"
                              onClick={handleSavePriceSodexo}
                              style={{ fontSize: "12px" }}
                              loading={loadingButtonLiberarCotacao}
                              themeType={"green"}
                            >
                              Salvar Preço
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  )}
                </>
              ) : parseInt(user.cadastro_tipo_id) === 2 ? (
                <>
                  {cotacao === "Análise Distribuidor/TRR" ||
                  cotacao === "Reanálise Distribuidor/TRR" ? (
                    <div style={{ float: "left", marginRight: "5px" }}>
                      <Button
                        type="button"
                        onClick={handleSaveUpdateSodexo}
                        loading={loadingButtonEnviar}
                      >
                        {buttonLabelDist}
                      </Button>
                    </div>
                  ) : (
                    <>
                      {(cotacao === "Análise Cliente" ||
                        cotacao === "Aprovado" ||
                        cotacao === "Reprovado" ||
                        cotacao === "Entregue") && (
                        <h5
                          style={{
                            lineHeight: "50px",
                            color: "#030894",
                          }}
                        >
                          <FormattedMessage id="cotacaoEnviadaCliente" />
                        </h5>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {cotacao === "Análise Cliente" ? (
                    <div
                      style={{
                        marginLeft: "auto",
                        width: "380px",
                        display: "flex",
                      }}
                    >
                      <div style={{ float: "left", marginRight: "5px" }}>
                        <Button
                          type="button"
                          onClick={handleRenegociarPedidoPeloCliente}
                          style={{ fontSize: "12px" }}
                          loading={loadingButtonLiberarCotacao}
                          themeType={"blue"}
                        >
                          Renegociar Pedido
                        </Button>
                      </div>
                      <div style={{ float: "left", marginRight: "5px" }}>
                        <Button
                          type="button"
                          onClick={handleReprovarPedidoPeloCliente}
                          style={{ fontSize: "12px" }}
                          loading={loadingButtonLiberarCotacao}
                        >
                          Reprovar Pedido
                        </Button>
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <Button
                          type="button"
                          onClick={handleAprovacaoPedidoPeloCliente}
                          style={{ fontSize: "12px" }}
                          loading={loadingButtonLiberarCotacao}
                          themeType={"green"}
                        >
                          Aprovar Pedido
                        </Button>
                      </div>
                    </div>
                  ) : cotacao === "Cancelado" || cotacao === "Reprovado" ? (
                    <>
                      {motivo && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "250px",
                            wordBreak: "break-word",
                            justifyContent: "space-around",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <h5 style={{ color: "#030894" }}>
                            Motivo{" "}
                            {cotacao === "Cancelado"
                              ? "Cancelamento"
                              : "Reprovação"}
                            : {motivo}
                          </h5>
                          {motivo === "Outros" && (
                            <strong>{justificativa}</strong>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    cotacao === "Análise Distribuidor/TRR" && (
                      <>
                        <h5 style={{ lineHeight: "50px", color: "#030894" }}>
                          Cotações em Análise
                        </h5>
                      </>
                    )
                  )}
                </>
              )}
            </CreateFooter>
          </CreateForm>
        ) : (
          <ContentSpinner />
        )}
      </Container>

      {showEndereco && (
        <ActionContainer>
          <header>
            <h4>
              <FormattedMessage id="informacoesEndereco" />
            </h4>
            <FiXCircle onClick={() => setShowEndereco(!showEndereco)} />
          </header>
          <main>
            <InputLabel
              label={<FormattedMessage id="logradouro" />}
              value={logradouro}
              onChange={(e) => setLogradouro(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="numero" />}
              value={numero}
              onChange={(e) => setNumero(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="complemento" />}
              value={complemento}
              onChange={(e) => setComplemento(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="bairro" />}
              value={bairro}
              onChange={(e) => setBairro(e)}
              readOnly
            />
            <InputLabel
              label={"UF:"}
              value={UF}
              onChange={(e) => setUF(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="cidade" />}
              value={cidade}
              onChange={(e) => setCidade(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="cep" />}
              value={cep}
              mask={MaskInput.CEP}
              onChange={(e) => setCep(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="distanciaKM" />}
              value={distancia}
              readOnly
            />
          </main>
          <br />
        </ActionContainer>
      )}
      {showInfoMotorista && (
        <InfoMotoristaContainer>
          <header>
            <h4>{<FormattedMessage id="infoMotorista" />}</h4>
            <FiXCircle
              onClick={() => setShowInfoMotorista(!showInfoMotorista)}
            />
          </header>
          <main>
            <InputLabel
              label={<FormattedMessage id="nomeMotorista" />}
              value={nomeMotorista}
              onChange={(e) => setMotorista(e.target.value)}
              readOnly={user.cadastro_tipo_id !== 2 ? false : true}
              maxLength={50}
            />

            <InputLabel
              label={<FormattedMessage id="rgMotorista" />}
              value={RgMotorista}
              onChange={(e) => setRgMotorista(e.target.value)}
              readOnly={user.cadastro_tipo_id !== 2 ? false : true}
              mask={MaskInput.RG}
            />
            <InputLabel
              label={<FormattedMessage id="cpfMotorista" />}
              value={cpfMotorista}
              onChange={(e) => setCpfMotorista(e.target.value)}
              readOnly={user.cadastro_tipo_id !== 2 ? false : true}
              mask={MaskInput.CPF}
            />
            <InputLabel
              label={<FormattedMessage id="placaVeiculo" />}
              value={placaVeiculo}
              onChange={(e) => setPlacaVeiculo(e.target.value)}
              readOnly={user.cadastro_tipo_id !== 2 ? false : true}
              maxLength={8}
            />
            <InputLabel
              label={<FormattedMessage id="celular" />}
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
              mask={MaskInput.cellphone}
              readOnly={user.cadastro_tipo_id !== 2 ? false : true}
            />
          </main>
          <br />
        </InfoMotoristaContainer>
      )}
    </>
  );
};

export default injectIntl(EditPainelOrders);
