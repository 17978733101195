import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    color: #FFD600;
    cursor: default;
    transition: all 0.3s;

    /* :hover {
      color: #27262a;
    } */
  }

  small {
    font-size: 12px;
    color: #fff;
  }

  p {
    margin-top: 5px;
  }

  small:last-child {
    margin-top: -10px;
    font-size: 10px;
  }

  .css-r9dwqp-menu {
    padding: 0!important;
    margin: 0!important;
    border-radius: 0!important;
  }

  .filter {
    &__option {
      background-color: white;
      color: #333333;


      &--is-focused {
        background-color: #eaeaea!important;
        color: #c02c31!important;
        font-weight: bold
      }
    }
  }
`;

export const List = styled(Select).attrs({})`
  width: 100% !important;
  font-size: 13px;
  font-family: sans-serif;
`;

export const ToolTip = styled.p`
  position: fixed;
  left: 5px;
  transform: translateY(-28px);
  height: 18px;
  background-color: #242b30;
  padding: 0px 15px;
  border-radius: 2px;
  z-index: 9999;
  color: #d1d1d1;
`;

export const ToolTip1 = styled.p`
  position: fixed;
  left: 5px;
  transform: translateY(-10px);
  height: 18px;
  background-color: #242b30;
  padding: 0px 15px;
  border-radius: 2px;
  z-index: 9999;
  color: #d1d1d1;
`;

export const EmpresaContainer = styled.div`
  margin-top: 3px;

  select {
    width: 100%;
  }
`;
