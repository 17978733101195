import styled from "styled-components";

export const FormPagination = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  button {
    width: auto;
    height: 100%;
    outline: none;
    border: none;
    font-weight: bold;
    font-family: 16px;
    margin-right: 6px;
    /* border-radius: 5px; */
    background-color: #fff;
    transition: all 0.4s;
    padding: 0 10px;
    border: 1px solid #908C99;

    :hover {
      background-color: #030894;
      color: #f8f8f8;
    }

    :disabled {
      background-color: #B5B2BC;
      /* opacity: 0.6; */
      color: #908C99;
      cursor: default;
      position: relative;
    }
  }

  span {
    margin-left: 5px;
    margin-right: 10px;
  }

  select {
    border: none;
    /* border-radius: 5px; */
  }
`;

export const Total = styled.div`
  margin: 5px 10px;
  font-weight: bold;
`;
