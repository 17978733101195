import React, {
  useMemo,
  useCallback,
  useState,
  createRef,
  useContext,
  useEffect,
} from "react";
import { useTable } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { FormattedMessage, injectIntl } from "react-intl";
import swal from "sweetalert";
import Alert from "./../../../assets/images/alert.png";
import {
  FiEdit,
  FiEye,
  FiTrash2,
  FiPlus,
  FiXCircle,
} from "react-icons/fi";
import iconFiltro from "./../../../assets/images/icone-filtro.png";
import ButtonIcon from "./../../../components/ButtonIcon";
import { Container, FilterContainer } from "./styles";
import iconVoltar from "./../../../assets/images/icone-voltar.svg";

import ContentSpinner from "./../../../components/Spinner/Content";

import OptionsList from "./../../../components/OptionsList";
import OptionsIconPlaceholder from "./../../../components/OptionsList/OptionsIconPlaceholder";

import MaskInput from "./../../../config/input-mask";
//import ContentSpinner from "./../../../components/Spinner/Content";
import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";

import ExcelLogo from "./../../../assets/images/logo-excel.png";
import ButtonImage from "./../../../components/ButtonImage";

import Paginate from "./../../../components/Paginate";

import AuthContext from "./../../../context/auth";

import api from "./../../../services/api";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ListAccessProfiles = ({ intl }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [list, setList] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const [cnpjFilter, setCnpjFilter] = useState("");
  const [razaoSocialFilter, setRazaoSocialFilter] = useState("");
  const [nomePerfilFilter, setNomePerfilFilter] = useState("");
  const [descricaoFilter, setDescricaoFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });

  // Paginação
  const location = useLocation();
  const [total, setTotal] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [currentPage, setCurrentPage] = useState(() => {
    return new URLSearchParams(location.search).get("page") || 1;
  });
  const [countPage, setCountPage] = useState([]);

  const refExcel = createRef(null);

  const history = useHistory();

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  //Placeholders internacionalizados
  const descricaoPerfilPlaceholder = intl.formatMessage({
    id: "descricaoPerfil",
  });
  const razaoSocialPlaceholder = intl.formatMessage({ id: "razaoSocial" });
  const nomePerfilPlaceholder = intl.formatMessage({ id: "nomePerfil" });
  const desejaExcluirRegistro = intl.formatMessage({
    id: "desejaExcluirRegistro",
  });
  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });

  /** obter os dados do usuario logado */
  const { user } = useContext(AuthContext);

  const handleGoToCreateAccess = useCallback(() => {
    history.push("/access/profiles/customers/create");
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleDisplay = useCallback(
    async (data) => {
      history.push("/access/profiles/customers/show", data.original);
    },
    [history]
  );

  const handleEdit = useCallback(
    async (data) => {
      history.push("/access/profiles/customers/edit", data.original);
    },
    [history]
  );

  const handleDelete = useCallback(
    async (id) => {
      const willDelete = await swal({
        title: voceTemCerteza,
        text: desejaExcluirRegistro,
        icon: Alert,
        buttons: {
          cancel: {
            text: cancelar,
            value: null,
            visible: true,
            closeModal: true,
            className: "teste",
          },
          confirm: {
            text: opcaoSim,
            value: true,
            visible: true,
            closeModal: true,
          },
        },
        dangerMode: true,
      });

      if (willDelete) {
        try {
          await api.delete(`/role/${id}`);
          setList(list.filter((perfil) => perfil.id !== id));
          toast.success(<FormattedMessage id="registroExcluidoComSucesso" />);
        } catch (err) {
          toast.error(<FormattedMessage id="erroExcluirRegistro" />);
        }
      }
    },
    [list, voceTemCerteza, desejaExcluirRegistro, opcaoSim, cancelar]
  );

  const handleExportToExcel = useCallback(() => {
    refExcel.current.click();
  }, [refExcel]);

  /** listar as Roles (Perfis) que estiverem vinculadas à empresa do usuário logado. Este relacionamento já virá pronto do end-point */

  function formatExcelData(data) {
    const dataFormat = data.map((item) => {
      return [
        {
          value: item?.col2 || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.col1 || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.label || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.col4 || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.col5 || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
      ];
    });

    return [
      {
        columns: [
          {
            title: "CNPJ",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Razão Social",
            width: { wpx: 320 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Nome do Perfil",
            width: { wpx: 180 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Descrição do Perfil",
            width: { wpx: 250 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Status",
            width: { wpx: 70 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
        ],
        data: dataFormat,
      },
    ];
  }

  useEffect(() => {
    (async () => {
      try {
        var response;

        if (parseInt(user.cadastro_tipo_id) === 3) {
          // Visão Cliente
          response = await api.post("/role/filter", {
            cadastro_tipo_id: 3,
            cadastro_id: user.cadastro_id,
            ativo: 1,
          });
        } else if (parseInt(user.cadastro_tipo_id) === 1) {
          // Visão Sodexo
          response = await api.post("/role/filter", {
            cadastro_tipo_id: 3,
            ativo: 1,
          });
        }

        var listRoles = [];

        response.data.data.forEach((lista_roles) => {
          listRoles.push({
            col1: lista_roles.razao_social,
            col2: lista_roles.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ),
            col3: lista_roles.name,
            col4: lista_roles.description,
            col5: lista_roles.ativo === 1 ? "Ativo" : "Inativo",
            id: lista_roles.roleId,
            cadastroId: lista_roles?.cadastroId,
            razaoSocial: lista_roles?.razao_social,
            label: lista_roles?.label,
          });
        });

        setTotal(response?.data?.total);
        setFirstPage(response?.data?.first_page_url);
        setLastPage(response?.data?.last_page);
        setLastPageUrl(response?.data?.last_page_url);
        setNextPage(response?.data?.next_page_url);
        setPrevPage(response?.data?.prev_page_url);
        setCurrentPage(response?.data?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.data?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.data?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page - current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = current_Page - current_Page + 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setList(listRoles);
        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [user.cadastro_id, user.cadastro_tipo_id]);

  const columns = useMemo(
    () => [
      {
        Header: "CNPJ",
        accessor: "col2", // accessor is the "key" in the data
      },
      {
        Header: <FormattedMessage id="razaoSocial" />,
        accessor: "col1",
      },
      {
        Header: <FormattedMessage id="nomePerfil" />,
        accessor: "label",
      },
      {
        Header: <FormattedMessage id="descricaoPerfil" />,
        accessor: "col4",
      },
      {
        Header: "Status",
        accessor: "col5",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: list });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // Paginação
  const handleUpdateList = useCallback(async (url) => {
    try {
      var response = await api.get(url);

      var listRoles = [];

      response.data.data.forEach((lista_roles) => {
        listRoles.push({
          col1: lista_roles.razao_social,
          col2: lista_roles.cpf_cnpj.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          ),
          col3: lista_roles.name,
          col4: lista_roles.description,
          col5: lista_roles.ativo === 1 ? "Ativo" : "Inativo",
          id: lista_roles.roleId,
          cadastroId: lista_roles?.cadastroId,
          razaoSocial: lista_roles?.razao_social,
          label: lista_roles?.label,
        });
      });

      setTotal(response?.data?.total);
      setFirstPage(response?.data?.first_page_url);
      setLastPage(response?.data?.last_page);
      setLastPageUrl(response?.data?.last_page_url);
      setNextPage(response?.data?.next_page_url);
      setPrevPage(response?.data?.prev_page_url);
      setCurrentPage(response?.data?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.data?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.data?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setList(listRoles);
      setLoadingList(false);
    } catch (error) {
      setLoadingList(false);
    }
  }, []);

  const handleFilter = useCallback(async () => {
    setLoadingButton(true);

    try {
      var response;

      const checkedStatus = isNaN(statusFilter?.value)
        ? ""
        : String(statusFilter?.value);

      if (parseInt(user.cadastro_tipo_id) === 3) {
        // Visão Cliente
        response = await api.post("/role/filter", {
          cadastro_tipo_id: 3,
          cadastro_id: user.cadastro_id,
          cpf_cnpj: cnpjFilter.replace(/[^\d]+/g, ""),
          razao_social: razaoSocialFilter,
          label: nomePerfilFilter,
          description: descricaoFilter,
          ativo: checkedStatus || "",
        });
      } else if (parseInt(user.cadastro_tipo_id) === 1) {
        // Visão Sodexo
        response = await api.post("/role/filter", {
          cadastro_tipo_id: 3,
          cpf_cnpj: cnpjFilter.replace(/[^\d]+/g, ""),
          razao_social: razaoSocialFilter,
          label: nomePerfilFilter,
          description: descricaoFilter,
          ativo: checkedStatus || "",
        });
      }

      var listRoles = [];

      response.data.data.forEach((lista_roles) => {
        listRoles.push({
          col1: lista_roles.razao_social,
          col2: lista_roles.cpf_cnpj.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          ),
          col3: lista_roles.name,
          col4: lista_roles.description,
          col5: lista_roles.ativo === 1 ? "Ativo" : "Inativo",
          id: lista_roles.roleId,
          cadastroId: lista_roles?.cadastroId,
          razaoSocial: lista_roles?.razao_social,
          label: lista_roles?.label,
        });
      });

      setTotal(response?.data?.total);
      setFirstPage(response?.data?.first_page_url);
      setLastPage(response?.data?.last_page);
      setLastPageUrl(response?.data?.last_page_url);
      setNextPage(response?.data?.next_page_url);
      setPrevPage(response?.data?.prev_page_url);
      setCurrentPage(response?.data?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.data?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.data?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setList(listRoles);
      setLoadingButton(false);
      setShowFilter(false);
      setLoadingList(false);
    } catch (error) {
      setLoadingButton(false);
      setLoadingList(false);
      setShowFilter(false);
    }
  }, [
    cnpjFilter,
    descricaoFilter,
    nomePerfilFilter,
    razaoSocialFilter,
    statusFilter.value,
    user.cadastro_id,
    user.cadastro_tipo_id,
  ]);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="perfilDeAcessoCliente" />
            </h2>
          </nav>

          <div>
            <ButtonIcon
              Icon={FiPlus}
              backgroundColor={"#030894"}
              onClick={handleGoToCreateAccess}
              placeholder={<FormattedMessage id="incluir" />}
              permission="roles-store[3]"
            />

                          <ButtonImage
                ImageSrc={iconFiltro}
                backgroundColor={"#030894"}
                color={"#ffffff"}
              placeholder={<FormattedMessage id="filtrar" />}
              onClick={() => setTimeout(() => setShowFilter(!showFilter), 150)}
            />

            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>
        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              <ExcelFile
                element={
                  <button style={{ display: "none" }} ref={refExcel}></button>
                }
                filename="Perfil Cliente"
              >
                <ExcelSheet
                  dataSet={formatExcelData(list)}
                  name="Perfil Cliente"
                />
              </ExcelFile>
              <table {...getTableProps()}>
                <thead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps()}>
                              {
                                // Render the header
                                column.render("Header")
                              }
                            </th>
                          ))
                        }
                        <th>
                          <FormattedMessage id="acoes" />
                        </th>
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    rows.map((row) => {
                      // Prepare the row for display
                      prepareRow(row);
                      return (
                        // Apply the row props
                        <tr {...row.getRowProps()}>
                          {
                            // Loop over the rows cells
                            row.cells.map((cell) => {
                              // Apply the cell props
                              return (
                                <td {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render("Cell")
                                  }
                                </td>
                              );
                            })
                          }
                          <td>
                            <OptionsList>
                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiEye}
                                  placeholder={<FormattedMessage id="exibir" />}
                                  onClick={() => handleDisplay(row)}
                                  permission="roles-show[3]"
                                />
                              </li>

                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiEdit}
                                  placeholder={<FormattedMessage id="editar" />}
                                  onClick={() => handleEdit(row)}
                                  permission="roles-update[3]"
                                />
                              </li>
                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiTrash2}
                                  placeholder={
                                    <FormattedMessage id="excluir" />
                                  }
                                  onClick={() => handleDelete(row.original.id)}
                                  permission="roles-destroy[3]"
                                />
                              </li>
                            </OptionsList>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </main>
            <Paginate
              handleUpdateListPaginate={handleUpdateList}
              countPage={countPage}
              currentPg={currentPage}
              firstPage={firstPage}
              lastPage={lastPage}
              lastPageUrl={lastPageUrl}
              nextPage={nextPage}
              prevPage={prevPage}
              total={total}
            />
          </>
        )}
      </Container>
      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>
 
          <main>
            <InputLabel
              name="cnpj"
              type="text"
              label="CNPJ:"
              mask={MaskInput.CNPJ}
              onChange={(e) => setCnpjFilter(e.target.value)}
              value={cnpjFilter}
            />
            <InputLabel
              name="razao_social"
              type="text"
              label={razaoSocialPlaceholder}
              maxLength={100}
              onChange={(e) => setRazaoSocialFilter(e.target.value)}
              value={razaoSocialFilter}
            />

            <InputLabel
              name="nome_perfil"
              type="text"
              label={nomePerfilPlaceholder}
              maxLength={80}
              onChange={(e) => setNomePerfilFilter(e.target.value)}
              value={nomePerfilFilter}
            />
            <InputLabel
              name="descricao"
              type="text"
              label={descricaoPerfilPlaceholder}
              maxLength={80}
              onChange={(e) => setDescricaoFilter(e.target.value)}
              value={descricaoFilter}
            />

            <ListSelect
              onChange={(e) => setStatusFilter(e)}
              options={statusOptions}
              value={statusFilter}
              labelPlaceholder="Status"
            />
          </main>

          <footer>
            <Button
              type="button"
              themeType="default"
              onClick={handleFilter}
              loading={loadingButton}
            >
              Filtrar
            </Button>
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(ListAccessProfiles);
