import React, { useCallback, useState, createRef } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import ListSelect from "./../../../components/ListSelect";
import MessageDefault from "./../../../components/MessageDefault";
import InputLabel from "./../../../components/InputLabel";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";

import api from "./../../../services/api";
import {
  Container,
  CreateForm,
  CreateFooter,
  CheckForm,
  RadioForm,
} from "./styles";
import Button from "./../../../components/ButtonDefault";
import CheckboxDefault from "./../../../components/CheckboxDefault";
import { toast } from "react-toastify";

//import Language from "./../../../context/language";

export const CreateCommunication = ({ intl }) => {
  const [tipoComunicacao, setTipoComunicacao] = useState("Banner");
  const [posicao, setPosicao] = useState({
    value: "Direita Alta",
    label: <FormattedMessage id="direitaAlta" />,
  });
  const [local, setLocal] = useState("Home");
  const [tipo, setTipo] = useState("Informativo");
  const [link, setLink] = useState("");

  const [exibirSodexo, setExibirSodexo] = useState(false);
  const [exibirDistribuidor, setExibirDistribuidor] = useState(false);
  const [exibirCliente, setExibirCliente] = useState(false);

  const [dataDe, setDataDe] = useState("");
  const [dataAte, setDataAte] = useState("");
  const [file, setFile] = useState([]);

  const [errors, setErrors] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const refTodos = createRef();
  const refSodexo = createRef();
  const refDistribuidor = createRef();
  const refCliente = createRef();
  //const { language } = useContext(Language);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const posicaoOptions = [
    { value: "Direita Alta", label: <FormattedMessage id="direitaAlta" /> },
    { value: "Direita Baixa", label: <FormattedMessage id="direitaBaixa" /> },
    { value: "Rodapé", label: <FormattedMessage id="rodape" /> },
  ];

  const arquivoPlaceholder = intl.formatMessage({ id: "arquivo" });
  const dataInicioPlaceholder = intl.formatMessage({ id: "dataInicio" });
  const dataFimPlaceholder = intl.formatMessage({ id: "dataFim" });

  const handleCheckBoxTodos = ((e) => {
    if (e.value === "Todos" && e.checked === true) {
      setExibirCliente(true);
      setExibirDistribuidor(true);
      setExibirSodexo(true);
      refSodexo.current.checked = true;
      refDistribuidor.current.checked = true;
      refCliente.current.checked = true;
    } else {
      setExibirCliente(false);
      setExibirDistribuidor(false);
      setExibirSodexo(false);
      refSodexo.current.checked = false;
      refDistribuidor.current.checked = false;
      refCliente.current.checked = false;
    }
  });

  const handleCheckBoxTodosLogin = ((e) => {
    if (e.target.checked === true) {
      setExibirCliente(true);
      setExibirDistribuidor(true);
      setExibirSodexo(true);
      refSodexo.current.checked = true;
      refDistribuidor.current.checked = true;
      refCliente.current.checked = true;
      refTodos.current.checked = true;
    }
  });

  const handleCheckBoxTodosHome = ((e) => {
    if (e.target.checked === true) {
      setExibirCliente(false);
      setExibirDistribuidor(false);
      setExibirSodexo(false);
      refSodexo.current.checked = false;
      refDistribuidor.current.checked = false;
      refCliente.current.checked = false;
      refTodos.current.checked = false;
    }
  });

  const handleCreate = useCallback(async () => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("ativo", 1);
    formData.append("tipo_comunicacao", tipoComunicacao);
    formData.append("posicao", posicao.value);
    formData.append("local", local);
    formData.append("tipo", tipo);
    formData.append("link", link);

    formData.append("exibir_sodexo", exibirSodexo === true ? 1 : 0);
    formData.append("exibir_clientes", exibirCliente === true ? 1 : 0);
    formData.append("exibir_distribuidor", exibirDistribuidor === true ? 1 : 0);

    formData.append("data_inicio", dataDe);
    formData.append("data_fim", dataAte);

    try {
      await api.post("/comunicacao", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(<FormattedMessage id="registroCriadoComSucesso" />);
      setTipoComunicacao("Banner");
      setPosicao({
        value: "Direita Alta",
        label: <FormattedMessage id="direitaAlta" />,
      });
      setLocal("Home");
      setTipo("Informativo");

      setExibirCliente(false);
      setExibirDistribuidor(false);
      setExibirSodexo(false);

      document.getElementById("todos").checked = false;
      document.getElementById("cliente").checked = false;
      document.getElementById("sodexo").checked = false;
      document.getElementById("distribuidor").checked = false;

      setLink("");
      setDataAte("");
      setDataDe("");
      setFile([]);
      document.getElementById("file").value = "";

      setErrors(null);
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      toast.error(<FormattedMessage id="erroCriarRegistro" />);
    }
  }, [
    tipoComunicacao,
    posicao,
    local,
    tipo,
    exibirCliente,
    exibirDistribuidor,
    exibirSodexo,
    link,
    dataAte,
    dataDe,
    file,
  ]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarComunicado" />
          </h4>
        </header>

        <form>
          <RadioForm>
            <main>
              <div>
                <CheckboxDefault
                  type="radio"
                  name="tipoComunicacao"
                  labelName={<FormattedMessage id="banner" />}
                  onChange={(e) => {
                    setTipoComunicacao("Banner");
                    setLocal("Home");
                    setPosicao({
                      value: "Direita Alta",
                      label: <FormattedMessage id="direitaAlta" />,
                    });
                  }}
                  checked={tipoComunicacao === "Banner" ? true : false}
                  geterror={errors?.tipo_comunicacao}
                />
              </div>
              <div>
                <CheckboxDefault
                  type="radio"
                  name="tipoComunicacao"
                  labelName="PopUp"
                  onChange={(e) => {
                    setTipoComunicacao("PopUp");
                    setPosicao({
                      value: "Centro",
                      label: <FormattedMessage id="centro" />,
                    });
                  }}
                  checked={tipoComunicacao === "PopUp" ? true : false}
                  geterror={errors?.tipo_comunicacao}
                />
              </div>
            </main>
          </RadioForm>

          <ListSelect
            name="posicao"
            labelPlaceholder={<FormattedMessage id="posicao" />}
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            options={posicaoOptions}
            value={posicao}
            onChange={(e) => setPosicao(e)}
            geterror={errors?.posicao}
            isDisabled={tipoComunicacao === "PopUp" ? true : false}
          />

          <CheckForm>
            <main>
              <p>
                <FormattedMessage id="local" />:
              </p>
              <div>
                <CheckboxDefault
                  type="radio"
                  name="local"
                  labelName="Home"
                  onChange={(e) => {
                    setLocal("Home");
                    handleCheckBoxTodosHome(e);
                  }}
                  checked={local === "Home" ? true : false}
                  disabled={tipoComunicacao === "Banner" ? true : false}
                  geterror={errors?.local}
                />
              </div>
              <div>
                <CheckboxDefault
                  type="radio"
                  name="local"
                  labelName="Login"
                  onChange={(e) => {
                    setLocal("Login");
                    handleCheckBoxTodosLogin(e);
                  }}
                  checked={local === "Login" ? true : false}
                  disabled={tipoComunicacao === "Banner" ? true : false}
                  geterror={errors?.local}
                />
              </div>
            </main>
          </CheckForm>

          <CheckForm>
            <main>
              <p>
                <FormattedMessage id="tipo" />:
              </p>
              <div>
                <CheckboxDefault
                  type="radio"
                  name="tipo"
                  labelName={<FormattedMessage id="informativo" />}
                  onChange={(e) => {
                    setTipo("Informativo");
                    setLink("");
                  }}
                  checked={tipo === "Informativo" ? true : false}
                  geterror={errors?.tipo}
                />
              </div>
              <div>
                <CheckboxDefault
                  type="radio"
                  name="tipo"
                  labelName={<FormattedMessage id="interativo" />}
                  onChange={() => setTipo("Interativo")}
                  checked={tipo === "Interativo" ? true : false}
                  geterror={errors?.tipo}
                />
              </div>
            </main>

            <div style={{width: '100%', margiTop: '-20px'}}>
                <InputLabel
                  label={"Link:"}
                  name="link"
                  type="text"
                  value={link}
                  maxLength={80}
                  onChange={(e) => setLink(e.target.value)}
                  geterror={errors?.link}
                  readOnly={tipo === "Interativo" ? false : true}
                />
              </div>
          </CheckForm>

          <InputLabel
            id={"file"}
            label={arquivoPlaceholder}
            required={true}
            name="file"
            type="file"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
            geterror={errors?.file}
          />
          {posicao.value === "Direita Alta" || posicao.value === "Direita Baixa" ?
          <em style={{ fontSize: "9pt" }}><FormattedMessage id="maximoDe" /> 3Mb / 200x300px </em> :
          posicao.value === "Rodapé" ?
          <em style={{ fontSize: "9pt" }}><FormattedMessage id="maximoDe" /> 3Mb / 1200x145px</em> :
          posicao.value === "Centro" ?
          <em style={{ fontSize: "9pt" }}><FormattedMessage id="maximoDe" /> 3Mb / 1080x1080px</em> :
          <em style={{ fontSize: "9pt" }}><FormattedMessage id="maximoDe" /> 3Mb</em>
          }
          <CheckForm>
            <main>
              <p>
                <FormattedMessage id="exibirPara"  />:
              </p>
              <div>
                <input
                  id={"todos"}
                  type="checkbox"
                  onClick={(e) => handleCheckBoxTodos(e.target)}
                  value={"Todos"}
                  ref={refTodos}
                  checked={
                    exibirSodexo && exibirCliente && exibirDistribuidor === true
                      ? true
                      : false
                  }
                  disabled={local === "Login" ? true : false}
                  onChange={(e) => {}}
                />
                <label>
                  <FormattedMessage id="todos" />
                </label>
              </div>
              <div>
                <input
                  id={"sodexo"}
                  type="checkbox"
                  value={exibirSodexo}
                  ref={refSodexo}
                  disabled={local === "Login" ? true : false}
                  onChange={(e) => {
                    setExibirSodexo(e.target.checked);
                  }}
                  geterror={errors?.exibir_para}
                />
                <label>Graal</label>
              </div>
              <div>
                <input
                  id={"cliente"}
                  type="checkbox"
                  value={exibirCliente}
                  ref={refCliente}
                  disabled={local === "Login" ? true : false}
                  onChange={(e) => {
                    setExibirCliente(e.target.checked);
                  }}
                />
                <label>
                  <FormattedMessage id="clientes" />
                </label>
              </div>
              <div>
                <input
                  id={"distribuidor"}
                  type="checkbox"
                  value={exibirDistribuidor}
                  ref={refDistribuidor}
                  disabled={local === "Login" ? true : false}
                  onChange={(e) => {
                    setExibirDistribuidor(e.target.checked);
                  }}
                />
                <label>
                  <FormattedMessage id="distribuidores" />
                </label>
              </div>
            </main>
          </CheckForm>

          <MessageDefault
            geterror={errors?.exibir_para}
          />

          <div className="sideBySide">
            <InputLabel
              name="date"
              label={dataInicioPlaceholder}
            required={true}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataDe}
              onChange={(e) => setDataDe(e.target.value)}
              geterror={errors?.data_inicio}
            />
          </div>

          <div className="sideBySideTwo">
            <InputLabel
              name="date"
              label={dataFimPlaceholder}
            required={true}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataAte}
              onChange={(e) => setDataAte(e.target.value)}
              geterror={errors?.data_fim}
            />
          </div>
        </form>
        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateCommunication);
