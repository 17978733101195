import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TreeComponent from "./../../../components/TreeMenu/TreeComponent";
import { FormattedMessage, injectIntl } from "react-intl";

import api from "./../../../services/api";

import AuthContext from "./../../../context/auth";

import {
  Container,
  CreateForm,
  CreateFooter,
  CheckForm,
  FormPermission,
  TitlePermission,
} from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ContentSpinner from "./../../../components/Spinner/Content";

import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

export const EditAccessProfiles = ({ intl }) => {
  const [empresa, setEmpresa] = useState("");
  const [descricao, setDescricao] = useState("");
  const [listaPermissoes, setListaPermissoes] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [status, setStatus] = useState("");
  const [label, setLabel] = useState("");

  const [loading, setLoading] = useState(true);

  const [checkBoxAll, setCheckBoxAll] = useState([]);

  const history = useHistory();
  const { user } = useContext(AuthContext);

  const statusOptions = [
    { value: "1", label: <FormattedMessage id="ativo" /> },
    { value: "0", label: <FormattedMessage id="inativo" /> },
  ];

  useEffect(() => {
    if (!history.location.state?.id) {
      history.push("/access/profiles/graal/list");
    }
    const id = history.location.state?.id;

    async function loadInfo() {
      try {
        const { data: response } = await api.get(`role/show/${id}`);

        setDescricao(response?.perfil?.description);
        setStatus(
          response?.perfil?.ativo === 1
            ? {
                value: 1,
                label: <FormattedMessage id="ativo" />,
              }
            : {
                value: 0,
                label: <FormattedMessage id="inativo" />,
              }
        );

        setEmpresa({
          value: history.location.state?.cadastroId,
          label: history.location.state?.razaoSocial,
        });

        setLabel(history.location.state?.label);

        response.perfilpermissoes.map((p) => {
          setCheckBoxAll((checkbox) => [...checkbox, p.name + 1]);
          return setPermissions((permissions) => [...permissions, p.name]);
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    loadInfo();
  }, [history, user.cadastro_id, user.cadastro_tipo_id]);

  useEffect(() => {
    async function loadPermissions() {
      try {
        const { data: response } = await api.get(`/permission`);

        const arr = Object.keys(response);

        // Formata Objeto de permissões da API

        const arrFormmated = arr.map((item) => {
          let arrPermission = [];
          const permissions = Object.keys(response[item]);

          permissions.map((p) => {
            return arrPermission.push({
              id: p,
              permission: item + "-" + response[item][p],
              name: item,
            });
          });

          const b = { [item]: arrPermission };

          return b;
        });

        setAllPermissions(arrFormmated);
      } catch (error) {}
    }

    loadPermissions();
  }, []);

  useEffect(() => {
    const arrFormmated = allPermissions;

    if (arrFormmated.length === 0) {
      return;
    }

    let menuData = [];

    // Acessos
    let acessosPermissoes;
    let usuarioPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "roles") {
        acessosPermissoes = e.roles;
      }
    });

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "usuario") {
        usuarioPermissoes = e.usuario;
      }
    });

    const acessos = {
      title: <FormattedMessage id="acessos" />,
      children: [
        {
          title: <FormattedMessage id="perfis" />,
          children: [
            {
              title: <FormattedMessage id="sodexo" />,
              component: (
                <Permissions
                  data={acessosPermissoes}
                  cadastroTipoId={1}
                  permissions={permissions}
                />
              ),
            },
            {
              title: <FormattedMessage id="distribuidores_trrs" />,
              component: (
                <Permissions
                  data={acessosPermissoes}
                  cadastroTipoId={2}
                  permissions={permissions}
                />
              ),
            },
            {
              title: <FormattedMessage id="clientes" />,
              component: (
                <Permissions
                  data={acessosPermissoes}
                  cadastroTipoId={3}
                  permissions={permissions}
                />
              ),
            },
          ],
        },
        {
          title: <FormattedMessage id="usuarios" />,
          children: [
            {
              title: <FormattedMessage id="sodexo" />,
              component: (
                <Permissions
                  data={usuarioPermissoes}
                  cadastroTipoId={1}
                  permissions={permissions}
                />
              ),
            },
            {
              title: <FormattedMessage id="distribuidores_trrs" />,
              component: (
                <Permissions
                  data={usuarioPermissoes}
                  cadastroTipoId={2}
                  permissions={permissions}
                />
              ),
            },
            {
              title: <FormattedMessage id="clientes" />,
              component: (
                <Permissions
                  data={usuarioPermissoes}
                  cadastroTipoId={3}
                  permissions={permissions}
                />
              ),
            },
          ],
        },
      ],
    };

    menuData.push(acessos);

    /////// CADASTRO ///////////
    let cadastroClientesPermissoes;
    let cadastroDistribuidorTRRPermissoes;
    let cadastroComunicacao;
    let cadastroBasesDistPermissoes;
    let cadastroProdutos;
    let cadastroClassClientes;
    let cadastroConsultores;
    let cadastroClassDistribuidores;
    let cadastroMesaOperacao;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "cadastro") {
        // Remove Permissões do cadastro QUE NÃO FAZEM PARTE DAS PERMISSÕES DE CADASTRO
        const arr = e.cadastro.filter((item) => {
          return (
            item.permission.indexOf("cadastro-sodexo-store") === -1 &&
            item.permission.indexOf("cadastro-sodexo-destroy") === -1 &&
            item.permission.indexOf("cadastro-sodexo-show") === -1 &&
            item.permission.indexOf("cadastro-sodexo-update") === -1 &&
            item.permission.indexOf("cadastro-sodexo-list") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-store") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-destroy") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-show") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-update") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-list") === -1 &&
            item.permission.indexOf("cadastro-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-consumocapacidade") === -1 &&
            item.permission.indexOf("cadastro-certificadodigitalnfe") === -1 &&
            item.permission.indexOf("cadastro-basesdistribuicao") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-sodexo-consumocapacidade") ===
              -1 &&
            item.permission.indexOf("cadastro-sodexo-certificadodigitalnfe") ===
              -1 &&
            item.permission.indexOf("cadastro-sodexo-basesdistribuicao") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadoscadastrais") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadosendereco") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadosentrega") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadoscobranca") ===
              -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-consumocapacidade"
            ) === -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-certificadodigitalnfe"
            ) === -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-basesdistribuicao"
            ) === -1
          );
        });

        cadastroClientesPermissoes = arr;
      }
      if (Object.keys(e)[0] === "tabelabasedistribuicao") {
        cadastroBasesDistPermissoes = e.tabelabasedistribuicao;
      }

      if (Object.keys(e)[0] === "cadastro") {
        // Remove Permissões do cadastro de distribuidor
        const arr = e.cadastro.filter((item) => {
          return (
            item.permission.indexOf("cadastro-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-consumocapacidade") === -1 &&
            item.permission.indexOf("cadastro-certificadodigitalnfe") === -1 &&
            item.permission.indexOf("cadastro-sodexo-store") === -1 &&
            item.permission.indexOf("cadastro-sodexo-destroy") === -1 &&
            item.permission.indexOf("cadastro-sodexo-show") === -1 &&
            item.permission.indexOf("cadastro-sodexo-update") === -1 &&
            item.permission.indexOf("cadastro-sodexo-list") === -1 &&
            item.permission.indexOf("cadastro-cliente-store") === -1 &&
            item.permission.indexOf("cadastro-cliente-destroy") === -1 &&
            item.permission.indexOf("cadastro-cliente-show") === -1 &&
            item.permission.indexOf("cadastro-cliente-update") === -1 &&
            item.permission.indexOf("cadastro-cliente-list") === -1 &&
            item.permission.indexOf("cadastro-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-consumocapacidade") === -1 &&
            item.permission.indexOf("cadastro-certificadodigitalnfe") === -1 &&
            item.permission.indexOf("cadastro-basesdistribuicao") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-sodexo-consumocapacidade") ===
              -1 &&
            item.permission.indexOf("cadastro-sodexo-certificadodigitalnfe") ===
              -1 &&
            item.permission.indexOf("cadastro-sodexo-basesdistribuicao") ===
              -1 &&
            item.permission.indexOf("cadastro-cliente-dadoscadastrais") ===
              -1 &&
            item.permission.indexOf("cadastro-cliente-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-cliente-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-cliente-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-cliente-consumocapacidade") ===
              -1 &&
            item.permission.indexOf(
              "cadastro-cliente-certificadodigitalnfe"
            ) === -1 &&
            item.permission.indexOf("cadastro-cliente-basesdistribuicao") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadosentrega") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadoscobranca") ===
              -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-consumocapacidade"
            ) === -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-certificadodigitalnfe"
            ) === -1
          );
        });

        cadastroDistribuidorTRRPermissoes = arr;
      }

      if (Object.keys(e)[0] === "comunicacao") {
        cadastroComunicacao = e.comunicacao;
      }

      if (Object.keys(e)[0] === "produto") {
        cadastroProdutos = e.produto;
      }

      if (Object.keys(e)[0] === "tabelaclassificacaocliente") {
        cadastroClassClientes = e.tabelaclassificacaocliente;
      }

      if (Object.keys(e)[0] === "tabelaconsultor") {
        cadastroConsultores = e.tabelaconsultor;
      }

      if (Object.keys(e)[0] === "tabelaclassificacaodistribuidor") {
        cadastroClassDistribuidores = e.tabelaclassificacaodistribuidor;
      }

      if (Object.keys(e)[0] === "tabelamesaoperacao") {
        cadastroMesaOperacao = e.tabelamesaoperacao;
      }
    });

    const cadastro = {
      title: <FormattedMessage id="cadastrar" />,
      children: [
        {
          title: <FormattedMessage id="operacao" />,
          children: [
            {
              title: <FormattedMessage id="clientes" />,
              component: (
                <Permissions
                  data={cadastroClientesPermissoes}
                  cadastroTipoId={1}
                  permissions={permissions}
                />
              ),
            },
            {
              title: <FormattedMessage id="baseDistribuicao" />,
              component: (
                <Permissions
                  data={cadastroBasesDistPermissoes}
                  cadastroTipoId={1}
                  permissions={permissions}
                />
              ),
            },
            {
              title: <FormattedMessage id="comunicacao" />,
              component: (
                <Permissions
                  data={cadastroComunicacao}
                  cadastroTipoId={1}
                  permissions={permissions}
                />
              ),
            },
            {
              title: <FormattedMessage id="distribuidores_trrs" />,
              component: (
                <Permissions
                  data={cadastroDistribuidorTRRPermissoes}
                  cadastroTipoId={1}
                  permissions={permissions}
                />
              ),
            },

            {
              title: <FormattedMessage id="produtos" />,
              component: (
                <Permissions
                  data={cadastroProdutos}
                  cadastroTipoId={1}
                  permissions={permissions}
                />
              ),
            },
            {
              title: <FormattedMessage id="tabelas" />,
              children: [
                {
                  title: <FormattedMessage id="classificacaoClientes" />,
                  component: (
                    <Permissions
                      data={cadastroClassClientes}
                      cadastroTipoId={1}
                      permissions={permissions}
                    />
                  ),
                },
                {
                  title: <FormattedMessage id="consultores" />,
                  component: (
                    <Permissions
                      data={cadastroConsultores}
                      cadastroTipoId={1}
                      permissions={permissions}
                    />
                  ),
                },
                {
                  title: <FormattedMessage id="classificacaoDistribuidores" />,
                  component: (
                    <Permissions
                      data={cadastroClassDistribuidores}
                      cadastroTipoId={1}
                      permissions={permissions}
                    />
                  ),
                },
                {
                  title: <FormattedMessage id="mesaOperacao" />,
                  component: (
                    <Permissions
                      data={cadastroMesaOperacao}
                      cadastroTipoId={1}
                      permissions={permissions}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    };

    menuData.push(cadastro);

    /////// DASHBOARD ///////////
    let dashboardPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "dashboard") {
        dashboardPermissoes = e.dashboard.filter(
          (item) => item.permission === "dashboard-sodexo[1]"
        );
      }
    });

    const dashboard = {
      title: "Dashboard",
      children: [
        {
          title: "Dashboard",
          component: (
            <Permissions
              data={dashboardPermissoes}
              cadastroTipoId={1}
              permissions={permissions}
            />
          ),
        },
      ],
    };
    menuData.push(dashboard);

    /////// HOME ///////////
    let homePermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "cards") {
        homePermissoes = e.cards.filter(
          (item) => item.permission === "cards-home[1]"
        );
      }
    });

    const home = {
      title: "Home",
      children: [
        {
          title: "Home",
          component: (
            <Permissions
              data={homePermissoes}
              cadastroTipoId={1}
              permissions={permissions}
            />
          ),
        },
      ],
    };
    menuData.push(home);

    /////// PEDIDOS ///////////
    let precosPermissoes;
    let pedidosPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "preco") {
        precosPermissoes = e.preco.filter(
          (item) =>
            item.permission === "preco-excel[1]" ||
            item.permission === "preco-importar[1]" ||
            item.permission === "preco-list[1]" ||
            item.permission === "preco-update[1]"
        );
      }

      if (Object.keys(e)[0] === "pedido") {
        pedidosPermissoes = e.pedido.filter((item) => {
          return (
            item.permission.indexOf("pedido-destroy") === -1 &&
            item.permission.indexOf("pedido-aprovar") === -1 &&
            item.permission.indexOf("pedido-reprovar") === -1
          );
        });
      }
    });

    const pedido = {
      title: <FormattedMessage id="pedidos" />,
      children: [
        {
          title: <FormattedMessage id="precosCombustiveis" />,
          component: (
            <Permissions
              data={precosPermissoes}
              cadastroTipoId={1}
              permissions={permissions}
            />
          ),
        },
        {
          title: <FormattedMessage id="painelControle" />,
          component: (
            <Permissions
              data={pedidosPermissoes}
              cadastroTipoId={1}
              permissions={permissions}
            />
          ),
        },
      ],
    };

    menuData.push(pedido);

    /////// RELATÓRIOS ///////////
    let reportPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "relatorio") {
        reportPermissoes = e.relatorio;
      }
    });

    const reports = {
      title: <FormattedMessage id="relatorio" />,
      children: [
        {
          title: <FormattedMessage id="relatorio" />,
          component: (
            <Permissions
              data={reportPermissoes}
              cadastroTipoId={1}
              permissions={permissions}
            />
          ),
        },
      ],
    };

    menuData.push(reports);

    setListaPermissoes(menuData);
  }, [permissions, allPermissions]);

  const handleSetInput = useCallback((e, p, cadastroTipoId) => {
    const permissionName =
      p.permission.substr(0, p.permission.length - 3) + `[${cadastroTipoId}]`;

    if (e.checked) {
      setPermissions((permissions) => [...permissions, permissionName]);
    } else {
      setPermissions((permissions) =>
        permissions.filter((p) => p !== permissionName)
      );

      const x = e.parentElement.parentElement.getElementsByTagName("div");

      const indice = Object.keys(x);

      indice.forEach((item) => {
        const t = x[item].childNodes[0]?.value.substr(
          0,
          x[item].childNodes[0]?.value.length - 3
        );

        setCheckBoxAll((checkbox) =>
          checkbox.filter(
            (p) =>
              p?.indexOf(
                t.indexOf(p?.permission?.substr(0, p.permission.length - 3))
              ) > 1
          )
        );
      });
    }
  }, []);

  const handleClickCheckbox = useCallback(
    (e, permissionObj, cadastroTipoId) => {
      try {
        if (e.checked === true) {
          setCheckBoxAll((checkbox) => [
            ...checkbox,
            permissionObj.permission + cadastroTipoId,
          ]);

          const x = e.parentNode.parentNode.getElementsByTagName("div");

          const indice = Object.keys(x);

          // Marca Todos
          indice.map((item) => {
            if (x[item].childNodes[0].value !== "on") {
              setPermissions((permissions) => [
                ...permissions,
                x[item].childNodes[0].value,
              ]);
            }
            return (x[item].childNodes[0].checked = true);
          });
        }

        // Desmarca Todos
        if (e.checked === false) {
          setCheckBoxAll((checkbox) =>
            checkbox.filter(
              (p) => p !== permissionObj.permission + cadastroTipoId
            )
          );

          const x = e.parentNode.parentNode.getElementsByTagName("div") || [];

          const indice = Object.keys(x);

          indice.map((item) => {
            setPermissions((permissions) =>
              permissions.filter((p) => p !== x[item].childNodes[0].value)
            );

            return (x[item].childNodes[0].checked = false);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const Permissions = useCallback(
    ({ data, cadastroTipoId, permissions }) => {
      return (
        <CheckForm>
          <main style={{ backgroundColor: "transparent" }}>
            {data.map((permissionObj, index) => {
              let labelPermission = "";

              const namePermission =
                permissionObj.permission.substr(
                  0,
                  permissionObj.permission.length - 3
                ) + `[${cadastroTipoId}]`;

              const checkNamePermission = permissionObj.permission.substr(
                0,
                permissionObj.permission.length - 3
              );

              if (
                checkNamePermission === "pedido-store" ||
                checkNamePermission === "pedido-update" ||
                checkNamePermission === "cadastro-cliente-store" ||
                checkNamePermission === "cadastro-distribuidor-store"
              ) {
                // Ajustes de Nome Especificos solicitado pelo cliente.
                switch (
                  permissionObj.permission
                    .replace(`${permissionObj.name}-`, "")
                    .substr(
                      0,
                      permissionObj.permission.replace(
                        `${permissionObj.name}-`,
                        ""
                      ).length - 3
                    )
                ) {
                  case "store":
                    labelPermission = <FormattedMessage id="novoPedido" />;
                    break;
                  case "update":
                    labelPermission = <FormattedMessage id="editarPedido" />;
                    break;
                  case "cliente-store":
                    labelPermission = <FormattedMessage id="cadastroGeral" />;
                    break;
                  case "distribuidor-store":
                    labelPermission = <FormattedMessage id="cadastroGeral" />;
                    break;
                  default:
                    labelPermission = permissionObj.permission.substr(
                      0,
                      permissionObj.permission.length - 3
                    );
                    break;
                }
              } else {
                switch (
                  permissionObj.permission
                    .replace(`${permissionObj.name}-`, "")
                    .substr(
                      0,
                      permissionObj.permission.replace(
                        `${permissionObj.name}-`,
                        ""
                      ).length - 3
                    )
                ) {
                  case "list":
                    labelPermission = <FormattedMessage id="listar" />;
                    break;
                  case "store":
                    labelPermission = <FormattedMessage id="cadastrar" />;
                    break;
                  case "show":
                    labelPermission = <FormattedMessage id="exibir" />;
                    break;
                  case "update":
                    labelPermission = <FormattedMessage id="editar" />;
                    break;
                  case "destroy":
                    labelPermission = <FormattedMessage id="excluir" />;
                    break;
                  case "redefinirsenha":
                    labelPermission = <FormattedMessage id="redefinirSenha" />;
                    break;
                  case "excel":
                    labelPermission = <FormattedMessage id="exportarExcel" />;
                    break;
                  case "importar":
                    labelPermission = <FormattedMessage id="importar" />;
                    break;
                  case "filter":
                    labelPermission = <FormattedMessage id="filtrar" />;
                    break;
                  case "aprovar":
                    labelPermission = <FormattedMessage id="aprovarPedido" />;
                    break;
                  case "reprovar":
                    labelPermission = <FormattedMessage id="reprovarPedido" />;
                    break;
                  case "liberar":
                    labelPermission = <FormattedMessage id="liberarPedido" />;
                    break;
                  case "cancelar":
                    labelPermission = <FormattedMessage id="cancelarPedido" />;
                    break;
                  case "integrar":
                    labelPermission = <FormattedMessage id="integrar" />;
                    break;
                  //Cadastro
                  case "cliente-list":
                    labelPermission = <FormattedMessage id="listar" />;
                    break;
                  case "cliente-store":
                    labelPermission = <FormattedMessage id="cadastrar" />;
                    break;
                  case "cliente-show":
                    labelPermission = <FormattedMessage id="exibir" />;
                    break;
                  case "cliente-update":
                    labelPermission = <FormattedMessage id="editar" />;
                    break;
                  case "cliente-destroy":
                    labelPermission = <FormattedMessage id="excluir" />;
                    break;
                  case "distribuidor-list":
                    labelPermission = <FormattedMessage id="listar" />;
                    break;
                  case "distribuidor-store":
                    labelPermission = <FormattedMessage id="cadastrar" />;
                    break;
                  case "distribuidor-show":
                    labelPermission = <FormattedMessage id="exibir" />;
                    break;
                  case "distribuidor-update":
                    labelPermission = <FormattedMessage id="editar" />;
                    break;
                  case "distribuidor-destroy":
                    labelPermission = <FormattedMessage id="excluir" />;
                    break;
                  case "cliente-dadoscadastrais":
                    labelPermission = (
                      <FormattedMessage id="editarDadosCadastrais" />
                    );
                    break;
                  case "cliente-dadosendereco":
                    labelPermission = (
                      <FormattedMessage id="editarDadosEndereco" />
                    );
                    break;
                  case "cliente-dadosentrega":
                    labelPermission = (
                      <FormattedMessage id="editarDadosEntrega" />
                    );
                    break;
                  case "cliente-dadoscobranca":
                    labelPermission = (
                      <FormattedMessage id="editarDadosCobrança" />
                    );
                    break;
                  case "cliente-consumocapacidade":
                    labelPermission = (
                      <FormattedMessage id="editarConsumoCapacidade" />
                    );
                    break;
                  case "cliente-certificadodigitalnfe":
                    labelPermission = (
                      <FormattedMessage id="editarCertificado" />
                    );
                    break;
                  case "cliente-basesdistribuicao":
                    labelPermission = (
                      <FormattedMessage id="editarBasesDistribuicao" />
                    );
                    break;
                  case "distribuidor-dadoscadastrais":
                    labelPermission = (
                      <FormattedMessage id="editarDadosCadastrais" />
                    );
                    break;
                  case "distribuidor-dadosendereco":
                    labelPermission = (
                      <FormattedMessage id="editarDadosEndereco" />
                    );
                    break;
                  case "distribuidor-basesdistribuicao":
                    labelPermission = (
                      <FormattedMessage id="editarBasesDistribuicao" />
                    );
                    break;
                  case "dadoscadastrais":
                    labelPermission = (
                      <FormattedMessage id="editarDadosCadastrais" />
                    );
                    break;
                  case "dadosendereco":
                    labelPermission = (
                      <FormattedMessage id="editarDadosEndereco" />
                    );
                    break;
                  case "dadosentrega":
                    labelPermission = (
                      <FormattedMessage id="editarDadosEntrega" />
                    );
                    break;
                  case "dadoscobranca":
                    labelPermission = (
                      <FormattedMessage id="editarDadosCobrança" />
                    );
                    break;
                  case "consumocapacidade":
                    labelPermission = <FormattedMessage id="dadosCapacidade" />;
                    break;
                  case "certificadodigitalnfe":
                    labelPermission = (
                      <FormattedMessage id="editarCertificado" />
                    );
                    break;
                  case "basesdistribuicao":
                    labelPermission = (
                      <FormattedMessage id="editarBasesDistribuicao" />
                    );
                    break;
                  // Dashboard
                  case "sodexo":
                    labelPermission = "Exibir Dashboard";
                    break;
                  // Home
                  case "home":
                    labelPermission = "Exibir Cards";
                    break;
                  //Relatórios
                  case "cadastroclientes":
                    labelPermission = <FormattedMessage id="cadastroCliente" />;
                    break;
                  case "cadastrodistribuidorestrrs":
                    labelPermission = (
                      <FormattedMessage id="cadastroDistribuidorTRR" />
                    );
                    break;
                  case "condicoescomerciaisclientes":
                    labelPermission = (
                      <FormattedMessage id="condicaoComercialCliente" />
                    );
                    break;
                  case "logs":
                    labelPermission = "Logs";
                    break;
                  case "precomedio":
                    labelPermission = (
                      <FormattedMessage id="precoMedioCombustiveis" />
                    );
                    break;
                  case "historiconegociacoes":
                    labelPermission = (
                      <FormattedMessage id="historicoNegociacaoDistribuidor" />
                    );
                    break;
                  case "saving":
                    labelPermission = "Saving";
                    break;
                  case "pedidosdetalhados":
                    labelPermission = <FormattedMessage id="pedidoDetalhado" />;
                    break;
                  case "reembolso":
                    labelPermission = (
                      <FormattedMessage id="extratoReembolsoDistribuidor" />
                    );
                    break;
                  case "index":
                    labelPermission = <FormattedMessage id="listar" />;
                    break;
                  default:
                    labelPermission = permissionObj.permission.substr(
                      0,
                      permissionObj.permission.length - 3
                    );
                    break;
                }
              }

              //Verifica se tem valor
              let checkAll = true;
              let checkValue = permissions.filter((p) => p === namePermission);

              // remove duplicado
              var noDuplicateArray = permissions.filter(
                (item, i) => permissions.indexOf(item) === i
              );

              let checkArray = [];

              data.forEach((item) => {
                const t = noDuplicateArray.find((i) => i === item.permission);

                if (t) {
                  checkArray.push(t);
                }
              });

              checkAll = checkBoxAll.find(
                (item) => item === permissionObj.permission + cadastroTipoId
              );

              if (checkAll) {
                checkAll = true;
              } else {
                checkAll = false;
              }

              if (checkValue.length > 0) {
                checkValue = true;
              } else {
                checkValue = false;
              }

              const CheckAll = () => (
                <div>
                  <input
                    type="checkbox"
                    defaultChecked={checkAll}
                    onClick={(e) =>
                      handleClickCheckbox(
                        e.target,
                        permissionObj,
                        cadastroTipoId
                      )
                    }
                    disabled={true}
                  />
                  <label>
                    <FormattedMessage id="todos" />
                  </label>
                </div>
              );

              const CheckOne = ({ hasCheckAll }) => (
                <>
                  {hasCheckAll === 0 && <CheckAll />}
                  <div>
                    <input
                      type="checkbox"
                      name={namePermission}
                      defaultChecked={checkValue}
                      onClick={(e) =>
                        handleSetInput(e.target, permissionObj, cadastroTipoId)
                      }
                      value={namePermission}
                      disabled={true}
                    />
                    <label>{labelPermission}</label>
                  </div>
                </>
              );

              // Retorna as permissoes nos inputs
              return <CheckOne key={index} hasCheckAll={index} />;
            })}
          </main>
        </CheckForm>
      );
    },
    [checkBoxAll, handleClickCheckbox, handleSetInput]
  );

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      {/* <button onClick={handleteste}>Entrar</button> */}
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirPerfilAcessoSodexo" />
          </h4>
        </header>
        {loading ? (
          <ContentSpinner />
        ) : (
          <form>
            <AsyncListSelect
              value={empresa}
              onChange={(e) => {
                setEmpresa(e);
              }}
              cacheOptions
              defaultOptions
              labelPlaceholder={<FormattedMessage id="empresa" />}
              placeholder={<FormattedMessage id="selecionar" />}
              loadingMessage={() => <FormattedMessage id="carregando" />}
              isSearchable={false}
              isDisabled={true}
            />

            <InputLabel
              name="name"
              maxLength={100}
              type="text"
              label={<FormattedMessage id="nome" />}
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              readOnly
            />

            <InputLabel
              name="descricao"
              maxLength={200}
              type="text"
              value={descricao}
              label={<FormattedMessage id="descricao" />}
              onChange={(e) => setDescricao(e.target.value)}
              readOnly
            />

            <ListSelect
              labelPlaceholder={"Status"}
              onChange={(e) => setStatus(e)}
              options={statusOptions}
              placeholder={<FormattedMessage id="selecionar" />}
              value={status}
              isDisabled={true}
            />

            <hr />

            <TitlePermission>Menu</TitlePermission>

            <FormPermission>
              <TreeComponent data={listaPermissoes} display={true}/>
            </FormPermission>
          </form>
        )}

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditAccessProfiles);
