import React, { useContext, useEffect, useState, createContext } from "react";
import { FormattedMessage } from "react-intl";
import AuthContext from "./../context/auth";

import iconHome from "./../assets/images/1.svg";
import iconDashboard from "./../assets/images/2.svg";
import iconPedidos from "./../assets/images/3.svg";
import iconRelatorios from "./../assets/images/4.svg";
import iconAcessos from "./../assets/images/5.svg";
import iconCadastros from "./../assets/images/6.svg";

const MenuContext = createContext();

export const SideBarMenu = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [menuUser] = useState(user?.cadastro_tipo_id || "");
  const [versao] = useState(user?.versao || "");
  const [home, setHome] = useState([]);
  const [Menu, setMenu] = useState([]);

  // 1 Sodexo, 2 Dist, 3 Cliente
  const [viewPermissions] = useState({
    "/home": {
      1: true,
      2: true,
      3: true,
    },

    "/dashboard": {
      1: true,
      2: true,
      3: true,
    },
    "/access": {
      1: true,
      2: true,
      3: true,
    },
    "/access/profiles": {
      1: true,
      2: true,
      3: true,
    },
    "/access/profiles/customers/list": {
      1: true,
      2: false,
      3: true,
    },
    "/access/profiles/distributors/list": {
      1: true,
      2: true,
      3: false,
    },
    "/access/profiles/graal/list": {
      1: true,
      2: false,
      3: false,
    },
    "/access/users": {
      1: true,
      2: true,
      3: true,
    },
    "/access/users/customer/list": {
      1: true,
      2: false,
      3: true,
    },
    "/access/users/distributor/list": {
      1: true,
      2: true,
      3: false,
    },
    "/access/users/graal/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register": {
      1: true,
      2: false,
      3: true,
    },
    "/register/sodexo": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/distribution-base/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/customers/list": {
      1: true,
      2: false,
      3: false,
    },
    "/communication/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/distributors-trrs/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/products-fuels/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/products/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/association/edit": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/tables": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/tables/customer-classification/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/tables/consultants-classification/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/tables/distribution-classification/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/tables/operation-station/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/tables/brands/list": {
      1: true,
      2: false,
      3: false,
    },
    "/register/graal/tables/category/list": {
      1: true,
      2: false,
      3: false,
    },
    "/orders": {
      1: true,
      2: true,
      3: true,
    },
    "/orders/price": {
      1: true,
      2: true,
      3: true,
    },
    "/orders-fuel": {
      1: true,
      2: true,
      3: false,
    },
    "/orders/price-fuel": {
      1: true,
      2: true,
      3: false,
    },
    "/orders-freight": {
      1: true,
      2: true,
      3: false,
    },
    "/orders/price-freight": {
      1: true,
      2: true,
      3: false,
    },
    "/orders-product": {
      1: true,
      2: true,
      3: false,
    },
    "/orders/price-product": {
      1: true,
      2: true,
      3: false,
    },
    "/orders/price-product-freight": {
      1: true,
      2: true,
      3: false,
    },
    "/orders/painel": {
      1: false,
      2: true,
      3: true,
    },
    "/orders/painel/v2": {
      1: true,
      2: false,
      3: false,
    },
    "/orders/painel/new": {
      1: true,
      2: true,
      3: true,
    },
    "/orders/new": {
      1: true,
      2: false,
      3: true,
    },
    "/orders/v2": {
      1: true,
      2: false,
      3: true,
    },
    "/orders/painel/program": {
      1: true,
      2: false,
      3: true,
    },
    "/orders/program": {
      1: true,
      2: false,
      3: true,
    },
    "/report": {
      1: true,
      2: true,
      3: true,
    },
    "/report/customer-conditions": {
      1: true,
      2: false,
      3: false,
    },
    "/report/customer-register": {
      1: true,
      2: false,
      3: false,
    },
    "/report/distributor-register": {
      1: true,
      2: false,
      3: false,
    },
    "/report/refund": {
      1: true,
      2: true, //desabilitado no momento
      3: false,
    },
    "/report/trading-history": {
      1: true,
      2: false, //desabilitado no momento
      3: false,
    },
    "/report/log": {
      1: true,
      2: true,
      3: true,
    },
    "/report/orders": {
      1: true,
      2: true,
      3: true,
    },
    "/report/price-combustivel": {
      1: true,
      2: false,
      3: false,
    },
    "/report/price-combustivel-billed": {
      1: true,
      2: false,
      3: false,
    },
    "/report/saving": {
      1: true,
      2: false,
      3: false,
    },
    "/certificate-edit": {
      1: false,
      2: false,
      3: true,
    },
  });

  useEffect(() => {
    let costumer;
    if (parseInt(user?.cadastro_tipo_id) !== 3 || "") {
      costumer = <FormattedMessage id="consultaPrecosCombustiveis" />;
    } else {
      costumer = <FormattedMessage id="consultaPrecosDia" />;
    }

    setHome([
      {
        isPrivate: viewPermissions["/home"][menuUser],
        path: "/home",
        icon: <img src={iconHome} alt={iconHome} />,
        title: "Home",
      },
    ]);
    setMenu([
      {
        isPrivate: viewPermissions["/dashboard"][menuUser],
        path: "/dashboard",
        icon: <img src={iconDashboard} alt={iconDashboard} />,
        title: "Dashboard",
        userTipo: user?.tipo_id,
        permission: user?.permission.filter(
          (p) =>
            p.namePermission === `dashboard-sodexo[${user?.cadastro_tipo_id}]` ||
            p.namePermission ===
              `dashboard-distribuidor[${user?.cadastro_tipo_id}]` ||
            p.namePermission === `dashboard-cliente[${user?.cadastro_tipo_id}]`
        ),
      },

      {
        isPrivate: viewPermissions["/orders"][menuUser],
        path: "/orders",
        icon: <img src={iconPedidos} alt={iconPedidos} />,
        title: <FormattedMessage id="pedidos" />,
        userTipo: user?.tipo_id,
        children: [
          {
            isPrivate: viewPermissions["/orders/price-fuel"][menuUser],
            path: "/orders/price-fuel",
            title:  <FormattedMessage id="valoresCombustiveis" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: viewPermissions["/orders/price-freight"][menuUser],
            path: "/orders/price-freight",
            title:  "Valores Combustíveis Fretes",
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: viewPermissions["/orders/price-product"][menuUser],
            path: "/orders/price-product",
            title: <FormattedMessage id="valoresProdutos" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: viewPermissions["/orders/price-product-freight"][menuUser],
            path: "/orders/price-product-freight",
            title: "Valores Produtos Fretes" ,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: versao === 'v1' && user?.cadastro_tipo_id === 3 && viewPermissions["/orders/price"][menuUser],
            path: "/orders/price",
            title: costumer,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: viewPermissions["/orders/painel/v2"][menuUser],
            path: "/orders/painel/v2",
            title: <FormattedMessage id="painelControle" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `pedido-list[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: viewPermissions["/orders/painel"][menuUser],
            path: "/orders/painel",
            title: <FormattedMessage id="painelControle" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `pedido-list[${user.cadastro_tipo_id}]`
            ),
          },
          user?.cadastro_tipo_id === 3 &&
          {
            isPrivate: versao === 'v1' && user?.cadastro_tipo_id === 3 ? viewPermissions["/orders/new"][menuUser] : viewPermissions["/orders/v2"][menuUser],
            path: versao === 'v1' && menuUser === 3 ? "/orders/new" : "/orders/v2",
            title: <FormattedMessage id="novoPedido" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `pedido-store[${user.cadastro_tipo_id}]`
            ),
          },

          user?.cadastro_tipo_id === 1 && {
            isPrivate:  viewPermissions["/orders/v2"][menuUser],
            path: "/orders/v2",
            title: "Novo Pedido v2",
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `pedido-store[${user.cadastro_tipo_id}]`
            ),
          },
          user?.cadastro_tipo_id === 1 && {
            isPrivate:  viewPermissions["/orders/new"][menuUser],
            path: "/orders/new",
            title: "Novo Pedido v1",
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `pedido-store[${user.cadastro_tipo_id}]`
            ),
          },

          // {
          //   isPrivate: viewPermissions["/orders/program"][menuUser],
          //   path: "/orders/program",
          //   title: <FormattedMessage id="programacaoPedido" />,
          //   userTipo: user?.tipo_id,
          //   permission: user?.permission.filter(
          //     (p) =>
          //       p.namePermission ===
          //       `programacao-store[${user.cadastro_tipo_id}]`
          //   ),
          // },
        ],
      },

      // {
      //   isPrivate: viewPermissions["/orders/painel"][menuUser],
      //   path: "/orders/painel",
      //   icon: <FaDollarSign />,
      //   title: <FormattedMessage id="financeiro" />,
      //   userTipo: user?.tipo_id,
      //   children: [
      //     {
      //       isPrivate: viewPermissions["/orders/painel"][menuUser],
      //       path: "/orders/painel",
      //       title: <FormattedMessage id="boleto" />,
      //       userTipo: user?.tipo_id,
      //     },
      //     {
      //       isPrivate: viewPermissions["/orders/painel"][menuUser],
      //       path: "/orders/painel",
      //       title: <FormattedMessage id="nfeCompra" />,
      //       userTipo: user?.tipo_id,
      //     },
      //     {
      //       isPrivate: viewPermissions["/orders/painel"][menuUser],
      //       path: "/orders/painel",
      //       title: <FormattedMessage id="nfeServico" />,
      //       userTipo: user?.tipo_id,
      //     },
      //   ],
      // },

      {
        isPrivate: viewPermissions["/report"][menuUser],
        path: "/report",
        icon: <img src={iconRelatorios} alt={iconRelatorios} />,
        title: <FormattedMessage id="relatorio" />,
        userTipo: user?.tipo_id,
        children: [
          {
            isPrivate: viewPermissions["/report/customer-register"][menuUser],
            path: "/report/customer-register",
            title: <FormattedMessage id="cadastroCliente" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `relatorio-cadastroclientes[1]`
            ),
          },
          {
            isPrivate:
              viewPermissions["/report/distributor-register"][menuUser],
            path: "/report/distributor-register",
            title: <FormattedMessage id="cadastroDistribuidorTRR" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `relatorio-cadastrodistribuidorestrrs[1]`
            ),
          },
          {
            isPrivate: viewPermissions["/report/customer-conditions"][menuUser],
            path: "/report/customer-conditions",
            title: <FormattedMessage id="condicaoComercialCliente" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `relatorio-condicoescomerciaisclientes[1]`
            ),
          },
          {
            isPrivate: viewPermissions["/report/refund"][menuUser],
            path: "/report/refund",
            title: <FormattedMessage id="extratoReembolsoDistribuidor" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission ===
                `relatorio-reembolso[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: viewPermissions["/report/trading-history"][menuUser],
            path: "/report/trading-history",
            title: <FormattedMessage id="historicoNegociacaoDistribuidor" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `relatorio-historiconegociacoes[1]` ||
                p.namePermission === "relatorio-historiconegociacoes[2]"
            ),
          },
          {
            isPrivate: viewPermissions["/report/log"][menuUser],
            path: "/report/log",
            title: "Log",
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === `relatorio-logs[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: viewPermissions["/report/orders"][menuUser],
            path: "/report/orders",
            title: <FormattedMessage id="pedidoDetalhado" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission ===
                `relatorio-pedidosdetalhados[${user.cadastro_tipo_id}]`
            ),
          },
          {
            isPrivate: viewPermissions["/report/price-combustivel"][menuUser],
            path: "/report/price-combustivel",
            title: "Preço Médio Produto Referência",
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `relatorio-precomedio[1]`
            ),
          },
          {
            isPrivate: viewPermissions["/report/price-combustivel-billed"][menuUser],
            path: "/report/price-combustivel-billed",
            title: "Preço Médio Produto Faturado",
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `relatorio-precomedio[1]`
            ),
          },
          {
            isPrivate: viewPermissions["/report/saving"][menuUser],
            path: "/report/saving",
            title: "Saving",
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === `relatorio-saving[1]`
            ),
          },
        ],
      },

      {
        isPrivate: viewPermissions["/access"][menuUser],
        path: "/access",
        icon: <img src={iconAcessos} alt={iconAcessos} />,
        title: <FormattedMessage id="acessos" />,
        userTipo: user?.tipo_id,
        children: [
          {
            isPrivate: viewPermissions["/access/profiles"][menuUser],
            path: "/access/profiles",
            title: <FormattedMessage id="perfis" />,
            userTipo: user?.tipo_id,
            children: [
              {
                isPrivate:
                  viewPermissions["/access/profiles/customers/list"][menuUser],
                path: "/access/profiles/customers/list",
                title: <FormattedMessage id="clientes" />,
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) => p.namePermission === "roles-list[3]"
                ),
              },
              {
                isPrivate:
                  viewPermissions["/access/profiles/distributors/list"][
                    menuUser
                  ],
                path: "/access/profiles/distributors/list",
                title: <FormattedMessage id="distribuidores" />,
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) => p.namePermission === "roles-list[2]"
                ),
              },
              {
                isPrivate:
                  viewPermissions["/access/profiles/graal/list"][menuUser],
                path: "/access/profiles/graal/list",
                title: "Graal",
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) => p.namePermission === "roles-list[1]"
                ),
              },
            ],
          },
          {
            isPrivate: viewPermissions["/access/users"][menuUser],
            path: "/access/users",
            title: <FormattedMessage id="usuarios" />,
            userTipo: user?.tipo_id,
            children: [
              {
                isPrivate:
                  viewPermissions["/access/users/customer/list"][menuUser],
                path: "/access/users/customer/list",
                title: <FormattedMessage id="clientes" />,
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) => p.namePermission === "usuario-list[3]"
                ),
              },
              {
                isPrivate:
                  viewPermissions["/access/users/distributor/list"][menuUser],
                path: "/access/users/distributor/list",
                title: <FormattedMessage id="distribuidores" />,
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) => p.namePermission === "usuario-list[2]"
                ),
              },
              {
                isPrivate:
                  viewPermissions["/access/users/graal/list"][menuUser],
                path: "/access/users/graal/list",
                title: "Graal",
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) => p.namePermission === "usuario-list[1]"
                ),
              },
            ],
          },
        ],
      },

      {
        isPrivate: viewPermissions["/register"][menuUser],
        path: "/register",
        icon: <img src={iconCadastros} alt={iconCadastros} />,
        title: <FormattedMessage id="cadastros" />,
        userTipo: user?.tipo_id,
        children: [
          {
            isPrivate: viewPermissions["/certificate-edit"][menuUser],
            path: "/certificate-edit",
            title: <FormattedMessage id="certificadoDigital" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) =>
                p.namePermission === "cadastro-cliente-certificadodigitalnfe[3]"
            ),
          },
          {
            isPrivate:
              viewPermissions["/register/graal/distribution-base/list"][
                menuUser
              ],
            path: "/register/graal/distribution-base/list",
            title: <FormattedMessage id="baseDistribuicao" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === "tabelabasedistribuicao-list[1]"
            ),
          },
          {
            isPrivate:
              viewPermissions["/register/graal/customers/list"][menuUser],
            path: "/register/graal/customers/list",
            title: <FormattedMessage id="clientes" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === "cadastro-cliente-list[1]"
            ),
          },
          {
            isPrivate: viewPermissions["/communication/list"][menuUser],
            path: "/communication/list",
            title: <FormattedMessage id="comunicacao" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === "comunicacao-index[1]"
            ),
          },
          {
            isPrivate:
              viewPermissions["/register/graal/distributors-trrs/list"][
                menuUser
              ],
            path: "/register/graal/distributors-trrs/list",
            title: <FormattedMessage id="distribuidores_trrs" />,
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === "cadastro-distribuidor-list[1]"
            ),
          },
          {
            isPrivate:
              viewPermissions["/register/graal/products-fuels/list"][menuUser],
            path: "/register/graal/products-fuels/list",
            title: "Produtos Combustíveis",
            userTipo: user?.tipo_id,
            permission: user?.permission.filter(
              (p) => p.namePermission === "produto-list[1]"
            ),
          },


          {
            isPrivate:
              viewPermissions["/register/graal/association/edit"][menuUser],
            path: "/register/graal/association/edit",
            title: <FormattedMessage id="associacaoProdutos" />,
            userTipo: user?.tipo_id,
            permission: true
            // user?.permission.filter(
            //   (p) =>
            //     p.namePermission === "produto-list[1]"
            // ),
          },
          {
            isPrivate: viewPermissions["/register/graal/tables"][menuUser],
            path: "/register/graal/tables",
            title: <FormattedMessage id="tabelas" />,
            userTipo: user?.tipo_id,
            children: [
              {
                isPrivate:
                  viewPermissions["/register/graal/products/list"][menuUser],
                path: "/register/graal/products/list",
                title: "Produtos em Geral",
                userTipo: user?.tipo_id,
                permission: true
                // user?.permission.filter(
                //   (p) =>
                //     p.namePermission === "produto-list[1]"
                // ),
              },
              {
                isPrivate:
                  viewPermissions[
                    "/register/graal/tables/customer-classification/list"
                  ][menuUser],
                path: "/register/graal/tables/customer-classification/list",
                title: <FormattedMessage id="classificacaoClientes" />,
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) =>
                    p.namePermission === "tabelaclassificacaocliente-list[1]"
                ),
              },
              {
                isPrivate:
                  viewPermissions[
                    "/register/graal/tables/distribution-classification/list"
                  ][menuUser],
                path: "/register/graal/tables/distribution-classification/list",
                title: <FormattedMessage id="classificacaoDistribuidores" />,
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) =>
                    p.namePermission ===
                    "tabelaclassificacaodistribuidor-list[1]"
                ),
              },
              {
                isPrivate:
                  viewPermissions[
                    "/register/graal/tables/consultants-classification/list"
                  ][menuUser],
                path: "/register/graal/tables/consultants-classification/list",
                title: <FormattedMessage id="consultores" />,
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) =>
                    p.namePermission ===
                    "tabelaclassificacaodistribuidor-list[1]"
                ),
              },
              {
                isPrivate:
                  viewPermissions[
                    "/register/graal/tables/operation-station/list"
                  ][menuUser],
                path: "/register/graal/tables/operation-station/list",
                title: <FormattedMessage id="mesaOperacao" />,
                userTipo: user?.tipo_id,
                permission: user?.permission.filter(
                  (p) => p.namePermission === "tabelamesaoperacao-list[1]"
                ),
              },
              {
                isPrivate:
                viewPermissions["/register/graal/tables/brands/list"][menuUser],
                path: "/register/graal/tables/brands/list",
                title: <FormattedMessage id="marcas" />,
                userTipo: user?.tipo_id,
                // permission: user?.permission.filter(
                //   (p) => p.namePermission === "tabelamesaoperacao-list[1]"
                // ),
                permission: true
              },
              {
                isPrivate:
                viewPermissions["/register/graal/tables/category/list"][menuUser],
                path: "/register/graal/tables/category/list",
                title: <FormattedMessage id="categorias" />,
                userTipo: user?.tipo_id,
                // permission: user?.permission.filter(
                //   (p) => p.namePermission === "tabelamesaoperacao-list[1]"
                // ),
                permission: true
              },
            ],
          },
        ],
      },
    ]);
  }, [menuUser, user, viewPermissions]);

  return (
    <MenuContext.Provider value={{ menu: Menu, viewPermissions, home }}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContext;
