import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import { getErrorsValidation } from "./../../utils/getErrorsValidation";
import Logo from "./../../assets/images/graal.png";

import api from "./../../services/api";

import Input from "./../../components/InputDefault";
import Button from "./../../components/ButtonDefault";
import BackgroundImage from "./../../assets/images/background-login.png";

import {
  Container,
  SignContainer,
  Background,
  Separator,
  LogoContainer,
} from "./styles";

import Language from "./../../components/header/dropdown/language";

import { toast } from "react-toastify";

const Recovery = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState();
  const [loadingButton, setLoadButton] = useState(false);

  const handleRecoveryPassword = useCallback(async () => {
    //Validando campos

    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .required(<FormattedMessage id="emailObrigatorio" />)
          .email(<FormattedMessage id="emailInvalido" />),
      });

      await schema.validate(
        { email },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      return setErrors(getErrorsValidation(error));
    }

    try {
      setLoadButton(true);
      setErrors("");
      await api.post("/recuperarsenha", { email });
      toast.success(
        "O link para redefinição de senha foi enviado para o seu e-mail",
        { autoClose: 5000 }
      );
      setEmail("");
      setLoadButton(false);
    } catch (error) {
      setLoadButton(false);
      return toast.error(
        "Erro ao encontrar e-mail, verifique o e-mail e tente novamente."
      );
    }
  }, [email]);

  return (
    <Container>
      <SignContainer>
        <img src={Logo} alt="Wizeo" />

        <div>
          <header>
            <h5>
              <FormattedMessage id="encontreSuaSenha" />
            </h5>
            <Language />
          </header>

          <Input
            placeholder="E-mail"
            type="text"
            maxLength="80"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            geterror={errors?.email}
          />

          <Button
            type="button"
            onClick={handleRecoveryPassword}
            loading={loadingButton}
          >
            <FormattedMessage id="enviar" />
          </Button>

          <footer>
            <p>
              <FormattedMessage id="volteLogin" />{" "}
              <Link to="/signin">
                {" "}
                <FormattedMessage id="cliqueAqui" />
              </Link>
            </p>
          </footer>

          <Separator />

          <span>
            <FormattedMessage id="copyright" />
          </span>
        </div>
      </SignContainer>
      <Background>
          <img src={BackgroundImage} alt="img" />
        </Background>

    </Container>
  );
};

export default Recovery;
