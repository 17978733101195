import styled from "styled-components";

export const Card = styled.div`
  header {
    width: 100%;
    height: 34px;
    background-color: #030894!important;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    width: max-content;
    height: max-content;
    margin-left: 10px;
    color: #f8f8f8;
    position: absolute;
    left: 0;
    margin-top: 8px;
  }

  @media (max-width: 700px) {
    .stats-title {
      font-size: 12px !important;
    }
    .stats-number {
      font-size: 15px !important;
      margin-bottom: 3px !important;
      font-weight: 600 !important;
      letter-spacing: -1px !important;
    }
  }
`;

export const InfoOrder = styled.div`
  /* background-color: #e8e8e8 !important; */
  /* padding: 5px 10px; */
  border: solid 1px #030894;
  width: 100%;
  margin-bottom: 15px;
  a {
    p {
      font-weight: 700;
      white-space: nowrap;
      background: #030894;
      color: #fff;
      margin-bottom: 5px;
      font-size: 12px;
      padding: 10px 0 10px 0

    }

    div {
      font-size: 18px;
      color: #27262a;
      padding: 5px 0 5px 0;
      font-weight: bold;
    }
  }
`;
