import styled from "styled-components";
import CreatableListSelect from "react-select/creatable";

export const Container = styled.div`
  margin-bottom: 10px;

  position: relative;

  p {
    margin-bottom: 0px !important;
    padding-left: 10px;
    font-weight: bold;
  }
`;

export const List = styled(CreatableListSelect).attrs({})`
  width: 100% !important;
  margin-bottom: 10px;
  font-size: 13px;
  font-family: sans-serif;
`;

export const MessageError = styled.div`
  width: 100%;
  height: 100%;

  align-items: center;
  height: 10px !important;
  transition: all 0.7s;
  margin-top: -5px;

  p {
    width: max-content;
    height: 100%;
    font-size: 11px;
    color: #27262a;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    width: max-content;
  }
`;

//<CreatableListSelect onInputChange/>
