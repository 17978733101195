import styled, { keyframes } from "styled-components";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width: 100%;
    opacity: 0.5;
  }
`;

export const Container = styled.div`
  height: 45px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;

  > div {
    margin: auto;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;

    cursor: pointer;
    transition: all 0.3s;

    :hover {
      color: #fff;

      ::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 0;
        height: 0;
        transition: all 0.4s;

        background-color: transparent;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #030894;
        margin-left: 5px;
      }
    }
  }
`;

export const OpenOption = styled.section`
  height: 40px;
  // width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  //width: max-content !important;

  animation: ${fadein} 0.2s;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in;

  /* border: 1px solid ; */
  /* border-radius: 5px; */
  transition: all 0.3s;

  position: absolute;
  right: 1px;
  background: #030894;
  z-index: 1;

  aside {
    height: 100%;
    /* border-radius: 5px 0 0 5px; */
    background: #c02c31;
    display: flex;
    align-items: center;
    svg {
      /* border-radius: 50%; */
      height: 30px;
      width: 30px;
      transition: all 0.4s;
      color: #27262a;

      :hover {
        font-size: 18px;
      }
    }

    :hover {
      background-color: #FCC1BE
    }
  }

  ul {
    margin: 0;

    width: 100%;
    display: flex;
    justify-content: space-around;
    list-style: none;

    li {
      cursor: pointer;

      svg, img {
        /* border-radius: 50%; */
        height: 20px; //aqui
        width: 20px;
        margin: 0 3px 0 3px;
        transition: all 0.4s;
        color: #fff;
        :hover {
          font-weight: bold;
          color: #c02c31;
        }

        div {
          display: flex;
          background-color: #2d353c;
          border-radius: 5px;
          position: absolute;
          bottom: calc(100%);
          color: #fff;
          transition: all 0.7s;
          z-index: 1;
          opacity: 1;

          cursor: default;

          &::before {
            content: "";
            border-style: solid;
            border-color: #2d353c transparent;
            border-width: 6px 6px 0 6px;
            bottom: 20px;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
          }
        }
      }
    }
  }
`;

export const ToolTip = styled.div`
  display: flex;
  background-color: #2d353c;
  /* border-radius: 5px; */
  position: absolute;
  bottom: calc(100%);
  color: #fff;
  transition: all 0.7s;
  z-index: 1;

  cursor: default;

  &::before {
    content: "";
    border-style: solid;
    border-color: #2d353c transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
`;
