import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import MessageDefault from "./../../../components/MessageDefault";

import { searchCep } from "./../../../services/cep";

import { FiPlus, FiTrash2, FiAlertCircle } from "react-icons/fi";

import api from "./../../../services/api";

import MaskInput from "./../../../config/input-mask";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";

import {
  Container,
  CreateForm,
  CreateFooter,
  TabStyled,
  FormTax,
  TitleTax,
  HeaderTax,
  FormConsumer,
} from "./styles";

import InputMaskDefault from "./../../../components/InputMaskDefault";
import InputLabel from "./../../../components/InputLabel";
import TextAreaLabel from "./../../../components/TextAreaLabel";
import Button from "./../../../components/ButtonDefault";
import ButtonIcon from "./../../../components/ButtonIcon";
import CheckboxDefault from "./../../../components/CheckboxDefault";

import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

export const CreateCustomers = ({ intl }) => {
  // Cliente = 3, Distribuidor = 2, Sodexo = 1
  const [tipoCadastro] = useState(3);

  // Dados Cadastrais
  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [registroANP, setRegistroANP] = useState("");
  const [grupoEconomico, setGrupoEconomico] = useState("");
  const [perfil, setPerfil] = useState("");
  const [matriz, setMatriz] = useState("");
  const [empresaMatriz, setEmpresaMatriz] = useState("");
  const [classificacao, setClassificacao] = useState("");
  const [contato, setContato] = useState("");
  const [email, setEmail] = useState("");
  const [contatoDois, setContatoDois] = useState("");
  const [emailDois, setEmailDois] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [celularDois, setCelularDois] = useState("");
  const [mesaOperacao, setMesaOperacao] = useState("");
  const [farmer, setFarmer] = useState("");
  const [hunter, setHunter] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });

  // Dados de endereços
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [cidadeFilter, setCidadeFilter] = useState("");

  // Dados de Entrega
  const [modalidadeTransporte, setModalidadeTransporte] = useState("");
  const [tanque, setTanque] = useState("");
  const [modoAbastecimento, setModoAbastecimento] = useState("");
  const [tipoRecebimento, setTipoRecibimento] = useState("");
  const [observacoes, setObservacoes] = useState("");

  //Dados Cobrança
  const [limiteCredito, setLimiteCredito] = useState("");
  const [saldoCredito, setSaldoCredito] = useState("");
  const [prazoPagamento, setPrazoPagamento] = useState("");
  const [taxaAdm, setTaxaAdm] = useState("");
  const [taxaFinanceira, setTaxaFinanceira] = useState([
    {
      id: "",
      taxa_financeira_de: "",
      taxa_financeira_ate: "",
      taxa_financeira_percentual: "",
    },
  ]);

  //Consumo e Capacidade
  const [produtos, setProdutos] = useState([]);

  //Certificado digital
  const [nomeCertificado, setNomeCertificado] = useState("");
  //const [validade, setValidade] = useState("");
  const [certificado, setCertificado] = useState("");
  const [senha, setSenha] = useState("");

  // Bases de distribuição
  const [listaCidades, setListaCidades] = useState("cidade");
  const [preferenciaDist, setPreferenciaDist] = useState("");
  const [basesDistribuicao, setBaseDistribuicao] = useState([
    { value: "selecione", label: <FormattedMessage id="selecione" /> },
  ]);

  const [loadingButton, setLoadingButton] = useState(false);

  const [errors, setErrors] = useState();

  const history = useHistory();

  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });

  //Dados Cadastrais
  const razaoSocialPlaceholder = intl.formatMessage({ id: "razaoSocial" });
  const nomeFantasiaPlaceholder = intl.formatMessage({ id: "nomeFantasia" });
  const inscricaoEstadualPlaceholder = intl.formatMessage({
    id: "inscricaoEstadual",
  });
  const registroANPPlaceholder = intl.formatMessage({ id: "registroANP" });
  const grupoEconomicoPlaceholder = intl.formatMessage({
    id: "grupoEconomico",
  });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });
  const matrizPlaceholder = intl.formatMessage({ id: "matriz" });
  const empresaMatrizPlaceholder = intl.formatMessage({ id: "empresaMatriz" });
  const classificacaoPlaceholder = intl.formatMessage({ id: "classificacao" });
  const contatoPlaceholder = intl.formatMessage({ id: "contato" });
  const telefonePlaceholder = intl.formatMessage({ id: "telefone" });
  const celularPlaceholder = intl.formatMessage({ id: "celular" });
  const mesaOperacaoPlaceholder = intl.formatMessage({ id: "mesaOperacao" });

  // Dados endereço
  const cepPlaceholder = intl.formatMessage({ id: "cep" });
  const logradouroPlaceholder = intl.formatMessage({ id: "logradouro" });
  const numeroPlaceholder = intl.formatMessage({ id: "numero" });
  const complementoPlaceholder = intl.formatMessage({ id: "complemento" });
  const bairroPlaceholder = intl.formatMessage({ id: "bairro" });

  //Dados Entrega
  const modalidadeTransportePlaceholder = intl.formatMessage({
    id: "modalidadeTransporte",
  });
  const tanquesPropriosTransportePlaceholder = intl.formatMessage({
    id: "tanquesProprios",
  });
  const modoAbastecimentoPlaceholder = intl.formatMessage({
    id: "modoAbastecimento",
  });
  const tipoRecebimentoPlaceholder = intl.formatMessage({
    id: "tipoRecebimento",
  });
  const distribuidorPlaceholder = intl.formatMessage({
    id: "distribuidor",
  });
  const observacoesPlaceholder = intl.formatMessage({
    id: "observacoes",
  });

  // Dados Cobraça

  const limiteCreditoDisponivelPlaceholder = intl.formatMessage({
    id: "limiteCreditoDisponivel",
  });
  const saldoCreditoDisponivelPlaceholder = intl.formatMessage({
    id: "saldoCreditoDisponivel",
  });
  const prazoPagamentoPlaceholder = intl.formatMessage({
    id: "prazoPagamento",
  });
  const taxaAdministrativaPlaceholder = intl.formatMessage({
    id: "taxaAdministrativa",
  });
  const taxaFinanceiraPlaceholder = intl.formatMessage({
    id: "taxaFinanceira",
  });
  const dePlaceholder = intl.formatMessage({ id: "de" });
  const atePlaceholder = intl.formatMessage({ id: "ate" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const profileOptions = [
    { value: "Privado", label: <FormattedMessage id="privado" /> },
    { value: "Público", label: <FormattedMessage id="publico" /> },
  ];

  const YesNoOptions = [
    { value: 1, label: <FormattedMessage id="opcaoSim" /> },
    { value: 0, label: <FormattedMessage id="opcaoNao" /> },
  ];

  const modalidadeTransporteOptions = [
    { value: "CIF (Entrega)", label: "CIF (Entrega)" },
    { value: "FOB (Retira)", label: "FOB (Retira)" },
  ];

  const abastecimentoOptions = [
    { value: "Bomba", label: "Com Bomba" },
    { value: "Descarga", label: "Sem Bomba" },
  ];

  useEffect(() => {
    async function loadProducts() {
      try {
        const { data: response } = await api.get("/produto");

        const formattedList = response.data.map((item) => {
          const obj = {};

          Object.assign(obj, {
            produto_id: item.id,
            codigo: item.codigo,
            descricao: item.descricao,
            capacidade_tanque: "",
            volume_compras_mensal: "",
          });

          return obj;
        });

        setProdutos(formattedList);
      } catch (error) {}
    }

    loadProducts();

  }, []);

  const handleSearchCEP = useCallback(async (cep) => {
    try {
      const response = await searchCep(cep);

      const { data: estado } = await api.post(`/estado/filter`, {
        sigla: response.uf,
      });

      const { data: cidade } = await api.post(`/municipio/filter`, {
        codigo: response.ibge,
      });

      setBairro(response.bairro);
      setLogradouro(response.logradouro);

      setListaCidades(estado[0].sigla);
      setEstado({ value: estado[0].id, label: estado[0].sigla });
      setCidade({ value: cidade[0]?.id, label: cidade[0]?.nome });
    } catch (error) {}
  }, []);

  const handleLoadUF = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado`);

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterCitiesByUf = useCallback(
    async (id) => {
      if (!id) {
        return;
      }

      try {
        const { data } = await api.post(`/municipio/filter`, {
          estado: id,
          nome: cidadeFilter,
        });

        const arr = data.map((municipio) => {
          const obj = {};

          Object.assign(obj, {
            value: String(municipio.id),
            label: municipio.nome,
          });

          return obj;
        });

        return arr;
      } catch (error) {}
    },
    [cidadeFilter]
  );

  //Função que cria registro
  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    const taxaFinanceiraFormmated = taxaFinanceira.map((taxa) => {
      return {
        taxa_financeira_ate: taxa.taxa_financeira_ate,
        taxa_financeira_de: taxa.taxa_financeira_de,
        taxa_financeira_percentual: taxa.taxa_financeira_percentual
          .replace(".", "")
          .replace(",", "."),
      };
    });

    const produtoFormatted = produtos.map((prod) => {
      if (prod.capacidade_tanque === "") {
        prod.capacidade_tanque = "0";
      }
      if (prod.volume_compras_mensal === "") {
        prod.volume_compras_mensal = "0";
      }

      return {
        produto_id: prod.produto_id,
        capacidade_tanque: prod.capacidade_tanque
          .replaceAll(".", "")
          .replaceAll(",", "."),
        volume_compras_mensal: prod.volume_compras_mensal
          .replaceAll(".", "")
          .replaceAll(",", "."),
      };
    });

    const basesDistribuicaoId = basesDistribuicao.map((base) => {
      return base.value;
    });

    const customerData = {
      dados: {
        ativo: status?.value,
        cadastro_tipo_id: tipoCadastro,
        cpf_cnpj: cnpj.replace(/[^\d]+/g, ""),
        razao_social: razaoSocial,
        fantasia: nomeFantasia,
        telefone: telefone.replace(/[^\d]+/g, ""),
        celular: celular.replace(/[^\d]+/g, ""),
        cel_contato_2: celularDois.replace(/[^\d]+/g, ""),
        email,
        contato,
        contato_email: email,
        contato_2: contatoDois,
        contato_email_2: emailDois,
        grupo_economico: grupoEconomico,
        perfil: perfil?.value,
        matriz: matriz?.value,
        empresa_matriz_id: empresaMatriz?.value,
        inscricao_estadual: inscricaoEstadual,
        farmer: farmer?.value,
        hunter: hunter?.value,
        registro_amp: registroANP,
        tabela_mesa_operacao_id: mesaOperacao?.value || "",
        tabela_classificacao_distribuidor_id: 1, // Campo chumbado
        tabela_classificacao_clientes_id: classificacao?.value,
        consultor_id: 1,
        distribuidor_preferencia_id: preferenciaDist?.value || "0",
      },
      endereco: {
        cep,
        logradouro,
        numero: numero,
        bairro,
        complemento,
        estado_id: estado?.value,
        municipio_id: cidade?.value,
        pais_id: 1,
      },
      dados_entrega: {
        modalidade_transporte: modalidadeTransporte?.value,
        tanques_proprios: tanque?.value,
        modo_abastecimento: modoAbastecimento?.value,
        tipo_recebimento: tipoRecebimento,
        observacao: observacoes,
      },
      dados_cobranca: {
        limite_credito: limiteCredito.replaceAll(".", "").replaceAll(",", "."),
        saldo_credito: saldoCredito.replaceAll(".", "").replaceAll(",", "."),
        prazo_pagamento: prazoPagamento,
        taxa_administrativa: taxaAdm.replaceAll(".", "").replaceAll(",", "."),
      },
      taxa_financeira: taxaFinanceiraFormmated,

      consumo_capacidade: produtoFormatted,

      base_distribuicao:
      basesDistribuicao[0]?.value === "selecione" ? [] : basesDistribuicaoId,
    };

    if (certificado) {
      Object.assign(customerData, {
        certificado_digital: {
          nome_certificado: nomeCertificado,
          validade_certificado: "2020/10/10",
          senha,
          certificado_digital: certificado,
        },
      });
    }

    try {
      await api.post("/cadastro", customerData);

      //Reseta campos e configs
      // Dados Cadastrais
      setCnpj("");
      setRazaoSocial("");
      setNomeFantasia("");
      setTelefone("");
      setCelular("");
      setCelularDois("");
      setLoadingButton(false);
      setErrors("");
      setGrupoEconomico("");
      setPerfil("");
      setMatriz("");
      setEmpresaMatriz("");
      setInscricaoEstadual("");
      setRegistroANP("");
      setEmail("");
      setContato("");
      setEmailDois("");
      setContatoDois("");
      setMesaOperacao("");
      setClassificacao("");
      setFarmer("")
      setHunter("")
      //Endereço
      setCep("");
      setLogradouro("");
      setNumero("");
      setBairro("");
      setComplemento("");
      setEstado("");
      setCidade("");
      //Dados entrega
      setModalidadeTransporte("");
      setTanque("");
      setModoAbastecimento("");
      setTipoRecibimento("");
      setObservacoes("");
      //Dados Cobrança
      setLimiteCredito("");
      setSaldoCredito("");
      setPrazoPagamento("");
      setTaxaAdm("");
      setTaxaFinanceira([
        {
          id: "",
          taxa_financeira_de: "",
          taxa_financeira_ate: "",
          taxa_financeira_percentual: "",
        },
      ]);
      // Consumo e Capacidade
      setProdutos((state) => {
        state.forEach((elem) => {
          elem.capacidade_tanque = "";
          elem.volume_compras_mensal = "";
        });

        return state;
      });
      // Certificado
      setNomeCertificado("");
      setCertificado("");
      setSenha("");
      // Bases de distribuição
      setListaCidades("cidade");
      setBaseDistribuicao([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
      setPreferenciaDist("");

      setLoadingButton(false);
      toast.success(<FormattedMessage id="registroCriadoComSucesso" />);
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      return toast.error(<FormattedMessage id="erroCriarRegistro" />);
    }

    setErrors(null);
  }, [
    basesDistribuicao,
    bairro,
    celular,
    celularDois,
    cep,
    certificado,
    cidade,
    classificacao,
    cnpj,
    complemento,
    contato,
    email,
    contatoDois,
    emailDois,
    empresaMatriz,
    estado,
    grupoEconomico,
    inscricaoEstadual,
    limiteCredito,
    logradouro,
    matriz,
    mesaOperacao,
    modalidadeTransporte,
    modoAbastecimento,
    nomeCertificado,
    nomeFantasia,
    numero,
    observacoes,
    perfil,
    prazoPagamento,
    preferenciaDist,
    produtos,
    razaoSocial,
    registroANP,
    saldoCredito,
    senha,
    status,
    tanque,
    taxaAdm,
    taxaFinanceira,
    telefone,
    tipoCadastro,
    tipoRecebimento,
    farmer,
    hunter
  ]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleAddTaxInput = useCallback(() => {
    setTaxaFinanceira([
      ...taxaFinanceira,

      {
        id: "",
        taxa_financeira_de: "",
        taxa_financeira_ate: "",
        taxa_financeira_percentual: "",
      },
    ]);
  }, [taxaFinanceira]);

  const handleChangeInputTaxaFinanceiraDe = useCallback(
    (e, index) => {
      taxaFinanceira[index].taxa_financeira_de = e.target.value.replace(
        /[^\d]+/g,
        ""
      );
      setTaxaFinanceira([...taxaFinanceira]);
    },
    [taxaFinanceira]
  );

  const handleChangeInputTaxaFinanceiraAte = useCallback(
    (e, index) => {
      taxaFinanceira[index].taxa_financeira_ate = e.target.value.replace(
        /[^\d]+/g,
        ""
      );
      setTaxaFinanceira([...taxaFinanceira]);
    },
    [taxaFinanceira]
  );

  const handleChangeInputTaxaFinanceiraPercent = useCallback(
    (e, index) => {
      taxaFinanceira[index].taxa_financeira_percentual = e.target.value;
      setTaxaFinanceira([...taxaFinanceira]);
    },
    [taxaFinanceira]
  );

  const handleRemoveInputTaxaFinanceira = useCallback(
    (position) => {
      setTaxaFinanceira([
        ...taxaFinanceira.filter((_, index) => {
          return index !== position;
        }),
      ]);
    },
    [taxaFinanceira]
  );

  const handleChangeInputCapacidadeTanque = useCallback(
    (e, index) => {
      produtos[index].capacidade_tanque = e.target.value;
      setProdutos([...produtos]);
    },
    [produtos]
  );

  const handleChangeInputVolumeCompraMensal = useCallback(
    (e, index) => {
      produtos[index].volume_compras_mensal = e.target.value;
      setProdutos([...produtos]);
    },
    [produtos]
  );


  const loadBases = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.post(
        `/tabela-base-distribuicao-lista`,
        {
          nome: inputValue,
          ativo: 1
        }
      );

      const basesFormatted = response.map((bases) => {
        const objeto = {
          value: bases.id,
          label: bases.nome,
        };

        return objeto;
      });

      return basesFormatted;
    } catch (error) {}
  }, []);

  const handleChangeBase = useCallback((item) => {
    if (!item || item?.length === 0) {
      setBaseDistribuicao([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    } else {
      if (item) {
        const basesDistribuicao = item.filter(
          (base) => base.value !== "selecione"
        );

        setBaseDistribuicao(basesDistribuicao);
      }
    }
  }, []);

  const handleFilterBasesByPreferenciaDist = useCallback(async (id) => {
    try {
      if (!id) {
        setBaseDistribuicao([
          { value: "selecione", label: <FormattedMessage id="selecione" /> },
        ]);

      } else {
        const { data: response } = await api.post(`/cadastro/filter`, {
          id: id,
        });

        const arr = response.data[0].base_distribuicao.map((municipio) => {
          const obj = {};

          Object.assign(obj, {
            value: String(municipio.id),
            label: municipio.nome,
          });

          return obj;
        });

        setBaseDistribuicao(arr);
      }

    } catch (error) {}
  }, []);

  const handleUploadFile = useCallback(async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const convertFile = await toBase64(file);

    setCertificado(convertFile);
    setNomeCertificado(file.name);
  }, []);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarCliente" />
          </h4>
          {/* {errors && <ButtonIcon Icon={FiAlertCircle} geterror={errors} />} */}
        </header>

        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="dadosCadastrais" />
              {(errors?.cpf_cnpj ||
                errors?.razao_social ||
                errors?.fantasia ||
                errors?.inscricao_estadual ||
                errors?.registro_amp ||
                errors?.grupo_economico ||
                errors?.perfil ||
                errors?.matriz ||
                errors?.tabela_classificacao_clientes_id ||
                errors?.contato ||
                errors?.email ||
                errors?.contato_email ||
                errors?.contato_2 ||
                errors?.contato_email_2 ||
                errors?.telefone ||
                errors?.celular ||
                errors?.cel_contato_2 ||
                errors?.tabela_mesa_operacao_id) && (
                <FiAlertCircle
                  size={15}
                  style={{ marginLeft: "5px", color: "#ff3232" }}
                />
              )}
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="dadosEndereco" />
              {(errors?.cep ||
                errors?.logradouro ||
                errors?.numero ||
                errors?.bairro ||
                errors?.estado_id ||
                errors?.municipio_id) && (
                <FiAlertCircle
                  size={15}
                  style={{ marginLeft: "5px", color: "#ff3232" }}
                />
              )}
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="dadosEntrega" />
              {(errors?.modalidade_transporte ||
                errors?.tanques_proprios ||
                errors?.modo_abastecimento ||
                errors?.tipo_recebimento) && (
                <FiAlertCircle
                  size={15}
                  style={{ marginLeft: "5px", color: "#ff3232" }}
                />
              )}
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="dadosCobranca" />
              {errors?.limite_credito ||
              errors?.saldo_credito ||
              errors?.prazo_pagamento ||
              errors?.taxa_administrativa ||
              errors?.taxa_financeira ||
              errors?.taxa_financeira_de ||
              errors?.taxa_financeira_ate ||
              Object?.keys(errors || [])?.filter((elem) => {
                let arr = elem.split(".");

                if (arr.length > 1) {
                  return (
                    arr[1] === "taxa_financeira_de" ||
                    arr[1] === "taxa_financeira_ate" ||
                    arr[1] === "taxa_financeira_percentual"
                  );
                } else {
                  return false;
                }
              }).length > 1 ? (
                <FiAlertCircle
                  siz
                  e={15}
                  style={{ marginLeft: "5px", color: "#ff3232" }}
                />
              ) : (
                <></>
              )}
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="consumoCapacidade" />
              {(Object.getOwnPropertyNames(errors | {})?.filter((item) => {
                return item.indexOf("volume_compras_mensal") !== -1;
              }).length > 0 ||
                Object.getOwnPropertyNames(errors || {})?.filter((item) => {
                  return item.indexOf("capacidade_tanque") !== -1;
                }).length > 0) && (
                <FiAlertCircle
                  size={15}
                  style={{ marginLeft: "5px", color: "#ff3232" }}
                />
              )}
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="certificadoDigital" />
              {errors?.senha && (
                <FiAlertCircle
                  size={15}
                  style={{ marginLeft: "5px", color: "#ff3232" }}
                />
              )}
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="baseDistribuicao" />
              {errors?.estado_id ||
                (errors?.["0"] && (
                  <FiAlertCircle
                    size={15}
                    style={{ marginLeft: "5px", color: "#ff3232" }}
                  />
                ))}
            </TabStyled>
          </TabList>

          {/* Dados Cadastrais */}
          <TabPanel>
            <form>
              <InputLabel
                name="cnpj"
                type="text"
                label={"CNPJ"}
                required={true}
                mask={MaskInput.CNPJ}
                value={cnpj}
                onChange={(e) => {
                  setCnpj(e.target.value);
                }}
                geterror={errors?.cpf_cnpj}
              />

              <InputLabel
                name="razaoSocial"
                type="text"
                label={razaoSocialPlaceholder}
            required={true}
                maxLength={100}
                value={razaoSocial}
                onChange={(e) => setRazaoSocial(e.target.value)}
                geterror={errors?.razao_social}
              />

              <InputLabel
                name="nomeFantasia"
                type="text"
                label={nomeFantasiaPlaceholder}
            required={true}
                maxLength={80}
                value={nomeFantasia}
                onChange={(e) => setNomeFantasia(e.target.value)}
                geterror={errors?.fantasia}
              />

              <InputLabel
                name="inscricaoEstadual"
                type="text"
                label={inscricaoEstadualPlaceholder}
                maxLength={15}
                value={inscricaoEstadual}
                onChange={(e) => setInscricaoEstadual(e.target.value)}
                geterror={errors?.inscricao_estadual}
              />

              <InputLabel
                name="registroANP"
                type="text"
                label={registroANPPlaceholder}
                maxLength={15}
                value={registroANP}
                onChange={(e) => setRegistroANP(e.target.value)}
                geterror={errors?.registro_amp}
              />

              <InputLabel
                name="grupoEconomico"
                type="text"
                label={grupoEconomicoPlaceholder}
            required={true}
                maxLength={50}
                value={grupoEconomico}
                onChange={(e) => setGrupoEconomico(e.target.value)}
                geterror={errors?.grupo_economico}
              />

              <ListSelect
                labelPlaceholder={perfilPlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                options={profileOptions}
                onChange={(e) => setPerfil(e)}
                value={perfil}
                geterror={errors?.perfil}
              />

              <ListSelect
                placeholder={<FormattedMessage id="selecionar" />}
                labelPlaceholder={matrizPlaceholder}
            required={true}
                options={YesNoOptions}
                onChange={(e) => {
                  setMatriz(e);
                  setEmpresaMatriz("");
                }}
                value={matriz}
                // isClearable
              />

              <AsyncListSelect
                value={empresaMatriz}
                onChange={(e) => {
                  setEmpresaMatriz(e);
                }}
                myOptions={"clientes"}
                cacheOptions
                defaultOptions
                placeholder={<FormattedMessage id="selecionar" />}
                labelPlaceholder={empresaMatrizPlaceholder}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                noOptionsMessage={() => (
                  <FormattedMessage id="nenhumRegistroEncontrado" />
                )}
                isDisabled={matriz.value === 1}
                geterror={errors?.matriz}
              />

              <AsyncListSelect
                placeholder={<FormattedMessage id="selecionar" />}
                labelPlaceholder={classificacaoPlaceholder}
            required={true}
                onChange={(e) => setClassificacao(e)}
                value={classificacao}
                myOptions={"classificaoClientes"}
                cacheOptions
                defaultOptions
                geterror={errors?.tabela_classificacao_clientes_id}
              />

              <InputLabel
                name="contato"
                type="text"
                label={contatoPlaceholder}
            required={true}
                maxLength={80}
                value={contato}
                onChange={(e) => setContato(e.target.value)}
                geterror={errors?.contato}
              />

              <InputLabel
                name="email"
                type="text"
                label={"E-mail"}
                required={true}
                maxLength={100}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                geterror={errors?.email}
              />

              <InputLabel
                name="telefone"
                type="text"
                label={telefonePlaceholder}
            required={true}
                mask={MaskInput.phone}
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                geterror={errors?.telefone}
              />

              <InputLabel
                name="celular"
                type="text"
                label={celularPlaceholder}
                mask={MaskInput.cellphone}
                value={celular}
                onChange={(e) => setCelular(e.target.value)}
                geterror={errors?.celular}
              />

              <InputLabel
                name="contato"
                type="text"
                label={"Segundo Contato"}
                maxLength={80}
                value={contatoDois}
                onChange={(e) => setContatoDois(e.target.value)}
                geterror={errors?.contato_2}
              />

              <InputLabel
                name="email"
                type="text"
                label={"Segundo E-mail"}
                maxLength={100}
                value={emailDois}
                onChange={(e) => setEmailDois(e.target.value)}
                geterror={errors?.contato_email_2}
              />

              <InputLabel
                name="celular"
                type="text"
                label={"Segundo Celular"}
                mask={MaskInput.cellphone}
                value={celularDois}
                onChange={(e) => setCelularDois(e.target.value)}
                geterror={errors?.celular}
              />

              <AsyncListSelect
                labelPlaceholder={mesaOperacaoPlaceholder}
                required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                cacheOptions
                defaultOptions
                onChange={(e) => setMesaOperacao(e)}
                value={mesaOperacao}
                myOptions={"classificaoMesaOperacao"}
                geterror={errors?.tabela_mesa_operacao_id}
              />

              <AsyncListSelect
                labelPlaceholder={"Farmer"}
                placeholder={<FormattedMessage id="selecionar" />}
                cacheOptions
                defaultOptions
                onChange={(e) => setFarmer(e)}
                value={farmer}
                myOptions={"farmer"}
                geterror={errors?.farmer}
              />

              <AsyncListSelect
                labelPlaceholder={"Hunter"}
                placeholder={<FormattedMessage id="selecionar" />}
                cacheOptions
                defaultOptions
                onChange={(e) => setHunter(e)}
                value={hunter}
                myOptions={"hunter"}
                geterror={errors?.hunter}
              />

              <ListSelect
                labelPlaceholder={"Status"}
                required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                value={status}
                defaultInputValue="Ativo"
                onChange={(e) => setStatus(e)}
                cacheOptions
                defaultOptions
                options={statusOptions}
              />
            </form>
          </TabPanel>
          {/* Dados de Endereço */}
          <TabPanel>
            <form>
              <InputLabel
                name="cep"
                type="text"
                label={cepPlaceholder}
            required={true}
                mask={MaskInput.CEP}
                onBlur={() => handleSearchCEP(cep)}
                value={cep}
                onChange={(e) => setCep(e.target.value.replace(/[^\d]+/g, ""))}
                geterror={errors?.cep}
              />

              <InputLabel
                name="logradouro"
                type="text"
                label={logradouroPlaceholder}
            required={true}
                maxLength={100}
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
                geterror={errors?.logradouro}
              />

              <InputLabel
                name="numero"
                // type="text"
                label={numeroPlaceholder}
            required={true}
                // mask={"int6"}
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
                geterror={errors?.numero}
              />

              <InputLabel
                name="complemento"
                type="text"
                label={complementoPlaceholder}
                maxLength={50}
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)}
              />

              <InputLabel
                name="bairro"
                type="text"
                label={bairroPlaceholder}
            required={true}
                maxLength={30}
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                geterror={errors?.bairro}
              />

              <AsyncListSelect
                value={estado}
                onChange={(e) => {
                  setCidade("");
                  setEstado(e);
                  setListaCidades(e?.label);
                }}
                loadOptions={handleLoadUF}
                cacheOptions
                defaultOptions
                labelPlaceholder={estadoPlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                isSearchable={false}
                geterror={errors?.estado_id}
              />

              <AsyncListSelect
                key={listaCidades}
                value={cidade}
                cacheOptions
                defaultOptions
                loadOptions={() => {
                  return handleFilterCitiesByUf(listaCidades);
                }}
                onChange={(e) => {
                  setCidade(e);
                }}
                onInputChange={(e) => {
                  setCidadeFilter(e);
                }}
                labelPlaceholder={cidadePlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                noOptionsMessage={() => (
                  <FormattedMessage id="selecioneEstadoCarregar" />
                )}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                // isSearchable={false}
                geterror={errors?.municipio_id}
              />
            </form>
          </TabPanel>
          {/* Dados de Entrega */}

          <TabPanel>
            <form>
              <ListSelect
                labelPlaceholder={modalidadeTransportePlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                options={modalidadeTransporteOptions}
                onChange={(e) => setModalidadeTransporte(e)}
                value={modalidadeTransporte}
                geterror={errors?.modalidade_transporte}
              />

              <ListSelect
                labelPlaceholder={tanquesPropriosTransportePlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                options={YesNoOptions}
                onChange={(e) => setTanque(e)}
                value={tanque}
                geterror={errors?.tanques_proprios}
              />

              <ListSelect
                labelPlaceholder={modoAbastecimentoPlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                options={abastecimentoOptions}
                onChange={(e) => setModoAbastecimento(e)}
                value={modoAbastecimento}
                geterror={errors?.modo_abastecimento}
              />

              <section>
                <strong style={{ marginLeft: "12px" }}>
                  {tipoRecebimentoPlaceholder}
                </strong>
                <CheckboxDefault
                  type="radio"
                  name="recebimento"
                  labelName="TRR"
                  onChange={() => setTipoRecibimento("TRR")}
                />
                <CheckboxDefault
                  type="radio"
                  name="recebimento"
                  labelName={distribuidorPlaceholder}
                  onChange={() => setTipoRecibimento("Distribuição")}
                />
              </section>

              <MessageDefault geterror={errors?.tipo_recebimento} />

              <TextAreaLabel
                name="obs"
                placeholder={observacoesPlaceholder + " -  Máx. 200 caracteres"}
                maxLength={200}
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
              />
            </form>
          </TabPanel>
          {/* Dados de cobrança */}
          <TabPanel>
            <form onSubmit={(e) => e.preventDefault()}>
              <InputMaskDefault
                type="text"
                name="limiteCredito"
                label={limiteCreditoDisponivelPlaceholder}
                required={true}
                mask={"currency9"}
                value={limiteCredito}
                onChange={(e) => setLimiteCredito(e.target.value)}
                geterror={errors?.limite_credito}
              />

              <InputMaskDefault
                type="text"
                name="saldoCredito"
                label={saldoCreditoDisponivelPlaceholder}
            required={true}
                mask={"currency10"}
                onPaste={(e) => e.preventDefault(e)}
                value={saldoCredito}
                onChange={(e) => setSaldoCredito(e.target.value)}
                geterror={errors?.saldo_credito}
              />

              <InputMaskDefault
                name="prazoPagamento"
                label={prazoPagamentoPlaceholder}
            required={true}
                mask={"int3"}
                value={prazoPagamento}
                onChange={(e) =>
                  setPrazoPagamento(e.target.value.replace(/[^\d]+/g, ""))
                }
                geterror={errors?.prazo_pagamento}
              />

              <InputMaskDefault
                name="taxaAdm"
                type="text"
                label={taxaAdministrativaPlaceholder}
            required={true}
                mask={"currency7"}
                value={taxaAdm}
                onChange={(e) => setTaxaAdm(e.target.value)}
                geterror={errors?.taxa_administrativa}
              />
              <HeaderTax>
                <strong>{taxaFinanceiraPlaceholder} *</strong>
                <ButtonIcon
                  Icon={FiPlus}
                  backgroundColor={"#030894"}
                  placeholder={<FormattedMessage id="incluir" />}
                  style={{ height: "25px", width: "25px" }}
                  onClick={handleAddTaxInput}
                  geterror={errors?.taxa_financeira}
                />
              </HeaderTax>
              <FormTax>
                <header>
                  <strong>
                    <TitleTax>{dePlaceholder}</TitleTax>
                  </strong>
                  <strong>
                    <TitleTax>{atePlaceholder}</TitleTax>
                  </strong>
                  <strong>
                    <TitleTax>%</TitleTax>
                  </strong>
                </header>

                <ul>
                  {taxaFinanceira.map((taxa, index) => {
                    return (
                      <div key={index}>
                        <li>
                          <InputMaskDefault
                            mask={"int2"}
                            value={taxa.taxa_financeira_de}
                            onChange={(e) => {
                              return handleChangeInputTaxaFinanceiraDe(
                                e,
                                index
                              );
                            }}
                            geterrorcolorbackground={
                              errors?.[`${index}.taxa_financeira_de`]
                            }
                          />
                        </li>
                        <li>
                          <InputMaskDefault
                            mask={"int2"}
                            value={taxa.taxa_financeira_ate}
                            onChange={(e) =>
                              handleChangeInputTaxaFinanceiraAte(e, index)
                            }
                            geterrorcolorbackground={
                              errors?.[`${index}.taxa_financeira_ate`]
                            }
                          />
                        </li>
                        <li>
                          <InputMaskDefault
                            onPaste={(e) => e.preventDefault(e)}
                            mask={"currency7"}
                            value={taxa.taxa_financeira_percentual}
                            onChange={(e) =>
                              handleChangeInputTaxaFinanceiraPercent(e, index)
                            }
                            geterrorcolorbackground={
                              errors?.[`${index}.taxa_financeira_percentual`]
                            }
                          />
                        </li>
                        <li>
                          <ButtonIcon
                            Icon={FiTrash2}
                            backgroundColor={"#e05262"}
                            color={"#fff"}
                            placeholder={<FormattedMessage id="excluir" />}
                            style={{ height: "27px", width: "27px" }}
                            onClick={() =>
                              handleRemoveInputTaxaFinanceira(index)
                            }
                          />
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </FormTax>
            </form>
          </TabPanel>
          {/* Capacidade e Consumo */}
          <TabPanel>
            <FormConsumer>
              <header>
                <strong>
                  <TitleTax>
                    <FormattedMessage id="produto" />
                  </TitleTax>
                </strong>
                <strong>
                  <TitleTax>
                    <FormattedMessage id="capacidadeTanque" />
                  </TitleTax>
                </strong>
                <strong>
                  <TitleTax>
                    <FormattedMessage id="volumeComprasMensal" />
                  </TitleTax>
                </strong>
              </header>

              <ul>
                {produtos.map((produto, index) => (
                  <div key={index}>
                    <li>
                      <InputLabel
                        value={produto.descricao}
                        onChange={() => {}}
                        readOnly
                      />
                    </li>
                    <li>
                      <InputMaskDefault
                        value={produto?.capacidade_tanque}
                        onChange={(e) => {
                          return handleChangeInputCapacidadeTanque(e, index);
                        }}
                        mask="currency6"
                        geterrorcolorbackground={
                          errors?.[`${index}.capacidade_tanque`]
                        }
                      />
                    </li>
                    <li>
                      <InputMaskDefault
                        value={produto?.volume_compras_mensal}
                        onChange={(e) =>
                          handleChangeInputVolumeCompraMensal(e, index)
                        }
                        mask="currency6"
                        geterrorcolorbackground={
                          errors?.[`${index}.volume_compras_mensal`]
                        }
                      />
                    </li>
                  </div>
                ))}
              </ul>
            </FormConsumer>
          </TabPanel>
          {/* Certificado digital */}
          <TabPanel>
            <form>
              <InputLabel
                name="nomeCertificado"
                type="text"
                label={<FormattedMessage id="nomeCertificado" />}
                maxLength={100}
                readOnly
              />
              <InputLabel
                name="validadeCertificado"
                type="text"
                label={<FormattedMessage id="validadeCertificado" />}
                mask={MaskInput.date}
                defaultValue=""
                readOnly
              />
              <InputLabel
                name="certificadoDigital"
                type="file"
                label={<FormattedMessage id="certificadoDigitalA1" />}
                // value={nome}
                onChange={(e) => handleUploadFile(e)}
                geterror={errors?.nome_certificado}
              />

              <InputLabel
                name="password"
                type="password"
                label={<FormattedMessage id="senha" />}
                value={senha}
                maxLength={20}
                onChange={(e) => setSenha(e.target.value)}
                geterror={errors?.senha}
              />
            </form>
          </TabPanel>
          {/* Bases de Distribuição */}
          <TabPanel>
            <form>
              <AsyncListSelect
                value={preferenciaDist}
                cacheOptions
                defaultOptions
                myOptions={"distribuidores"}
                onChange={(e) => {
                  setPreferenciaDist(e);
                  handleFilterBasesByPreferenciaDist(e?.value);
                }}
                labelPlaceholder={
                  <FormattedMessage id="distribuidorPreferencia" />
                }
                placeholder={<FormattedMessage id="selecionar" />}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                isClearable={true}
              />

              <AsyncListSelect
                labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                placeholder={<FormattedMessage id="selecionar" />}
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={loadBases}
                value={basesDistribuicao}
                onChange={handleChangeBase}
              />
              <MessageDefault geterror={errors?.[`0`]} />
            </form>
          </TabPanel>
        </Tabs>
        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateCustomers);
