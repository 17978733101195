import styled, { keyframes } from "styled-components";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;

  .header-red {
    width: 100%;
    height: 34px;
    background-color: #030894!important;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 10px;
    width: 95%;

    h4 {
      font-size: 13px;
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 27%;
    }
  }

  .header-selected {
    width: 100%;
    height: 34px;
    background-color: #030894!important;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 13px;
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 27%;
    }
  }
`;

export const CreateForm = styled.div`
  width: 100%;
  margin-top: 0px;
  /* background-color: #f8f8f8; */

  border-radius: 10px;

  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #030894;
    margin-bottom: 20px;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }

  form {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const CheckForm = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;

  div {
    width: max-content;
    height: 18px;
    display: flex;

    input {
      margin-top: 2px;
      margin-right: 5px;
    }
  }
`;

export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  div {
    width: 120px;
  }
`;

export const FormTax = styled.div`
  width: auto;
  padding: 6px 5px;
  margin-bottom: 20px;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  display: block;
  margin: 0 15px;
  margin-bottom: 10px;

  main {
    display: flex;
    flex-direction: column;
    > ul {
      div {
        display: flex;
        margin: 0;
        margin-left: -5px;

        li {
          height: 100%;
          width: 35%;
          padding: 0 5px;
          text-align: center;
          margin-top: 5px;
        }
      }
    }
  }
  header {
    width: auto;
    height: 90px;
    position: relative;
    background-color: #e05262;
    margin-bottom: 5px;
        /* border-radius: 10px 10px 0 0; */
    display: flex;
    justify-content: flex-start;

    div {
      width: auto;

      margin: 0 10px;
      h1 {
        color: #333;
        margin-right: auto;
        padding-left: 20px;
        font-size: 17px;
        margin-top: 7px;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        input:first-child {
          margin-right: 6px;
        }

        label {
          margin: 0;
          padding: 0;
          cursor: pointer;
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }
  }
`;

export const HeaderInfo = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  position: relative;

  div {
    display: block !important;

    input {
      text-align: center;
    }

    p {
      white-space: nowrap;
      padding: 0;
    }
  }

  div + div {
    height: 55px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 10px !important;
    margin-left: 2% !important;
    white-space: nowrap !important;
    p {
      padding: 20px !important;
      margin-bottom: 10px;
    }

    h2 {
      color: #333;
      margin: auto;
    }
  }

  div + div + div {
    width: 100%;

    display: flex !important;
    align-items: flex-end !important;
    justify-content: center !important;

    padding-right: 5px;
  }
`;

export const TitleTax = styled.p`
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
`;

export const PrazoGrid = styled.div`
  display: grid;
  grid-template-columns: 14.5% 14% 14% 14% 14% 14% 14.5%;
  gap: 5px;
  margin: 10px 0;

  li {
    align-self: center;
    justify-self: center;
  }

  @keyframes fa-blink {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: #97df99;
    }
    100% {
      background-color: transparent;
    }
  }
  .blink {
    font-weight: bold;
    -webkit-animation: fa-blink 2.15s linear infinite;
    -moz-animation: fa-blink 2.15s linear infinite;
    -ms-animation: fa-blink 2.15s linear infinite;
    -o-animation: fa-blink 2.15s linear infinite;
    animation: fa-blink 2.15s linear infinite;
  }
`;

export const GridColumn = styled.div`
  display: grid;
  grid-template-columns: 47% 47%;
  place-content: center;
  gap: 3%;

  h4 {
    p {
      float: right;
      margin-left: 10px;
    }
  }
`;

export const GridInput = styled.div`
  display: grid;
  grid-template-columns: 27% 27% 15% 16% 10%;
  gap: 1%;
  place-content: center;
`;

export const GridInput3 = styled.div`
  display: grid;
  grid-template-columns: 30% 33% 33%;
  gap: 1%;
  place-content: center;
`;

export const FormProducts = styled.div`
  h5 {
    text-align: right;
    margin-bottom: 10px;
    font-size: 13px;
  }
`;
