import React from "react";

import { CustomersListSelect } from "./store/customers";
import { CustomersListSelectv1 } from "./store/customersv1";
import { CustomersListSelectv2 } from "./store/customersv2";
import { DistributorsListSelect } from "./store/distributors";
import { DistributionBasesListSelect } from "./store/distributionsBases";
import { ProductsListSelect } from "./store/products";
import { CustomerClassificationListSelect } from "./store/customerClassification";
import { OperationClassificationListSelect } from "./store/operationStationClassification";
import { DistributionClassificationListSelect } from "./store/distributionClassification";
import { ConsultantsClassificationListSelect } from "./store/consultantsClassification";
import { UserOperationSelect } from "./store/userOperation";
import { OperatorSelect } from "./store/operator";
import { FarmerSelect } from "./store/farmer";
import { HunterSelect } from "./store/hunter";
import { MerchantSelect } from "./store/merchant";

const AppProvider = ({ children }) => {
  return (
    <CustomersListSelectv1>
     <CustomersListSelectv2>
      <MerchantSelect>
         <HunterSelect>
          <FarmerSelect>
            <OperatorSelect>
              <UserOperationSelect>
                <DistributorsListSelect>
                  <CustomersListSelect>
                    <DistributionBasesListSelect>
                      <ProductsListSelect>
                        <CustomerClassificationListSelect>
                          <OperationClassificationListSelect>
                            <DistributionClassificationListSelect>
                              <ConsultantsClassificationListSelect>
                                {children}
                              </ConsultantsClassificationListSelect>
                            </DistributionClassificationListSelect>
                          </OperationClassificationListSelect>
                        </CustomerClassificationListSelect>
                      </ProductsListSelect>
                    </DistributionBasesListSelect>
                  </CustomersListSelect>
                </DistributorsListSelect>
              </UserOperationSelect>
            </OperatorSelect>
          </FarmerSelect>
        </HunterSelect>
      </MerchantSelect>
      </CustomersListSelectv2>
    </CustomersListSelectv1>
  );
};

export default AppProvider;
