import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FormattedMessage } from "react-intl";

import AuthContext from "./../../../context/auth";
import { Container } from "./styles";
import api from "../../../services/api";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

import moment from "moment";
import iconVoltar from "./../../../assets/images/icone-voltar.svg";
import { IoMdStar } from "react-icons/io";

import InputLabel from "../../../components/InputLabel";
import ContentSpinner from "../../../components/Spinner/Content";
import MaskInput from "./../../../config/input-mask";

import GraphVolumeVendido from "../../DashboardGraphs/DistributorVolumeVendido";
import GraphVolumeVendidoMedia from "../../DashboardGraphs/DistributorVolumeVendidoMedia";

import iconFiltro from "./../../../assets/images/icone-filtro.png";
import iconLixeira from "./../../../assets/images/icone-lixeira.png";
import ButtonImage from "../../../components/ButtonImage";

const Dashboard = () => {
  const { user } = useContext(AuthContext);

  const [loadingList, setLoadingList] = useState(true);
  const [data, setData] = useState({});

  const [favorite, setFavorite] = useState("");

  const [dataDeFilter, setDataDeFilter] = useState("");
  const [dataAteFilter, setDataAteFilter] = useState("");
  const [bases, setBases] = useState("");
  const [cnpjFilter, setCnpjFilter] = useState("");
  const [grupoEconomicoFilter, setGrupoEconomicoFilter] = useState("");
  const [estadoBasesDist, setEstadoBasesDist] = useState("");
  const [cidadeBasesDist, setCidadeBasesDist] = useState("");
  const [listaCidadesBasesDist, setListaCidadesBasesDist] = useState(
    "cidadeBasesDist"
  );

  useEffect(() => {
    (async () => {
      try {
        var response = await api.get("/favorito-home");
        setFavorite(response.data.entity_id);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, []);

  const handleLoadBases = useCallback(async () => {
    try {
      const arr = user.base_distribuicao.map((item) => {
        const obj = {};

        Object.assign(obj, {
          value: item.id,
          label: item.nome,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, [user.base_distribuicao]);

  const handleLoadUF = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado`);

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterBasesByUF = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data: response } = await api.post(
        `/tabela-base-distribuicao/filter`,
        {
          estado_id: id,
        }
      );

      const arr = response.data.map((estado) => {
        const obj = {};

        Object.assign(obj, {
          value: String(estado.id),
          label: estado.nome,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterCitiesByUf = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data } = await api.post(`/municipio/filter`, {
        estado: id,
      });

      const arr = data.map((municipio) => {
        const obj = {};

        Object.assign(obj, {
          value: String(municipio.id),
          label: municipio.nome,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleFavorite = useCallback(async (id) => {
    const data = {
      entity_id: id,
    };

    try {
      await api.post("/favorito-home", data);
      var response = await api.get("/favorito-home");
      setFavorite(response.data.entity_id);
    } catch (error) {
      console.log(error, 'home fav')
    }
  }, []);

  const handleFilter = useCallback(() => {
    try {
      const data = {
        bases,
        cnpjFilter,
        grupoEconomicoFilter,
        estadoBasesDist,
        cidadeBasesDist,
        dataDeFilter,
        dataAteFilter,
      };

      setData(data);
    } catch (error) {
      // setErrors(getErrorsApiValidation(error));
    }
  }, [
    bases,
    cidadeBasesDist,
    cnpjFilter,
    dataAteFilter,
    dataDeFilter,
    estadoBasesDist,
    grupoEconomicoFilter,
  ]);

  const handleClean = useCallback(() => {
    try {
      setDataDeFilter("");
      setDataAteFilter("");
      setBases("");
      setCidadeBasesDist("");
      setCnpjFilter("");
      setEstadoBasesDist("");
      setGrupoEconomicoFilter("");

      const dataDeRandom = moment()
      .subtract(2, "months")
      .format("YYYY-MM-DD 00:00:00");
      const dataDeFormat = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');

      const data = {
        bases: "",
        cnpjFilter: "",
        grupoEconomicoFilter: "",
        estadoBasesDist: "",
        cidadeBasesDist: "",
        dataDeFilter: dataDeFormat,
        dataAteFilter: moment().format("YYYY-MM-DD 23:59:59"),
      };

      setData(data);
    } catch (error) {
      // setErrors(getErrorsApiValidation(error));
    }
  }, []);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>Dashboard</h2>
          </nav>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <div className="grid grid-template-columns-4">
              <InputLabel
                name="date"
                label={<FormattedMessage id="dataInicio" />}
                type="date"
                style={{ color: "hsl(0,0%,50%)" }}
                value={dataDeFilter}
                onChange={(e) => setDataDeFilter(e.target.value)}
              />

              <InputLabel
                name="date"
                label={<FormattedMessage id="dataFim" />}
                type="date"
                style={{ color: "hsl(0,0%,50%)" }}
                value={dataAteFilter}
                onChange={(e) => setDataAteFilter(e.target.value)}
              />
            </div>

            <div className="grid grid-template-columns-5">
              <InputLabel
                name="cnpj"
                type="text"
                label="CNPJ:"
                mask={MaskInput.CNPJ}
                onBlur={(e) => {
                  return setCnpjFilter(e.target.value)
                    ?.replaceAll(".", "")
                    ?.replaceAll("/", "")
                    ?.replaceAll("-", "")
                    ?.replaceAll("_", "");
                }}
              />

              <InputLabel
                name="grupoEconomico"
                type="text"
                label={<FormattedMessage id="grupoEconomico" />}
                maxLength={50}
                value={grupoEconomicoFilter}
                onChange={((e) => setGrupoEconomicoFilter(e.target.value))}
              />

              <AsyncListSelect
                value={bases}
                labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                placeholder={<FormattedMessage id="selecionar" />}
                onChange={(e) => {
                  setBases(e);
                }}
                loadOptions={handleLoadBases}
                cacheOptions
                defaultOptions
              />

              <AsyncListSelect
                value={estadoBasesDist}
                onChange={(e) => {
                  setCidadeBasesDist("");
                  setEstadoBasesDist(e);
                  setListaCidadesBasesDist(e?.label);
                  handleFilterBasesByUF(e.value);
                }}
                onInput={(e) => {}}
                loadOptions={handleLoadUF}
                cacheOptions
                defaultOptions
                labelPlaceholder={<FormattedMessage id="estado" />}
                placeholder={<FormattedMessage id="selecionar" />}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                isSearchable={false}
              />

              <AsyncListSelect
                key={listaCidadesBasesDist}
                value={cidadeBasesDist}
                cacheOptions
                defaultOptions
                loadOptions={() => {
                  return handleFilterCitiesByUf(listaCidadesBasesDist);
                }}
                onChange={(e) => {
                  setCidadeBasesDist(e);
                }}
                labelPlaceholder={<FormattedMessage id="cidade" />}
                placeholder={<FormattedMessage id="selecionar" />}
                noOptionsMessage={() => (
                  <FormattedMessage id="selecioneEstadoCarregar" />
                )}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                isSearchable={false}
              />

              <ButtonImage
                ImageSrc={iconFiltro}
                backgroundColor={"#030894"}
                color={"#ffffff"}
                onClick={handleFilter}
                placeholder={<FormattedMessage id="filtrar" />}
              />
              <ButtonImage
                ImageSrc={iconLixeira}
                backgroundColor={"#B30000"}
                color={"#ffffff"}
                onClick={handleClean}
                placeholder={<FormattedMessage id="limparTudo" />}
              />
            </div>

            <div className="grid grid-template-columns-2">
              <div>
                <IoMdStar
                    className="star"
                    onClick={() =>
                      handleFavorite(
                        "Volume Vendido e Não Vendido"
                      )
                    }
                    style={
                      favorite ===
                      "Volume Vendido e Não Vendido"
                        ? { color: "#FFD600" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                <GraphVolumeVendido
                  dataFilter={data}
                />
              </div>
              <div>

                <IoMdStar
                  className="star"
                  onClick={() =>
                    handleFavorite(
                      "Volume Vendido e Não Vendido Media"
                    )
                  }
                  style={
                    favorite ===
                    "Volume Vendido e Não Vendido Media"
                      ? { color: "#FFD600" }
                      : { color: "#bebebe" }
                  }
                  size={25}
                />
                <GraphVolumeVendidoMedia
                  dataFilter={data}
                />
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
