import React, { useEffect, useState, useContext } from "react";
import Chart from "react-apexcharts";
import { format } from "date-fns";
import { ptBR, enAU, es } from "date-fns/locale";
import moment from "moment";

import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";

import { FormattedMessage, injectIntl } from "react-intl";

import Language from "../../context/language";

const GraphNegociacao = ({ dataFilter, intl }) => {
  const [loadingList, setLoadingList] = useState(true);

  const { language } = useContext(Language);

  const [labels, setLabels] = useState([]);
  const [graphFaturado, setGraphFaturado] = useState([]);
  const [graphOutros, setGraphOutos] = useState([]);

  //Placeholders internacionalizados
  const faturadosPlaceholder = intl.formatMessage({
    id: "faturados",
  });


  let loadGraph = {
    series: [
      {
        name: "Reprovados",
        data: graphOutros,
      },
      {
        name: faturadosPlaceholder,
        data: graphFaturado,
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#c02c31", "#030894"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val))
        },
        textAnchor: 'middle',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          opacity: 0.9,

        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val))
          }
        }
      },
      labels: labels,
      tooltip: {
        y: {
          formatter: function (val) {
            return (parseFloat(val)).toLocaleString('pt-BR')
          }
        }
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const dataFaturados = [];
        const dataReprovados = [];
        const labels = [];

        const response = await api.get(
          `/dashboard/pedido-ralizado-faturado`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter
                ?.replaceAll(".", "")
                ?.replaceAll("/", "")
                ?.replaceAll("-", "")
                ?.replaceAll("_", ""),
              grupo_economico: dataFilter?.grupoEconomicoFilter,
              base_distribuicao_id: dataFilter?.bases?.value,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
            },
          }
        );

        let setLanguage = ptBR;
        if (language === "en") {
          setLanguage = enAU;
        } else if (language === "es") {
          setLanguage = es;
        } else if (language === "pt_BR") {
          setLanguage = ptBR;
        }

        Object.values(response.data).map((item, index) => {
          const obj = {};
          const date = item.data_group.split("-");

          Object.assign(obj, {
            dataReprovados: dataReprovados.push(item.reprovados),
            dataFaturados: dataFaturados.push(item.faturados),
            labels: labels.push(
              format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
                locale: setLanguage,
              })
            ),
          });

          return obj;
        });

        setGraphOutos(dataReprovados);
        setGraphFaturado(dataFaturados);
        setLabels(labels);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, language]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoNegociacoesRealizadosXPedidosFaturados" />
            </PanelHeader>
            <PanelBody className="p-0">
              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="bar"
                width="100%"
                height="300px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default injectIntl(GraphNegociacao);
