import styled, { keyframes } from "styled-components";
import { lighten, shade } from "polished";

const buttonThemes = {
  default: "#c02c31",
  green: "#22bd41",
  blue: "#3b89ad",
  back: "#FFD600",
  secundary: "#fff",
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);

  }

  to {
    transform: rotate(360deg);
  }
`;

export const Button = styled.button`
  height: ${(props) => (props.program ? `${props.program}px` : "45px !important")};

  width: 100% !important;
  /* min-height: 45px; */

  background-color: ${(props) =>
    buttonThemes[props.themeType || "default"]} !important;
  /* border-radius: 5px !important; */
  border: 2px solid #030894;
  color:  ${(props) => (props.themeType === "back" ? `#030894` : "#fff")};
  font-size: 14px;
  font-weight: bold;
  outline: none;
  margin: 5px auto;

  transition: all 0.4s;

 :hover {
    background-color: ${(props) =>
      shade(0.1, buttonThemes[props.themeType || "default"])} !important;
  }

  :active {
    outline: none;
    background-color: ${(props) =>
      lighten(0.3, buttonThemes[props.themeType || "default"])} !important;
    box-shadow: none;
  }

  :focus {
    outline: none;
  }

  /* :disabled {
    background-color: ${(props) =>
      shade(0.5, buttonThemes[props.themeType || "default"])} !important;
  } */

  :disabled {
    background-color: ${(props) =>
       buttonThemes[props.themeType || "default"]} !important;
  }

  svg, img {
    height: 35px;
    width: 35px;
    animation: ${rotate} 2s linear infinite;
    color: #27262a;
  }
`;
