import styled, { keyframes } from "styled-components";
import { shade } from "polished";
import { FiLoader } from "react-icons/fi";

const rotate = keyframes`
  from {
    transform: rotate(0deg);

  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button`
  height: ${(props) => (props.size ? `${props.size}px` : "30px")};
  width: ${(props) => (props.size ? `${props.size}px` : "30px")};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.radius ? '50%' : ''};

  /* border-radius: 50%; */
  /* border: 1px solid;
  border-color: ${(props) =>
    props.borderColor ? shade(0.3, props.borderColor) : "#333"}; */
  border:none;
  transition: all 0.3s;

  svg {
    height: ${(props) => (props.size ? "100%" : "65%")};
    width: ${(props) => (props.size ? "100%" : "65%")};
    color: ${(props) => (props.color ? props.color : "#fff")};
  }

  &:hover {
    /* background-color: ${(props) =>
      props.backgroundColor ? shade(0.3, props.backgroundColor) : "#030894"}; */

      /* background-color: ${(props) =>
      props.backgroundColor ? "#030894" : "#030894"}; */

    div {
      opacity: 1;
      visibility: visible;
    }
  }

  & div {
    opacity: 0;
    visibility: hidden;
    width: max-content !important;
    padding: 2px 10px;
  }
`;

export const ToolTip = styled.div`
  display: flex;
  background-color: #c02c31;
  /* border-radius: 5px; */
  position: absolute;
  bottom: calc(100%);
  transition: all 0.7s;
  z-index: 9999;
  color: #fff;
  font-weight: 700;

  cursor: default;

  &::before {
    content: "";
    border-style: solid;
    border-color: #c02c31 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;

    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
`;

export const ListError = styled.div`
  height: max-content;
  max-height: 600px;
  background-color: #B30000;
  border-radius: 5px;
  position: absolute;
  bottom: calc(100%);
  transition: all 0.7s;
  z-index: 1;
  color: #000;
  font-weight: 700;
  right: 50%;
  top: 0;
  cursor: default;

  /* &::before {
    content: "";
    border-style: solid;
    border-color: #e05262 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;

    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  } */
`;

export const LoadingIcon = styled(FiLoader)`
  height: 18px;
  width: 18px;
  animation: ${rotate} 2s linear infinite;
  color: #fff;
`;
