import React, { useEffect, useState, useContext } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR, enAU, es } from "date-fns/locale";

import moment from "moment";

import { FormattedMessage, injectIntl } from "react-intl";

import Language from "../../context/language";

const GraphReceita = ({ dataFilter, intl }) => {
  const [loadingList, setLoadingList] = useState(true);

  const [labels, setLabels] = useState([]);
  const [graphRealizados, setGraphRealizados] = useState([]);
  const [graphAprovados, setGraphAprovados] = useState([]);

  const { language } = useContext(Language);

  //Placeholders internacionalizados
  const volumeVendidoPlaceholder = intl.formatMessage({
    id: "volumeVendido",
  });
  const volumeNaoVendidoPlaceholder = intl.formatMessage({
    id: "volumeNaoVendido",
  });

  let loadGraph = {
    series: [
      {
        name: volumeNaoVendidoPlaceholder,
        data: graphAprovados,
      },
      {
        name: volumeVendidoPlaceholder,
        data: graphRealizados,
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: ["#c02c31", "#030894"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString('pt-BR')
        },
        textAnchor: 'middle',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          opacity: 0.9,

        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString('pt-BR')
          }
        }
      },
      labels: labels,

      tooltip: {
        y: {
          formatter: function(val, { series, seriesIndex, dataPointIndex, w }) {
            return (parseFloat(val)).toLocaleString('pt-BR') + " Litros " + (w.globals.seriesPercent[seriesIndex][dataPointIndex]).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) + "%"
          }
        }
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const dataRealizados = [];
        const dataAprovados = [];
        const labels = [];

        const response = await api.get(
          `/dashboard/vendido-naovendido`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter
                ?.replaceAll(".", "")
                ?.replaceAll("/", "")
                ?.replaceAll("-", "")
                ?.replaceAll("_", ""),
              grupo_economico: dataFilter?.grupoEconomicoFilter,
              base_distribuicao_id: dataFilter?.bases?.value,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
            },
          }
        );

        Object.values(response.data.reprovados).map((item, index) => {
          const obj = {};

          Object.assign(obj, {
            dataRealizados: dataRealizados.push(item.total_litros),
          });

          return obj;
        });

        let setLanguage = ptBR;

        if (language === "en") {
          setLanguage = enAU;
        } else if (language === "es") {
          setLanguage = es;
        } else if (language === "pt_BR") {
          setLanguage = ptBR;
        }

        Object.values(response.data.aprovados).map((item, index) => {
          const obj2 = {};
          const date = item.data_group.split("-");

          Object.assign(obj2, {
            dataAprovados: dataAprovados.push(item.total_litros),
            labels: labels.push(
              format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
                locale: setLanguage,
              })
            ),
          });

          return obj2;
        });

        setGraphRealizados(dataRealizados);
        setGraphAprovados(dataAprovados);
        setLabels(labels);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, language]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoGeralVolumeVendidoENaoVendidoDistTRR" />
            </PanelHeader>
            <PanelBody className="p-0">
              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="bar"
                width="100%"
                height="300px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default injectIntl(GraphReceita);
