import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import swal from '@sweetalert/with-react'

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { registerLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";

import ListSelect from "./../../../../components/ListSelect";
import InputLabel from "./../../../../components/InputLabel";
import InputMaskDefault from "./../../../../components/InputMaskDefault";
import { parseISO, format, addDays, compareAsc } from "date-fns";
import MessageDefault from "./../../../../components/MessageDefault";
import CheckboxDefault from "./../../../../components/CheckboxDefault";
import { BsExclamation  } from "react-icons/bs";

import api from "./../../../../services/api";
import {
  Container,
  CreateForm,
  CreateFooter,
  InfoOrder,
  FormTax,
  FormProducts,
  TitleTax,
  LoadingPrazos,
  HeaderOrder,
  ProgramOrderContainer,
  DatePickerComponent,
  FooterDatePicker,
  ContainerButton
} from "./styles";

import moment from "moment";
import Button from "./../../../../components/ButtonDefault";
import TextArea from "./../../../../components/TextAreaLabel";
import LoadingContent from "./../../../../components/Spinner/Content";
import { getErrorsApiValidation } from "./../../../../utils/getErrorsValidation";

import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

import AuthContext from "./../../../../context/auth";
import { FiPlus, FiTrash2, FiEye, FiXCircle } from "react-icons/fi";
import ButtonIcon from "./../../../../components/ButtonIcon";

export const CreateOrders = ({ intl }) => {
  const [combustivel, setCombustivel] = useState(true);
  const [produto, setProduto] = useState(false);
  const [filter, setFilter] = useState(false);

  const [showContent, setShowContent] = useState(true);
  const [cotacao] = useState("Cotação");
  const [saldo, setSaldo] = useState("");
  const [limiteCredito, setLimiteCredito] = useState("");
  const [tolerancia, setTolerancia] = useState("");

  const [operadorId, setOperadorId] = useState("");
  const [cliente, setCliente] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [usuarios, setUsuarios] = useState("");
  const [pedidoSistema, setPedidoSistema] = useState("");
  const [listaUsuarios, setListaUsuarios] = useState("");
  const [endereco, setEndereco] = useState("");
  const [listaEnderecos, setListaEnderecos] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");
  const [date, setDate] = useState("");
  const [frete, setFrete] = useState("");
  const [abastecimento, setAbastecimento] = useState("");
  const [periodo, setPeriodo] = useState("");
  const [horario, setHorario] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  const [serverDate, setServerDate] = useState();
  const [dataProgramacao, setDataProgramacao] = useState([{ date: undefined }]);
  const [todosPedidosProgramados, setTodosPedidosProgramados] = useState([]);
  const [excludeDatesDatePicker, setExcludeDatesDatePicker] = useState([]);
  const [holidays, setHolidays] = useState([]);

  const [arrayPrazo, setArrayPrazo] = useState([
    {
      prazo: "",
    },
  ]);

  const [hasPrazo, setHasPrazo] = useState(true);

  const [listaProdutos, setListaProdutos] = useState([]);
  const [listagemProdutos, setListagemProdutos] = useState([]);
  const [produtoSelected, setProdutoSelected] = useState([]);
  const [brandSelected, setBrandSelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [dataEntrega, setDataEntrega] = useState([]);
  const [litroCIF, setLitroCIF] = useState("0");
  const [litroFOB, setLitroFOB] = useState("0");
  const [previsaoEntrega, setPrevisaoEntrega] = useState("");
  const [distribuidorId, setDistribuidorId] = useState('');
  const [aprovar, setAprovar] = useState(false);
  const [renegociar, setRenegociar] = useState(false);
  const [quantidadeProduto, setQuantidadeProduto] = useState('0');
  const [addProdutoButton, setAddProdutoButton] = useState(false);

  const [checkUser, setCheckUser] = useState(false);

  const [showScreen, setShowScreen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState();

  const [showInfoMotorista, setShowInfoMotorista] = useState(false);
  const [nomeMotorista, setMotorista] = useState("");
  const [cpfMotorista, setCpfMotorista] = useState("");
  const [RgMotorista, setRgMotorista] = useState("");
  const [placaVeiculo, setPlacaVeiculo] = useState("");
  const [celular, setCelular] = useState("");

  const history = useHistory();
  const { user } = useContext(AuthContext);
  //const { language } = useContext(Language);

  const prazoRef = useRef(null);
  const produtoRef = useRef(null);
  const brandRef = useRef(null);
  const categoryRef = useRef(null);

  const freteOptions = [
    { value: "CIF (Entrega)", label: "CIF (Entrega)" },
    { value: "FOB (Retira)", label: "FOB (Retira)" },
  ];

  const abastecimentoOptions = [
    { value: "Bomba", label: "Com Bomba" },
    { value: "Descarga", label: "Sem Bomba" },
  ];

  const periodoOptions = [
    { value: "Manhã", label: "Manhã" },
    { value: "Tarde", label: "Tarde" },
    { value: "Noite", label: "Noite" },
  ];

  const clientePlaceholder = intl.formatMessage({ id: "cliente" });
  const usuarioPlaceholder = intl.formatMessage({ id: "usuario" });
  const dataEntregaDesejadaPlaceholder = intl.formatMessage({
    id: "dataEntregaDesejada",
  });
  const enderecoEntregaPlaceholder = intl.formatMessage({
    id: "enderecoEntrega",
  });
  const fretePlaceholder = intl.formatMessage({ id: "frete" });
  const modoAbastecimentoPlaceholder = intl.formatMessage({
    id: "modoAbastecimento",
  });
  const periodoDesejadoPlaceholder = intl.formatMessage({
    id: "periodoDesejado",
  });
  const horarioDesejadoPlaceholder = intl.formatMessage({
    id: "horarioDesejado",
  });

  const handleBackButton = useCallback(() => {
    if ( user.cadastro_tipo_id === 1){
      history.push("/orders/painel/v2");

    } else {
      history.push("/orders/painel");
    }
  }, [history]);

  registerLocale("pt", ptBr);

  const [highlightDaPicker, setHighlightDaPicker] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        // if (user.cadastro_tipo_id === 3) {
        //   const { data: response } = await api.get(
        //     `/cadastro/${user.cadastro_id}`
        //   );

        //   if (response.ativo === 0) {
        //     toast.warning(
        //       "Sem permissão para fazer pedido/cotação. Favor contatar a mesa de operação"
        //     );
        //     return history.push("/NotAuthorized");
        //   }
        // }

        setShowContent(false);
        const { data: date } = await api.get("/utilitarios/get-utc-time");
        const { data: responseHolidays } = await api.get(
          "/utilitarios/get-feriados"
        );
        const data = format(addDays(parseISO(date), 0), "yyyy-MM-dd");

        setMinDate(data);
        setMaxDate(addDays(parseISO(date), 90));
        setServerDate(parseISO(date));
        const formatHolidays = responseHolidays.map((item) => {
          return parseISO(item?.data);
        });

        setHighlightDaPicker([
          {
            "react-datepicker__day--highlighted-feriados": formatHolidays,
          },
        ]);

        setHolidays(formatHolidays);
        // Bloqueia  a programação do dia seguinte
        setExcludeDatesDatePicker((state) => [
          ...state,
          addDays(parseISO(date), 1),
        ]);

        const { data: response } = await api.get(`/numeracao/pedido`);
        setPedidoSistema(response.numeracao);
      } catch (error) {
        console.log(error);
      }

      if (parseInt(user.cadastro_tipo_id) === 3) {
        try {
          const { data: response } = await api.get(
            `/cadastro/${user.cadastro_id}`
          );

          if (response.ativo === 0) {
            toast.warning(
              "Sem permissão para fazer pedido/cotação. Favor contatar a mesa de operação"
            );
            return history.push("/NotAuthorized");
          }

          const { data: datas } = await api.get(
            `programacao/${user.cadastro_id}/datas-programadas`
          );

          const formmatedDataProgramadas = datas.map((elem) =>
            parseISO(elem.data_programada)
          );

          setTodosPedidosProgramados(formmatedDataProgramadas);

          setExcludeDatesDatePicker((state) => [
            ...state.concat(formmatedDataProgramadas),
          ]);

          const cliente = response?.cpf_cnpj + " - " + response?.razao_social;
          const saldo = response.dados_cobranca.saldo_credito_total;
          const limite_credito = response.dados_cobranca.limite_credito;
          // const frete = response.dados_entrega.modalidade_transporte;
          const usuarios = user.user_id;

          setOperadorId(response.mesa_operacao.operador_id);
          setSaldo(saldo);
          setLimiteCredito(limite_credito);
          setTolerancia(response.dados_cobranca.tolerancia_maxima);
          setArrayPrazo([
            {
              prazo: "",
            },
          ]);
          setCliente({
            value: response.id,
            label: cliente,
          });
          setListaEnderecos(response?.id);
          setFrete(response.dados_entrega.modalidade_transporte);

          if ( response.dados_entrega.modo_abastecimento === "Bomba") {
            setAbastecimento({
              value: response.dados_entrega.modo_abastecimento,
              label: "Com Bomba",
            });
          } else {
            setAbastecimento({
              value:  response.dados_entrega.modo_abastecimento,
              label: "Sem Bomba",
            });
          }

          setObservacoes(response.dados_entrega.observacao);
          setUsuarios(usuarios);
          setListagemProdutos([]);

        } catch (error) {
          console.log(error);
        }

      // try {
      //   const { data: response } = await api.post(`/preco/calcularpreco`, {
      //     id,
      //   });

      //   let formattedList = response.map((produto) => {
      //     const obj = {};
      //     Object.assign(obj, {
      //       id: produto.produto.id,
      //       descricao: produto.produto.descricao,
      //       valor_unitario: produto.preco?.replace(".", ","),
      //     });

      //     return obj;
      //   });

      //   setListaProdutos(formattedList);
      //   } catch (error) {
      //     console.log(error);
      //   }
      }
    })();
  }, [history, user.cadastro_id, user.cadastro_tipo_id, user.user_id]);

  const handleImage = useCallback(
    async (item) => {
      console.log(item)

      if (item) {
        swal(
          <div>
          {item?.imagem && (
              <img src={item?.imagem} alt="file" style={{ width: '-webkit-fill-available'}}/>
            )}
            <p className="infoImage">Nome: {item?.nome}</p>
            <p className="infoImage">Descrição: {item?.descricao}</p>
        </div>
        )
      } else {
        swal(
          <div>
            {produtoSelected?.imagem && (
              <img src={produtoSelected?.imagem} alt="file" style={{ width: '-webkit-fill-available'}}/>
            )}

            <p className="infoImage">Nome: {produtoSelected?.label}</p>
            <p className="infoImage">Descrição: {produtoSelected?.descricao}</p>

          </div>
        )
      }

    },
    [produtoSelected]
  );

  const handleFilterCitiesByUf = useCallback(async () => {

    try {
      const { data } = await api.post(`/produto/filter`, {
        tipo_produto: 'outros',
      });

      const arr = data.map((prod) => {
        const obj = {};

        Object.assign(obj, {
          value: String(prod.id),
          label: prod.descricao,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleChangeInput = useCallback(
    (e) => {
      let newValue;

      // Comportamento da Mascara
      const checkValueFormat = e?.target?.value.split(",");
      if (checkValueFormat?.length <= 1) {
        newValue = e?.target?.value + ",00";
      } else if (checkValueFormat[1]?.length < 2) {
        newValue = e?.target?.value + "00";
      } else if (checkValueFormat[0].length <= 2 && checkValueFormat[1]?.length < 2 ) {
        newValue = checkValueFormat[0] + ",00";
      } else if (checkValueFormat[0]?.length === 0) {
        newValue = "0,00";
      } else {
        newValue = e?.target?.value;
      }

      setQuantidadeProduto(newValue || e.target.value);
    },
    [quantidadeProduto]
  );

  const handleChangeInputExist = useCallback(
    (e, index) => {
      let newValue;

      // Comportamento da Mascara
      const checkValueFormat = e?.target?.value.split(",");
      if (checkValueFormat?.length <= 1) {
        newValue = e?.target?.value + ",00";
      } else if (checkValueFormat[1]?.length < 2) {
        newValue = e?.target?.value + "00";
      } else if (
        checkValueFormat[0].length <= 2 &&
        checkValueFormat[1]?.length < 2
      ) {
        newValue = checkValueFormat[0] + ",00";
      } else if (checkValueFormat[0]?.length === 0) {
        newValue = "0,00";
      } else {
        newValue = e?.target?.value;
      }

      listagemProdutos[index].quantidade = newValue || e.target.value;
      setListagemProdutos([...listagemProdutos]);
    },
    [listagemProdutos]
  );

  const handleLoadUsuarios = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data: response } = await api.post(`/cadastro/filter`, {
        id,
      });

      const cliente = response.data[0].users;

      setUsuarios(null);

      if (cliente.length === 0) {
        setCheckUser(true);
        toast.warning("Não tem usuários cadastrados para esse cliente.");
      } else {
        setCheckUser(false);
      }

      const user = cliente.map((item) => {
        const obj = {};

        Object.assign(obj, {
          value: item.id,
          label: item.name,
        });

        return obj;
      });
      return user;
    } catch (error) {
      console.log(error, 'load user')
    }
  }, []);

  const handleLoadEnderecos = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data: response } = await api.post(`/cadastro/filter`, {
        id,
      });

      setEndereco(null);

      const enderecos = response.data[0].enderecos;

      let enderecosFormatted = enderecos.map((endereco) => {
        const objeto = {
          value: endereco.id,
          label: endereco.logradouro + ", " + endereco.numero + " - " + endereco.municipio.nome + "/" + endereco.estado.estado + " " + endereco.cep.replace(/\D/g, '')
          .replace(/(\d{5})(\d)/, '$1-$2')
          .replace(/(-\d{3})\d+?$/, '$1')
        };

        return objeto;
      });

      setEndereco({
        value: enderecosFormatted[0].value,
        label: enderecosFormatted[0].label
      })

      return enderecosFormatted;
    } catch (error) {
      console.log(error, 'load endereco')
    }
  }, [endereco]);


  const handleCalculatePrice = useCallback(async (value, param) => {
    const data = {
      cadastro_id: cliente?.value,
      endereco_id: param === 'endereco' ? value : endereco?.value,
      produto_id: param === 'produto' ? value : produtoSelected?.value,
      quantidade: param === 'quantidade' ?
      parseFloat(
        value?.replaceAll(".", "")?.replaceAll(",", ".")
      ) :
      parseFloat(
        quantidadeProduto?.replaceAll(".", "").replaceAll(",", ".")
      ),
      prazo: param === 'prazo' ? value : arrayPrazo[0]?.prazo,
      data_entrega: param === 'data' ? value : date
    };

    try {
        const { data: response } = await api.get("/preco/calcularpreco", {
          params: data,
        });

        if (response.length !== 0){
          setLitroCIF((
            response.valor_cif_litro?.replaceAll(".", ",")
          ))
          setLitroFOB((
            response.valor_fob_litro?.replaceAll(".", ",")
          ))

          setPrevisaoEntrega(response.prazo_entrega)

        } else {
          setLitroCIF("")
          setLitroFOB("")
        }

        setDistribuidorId(response.distribuidor_id)

    } catch (error) {
      console.log(error);
    }

    if (listagemProdutos.length !== 0 && (param === 'endereco' || param === 'data' )) {
        setListagemProdutos([]);
    }

    if (listagemProdutos.length !== 0 && param === 'prazo'){
      listagemProdutos.map(async(produto, index) => {
        const data = {
          cadastro_id: cliente?.value,
          endereco_id: endereco?.value,
          produto_id: produto.produto_id,
          quantidade:  parseFloat(
            produto.quantidade?.replaceAll(".", "")?.replaceAll(",", ".")
          ),
          prazo: param === 'prazo' ? value : arrayPrazo[0]?.prazo,
          data_entrega: date
        };

        try {
          const { data: response } = await api.get("/preco/calcularpreco", {
            params: data,
          });

          listagemProdutos[index].litro_cif = response.valor_cif_litro?.replaceAll(".", ",");
          listagemProdutos[index].litro_fob = response.valor_fob_litro?.replaceAll(".", ",");
          listagemProdutos[index].distribuidor_id = response.distribuidor_id;

          setListagemProdutos([...listagemProdutos])

        } catch (error) {
          console.log(error);
        }
      })
    }
  }, [user, endereco, produtoSelected, quantidadeProduto, arrayPrazo, date, listagemProdutos, previsaoEntrega]);

  const handleLoadTaxas = useCallback(async () => {
    try {
      if (user.cadastro_tipo_id === 3) {
        const { data: response } = await api.get(
          `/cadastro/${user.cadastro_id}`
        );

        let formattedTaxasFinan =
          response.taxa_financeira.map((taxa) => {
            return {
              value: taxa.taxa_financeira_de,
              label: taxa.taxa_financeira_de,
            };
          }) || [];

        // Limita Prazos com base no prazo de pagamento do cadastro
        formattedTaxasFinan = formattedTaxasFinan.filter(
          (prazo) => prazo?.value <= response?.dados_cobranca?.prazo_pagamento
        );

        // Remove do Array prazo que ja esta sendo usado.
        formattedTaxasFinan = formattedTaxasFinan.filter((prazo) => {
          return (
            arrayPrazo.findIndex((item) => item.prazo === prazo.value) === -1
          );
        });

        formattedTaxasFinan = formattedTaxasFinan.filter(
          (prazo) => prazo.value >= 4
        );

        if (formattedTaxasFinan.length === 0) {
          toast.warning("Cliente sem prazo mínimo de 4 dias.");
        }

        // Remove prazos abaixo de 4 - Reunião 15/06/21 Solicitação Erick

        return formattedTaxasFinan;
      } else {
        if (!cliente) {
          return;
        }

        const { data: response } = await api.get(`/cadastro/${cliente?.value}`);

        let formattedTaxasFinan =
          response.taxa_financeira.map((taxa) => {
            return {
              value: taxa.taxa_financeira_de,
              label: taxa.taxa_financeira_de,
            };
          }) || [];

        // Limita Prazos com base no prazo de pagamento do cadastro
        formattedTaxasFinan = formattedTaxasFinan.filter(
          (prazo) =>
            prazo?.value <= response?.dados_cobranca?.prazo_pagamento || 0
        );

        // Remove do Array prazo que ja esta sendo usado.
        formattedTaxasFinan = formattedTaxasFinan.filter((prazo) => {
          return (
            arrayPrazo.findIndex((item) => item.prazo === prazo.value) === -1
          );
        });

        // Remove prazos abaixo de 4 - Reunião 15/06/21 Solicitação Erick
        formattedTaxasFinan = formattedTaxasFinan.filter(
          (prazo) => prazo.value >= 4
        );

        if (formattedTaxasFinan.length === 0) {
          toast.warning("Cliente sem prazo mínimo de 4 dias.");
        }

        return formattedTaxasFinan;
      }
    } catch (error) {
      console.log(error);
    }
  }, [arrayPrazo, cliente, user.cadastro_id, user.cadastro_tipo_id]);

  const handleLoadInfo = useCallback(
    async (id) => {
      setFrete("");
      setAbastecimento("");
      setObservacoes("");
      setDataProgramacao([
        {
          date: undefined,
        },
      ]);
      setHasPrazo(true);

      if (!id) {
        return;
      }

      try {
        const { data: response } = await api.get(`/cadastro/${id}`);

        const { data: datas } = await api.get(
          `programacao/${id}/datas-programadas`
        );

        const formmatedDataProgramadas = datas.map((elem) =>
          parseISO(elem.data_programada)
        );

        setTodosPedidosProgramados(formmatedDataProgramadas);

        setHighlightDaPicker([
          {
            "react-datepicker__day--highlighted-feriados": holidays,
          },
          {
            "react-datepicker__day--highlighted-programados":
              formmatedDataProgramadas,
          },
        ]);

        // Bloqueia  a programação do dia seguinte e Feriados
        setExcludeDatesDatePicker((state) => {
          return [addDays(serverDate, 1)].concat(formmatedDataProgramadas);
        });

        const saldo = response.dados_cobranca?.saldo_credito_total || "";

        const limite_credito = response.dados_cobranca.limite_credito || "";
        const prazo = response.dados_cobranca?.prazo_pagamento || 0;
        const frete = response.dados_entrega?.modalidade_transporte || "";
        const obs = response.dados_entrega?.observacao || "";

        setOperadorId(response.mesa_operacao.operador_id);
        setLimiteCredito(limite_credito);
        setTolerancia(response.dados_cobranca.tolerancia_maxima || "");

        setSaldo(saldo);

        if (prazo === "" || !prazo) {
          setHasPrazo(false);
        }

        setArrayPrazo([
          {
            prazo: "",
          },
        ]);

        setFrete(response.dados_entrega?.modalidade_transporte);
        setObservacoes(obs);

        if ( response.dados_entrega.modo_abastecimento === "Bomba") {
          setAbastecimento({
            value: response.dados_entrega.modo_abastecimento,
            label: "Com Bomba",
          });
        } else {
          setAbastecimento({
            value:  response.dados_entrega.modo_abastecimento,
            label: "Sem Bomba",
          });
        }
      } catch (error) {
        console.log(error);
      }


    },
    [holidays, serverDate]
  );

  const handleChangeInputPrazo = useCallback(
    (e, index) => {
      arrayPrazo[index].prazo = e.value;
      setArrayPrazo([...arrayPrazo]);
    },
    [arrayPrazo]
  );

  const loadProdutos = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.post("/produto/filter", {
        descricao: inputValue,
        tipo_produto: produto ? 'outros' : '',
        categoria_id:  produto ? categorySelected?.value : '',
        marca_id:  produto ?  brandSelected?.value : ''
      });

      let produtosFormatted = response.data.map((prod) => {
        const objeto = {
          value: String(prod.id),
          label: produto ? prod.nome : prod.descricao,
          marca: prod?.marca?.nome,
          categoria: prod?.categoria?.nome,
          tipo_unitario: prod?.tipo_unitario?.nome,
          imagem: prod?.imagem,
          descricao: prod.descricao
        };

        return objeto;
      });

      produtosFormatted = produtosFormatted.filter((produto) => {
        return (
          listagemProdutos.findIndex((item) => parseInt(item.produto_id) === parseInt(produto.value)) === -1
        );
      });

      return produtosFormatted;
    } catch (error) {
      console.log(error, 'array produto')
    }
  }, [listagemProdutos, categorySelected, brandSelected, produto]);

  const loadBrands = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.get(`/produto-marca?nome=${inputValue}`);

      const formattedList = response.data.map((item) => {
        const obj = {};

        Object.assign(obj, {
          value: item.id,
          label: item.nome,
        });

        return obj;
      });

      return formattedList;

    } catch (error) {
      console.log(error);
    }
  }, []);

  const loadCategories = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.get(`/produto-categoria?nome=${inputValue}`);

      const formattedList = response.data.map((item) => {
        const obj = {};

        Object.assign(obj, {
          value: item.id,
          label: item.nome,
        });

        return obj;
      });

      return formattedList;

    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeProduto = useCallback(async (e) => {
    setAddProdutoButton(true)
    listaProdutos.forEach((produto_id) => {
      if (parseInt(e) === parseInt(produto_id.id)){
      }
    })
    setAddProdutoButton(false);

  }, [listaProdutos]);

  const handleAddProduto = useCallback(() => {
    if (produtoSelected.label === "" || produtoSelected.label === undefined ){
      toast.error("Selecione um produto");
      return
    }

    if (quantidadeProduto === "" || quantidadeProduto === 0 || quantidadeProduto === "0" ){
      toast.error("Informe a quantidade");
      return
    }

    if (!aprovar && !renegociar){
      toast.error("Selecione Aprovar ou Renegociar");
      return
    }

    if (!litroCIF || !litroFOB){
      toast.error("Este produto não tem valor.");
      return
    }

    if (parseInt(litroCIF) === 0 || parseInt(litroFOB) === 0){
      toast.error("Este produto não tem valor.");
      return
    }

    setListagemProdutos([...listagemProdutos,
      {
        produto_id: parseFloat(produtoSelected.value),
        descricao: produtoSelected.descricao,
        nome: produtoSelected.label,
        quantidade: quantidadeProduto,
        tipo_unidade: produtoSelected?.tipo_unitario,
        data_entrega: dataEntrega,
        litro_cif: litroCIF?.replaceAll(",", "."),
        litro_fob: litroFOB?.replaceAll(",", "."),
        aprovar: aprovar,
        renegociar: renegociar,
        distribuidor_id: distribuidorId,
        imagem: produtoSelected?.imagem,
        prazo_entrega:  previsaoEntrega
      }
    ]);

    setProdutoSelected([]);
    setQuantidadeProduto("0");
    setPrevisaoEntrega("")
    setLitroCIF("");
    setLitroFOB("");
    setAprovar(false);
    setRenegociar(false);
    setDistribuidorId('')

  }, [listagemProdutos, produtoSelected, quantidadeProduto, litroFOB, litroCIF, aprovar, renegociar, distribuidorId, frete, previsaoEntrega]);

  const handleRemoveProduto = useCallback((position) => {
    setListagemProdutos((state) =>
      state.filter((_, index) => {
        return index !== position;
      })
    );

  }, []);

  const afterDays = (date) => {
    return date >= new Date();
  };

  const handleAddProgramDate = useCallback(() => {
    if (dataProgramacao.length >= 6) {
      return;
    }
    setDataProgramacao([...dataProgramacao, { date: undefined }]);
  }, [dataProgramacao]);

  const handleRemoveProgramDate = useCallback(
    (position) => {
      let verifyDates = [];
      setDataProgramacao((state) => {
        verifyDates = state.filter((_, index) => {
          return index !== position;
        });

        verifyDates = verifyDates
          .filter((item) => item.date !== undefined)
          .map((i) => i.date);

        return state.filter((_, index) => {
          return index !== position;
        });
      });

      setExcludeDatesDatePicker((state) => {
        const newState = [addDays(serverDate, 1)].concat(verifyDates);

        return newState;
      });
    },
    [serverDate]
  );

  const handleChangeProgramDate = useCallback(
    (e, index) => {
      dataProgramacao[index].date = e;

      setDataProgramacao([
        ...dataProgramacao.sort((a, b) => compareAsc(a.date, b.date)),
      ]);

      let verifyDates = [];
      verifyDates = dataProgramacao
        .filter((item) => item.date !== undefined)
        .map((i) => i.date);

      setExcludeDatesDatePicker((state) => {
        const newState = [addDays(serverDate, 1)].concat(verifyDates);

        return newState;
      });
    },
    [dataProgramacao, serverDate]
  );

  const handleCreate = useCallback(async () => {

    let idPedidoTeste = ""
    setLoadingButton(true);

    let hoje = moment().format("YYYY-MM-DD 00:00:00");
    let dataPedido = moment(date).format("YYYY-MM-DD 00:00:00");
    let maxDataPedido = moment().add(1, 'years').format("YYYY-MM-DD 00:00:00")


    if (!(hoje <= dataPedido) || dataPedido > maxDataPedido ){
      toast.error("Por favor, preencha uma data válida.");
      setErrors(null);
      setLoadingButton(false);
     return
    }

    let listaProdutosFormmated = listagemProdutos.map((produto) => {
      return {
        produto_id: produto.produto_id,
        valor_unitario: frete === "CIF (Entrega)" ? produto.litro_cif?.replaceAll(",", ".") :
                                       produto.litro_fob?.replaceAll(",", "."),
        quantidade:
          produto?.quantidade && (
          combustivel ?
           parseFloat(
            produto?.quantidade?.replaceAll(".", "").replaceAll(",", ".")
          ) * 1000 : produto?.quantidade),
         distribuidor_id: produto.distribuidor_id,
         tipo: produto.aprovar ? "aprovado" : "renegociado",
      };
    });

    const prazo_1 = !arrayPrazo[0]?.prazo ? 0 : parseInt(arrayPrazo[0]?.prazo);
    const prazo_2 = !arrayPrazo[1]?.prazo ? 0 : parseInt(arrayPrazo[1]?.prazo);
    const prazo_3 = !arrayPrazo[2]?.prazo ? 0 : parseInt(arrayPrazo[2]?.prazo);
    const prazo_4 = !arrayPrazo[3]?.prazo ? 0 : parseInt(arrayPrazo[3]?.prazo);
    const prazo_5 = !arrayPrazo[4]?.prazo ? 0 : parseInt(arrayPrazo[4]?.prazo);

    const arr = [prazo_1, prazo_2, prazo_3, prazo_4, prazo_5].filter(
      (i) => i !== 0
    );

    let novaArr = arr.filter((prazo, i) => arr.indexOf(prazo) === i);

    const data = {
      status_pedido: cotacao,
      cadastro_id: cliente?.value,
      user_id: usuarios?.value || usuarios,
      numero_pedido_cliente: pedidoCliente,
      numero_pedido_sistema: pedidoSistema,
      data_entrega_desejada: date,
      frete: frete,
      modo_abasteciemento: abastecimento?.value,
      periodo_desejado: periodo?.value,
      horario_desejado: "00:00:00",
      produtos: listaProdutosFormmated,
      prazo: prazo_1 !== 0 ? prazo_1 : "",
      prazos: novaArr,
      criado_por_user_id: user.user_id,
      observacao: observacoes || "",
      endereco_id: endereco.value,
      tipo_produto: combustivel ? "combustivel" : "outros"
    };


    if (user.cadastro_tipo_id === 3) {
      // Operador da mesa do cliente
      Object.assign(data, { operador_id: operadorId });
    } else if (user.cadastro_tipo_id === 1) {
      Object.assign(data, { operador_id: user.user_id });
    }

    try {
      // Cria o Pedido
      const { data: response } = await api.post("/pedido-v2", data);

      idPedidoTeste = response.id

      toast.success(<FormattedMessage id="pedidoCriadoComSucesso" />);
      toast.success("Seu e-mail está sendo enviado, aguarde.");

      if (user.cadastro_tipo_id === 1) {
        setCliente("");
        setObservacoes("");
      }
      setPedidoCliente("");

      const { data: responseNumeroPedido } = await api.get(`/numeracao/pedido`);
      setPedidoSistema(responseNumeroPedido?.numeracao);

      setDate("");
      setPeriodo("");
      setHorario("");
      setDataProgramacao([{ date: undefined }]);
      setPrevisaoEntrega(0)

      setArrayPrazo([
        {
          prazo: "",
        },
      ]);

      if (parseInt(user.cadastro_tipo_id) === 1) {
        setFrete("");
        setSaldo("");
        setLimiteCredito("");
        setObservacoes("");
        setTolerancia("");
        setAbastecimento("");
        setUsuarios("");
        setListagemProdutos([]);
        setMotorista("");
        setCpfMotorista("");
        setRgMotorista("");
        setPlacaVeiculo("");
        setCelular("");
        setEndereco("");
      }

      if (parseInt(user.cadastro_tipo_id) === 3) {
        setListagemProdutos([]);
      }

      setErrors(null);
      setLoadingButton(false);
    } catch (error) {
      console.log(error, 'envio pedido')

      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);

      if (error?.response?.status === 406) {
        toast.error("Cadastro inativo para criar pedido.");
      } else {
        toast.error(<FormattedMessage id="erroCriarRegistro" />);
      }
    }


    if (idPedidoTeste) {
      try {
        await api.get(`/email/pedido-criado/${idPedidoTeste}`);
        toast.success("Email de criação de pedido enviada com sucesso.");
      } catch (error) {
        toast.error("Erro no envio do e-mail de criação de pedido.");
      }
    }

  }, [
    combustivel,
    arrayPrazo,
    cotacao,
    cliente.value,
    usuarios,
    pedidoCliente,
    pedidoSistema,
    date,
    frete,
    abastecimento.value,
    periodo.value,
    horario,
    user.user_id,
    user.cadastro_tipo_id,
    operadorId,
    // handleLoadPedido,
    observacoes,
    listagemProdutos,
    endereco
  ]);

  if (showContent) {
    return <LoadingContent />;
  }





  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="novoPedido" />
          </h4>
        </header>

        <form onSubmit={(e) => e.preventDefault()}>
        <HeaderOrder>
        <ContainerButton>
          <button
           style={
            combustivel
              ? { backgroundColor: "#030894 "}
              : { backgroundColor: "#908C99"}
            }
            onClick={() => {
              setCombustivel(true);
              setProduto(false);
              setListagemProdutos([]);
              setProdutoSelected([]);
              setQuantidadeProduto('0')
            }}
          >
            <FormattedMessage id="combustiveis" />
          </button>
          <button
           style={
            produto
              ? { backgroundColor: "#030894 "}
              : { backgroundColor: "#908C99"}
            }
            onClick={() => {
              setCombustivel(false);
              setProduto(true);
              setListagemProdutos([]);
              setProdutoSelected([]);
              setQuantidadeProduto('0')
            }}
          >
            <FormattedMessage id="outrosProdutos" />
          </button>
          </ContainerButton>
            <div className="infoPedido">
              <InfoOrder>
                <div>
                  <p>
                    <FormattedMessage id="limiteCreditoDisponivel" />
                  </p>
                  <strong>
                    {!produto ? (
                      isNaN(limiteCredito)
                        ? (0).toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "BRL",
                          })
                        : parseFloat(
                            limiteCredito * (tolerancia / 100 + 1) || 0
                          ).toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "BRL",
                          })
                    ) : (
                      "-"
                    )}

                  </strong>
                </div>
                <div>
                  <p>
                    <FormattedMessage id="seuLimiteSaldoDisponivel" />
                  </p>
                  <strong>
                    {!produto ? (
                      isNaN(saldo)
                      ? (0).toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "BRL",
                        })
                      : parseFloat(saldo || 0).toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "BRL",
                        })
                    ) : (
                      "-"
                    )}
                  </strong>
                </div>

                <div style={{ width: "100px", marginBottom: "-5px" }}>
                  <h5>Status</h5>
                  <h5>{cotacao}</h5>
                </div>
              </InfoOrder>
            </div>
          </HeaderOrder>

            <div>
              <header className="header-two">
                <h4>
                  <FormattedMessage id="dadosPedido" />
                </h4>
              </header>
            </div>


            <div className="grid grid-template-columns-3">
            {parseInt(user.cadastro_tipo_id) === 1 ? (
              <>
                <AsyncListSelect
                  value={cliente}
                  labelPlaceholder={clientePlaceholder + " *"}
                  placeholder={<FormattedMessage id="selecionar" />}
                  onChange={(e) => {
                    setCliente(e);
                    setListaUsuarios(e?.value);
                    setListaEnderecos(e?.value)
                    handleLoadInfo(e?.value);
                    setArrayPrazo([]);
                    setListagemProdutos([]);
                    setProdutoSelected([]);
                    setQuantidadeProduto('0')
                  }}
                  myOptions={"clientesv2"}
                  cacheOptions
                  defaultOptions
                  geterror={errors?.cadastro_id}
                />

                <AsyncListSelect
                  key={listaUsuarios}
                  labelPlaceholder={usuarioPlaceholder + " *"}
                  placeholder={
                    checkUser ? (
                      <FormattedMessage id="semUsuariosCadastrados" />
                    ) : (
                      <FormattedMessage id="selecionar" />
                    )
                  }
                  value={usuarios}
                  onChange={(e) => {
                    setUsuarios(e);
                  }}
                  cacheOptions
                  defaultOptions
                  loadOptions={() => {
                    return handleLoadUsuarios(listaUsuarios);
                  }}
                  noOptionsMessage={() => (
                    <FormattedMessage id="selecioneClienteCarregar" />
                  )}
                  loadingMessage={() => <FormattedMessage id="carregando" />}
                  isSearchable={false}
                  isDisabled={checkUser}
                  geterror={errors?.user_id}
                />
              </>
            ) : (
              <AsyncListSelect
                value={cliente}
                labelPlaceholder={clientePlaceholder + " *"}
                placeholder={<FormattedMessage id="selecionar" />}
                onChange={(e) => {
                  setCliente(e);
                }}
                isDisabled
                myOptions={"clientes"}
                cacheOptions
                defaultOptions
                geterror={errors?.cadastro_id}
              />
            )}

            {arrayPrazo.length !== 0 ? (
              <>
                <FormTax>
                  <strong>
                    <FormattedMessage id="prazoPagamentoEmDias" /> *
                  </strong>
                    {arrayPrazo.map((prazo, index) => {
                      return (
                        <div key={index}>
                                <AsyncListSelect
                                  key={cliente}
                                  ref={prazoRef}
                                  value={
                                    prazo.prazo
                                      ? {
                                          value: prazo.prazo,
                                          label: prazo.prazo,
                                        }
                                      : null
                                  }
                                  placeholder={
                                    <FormattedMessage id="selecionar" />
                                  }
                                  onChange={(e) => {
                                    handleCalculatePrice(e.value, 'prazo')
                                    return handleChangeInputPrazo(e, index);
                                  }}
                                  loadOptions={handleLoadTaxas}
                                  cacheOptions
                                  defaultOptions
                                  isSearchable={false}
                                  geterror={errors?.cadastro_id}
                                />
                        </div>
                      );
                    })}
                  {!hasPrazo && (
                    <LoadingPrazos>
                      Nenhum prazo, contate a mesa de operação!
                    </LoadingPrazos>
                  )}
                </FormTax>
              </>
            ) : (
              <LoadingPrazos>Carregando prazos...</LoadingPrazos>
            )}
            </div>

            <div className="grid grid-template-columns-2">

              <AsyncListSelect
                key={listaEnderecos}
                labelPlaceholder={enderecoEntregaPlaceholder + " *"}
                placeholder={<FormattedMessage id="selecionar" />}
                value={endereco}
                onChange={(e) => {
                  setEndereco(e);
                  handleCalculatePrice(e.value, 'endereco');
                }}
                cacheOptions
                defaultOptions
                loadOptions={() => {
                  return handleLoadEnderecos(listaEnderecos);
                }}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                isSearchable={false}
                geterror={errors?.endereco_id}
              />


              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  width: "100%",
                  paddingBottom: '10px',
                }}
              >
                <InputLabel
                  name="data_entrega_desejada"
                  label={dataEntregaDesejadaPlaceholder + " *"}
                  type="date"
                  min={minDate}
                  style={{ color: "hsl(0,0%,50%)", width: "100%" }}
                  value={date}
                  onChange={(e) => {
                    e.persist();
                    setDataProgramacao([{ date: undefined }]);
                    setExcludeDatesDatePicker(() => {
                      return [addDays(serverDate, 1)]
                        .concat(todosPedidosProgramados)
                        .concat([parseISO(e.target?.value)]);
                    });
                    setDate(e.target?.value);
                    handleCalculatePrice(e.target?.value, 'data')
                  }}
                  toolTip={
                    <ButtonIcon
                      Icon={BsExclamation}
                      size={16}
                      backgroundColor={"#c02c31"}
                      placeholder={
                        "Independentemente da data desejada, a entrega respeitará o cronograma do Fornecedor."
                      }
                    />
                  }
                  geterror={errors?.data_entrega_desejada}
                />
                {/* {date && (
                  <Button
                    type="button"
                    onClick={() => setShowScreen(!showScreen)}
                    style={{ marginLeft: 20, marginTop: 10 }}
                  >
                    {<FormattedMessage id="programar" />}
                  </Button>
                )} */}
              </div>
            </div>
        </form>

        <br />

        {combustivel && (
          <FormProducts>
            <header style={{backgroundColor: "#c02c31"}}></header>
              <section>
                <li>
                  <TitleTax>
                    <FormattedMessage id="produto" />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>
                    <FormattedMessage id="quantidade" /> (M³)
                  </TitleTax>
                </li>

                <li>
                  <TitleTax>
                    <CheckboxDefault
                      type="radio"
                      name="tipoComunicacao"
                      labelName={"R$ Litro CIF"}
                      onChange={(e) => {
                        setFrete("CIF (Entrega)");
                      }}
                      checked={frete === "CIF (Entrega)"}
                    />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>

                    <CheckboxDefault
                      type="radio"
                      name="tipoComunicacao"
                      labelName={"R$ Litro FOB"}
                      onChange={(e) => {
                        setFrete("FOB (Retira)");
                      }}
                      checked={frete === "FOB (Retira)"}
                    />
                  </TitleTax>
                </li>

                <li>
                  <TitleTax>
                    <FormattedMessage id="aprovar" />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>
                    <FormattedMessage id="renegociar" />
                  </TitleTax>
                </li>
              </section>

            {!cliente ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "50px",
                }}
              >
                {/* <FiFileText size={"40px"} color="#5e5e5e" /> */}
                <h3
                  style={{
                    color: "#b30000",
                    marginLeft: "10px",
                    fontSize: "15px",
                  }}
                >
                  <FormattedMessage id="selecioneClienteCarregar" />
                </h3>
              </div>
            ) : (
              <ul>
                  <section>
                    <li>
                      <AsyncListSelect
                        key={produtoSelected}
                        value={produtoSelected}
                        placeholder={<FormattedMessage id="selecionar" />}
                        onChange={(e) => {
                          handleChangeProduto(e.value);
                          setProdutoSelected(e);
                          handleCalculatePrice(e.value, 'produto');
                        }}
                        loadOptions={loadProdutos}
                        ref={produtoRef}
                        cacheOptions
                        defaultOptions
                      />
                    </li>
                    <li>
                      <InputMaskDefault
                        mask={"volumePedido"}
                        type="text"
                        value={quantidadeProduto}
                        onChange={(e) => {
                          handleCalculatePrice(e.target.value, 'quantidade')
                          return handleChangeInput(e)
                        }}
                      />
                    </li>
                    <li>
                      <InputLabel
                        type="text"
                        name="litro_cif"
                        value={litroCIF}
                        readOnly
                      />
                    </li>
                    <li>
                      <InputLabel
                        type="text"
                        name="litro_fob"
                        value={litroFOB}
                        readOnly
                      />
                    </li>

                    <li>
                      <input
                        type="checkbox"
                        value={aprovar}
                        checked={aprovar}
                        onChange={(e) => {
                          setAprovar(!aprovar);
                          if (!aprovar) {
                            setRenegociar(false)
                          }
                        }}
                      />
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value={renegociar}
                        checked={renegociar}
                        onChange={(e) => {
                          setRenegociar(!renegociar)
                          if (!renegociar) {
                            setAprovar(false)
                          }
                        }}
                      />
                    </li>

                    <div className="produto">
                      <Button
                        onClick={(e) => {
                          handleAddProduto()
                        }}
                        backgroundColor={"#030894"}
                        placeholder={"Incluir"}
                        loading={addProdutoButton}
                      >
                        Incluir Produto
                      </Button>
                    </div>

                  </section>
                  <hr />

                {listagemProdutos.map((data, index) => {
                  return (
                    <section style={{justifyItems: "center"}} key={index}>
                      <li>
                        <p style={{ fontWeight: "600", marginTop: "10px" }}>
                          {data?.descricao}
                        </p>
                      </li>

                      <li>
                        <p style={{ marginTop: "15px" }}>{data?.quantidade?.replace(".", ",")}</p>
                      </li>

                      <li>
                        {frete === "CIF (Entrega)" ? (
                          <p style={{ marginTop: "15px" }}>{data?.litro_cif?.replace(".", ",")}</p>
                        ): (
                          <p></p>
                        )}
                      </li>

                      <li>
                      {frete === "FOB (Retira)" ? (
                        <p style={{ marginTop: "15px" }}>{data?.litro_fob?.replace(".", ",")}</p>
                      ) : (
                        <p></p>
                      )}
                      </li>

                      <li>
                        <input
                          type="checkbox"
                          value={data.aprovar}
                          checked={data.aprovar}
                          disabled
                        />
                      </li>

                      <li>
                        <input
                          type="checkbox"
                          value={data.renegociar}
                          checked={data.renegociar}
                          disabled
                        />
                      </li>

                      <div className="prazo" style={{marginTop: '9px'}}>
                        <ButtonIcon
                          Icon={FiTrash2}
                          style={{ height: "27px", width: "27px" }}
                          onClick={(e) => handleRemoveProduto(index)}
                          backgroundColor={"#030894"}
                          placeholder={"Remover"}
                          loading={addProdutoButton}
                        />
                      </div>
                    </section>
                  );
                })}
              </ul>
            )}

            <br />

            <MessageDefault
              geterror={errors?.saldo_credito_total}
              style={{ float: "right" }}
            />
          </FormProducts>
        )}

        {/* OUTROS PRODUTOS */}
        {produto && (
          <FormProducts>
             <header style={{backgroundColor: "#c02c31"}}></header>

              <div className="grid grid-template-columns-2" style={{marginBottom: 10}}>
                <AsyncListSelect
                  value={categorySelected}
                  onChange={(e) => {
                    setCategorySelected(e);
                    setFilter(!filter);
                  }}
                  loadOptions={loadCategories}
                  cacheOptions
                  defaultOptions
                  labelPlaceholder={<FormattedMessage id="categoria" />}
                  placeholder={"Selecione"}
                  loadingMessage={() => <FormattedMessage id="carregando" />}
                  isSearchable={false}
                  isClearable
                />

                <AsyncListSelect
                  key={brandSelected}
                  value={brandSelected}
                  placeholder={<FormattedMessage id="selecionar" />}
                  labelPlaceholder={"Marca"}
                  onChange={(e) => {
                    setBrandSelected(e);
                    setFilter(!filter);
                  }}
                  loadOptions={loadBrands}
                  // ref={brandRef}
                  cacheOptions
                  defaultOptions
                  isClearable={() => {
                    setCategorySelected([])
                  }}
                />

              </div>
              <nav>
               <li>

                </li>
                <li>
                  <TitleTax>
                    <FormattedMessage id="produto" />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>
                    <FormattedMessage id="tipoUnitario" />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>
                    <FormattedMessage id="quantidade" />
                  </TitleTax>
                </li>

                <li>
                  <TitleTax>
                    <CheckboxDefault
                      type="radio"
                      name="tipoComunicacao"
                      labelName={"R$ Preço CIF"}
                      onChange={(e) => {
                        setFrete("CIF (Entrega)");
                      }}
                      checked={frete === "CIF (Entrega)"}
                    />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>

                    <CheckboxDefault
                      type="radio"
                      name="tipoComunicacao"
                      labelName={"R$ Preço FOB"}
                      onChange={(e) => {
                        setFrete("FOB (Retira)");
                      }}
                      checked={frete === "FOB (Retira)"}
                    />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>
                    <FormattedMessage id="previsaoEntrega" />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>
                    <FormattedMessage id="aprovar" />
                  </TitleTax>
                </li>
                <li>
                  <TitleTax>
                    <FormattedMessage id="renegociar" />
                  </TitleTax>
                </li>
              </nav>

            {!cliente ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "50px",
                }}
              >
                {/* <FiFileText size={"40px"} color="#5e5e5e" /> */}
                <h3
                  style={{
                    color: "#b30000",
                    marginLeft: "10px",
                    fontSize: "15px",
                  }}
                >
                   <FormattedMessage id="selecioneClienteCarregar" />
                </h3>
              </div>
            ) : (
              <ul>
                  <nav>
                    <li>
                      {produtoSelected.length !== 0 ? (
                         <ButtonIcon
                         Icon={FiEye}
                         backgroundColor={"transparent"}
                         color={"#030894"}
                         onClick={() => handleImage("")}
                         placeholder={"Clique para mais detalhes do produto"}
                       />
                      ): (
                        <ButtonIcon
                        Icon={FiEye}
                        backgroundColor={"transparent"}
                        color={"#030894"}
                        placeholder={"Selecione para mais detalhes do produto"}
                        disabled
                      />
                      )}
                    </li>
                    <li>
                    <AsyncListSelect
                        key={filter}
                        value={produtoSelected}
                        placeholder={<FormattedMessage id="selecionar" />}
                        onChange={(e) => {
                          handleChangeProduto(e.value);
                          setProdutoSelected(e);
                          handleCalculatePrice(e.value, 'produto');
                        }}
                        loadOptions={loadProdutos}
                        ref={produtoRef}
                        cacheOptions
                        defaultOptions
                      />
                    </li>
                    <li>
                      <InputLabel
                        type="text"
                        name="tipo_unitario"
                        value={produtoSelected?.tipo_unitario}
                        readOnly
                      />
                    </li>
                    <li>
                      <InputLabel
                        type="number"
                        min="0"
                        max="999"
                        value={quantidadeProduto}
                        onChange={(e) => {
                          handleCalculatePrice(e.target.value, 'quantidade')
                          setQuantidadeProduto(e.target.value)
                        }}
                      />
                    </li>

                    <li>
                      <InputLabel
                        type="text"
                        name="litro_cif"
                        value={litroCIF}
                        readOnly
                      />
                    </li>
                    <li>
                      <InputLabel
                        type="text"
                        name="litro_fob"
                        value={litroFOB}
                        readOnly
                      />
                    </li>
                    <li>
                      <InputLabel
                        type="text"
                        name="previsaoEntrega"
                        value={previsaoEntrega}
                        readOnly
                      />
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value={aprovar}
                        checked={aprovar}
                        onChange={(e) => {
                          setAprovar(!aprovar);
                          if (!aprovar) {
                            setRenegociar(false)
                          }
                        }}
                      />
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value={renegociar}
                        checked={renegociar}
                        onChange={(e) => {
                          setRenegociar(!renegociar)
                          if (!renegociar) {
                            setAprovar(false)
                          }
                        }}
                      />
                    </li>

                    <div className="produto">
                      <Button
                        onClick={(e) => {
                          handleAddProduto()
                        }}
                        backgroundColor={"#030894"}
                        placeholder={"Incluir"}
                        loading={addProdutoButton}
                      >
                        Incluir Produto
                      </Button>
                    </div>

                  </nav>
                  <hr />

                {listagemProdutos.map((data, index) => {
                  return (
                    <nav style={{justifyItems: "center"}} key={index}>

                      <li>
                      <ButtonIcon
                         Icon={FiEye}
                         backgroundColor={"transparent"}
                         color={"#030894"}
                         onClick={() => handleImage(data)}
                         placeholder={"Clique para mais detalhes do produto"}
                       />
                      </li>
                      <li>
                        <p style={{ fontWeight: "600", marginTop: "10px" }}>
                          {data?.nome}
                        </p>
                      </li>

                      <li>
                        <p style={{ marginTop: "15px" }}>{data?.tipo_unidade}</p>
                      </li>

                      <li>
                        <p style={{ marginTop: "15px" }}>{data?.quantidade?.replace(".", ",")}</p>
                      </li>

                      <li>
                        {frete === "CIF (Entrega)" ? (
                          <p style={{ marginTop: "15px" }}>{data?.litro_cif?.replace(".", ",")}</p>
                        ): (
                          <p></p>
                        )}
                      </li>

                      <li>
                      {frete === "FOB (Retira)" ? (
                        <p style={{ marginTop: "15px" }}>{data?.litro_fob?.replace(".", ",")}</p>
                      ) : (
                        <p></p>
                      )}
                      </li>

                      <li>
                        <p style={{ marginTop: "15px" }}>{data?.prazo_entrega}</p>
                      </li>

                      <li>
                        <input
                          type="checkbox"
                          value={data.aprovar}
                          checked={data.aprovar}
                          disabled
                        />
                      </li>

                      <li>
                        <input
                          type="checkbox"
                          value={data.renegociar}
                          checked={data.renegociar}
                          disabled
                        />
                      </li>

                      <div className="prazo" style={{marginTop: '9px'}}>
                        <ButtonIcon
                          Icon={FiTrash2}
                          style={{ height: "27px", width: "27px" }}
                          onClick={(e) => handleRemoveProduto(index)}
                          backgroundColor={"#030894"}
                          placeholder={"Remover"}
                          loading={addProdutoButton}
                        />
                      </div>
                    </nav>
                  );
                })}
              </ul>
            )}

            <br />

            <MessageDefault
              geterror={errors?.saldo_credito_total}
              style={{ float: "right" }}
            />
          </FormProducts>
        )}

        <br />
        <br />

        <form>
        <header style={{backgroundColor: "#c02c31"}}></header>

          <div className="grid grid-template-columns-4">
            <InputLabel
              label={<FormattedMessage id="numeroPedidoSistema" />}
              type="text"
              value={pedidoSistema}
              readOnly
              geterror={errors?.numero_pedido_sistema}
            />

            <InputMaskDefault
              name="pedidoCliente"
              mask={"int15"}
              label={<FormattedMessage id="numeroPedidoCliente" />}
              onChange={(e) => setPedidoCliente(e.target.value)}
              type="text"
              value={pedidoCliente}
              geterror={errors?.numero_pedido_cliente}
            />

            {combustivel && (
               <ListSelect
                labelPlaceholder={modoAbastecimentoPlaceholder + " *"}
                placeholder={<FormattedMessage id="selecionar" />}
                value={abastecimento}
                cacheOptions
                defaultOptions
                onChange={(e) => setAbastecimento(e)}
                options={abastecimentoOptions}
                geterror={errors?.modo_abasteciemento}
              />
            )}

            <ListSelect
              labelPlaceholder={periodoDesejadoPlaceholder + " *"}
              placeholder={<FormattedMessage id="selecionar" />}
              value={periodo}
              cacheOptions
              defaultOptions
              onChange={(e) => setPeriodo(e)}
              options={periodoOptions}
              geterror={errors?.periodo_desejado}
            />
          </div>

          <div className="grid grid-template-columns-1">
            <TextArea
              maxLength={"200"}
              placeholder={"Observações - Máx. 200 caracteres"}
              value={observacoes}
              onChange={(e) => setObservacoes(e.target.value)}
            />
          </div>
        </form>

        <br/>
        <br/>
        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="enviar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
      {showScreen && (
        <ProgramOrderContainer>
          <header>
            <h3>Programar Pedido</h3>
            <FiXCircle onClick={() => setShowScreen(!showScreen)} />
          </header>
          <main>
            <header>
              <h5>Adicionar datas para programação</h5>
              <ButtonIcon
                Icon={FiPlus}
                backgroundColor={"#030894"}
                onClick={() => handleAddProgramDate()}
              />
            </header>
            <div>
              {dataProgramacao.map((item, index) => {
                return (
                  <div key={index}>
                    <strong>{index + 1}º</strong>
                    <DatePickerComponent
                      selected={dataProgramacao[index]?.date}
                      onChange={(date) => handleChangeProgramDate(date, index)}
                      locale={"pt"}
                      dateFormat={"dd/MM/yyyy"}
                      filterDate={afterDays} // Remove fim de semana
                      excludeDates={excludeDatesDatePicker}
                      highlightDates={highlightDaPicker}
                      withPortal
                      maxDate={maxDate}
                    >
                      <FooterDatePicker>
                        <div>
                          <div
                            className={
                              "react-datepicker__day--highlighted-feriados"
                            }
                          ></div>
                          <strong>Feriados</strong>
                        </div>
                        <div>
                          <div
                            className={
                              "react-datepicker__day--highlighted-programados"
                            }
                          ></div>
                          <strong>Data com pedido programados</strong>
                        </div>
                      </FooterDatePicker>
                    </DatePickerComponent>
                    <ButtonIcon
                      Icon={FiTrash2}
                      color={"#fff"}
                      onClick={() => handleRemoveProgramDate(index)}
                      backgroundColor={"#e05262"}
                      placeholder={<FormattedMessage id="excluir" />}
                    />
                  </div>
                );
              })}
            </div>
          </main>
        </ProgramOrderContainer>
      )}
    </Container>
  );
};

export default injectIntl(CreateOrders);
