import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import ListSelect from "./../../../components/ListSelect";
import InputLabel from "./../../../components/InputLabel";
import CheckboxDefault from "./../../../components/CheckboxDefault";
import ContentSpinner from "./../../../components/Spinner/Content";

import api from "./../../../services/api";
import {
  Container,
  CreateForm,
  CreateFooter,
  CheckForm,
  RadioForm,
  Image,
} from "./styles";
import Button from "./../../../components/ButtonDefault";

export const DisplayCommunication = ({ intl }) => {
  const [tipoComunicacao, setTipoComunicacao] = useState("");
  const [posicao, setPosicao] = useState("");
  const [local, setLocal] = useState("");
  const [tipo, setTipo] = useState("");
  const [link, setLink] = useState("");
  const [dataDe, setDataDe] = useState(new Date());
  const [dataAte, setDataAte] = useState("");
  const [file, setFile] = useState("");

  const [exibirSodexo, setExibirSodexo] = useState(false);
  const [exibirDistribuidor, setExibirDistribuidor] = useState(false);
  const [exibirCliente, setExibirCliente] = useState(false);

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const posicaoOptions = [
    { value: "Direita Alta", label: "Direita Alta" },
    { value: "Direita Baixa", label: "Direita Baixa" },
    { value: "Rodapé", label: "Rodapé" },
  ];

  const dataInicioPlaceholder = intl.formatMessage({ id: "dataInicio" });
  const dataFimPlaceholder = intl.formatMessage({ id: "dataFim" });

  useEffect(() => {
    if (!history.location?.state) {
      return history.push("/communication/list");
    }
    const id = history.location?.state;

    async function loadInfo() {
      try {
        const { data: response } = await api.get(`comunicacao/${id}`);

        const comunicacao = response.data[0];

        setTipoComunicacao(comunicacao?.tipo_comunicacao);

        if (comunicacao?.posicao === "Direita Alta") {
          setPosicao({
            value: comunicacao?.posicao,
            label: <FormattedMessage id="direitaAlta" />,
          });
        }

        if (comunicacao?.posicao === "Direita Baixa") {
          setPosicao({
            value: comunicacao?.posicao,
            label: <FormattedMessage id="direitaBaixa" />,
          });
        }

        if (comunicacao?.posicao === "Rodapé") {
          setPosicao({
            value: comunicacao?.posicao,
            label: <FormattedMessage id="rodape" />,
          });
        }

        if (comunicacao?.posicao === "Centro") {
          setPosicao({
            value: comunicacao?.posicao,
            label: <FormattedMessage id="centro" />,
          });
        }

        setExibirCliente(comunicacao?.exibir_clientes === 1 ? true : false);
        setExibirDistribuidor(
          comunicacao?.exibir_distribuidor === 1 ? true : false
        );
        setExibirSodexo(comunicacao?.exibir_sodexo === 1 ? true : false);

        setLocal(comunicacao?.local);
        setTipo(comunicacao?.tipo);
        setLink(comunicacao?.link);

        setFile(comunicacao?.link_arquivo);

        setDataDe(comunicacao?.data_inicio);
        setDataAte(comunicacao?.data_fim);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    loadInfo();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirComunicado" />
          </h4>
        </header>
        {loading ? (
          <ContentSpinner />
        ) : (
          <form>
            <RadioForm>
              <main>
                <div>
                  <CheckboxDefault
                    type="radio"
                    name="tipoComunicacao"
                    labelName={<FormattedMessage id="banner" />}
                    value={tipoComunicacao}
                    onChange={() => setTipoComunicacao("Banner")}
                    checked={tipoComunicacao === "Banner" ? true : false}
                    disabled
                  />
                </div>
                <div>
                  <CheckboxDefault
                    type="radio"
                    name="tipoComunicacao"
                    labelName="PopUp"
                    value={tipoComunicacao}
                    onChange={() => setTipoComunicacao("PopUp")}
                    checked={tipoComunicacao === "PopUp" ? true : false}
                    disabled
                  />
                </div>
              </main>
            </RadioForm>

            <ListSelect
              name="posicao"
              labelPlaceholder={<FormattedMessage id="posicao" />}
              placeholder={<FormattedMessage id="selecionar" />}
              cacheOptions
              defaultOptions
              options={posicaoOptions}
              value={posicao}
              onChange={(e) => setPosicao(e)}
              isDisabled={true}
            />

            <CheckForm>
              <main>
                <p>
                  <FormattedMessage id="local" />:
                </p>
                <div>
                  <CheckboxDefault
                    type="radio"
                    name="local"
                    labelName="Home"
                    value={local}
                    onChange={() => setLocal("Home")}
                    checked={local === "Home" ? true : false}
                    disabled
                  />
                </div>
                <div>
                  <CheckboxDefault
                    type="radio"
                    name="local"
                    value={local}
                    labelName="Login"
                    onChange={() => setLocal("Login")}
                    checked={local === "Login" ? true : false}
                    disabled
                  />
                </div>
              </main>
            </CheckForm>

            <CheckForm>
              <main>
                <p>
                  <FormattedMessage id="tipo" />:
                </p>
                <div>
                  <CheckboxDefault
                    type="radio"
                    name="tipo"
                    value={tipo}
                    labelName={<FormattedMessage id="informativo" />}
                    onChange={() => setTipo("Informativo")}
                    checked={tipo === "Informativo" ? true : false}
                    disabled
                  />
                </div>
                <div>
                  <CheckboxDefault
                    type="radio"
                    name="tipo"
                    value={tipo}
                    labelName={<FormattedMessage id="interativo" />}
                    onChange={() => setTipo("Interativo")}
                    checked={tipo === "Interativo" ? true : false}
                    disabled
                  />
                </div>
              </main>

              <div style={{ width: "100%", margiTop: "-20px" }}>
                <InputLabel
                  label={"Link:"}
                  name="link"
                  type="text"
                  value={link}
                  maxLength={80}
                  onChange={(e) => setLink(e.target.value)}
                  readOnly
                />
              </div>
            </CheckForm>

            <Image>
              <label>
                <FormattedMessage id="imagem" />:
              </label>
              <div>
                {posicao.value === "Rodapé" || tipoComunicacao === "PopUp" ? (
                  <img className="rodape" src={file} alt="file" />
                ) : (
                  <img src={file} alt="file" />
                )}
              </div>
            </Image>

            <CheckForm>
              <main>
                <p>
                  <FormattedMessage id="exibirPara" />:
                </p>
                <div>
                  <input
                    type="checkbox"
                    value={<FormattedMessage id="todos" />}
                    checked={
                      exibirSodexo &&
                      exibirCliente &&
                      exibirDistribuidor === true
                        ? true
                        : false
                    }
                    disabled
                  />
                  <label>
                    <FormattedMessage id="todos" />
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    value={exibirSodexo}
                    checked={exibirSodexo === true ? true : false}
                    disabled
                  />
                  <label>Graal</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    value={exibirCliente}
                    checked={exibirCliente === true ? true : false}
                    disabled
                  />
                  <label>
                    <FormattedMessage id="clientes" />
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    value={exibirDistribuidor}
                    checked={exibirDistribuidor === true ? true : false}
                    disabled
                  />
                  <label>
                    <FormattedMessage id="distribuidores" />
                  </label>
                </div>
              </main>
            </CheckForm>

            <div className="sideBySide">
              <InputLabel
                name="date"
                label={dataInicioPlaceholder}
            required={true}
                type="date"
                style={{ color: "hsl(0,0%,50%)" }}
                value={dataDe}
                onChange={(e) => setDataDe(e.target.value)}
                readOnly
              />
            </div>

            <div className="sideBySideTwo">
              <InputLabel
                name="date"
                label={dataFimPlaceholder}
            required={true}
                type="date"
                style={{ color: "hsl(0,0%,50%)" }}
                value={dataAte}
                onChange={(e) => setDataAte(e.target.value)}
                readOnly
              />
            </div>
          </form>
        )}

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayCommunication);
