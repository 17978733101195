import styled, { keyframes } from "styled-components";
import { Tab } from "react-tabs";
import DatePicker from "react-datepicker";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }
`;

export const Container = styled.div`
  width: auto;

  position: relative;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;

  overflow: hidden;

  @media screen and (max-width: 865px) {
    button {
      width: 70px !important;
    }
  }

  main {
    height: 100%;
    margin: 0 15px;

    margin-bottom: 20px;

    ::-webkit-scrollbar-track {
      height: 6px;
      background-color: #cecece;
    }
    ::-webkit-scrollbar {
      height: 6px;
      width: 5px;
      background: #cecece;
    }
    ::-webkit-scrollbar-thumb {
      height: 6px;
      /* border-radius: 5px; */
      background: #030894;
    }
    table {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      position: relative;
      overflow-x: scroll;
      overflow-y: auto;
      thead {
        z-index: 99999;
        tr {
          th {
            padding: 5px;
            position: -webkit-sticky;
            position: sticky;
            background-color: #030894;
            color: #fff;
            z-index: 999;
            text-align: center;
            border: 1px solid #dddddd;
          }

          //Tamanho das Colunas
          > :nth-child(1) {
            width: 90px;
          }

          > :nth-child(2) {
            width: 110px;
          }

          > :nth-child(3) {
            width: 80px;
          }

          > :nth-child(4) {
            width: 100px;
          }

          > :nth-child(5) {
            width: 100px;
          }

          > :nth-child(6) {
            width: 230px;
          }

          > :nth-child(7) {
            width: 160px;
          }

          > :nth-child(8) {
            width: 100px;
          }

          > :nth-child(9) {
            width: 100px;
          }

          > :nth-child(10) {
            width: 100px;
          }

          > :nth-child(11) {
            width: 100px;
          }
          > :nth-child(12) {
            width: 100px;
          }
        }
      }

      tbody {
        tr {
          td {
            position: relative;
            border: 1px solid #dddddd;
            text-align: center;
            font-size: 11px;
            padding: 5px;
            word-break: break-word;
          }
        }

        tr {
          background-color: #f4f4f4;
        }

        tr:disabled {
          background-color: grey;
        }

        tr:nth-child(even) {
          background-color: #fff;
        }
      }
    }
  }
`;

export const Content = styled.div`
  height: 350px;
  width: 100%;
  overflow: scroll;

  ::-webkit-scrollbar-track {
    height: 6px;
    background-color: #cecece;
  }
  ::-webkit-scrollbar {
    height: 6px;
    width: 5px;
    background: #cecece;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    background: #030894;
  }
`;

export const CompanyContainer = styled.div`
  padding: 0 15px;
  z-index: 9999;

  > footer {
    display: grid;
    grid-template-columns: 150px 150px 150px 170px 150px;
    grid-gap: 5px;

    > :last-child {
      margin-top: 18px;
      /* margin-left: 10px; */
    }
  }

  @media screen and (max-width: 865px) {
    > footer {
      display: grid;
      grid-template-columns: 150px 150px;
      grid-gap: 5px;

      > :last-child {
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
`;

export const CreateForm = styled.div`
  width: 100%;
  /* background-color: #f8f8f8; */
  padding-bottom: 5px;

  border-radius: 10px;

  > header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #030894;
    margin-bottom: 10px;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;

    > h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }
`;

export const TabStyled = styled(Tab)`
  background: ${(props) => props.selected && "#FFD600"};
  color: ${(props) => (props.selected ? "#030894" : "#030894")};
  font-weight: ${(props) => (props.selected ? 'bold' : "")};
  border: 1px solid #030894;
  border-radius: 0;
`;

export const ContainerPedidoLink = styled.div`
  a {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  svg {
    height: 14px;
    width: 14px;
    color: #27262a;
  }
`;

export const DatePickerComponent = styled(DatePicker).attrs()`
  height: 25px;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  text-align: center;

  color: black;

  .react-datepicker__day {
    color: black !important;
  }
`;

export const DateContainer = styled.div`
  text-align: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }
`;
